import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import { HttpClient as HttpClientIntegration } from "@sentry/integrations";

export const initializeSentry = () => {
  /*

TODO: Look into using "captureUserFeedback" to capture user feedback from Sentry
https://docs.sentry.io/platforms/javascript/guides/react/enriching-events/user-feedback/

Sentry also offers the ability to track the performance of your app's components
https://docs.sentry.io/platforms/javascript/guides/react/features/component-tracking/?original_referrer=https%3A%2F%2Fdocs.sentry.io%2Fplatforms%2Fjavascript%2Fguides%2Freact%2Ffeatures%2Ferror-boundary%2F

We can look into use CaptureConsole integration if we want console logs / info / warning
https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/plugin/?original_referrer=https%3A%2F%2Fdocs.sentry.io%2Fplatforms%2Fjavascript%2Fguides%2Freact%2Ffeatures%2Ferror-boundary%2F#captureconsole
*/
  Sentry.init({
    dsn: "https://005fe01b611b4314bb9a2e8cdf50a107@o4504969350348800.ingest.sentry.io/4505042477776896",
    environment: process.env.NODE_ENV,
    enabled: process.env.NODE_ENV !== "development",
    integrations: [
      new Sentry.BrowserTracing({
        // See docs for support of different versions of variation of react router
        // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
      new Sentry.Replay(),
      new HttpClientIntegration(),
    ],
    // in the future, we can set the `release` when we have specific releases of our app

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    // This is preventing CORS errors from graphql endpoint too
    tracePropagationTargets: [
      "localhost:1337",
      "https://app.tangible-api.com",
      "https://app.tangiblematerials.com",
    ],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
};
