import { Alert, Typography } from "@mui/material";
import _ from "lodash";
import React, { ForwardedRef } from "react";

export const ServerErrors = React.forwardRef(
  (
    { serverErrors }: { serverErrors: Error[] },
    errorsRef: ForwardedRef<HTMLDivElement>
  ) => {
    if (_.isEmpty(serverErrors)) return null;

    return (
      <div id="errors" ref={errorsRef}>
        <Typography color="error" fontSize="small">
          There were some errors:
        </Typography>
        <div className="server-error-messages">
          {serverErrors.map((error, index) => (
            <Alert key={index} severity="error">
              {error?.message}
            </Alert>
          ))}
        </div>
      </div>
    );
  }
);
