import { PropsWithChildren } from "react";
import { createTheme, ThemeProvider } from "@mui/material";
import { HTML5Backend } from "react-dnd-html5-backend";
import { ErrorBoundary as SentryErrorBoundary } from "@sentry/react";
import { BrowserRouter } from "react-router-dom";

import MyApolloProvider from "./ApolloClient/CustomApolloProvider";
import CatchAllErrorFallback from "./components/CatchAllError";

import { appDotTangibleTheme } from "./themes";
import { initializeSentry } from "./utils/tooling";
import {
  UserAndOrganizationContext,
  useUserAndOrganizationContext,
} from "context/UserAndOrganizationContext";
import { UnauthorizedBoundary } from "context/UnauthorizedBoundary";
import { SnackbarContext, useSnackbar } from "context/SnackbarContext";
import { LoggedOutRoutes } from "./constants";

import Main from "./Main";
import "./App.scss";
import "./styles/_colors.scss";

const customTheme = createTheme(appDotTangibleTheme);

initializeSentry();

const ApolloConsumer = ({ children }: PropsWithChildren) => {
  const [value, methods] = useUserAndOrganizationContext();

  return (
    <UserAndOrganizationContext.Provider value={[value, methods]}>
      {children}
    </UserAndOrganizationContext.Provider>
  );
};

const App = () => {
  const snackbar = useSnackbar();
  const handleUnauthorized = (/*response: ErrorResponse*/) => {
    snackbar.flashMessage("It's been a while; you'll need to login again.");
  };

  return (
    <SentryErrorBoundary
      fallback={(props) => (
        <div id="main-content">
          <CatchAllErrorFallback {...props} />
        </div>
      )}
    >
      <BrowserRouter>
        <ThemeProvider theme={customTheme}>
          <UnauthorizedBoundary
            logoutRoute={LoggedOutRoutes.Login}
            handleUnauthorized={handleUnauthorized}
          >
            <SnackbarContext.Provider value={snackbar}>
              <MyApolloProvider>
                <ApolloConsumer>
                  <Main />
                </ApolloConsumer>
              </MyApolloProvider>
            </SnackbarContext.Provider>
          </UnauthorizedBoundary>
        </ThemeProvider>
      </BrowserRouter>
    </SentryErrorBoundary>
  );
};

export default App;
