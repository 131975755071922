import {
  ChangeEvent,
  KeyboardEventHandler,
  useCallback,
  useState,
} from "react";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import { Search as SearchIcon, Clear as ClearIcon } from "@mui/icons-material";
import "./SearchBar.scss";
import { IconButton } from "@mui/material";
import _ from "lodash";

interface Props {
  onSearch: (searchTerm: string) => void;
  defaultValue?: string;
  hideClearButton?: boolean;
  onChange?: (searchTerm: string) => void;
  placeholder?: string;
  value?: string;
}

const SearchBar = ({
  onSearch,
  onChange,
  value,
  placeholder = "Search",
  defaultValue = "",
  hideClearButton = false,
}: Props) => {
  const [localValue, setLocalValue] = useState(defaultValue);

  const keyPress: KeyboardEventHandler<HTMLInputElement> = (event) => {
    if (event.key === "Enter") {
      onSearch(event.target.value);
    }
  };

  const handleIconButtonClick = useCallback(() => {
    if (onChange) {
      onChange(defaultValue);
    } else {
      setLocalValue(defaultValue);
    }
    onSearch(defaultValue);
  }, [onChange, onSearch, defaultValue, setLocalValue]);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        onChange(e.target.value);
      } else {
        setLocalValue(e.target.value);
      }
    },
    [onChange, setLocalValue]
  );

  if (defaultValue && value) {
    console.warn(
      "SearchBar component should either be controlled or uncontrolled."
    );
  }

  return (
    <TextField
      className="search-bar"
      color="info"
      size="small"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              disabled={hideClearButton || _.isEmpty(value || localValue)}
              size="small"
              aria-label="Clear"
              onClick={handleIconButtonClick}
            >
              <ClearIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
      onKeyDown={keyPress}
      onChange={handleChange}
      placeholder={placeholder}
      variant="outlined"
      value={value || localValue || defaultValue}
    />
  );
};

export default SearchBar;
