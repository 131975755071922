import _ from "lodash";
import { Selection, BaseType, select } from "d3-selection";
import clsx from "clsx";

type GenericSelection<D> = Selection<BaseType, D, BaseType, any>;

export const addClass = (
  selectedElement: GenericSelection<any>,
  className: string
) => {
  const existingClasses = _.split(selectedElement.attr("class"), " ");
  selectedElement.attr("class", clsx(_.uniq([...existingClasses, className])));
};

export const removeClass = (
  selectedElement: GenericSelection<any>,
  className: string
) => {
  const existingClasses = _.split(selectedElement.attr("class"), " ");
  selectedElement.attr(
    "class",
    clsx(_.filter(existingClasses, (c) => c !== className))
  );
};

const setTooltipVisibility = <D>(
  tooltip: GenericSelection<D> | null,
  visible: boolean
) => {
  if (tooltip) {
    if (visible) {
      addClass(tooltip, "display-visible");
      removeClass(tooltip, "display-hidden");
    } else {
      addClass(tooltip, "display-hidden");
      removeClass(tooltip, "display-visible");
    }
  }
};

export const setupTooltip = <D>(
  // we are accepting multiple selections, so we can attach the same tooltip to multiple elements (e.g. axis labels and bars)
  selections: GenericSelection<D>[],
  tooltip: GenericSelection<unknown>,
  getTooltipContent: (data: any) => string,
  containerDimensions: { height: number; width: number },
  // may want to pass in full dataset, e.g. rather than just use the individual datum attached to the selection
  dataOverride?: any,
  difference = 4
) => {
  _.map(selections, (selection) =>
    selection
      .on("mouseover", function (e, d) {
        const node = select(this);
        addClass(node, "hovered");

        setTooltipVisibility(tooltip, true);
        tooltip.html(getTooltipContent(dataOverride || d));
      })
      .on("mousemove", function (e) {
        const { width: tooltipWidth, height: tooltipHeight } = (
          tooltip.node() as HTMLElement
        ).getBoundingClientRect();

        if (tooltip.node()) {
          let left = e.offsetX;
          let top = e.offsetY;
          if (top > containerDimensions.height - tooltipHeight) {
            top = e.offsetY - tooltipHeight - 5;
          }
          if (left > containerDimensions.width - tooltipWidth) {
            left = e.offsetX - tooltipWidth - 5;
          }
          tooltip
            .style("left", `${left + difference}px`)
            .style("top", `${top + difference}px`);
        }
      })
      .on("mouseout", function () {
        const node = select(this);
        removeClass(node, "hovered");
        setTooltipVisibility(tooltip, false);
      })
  );
};

export interface StackingDataFields {
  percent: number;
  trailingCumulative: number;
}

export const transformDataForStacking = <D extends Record<string, any>>(
  data: D[],
  valueAccessor: string = "value"
): (D & StackingDataFields)[] => {
  // we want % to be based on only positive values
  const totalValue = _.sumBy(data, (d) =>
    _.get(d, valueAccessor) > 0 ? _.get(d, valueAccessor) : 0
  );
  let cumulativeTotal = 0;
  return _.map(data, (d) => {
    const value = _.get(d, valueAccessor);
    let percent = (value / totalValue) * 100;
    if (_.isNaN(percent)) {
      percent = 0;
    }
    cumulativeTotal += Math.abs(value);
    return {
      ...d,
      percent,
      trailingCumulative: cumulativeTotal - Math.abs(value),
    };
  });
};
