import _ from "lodash";
import { Dispatch, Fragment, useMemo } from "react";
import MultiSelectFilter from "./MultiSelectFilter";
import { TaxonomyFilter } from "./MultiSelectFilter/types";

import {
  CertificationTypeEnum,
  ProductFilter,
  ProductFilterInput,
} from "gql/graphql";
import {
  extractMultiSelectOptions,
  formatNewFiltersState,
  getSelectedValues,
} from "./utils";
import CollapsableFiltersSection from "./CollapsableFiltersSection";
import CheckboxFilterCollection from "./CheckboxFilterCollection";

interface Props {
  filtersAvailable: ProductFilter[];
  filtersState: ProductFilterInput;
  sectionTitle: string;
  setFiltersState: Dispatch<React.SetStateAction<ProductFilterInput>>;
}

const EPD_TYPES = [
  CertificationTypeEnum.IndustryWideEpd,
  CertificationTypeEnum.ProductEpd,
];

const ProductFiltersSection = ({
  filtersAvailable,
  filtersState,
  sectionTitle,
  setFiltersState,
}: Props) => {
  const {
    ID,
    ENUM: checkboxFilters,
    STRING,
  } = _.groupBy(filtersAvailable, "type");

  const multiSelectFilters = [...ID, ...STRING];

  // We can change this in the future when we have more than one checkbox filter
  // Right now checkboxFilters is only length of 1 (Certifications)
  const [epdTypes, allOtherCertifications] = useMemo(() => {
    const certificationFilters = _.find(checkboxFilters, {
      displayName: "Certification",
    });
    return _.chain(certificationFilters?.availableValues.nodes)
      .map((f) => ({ ...f, type: "ENUM" }))
      .partition((f) =>
        _.includes(EPD_TYPES, (f?.asAttribute as any)?.stringValue)
      )
      .value();
  }, [checkboxFilters]);

  return (
    <CollapsableFiltersSection sectionTitle={sectionTitle}>
      <Fragment>
        {_.map(multiSelectFilters, (mSf) => {
          const availableValues = extractMultiSelectOptions(mSf);
          const selectedValues = getSelectedValues(mSf, filtersState);
          return (
            <MultiSelectFilter
              key={mSf.displayName}
              options={availableValues}
              selectedOptions={selectedValues}
              taxonomyType={_.capitalize(mSf.displayName as string)}
              onChange={(selectedOptions: TaxonomyFilter[]) => {
                const newFilterState = formatNewFiltersState(
                  mSf.displayName as string,
                  filtersState,
                  selectedOptions
                );

                setFiltersState(newFilterState as ProductFilterInput);
              }}
            />
          );
        })}
        <CheckboxFilterCollection
          collectionName="EPD Type"
          filters={epdTypes}
          filterStateKey="certifiedWith"
          filtersState={filtersState}
          setFiltersState={setFiltersState}
        />
        <CheckboxFilterCollection
          collectionName="Certifications and Documents"
          filters={allOtherCertifications}
          filterStateKey="certifiedWith"
          filtersState={filtersState}
          setFiltersState={setFiltersState}
        />
      </Fragment>
    </CollapsableFiltersSection>
  );
};

export default ProductFiltersSection;
