import {
  Box,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography,
} from "@mui/material";
import { User } from "@sentry/react";
import { Organization } from "gql/graphql";
import { PropsWithChildren } from "react";

import "./DisplayTable.scss";

export const DisplayTable = ({
  children,
  organization,
  user,
}: {
  organization?: Organization;
  user?: User;
} & PropsWithChildren) => (
  <Box className="display-table">
    <Table>
      <TableBody>
        <TableRow>
          <TableCell>
            <Typography variant="body2" id="display-table-name">
              Name
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant="body2" aria-labelledby="display-table-name">
              {user?.name}
            </Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Typography variant="body2" id="display-table-email">
              Email
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant="body2" aria-labelledby="display-table-email">
              {user?.email}
            </Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Typography
              variant="body2"
              aria-labelledby="display-table-organization-name"
            >
              Organization
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant="body2" id="display-table-organization-name">
              {organization?.name}
            </Typography>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
    {children}
  </Box>
);
