import _ from "lodash";
import { InMemoryCache } from "@apollo/client";

export const mergeOnField = (
  existing: any = [],
  incoming: any,
  field: string,
  { readField }: any
) => {
  const existingIdSet = new Set(
    existing.map((project: any) => readField(field, project))
  );

  const nodes = incoming.nodes || _.map(incoming.edges, "node");

  const nonDuplicateIncomingNodes = _.reject(nodes, (project: any) =>
    existingIdSet.has(readField(field, project))
  );

  return _.concat(existing, nonDuplicateIncomingNodes);
};

export const cache = new InMemoryCache({
  typePolicies: {
    Project: {
      keyFields: ["slug"],
      fields: {
        products: {
          keyArgs: ["id"],
        },
        address: {
          merge(existing, incoming, { mergeObjects }) {
            return mergeObjects(existing, incoming);
          },
        },
        recommendations: {
          keyArgs: false,
        },
      },
    },
    ProjectRecommendationConnection: {
      keyFields: false,
    },
    Report: {
      merge(existing, incoming, { mergeObjects }) {
        return mergeObjects(existing, incoming);
      },
    },
    Catalog: {
      keyFields: ["__typename"],
    },
    Portfolio: {
      keyFields: false,
    },
    Query: {
      fields: {
        // caching properly
        // https://www.apollographql.com/docs/react/pagination/cursor-based/#keeping-cursors-separate-from-items
        products: {
          keyArgs: ["filter"],
        },
        portfolio: {
          keyArgs: false,
          // Attempting to ensure portfolio is never cached,
          // but doesn't work in all scenarios. Safest to use `no-cache` policy when querying.
          merge: (existing, incoming) => incoming,
        },
        projects: {
          keyArgs: false,
          merge(existing: any[], incoming: any, { args, readField }) {
            return mergeOnField(existing, incoming, "slug", {
              args,
              readField,
            });
          },
        },
        users: {
          keyArgs: false,
          merge(existing = [], incoming) {
            const edges = [..._.get(existing, "edges", []), ...incoming.edges];
            const uniqueEdges = _.uniqBy(edges, "node.__ref");
            return {
              edges: uniqueEdges,
            };
          },
        },
      },
    },
  },
});
