import { PropsWithChildren, useContext, useMemo } from "react";
import { ValidateResult } from "react-hook-form";
import { InvalidContext } from "../../Context/InvalidContext";
import { Box, Fade, Typography } from "@mui/material";
import _ from "lodash";
import {
  ErrorMessage,
  Props as ErrorMessageProps,
} from "@hookform/error-message";

import "./CustomErrorMessages.scss";

const Blink = ({ children }: PropsWithChildren) => {
  return <div className="blinking">{children}</div>;
};

type CustomMessages = {
  [keyPath: string]: string | { [keyPath: string]: string };
};

export const CustomErrorMessage = ({
  message,
  name,
  type,
  customMessages = {},
}: {
  message: ValidateResult;
  name: string;
  type: string;
  customMessages?: CustomMessages;
}) => {
  const { count } = useContext(InvalidContext);

  const CustomTransition = useMemo(() => (count === 1 ? Fade : Blink), [count]);

  const generatedMessage: string | undefined = useMemo(() => {
    const customMessage = _.get(customMessages, type) as string | undefined;
    if (customMessage) return customMessage;

    return `${_.capitalize(_.startCase(name))} is ${type}`;
  }, [name, type, customMessages]);

  return (
    <CustomTransition in appear>
      <Typography
        key={type}
        variant="body2"
        color="error"
        data-testid={`invalid-${count}`}
      >
        {_.isBoolean(message) ? generatedMessage : message}.
      </Typography>
    </CustomTransition>
  );
};

const Container = ({ children }: PropsWithChildren) => (
  <Box pt={1} className="custom-error-messages" children={children} />
);

export const CustomErrorMessages = ({
  name,
  errors,
  customMessages,
  holdPlace = true,
}: Required<Pick<ErrorMessageProps<any, any>, "name" | "errors">> & {
  customMessages?: CustomMessages;
  holdPlace?: boolean;
}) => {
  const fieldHasErrors = _.get(errors, name);
  if (!fieldHasErrors) {
    if (!holdPlace) return null;
    return <Container />;
  }
  return (
    <ErrorMessage
      name={name}
      errors={errors}
      render={({ messages }) =>
        messages && (
          <Container>
            {_.entries(messages).map(([type, message]) => (
              <CustomErrorMessage
                key={type}
                {...{ message, name, type, customMessages }}
              />
            ))}
          </Container>
        )
      }
    />
  );
};
