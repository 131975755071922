import { SyntheticEvent, useCallback } from "react";
import { FormControlLabel, Switch, Typography } from "@mui/material";

interface Props {
  handleToggleChange: (checked: boolean) => void;
  toggledOn: boolean;
}
const HideExpiredEPDsToggle = ({ toggledOn, handleToggleChange }: Props) => {
  const onChange = useCallback(
    (e: SyntheticEvent, checked: boolean) => handleToggleChange(checked),
    [handleToggleChange]
  );
  return (
    <FormControlLabel
      className="hide-expired-epds-switch"
      control={<Switch size="small" />}
      label={<Typography className="caption">Hide expired EPDs</Typography>}
      checked={toggledOn}
      onChange={onChange}
    />
  );
};

export default HideExpiredEPDsToggle;
