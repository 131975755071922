import { Box, Button, Drawer, DrawerProps, Paper } from "@mui/material";
import { UserCell } from "./UserCell";
import { Organization, User } from "gql/graphql";
import { DeleteOutline, EditOutlined } from "@mui/icons-material";

import { EditUserForm } from "./EditUserForm";

import "./UserDrawer.scss";
import { FormEvent, MouseEventHandler, useCallback, useContext } from "react";
import { DisplayTable } from "./DisplayTable";
import UserAndOrganizationContext from "context/UserAndOrganizationContext";
import { UserManagementMode } from "../Users";

interface UserDrawerProps extends DrawerProps {
  handleEvent: (name: UserManagementMode, user: Partial<User>) => void;
  onClose: (event: MouseEvent | TouchEvent | FormEvent) => void;
  mode?: UserManagementMode;
  organization?: Organization;
  user?: User;
}

const defaultDrawerProps: DrawerProps = {
  anchor: "right",
};

export const UserDrawer = ({
  open,
  onClose,
  handleEvent,
  organization,
  user,
  mode,
  ...drawerProps
}: UserDrawerProps) => {
  const wrappedHandler = useCallback(
    (name: UserManagementMode) =>
      (_event: React.MouseEvent<HTMLButtonElement>) => {
        handleEvent(name, user!);
      },
    [handleEvent, user]
  );
  const afterSubmit = useCallback(
    (event: FormEvent, _savedUser: Partial<User>) => {
      onClose(event);
    },
    [onClose]
  );
  const [{ currentUser }] = useContext(UserAndOrganizationContext);
  const isSelf = user?.id === currentUser?.id;
  return (
    <Drawer
      elevation={2}
      className="user-drawer"
      open={open}
      ModalProps={{
        onClose,
      }}
      {...defaultDrawerProps}
      {...drawerProps}
    >
      <Paper elevation={0}>
        <Box className="user-drawer-content" p={4}>
          {user ? (
            <>
              <UserCell
                clickable={false}
                size="large"
                name={user.name}
                email={user.email}
              />
              {mode === UserManagementMode.Show ? (
                <DisplayTable user={user} organization={organization}>
                  <ButtonsForShow
                    onDelete={
                      isSelf
                        ? undefined
                        : wrappedHandler(UserManagementMode.Delete)
                    }
                    onEdit={wrappedHandler(UserManagementMode.Edit)}
                  />
                </DisplayTable>
              ) : mode === UserManagementMode.Edit ? (
                <EditUserForm user={user} afterSubmit={afterSubmit}>
                  <ButtonsForEdit
                    onShow={wrappedHandler(UserManagementMode.Show)}
                  />
                </EditUserForm>
              ) : null}
            </>
          ) : null}
        </Box>
      </Paper>
    </Drawer>
  );
};

const ButtonsForShow = ({
  onEdit,
  onDelete,
}: {
  onEdit: MouseEventHandler<HTMLButtonElement>;
  onDelete?: MouseEventHandler<HTMLButtonElement>;
}) => (
  <Box display="flex" flexDirection="row" gap={2}>
    <Button
      fullWidth
      disableElevation
      variant="contained"
      color="primary"
      onClick={onEdit}
      startIcon={<EditOutlined />}
    >
      Edit profile
    </Button>
    {onDelete ? (
      <Button
        fullWidth
        disableElevation
        className="destructive"
        variant="contained"
        color="secondary"
        onClick={onDelete}
        startIcon={<DeleteOutline />}
      >
        Delete user
      </Button>
    ) : null}
  </Box>
);

const ButtonsForEdit = ({
  onShow,
}: {
  onShow: MouseEventHandler<HTMLButtonElement>;
}) => (
  <Box display="flex" flexDirection="row" gap={2}>
    <Button
      disableElevation
      fullWidth
      color="secondary"
      variant="contained"
      onClick={onShow}
    >
      Cancel
    </Button>
    <Button
      disableElevation
      fullWidth
      type="submit"
      color="primary"
      variant="contained"
    >
      Save
    </Button>
  </Box>
);
