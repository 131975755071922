import {
  Box,
  BoxProps,
  Button,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";

import "./ProfileForm.scss";
import { FormEvent, useCallback } from "react";
import { useMutation } from "@apollo/client";
import { UPDATE_USER } from "graphql/mutations/users";
import { User } from "gql/graphql";

interface ProfileFormProps extends BoxProps {
  afterUpdate?: (data: Partial<User>) => void;
  email?: string;
  id?: string;
  name?: string;
  organizationName?: string;
}

export const ProfileForm = (props: ProfileFormProps) => {
  const { id, name, email, organizationName, afterUpdate, ...boxProps } = props;

  const [updateUser] = useMutation(UPDATE_USER);
  const saveUser = useCallback(
    (e: FormEvent) => {
      const form = e.target as HTMLFormElement;
      const values = new FormData(form);
      const user = {
        name: values.get("fullName") as string,
      };
      const toSave = { ...user, id };
      updateUser({ variables: { input: { user: toSave } } });
      if (afterUpdate) {
        afterUpdate(toSave);
      }
    },
    [id, updateUser, afterUpdate]
  );

  return (
    <form
      name="profile-form"
      onSubmit={useCallback(
        (e: FormEvent) => {
          e.preventDefault();
          saveUser(e);
        },
        [saveUser]
      )}
    >
      <Box className="profile-form" {...boxProps}>
        <Typography variant="h6" className="subheading" mb={1}>
          Info
        </Typography>
        <Box display="grid" gridTemplateColumns="200px 1fr" rowGap={1}>
          <InputLabel htmlFor="fullName">Name</InputLabel>
          <TextField
            id="fullName"
            autoComplete="name"
            size="small"
            defaultValue={name}
            name="fullName"
          />

          <InputLabel htmlFor="email">Work email address</InputLabel>
          <TextField
            disabled
            id="email"
            type="email"
            size="small"
            value={email}
            name="email"
          />

          <InputLabel htmlFor="company-name">Company name</InputLabel>
          <TextField
            disabled
            id="company-name"
            size="small"
            value={organizationName}
            name="organizationName"
          />
        </Box>
      </Box>
      <Button
        disableElevation
        type="submit"
        variant="contained"
        color="primary"
      >
        Save changes
      </Button>
    </form>
  );
};
