import _ from "lodash";
import { GridRenderCellParams } from "@mui/x-data-grid";
import Tooltip from "@mui/material/Tooltip";
import "./CarbonCell.scss";
import { Icon } from "@mui/material";

type Align = "right" | "left" | "center";

const justifyContentProp = (align: Align) => {
  switch (align) {
    case "left":
      return "flex-start";
    case "right":
      return "flex-end";
    default:
      return align;
  }
};

const CarbonCell = (
  props: GridRenderCellParams<any, any> & {
    align?: Align;
    showWarnings?: boolean;
  }
) => {
  const { formattedValue, row, align = "right", showWarnings = true } = props;

  return (
    <div style={{ justifyContent: justifyContentProp(align) }}>
      {formattedValue}
      {!_.isEmpty(row.carbonWarnings) && showWarnings && (
        <Tooltip
          placement="top-end"
          title={_.map(row.carbonWarnings, (warning) => warning.message).join(
            " "
          )}
          componentsProps={{
            popper: { style: { width: "max-content" } },
          }}
        >
          <Icon
            className="material-symbols-outlined md-18"
            style={{ cursor: "pointer" }}
          >
            error
          </Icon>
        </Tooltip>
      )}
    </div>
  );
};

CarbonCell.className = "absolute-carbon-cell";

export default CarbonCell;
