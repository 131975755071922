import _ from "lodash";
import { FormControl, FormHelperText, InputLabel } from "@mui/material";
import FieldWithError from "components/FieldWithError";
import {
  validations,
  customMessages,
} from "pages/CreateProject/GuidedProjectOnboarding/constants";
import {
  RegisterOptions,
  useController,
  useFormContext,
} from "react-hook-form";
import { CustomErrorMessages } from "./CustomErrorMessages";
import { ariaProps } from "utils/forms";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { DATE_PICKER_FORMAT } from "utils/formatting";

export type MonthInputProps = {
  id: string;
  name: string;
  controllerProps?: { rules: RegisterOptions<any> };
  helperText?: string;
  label?: string;
};

export const MonthInput = ({
  id,
  name,
  controllerProps,
  helperText,
  label,
}: MonthInputProps) => {
  try {
    const {
      control,
      trigger,
      formState: { errors },
    } = useFormContext();

    const validation = _.get(validations, name, {});
    const { field } = useController({
      control,
      name,
      rules: {
        ...validation,
        ..._.get(controllerProps, "rules", {}),
      },
    });
    const inputId = `${id}-input`;
    return (
      <section id={id}>
        {label && <InputLabel htmlFor={inputId}>{label}</InputLabel>}
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
        <FieldWithError
          fieldElement={
            <FormControl fullWidth className={id}>
              <DatePicker
                slotProps={{
                  textField: {
                    id: inputId,
                    ...validation,
                    ...ariaProps(errors, name),
                  },
                }}
                value={field.value ? dayjs(field.value) : null}
                onChange={(value) => {
                  field.onChange(value);
                  trigger(field.name);
                }}
                format={DATE_PICKER_FORMAT}
                views={["month", "year"]}
              />
              <CustomErrorMessages
                name={name}
                customMessages={_.get(customMessages, name)}
                errors={errors}
              />
            </FormControl>
          }
        />
      </section>
    );
  } catch (e) {
    throw new Error("MonthInput: Must be used within a FormContext");
  }
};
