import React, { useMemo } from "react";
import clsx from "clsx";

import "./GroupList.scss";

type Props = {
  children: React.ReactElement[];
  group: string;
  separator?: string;
};

export const GroupList = ({ children, group }: Props): JSX.Element | null => {
  const classes = useMemo(
    () => ({
      GroupList: true,
      list: true,
      "group-without-header": !group,
    }),
    [group]
  );

  if (!children || children.length === 0) return null;

  return (
    <ul className={clsx(classes)}>
      {children.map((child) =>
        React.cloneElement(child, {
          title: child.props.children,
        })
      )}
    </ul>
  );
};

export default GroupList;
