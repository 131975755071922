import {
  ApolloClient,
  ApolloProvider,
  NormalizedCacheObject,
  from,
} from "@apollo/client";
import { CachePersistor, LocalStorageWrapper } from "apollo3-cache-persist";
import { ErrorResponse } from "@apollo/client/link/error";
import {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { UnauthorizedContext } from "context/UnauthorizedContext";
import { cache } from "./cache";
import {
  authMiddleware,
  defaultHttpLink,
  errorsMiddleware,
  extractNestedErrorsMiddleware,
} from "./links";
import { SnackbarContext } from "context/SnackbarContext";
import { GraphQLErrors } from "@apollo/client/errors";
import { loadErrorMessages, loadDevMessages } from "@apollo/client/dev";
import _ from "lodash";

// Beginning with version 3.8, Apollo Client omits error messages from its core bundle.
// https://www.apollographql.com/docs/react/errors/#:~:text=Beginning%20with%20version%203.8%2C%20Apollo,this%20page%20to%20view%20details.&text=loadDevMessages()%20loads%20messages%20for,only%20in%20a%20development%20environment.
if (process.env.NODE_ENV === "development") {
  loadDevMessages();
  loadErrorMessages();
}
interface MakeApolloConfigOptions {
  handleGraphQLErrors: (graphQLErrors: GraphQLErrors) => void;
  handleNetworkError: () => void;
  handleUnauthorized: (response: ErrorResponse) => void;
  cachePersistor?: CachePersistor<NormalizedCacheObject>;
  httpLink?: typeof defaultHttpLink;
}

export const makeApolloClient = async ({
  handleGraphQLErrors,
  handleNetworkError,
  handleUnauthorized,
  cachePersistor = new CachePersistor({
    cache,
    storage: new LocalStorageWrapper(window.localStorage),
  }),
  httpLink = defaultHttpLink,
}: MakeApolloConfigOptions) => {
  // Restore cache from configured storage
  await cachePersistor.restore();

  const apolloClient = new ApolloClient({
    cache,
    link: from([
      authMiddleware,
      errorsMiddleware({
        cachePersistor,
        handleGraphQLErrors,
        handleNetworkError,
        handleUnauthorized,
      }),
      extractNestedErrorsMiddleware,
      httpLink,
    ]),
    connectToDevTools: true,
    defaultOptions: {
      // "Note: The useQuery hook uses Apollo Client's watchQuery function.
      // To set defaultOptions when using the useQuery hook, make sure to set
      // them under the defaultOptions.watchQuery property."
      // - https://www.apollographql.com/docs/react/api/core/ApolloClient/#example-defaultoptions-object
      watchQuery: {
        // cache-and-network policy will retrieve from cache if exists,
        // then query server. If result is different, component will re-render.
        fetchPolicy: "cache-and-network",
      },
    },
  }) as ApolloClientWithCachePersistor;

  // Purge the cache when store is reset. Make sure you use client.clearStore().
  apolloClient.onClearStore(async () => {
    await cachePersistor.purge();
  });

  apolloClient.cachePersistor = cachePersistor;

  return apolloClient;
};

export interface ApolloClientWithCachePersistor
  extends ApolloClient<NormalizedCacheObject> {
  cachePersistor: CachePersistor<NormalizedCacheObject>;
}

const MyApolloProvider = ({ children }: PropsWithChildren) => {
  const { setUnauthorizedResponse } = useContext(UnauthorizedContext);

  const { flashMessage } = useContext(SnackbarContext);

  const [client, setClient] = useState<ApolloClientWithCachePersistor>();

  const flashGraphQLErrorMessage = useCallback(
    (graphQLErrors: GraphQLErrors) => {
      flashMessage(_.join(_.map(graphQLErrors, "message"), "\n"), {
        severity: "error",
      });
    },
    [flashMessage]
  );

  const flashNetworkErrorMessage = useCallback(() => {
    flashMessage("There was a network error. Please try again.", {
      severity: "error",
    });
  }, [flashMessage]);

  useEffect(() => {
    if (client) return;
    (async () => {
      const apolloClient = await makeApolloClient({
        handleUnauthorized: setUnauthorizedResponse,
        handleGraphQLErrors: flashGraphQLErrorMessage,
        handleNetworkError: flashNetworkErrorMessage,
      });
      setClient(apolloClient);
    })();
  }, [
    client,
    setUnauthorizedResponse,
    flashGraphQLErrorMessage,
    flashNetworkErrorMessage,
  ]);

  if (!client) return null;

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default MyApolloProvider;
