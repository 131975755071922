import { SyntheticEvent, useCallback } from "react";
import { Checkbox, FormControlLabel, Typography } from "@mui/material";
interface Props {
  checked: boolean;
  handleCheckboxChange: (checked: boolean) => void;
}

const YourUploadedEPDsCheckbox = ({ checked, handleCheckboxChange }: Props) => {
  const onChange = useCallback(
    (e: SyntheticEvent, checked: boolean) => handleCheckboxChange(checked),
    [handleCheckboxChange]
  );
  return (
    <FormControlLabel
      control={
        <Checkbox checked={checked} color="default" onChange={onChange} />
      }
      label={<Typography className="caption">Your Uploaded EPDs</Typography>}
    />
  );
};
export default YourUploadedEPDsCheckbox;
