import { gql } from "@apollo/client";

export const PRODUCT_CARD_FRAGMENT = gql`
  fragment ProductCardFragment on Product {
    id
    name
    imageUrl
    slug
    manufacturer {
      name
    }
    geographies {
      name
    }
    primaryCategory {
      name
    }
    hasIwEpd: hasCertification(type: INDUSTRY_WIDE_EPD)
    hasProductEpd: hasCertification(type: PRODUCT_EPD)
    environmentalImpact {
      carbon {
        comparedToBaseline {
          isAverage
          isAboveBaseline
          isCarbonSequestering
          isLowCarbon
        }
      }
    }
  }
`;

export const PAGINATION_FRAGMENT = gql`
  fragment PaginationFragment on PageInfo {
    endCursor
    startCursor
    hasNextPage
    hasPreviousPage
  }
`;

export const ADDRESS_FRAGMENT = gql`
  fragment AddressFragment on Address {
    city
    state
    country
    countryCode
    stateCode
    postalCode
    street1
    latitude
    longitude
  }
`;

export const PROJECT_FRAGMENT = gql`
  ${ADDRESS_FRAGMENT}
  fragment ProjectFragment on Project {
    name
    slug
    completedAt
    startedAt
    stage
    description
    propertyUse
    structureType
    includeInPortfolio
    aboveGroundFloors {
      areaPerFloor {
        area
        units
      }
      count
    }
    belowGroundFloors {
      areaPerFloor {
        area
        units
      }
      count
    }
    parkingFloors {
      areaPerFloor {
        area
        units
      }
      count
    }
    address {
      ...AddressFragment
    }
  }
`;

export const DOCUMENT_FRAGMENT = gql`
  fragment DocumentFragment on Document {
    url
    displayName
  }
`;

export const ESTIMATIONS_FRAGMENT = gql`
  fragment EstimationsFragment on ProjectEstimation {
    specificationLevel
    summary {
      categories {
        category
        absolute
      }
      total {
        kgCo2e
        perArea {
          unit
          kgCo2e
        }
      }
    }
  }
`;

export const CATEGORY_FRAGMENT = gql`
  fragment CategoryFragment on Category {
    id
    name
    declaredUnit
  }
`;

export const PROJECT_FILES_FRAGMENT = gql`
  fragment ProjectFilesFragment on Project {
    files {
      nodes {
        id
        file {
          name
          byteSize
          url
        }
      }
    }
  }
`;

export const ORGANIZATION_CONFIG_FRAGMENT = gql`
  fragment OrganizationConfigFragment on OrganizationConfig {
    portfolioIntensityTarget {
      label
      description
      intensity
    }
  }
`;
