import { useEffect, useState } from "react";
import {
  PLACEHOLDER_PRODUCT_IMG_SRC,
  PLACEHOLDER_PROJECT_IMG_SRC,
} from "../constants";

interface Props {
  entity: "project" | "product" | "IW EPD";
  imageUrl: string;
  name: string;
  alt?: string;
}

const ImageWithPlaceholder = ({ alt, entity, imageUrl, name }: Props) => {
  const placeholderImgSrc =
    entity === "product"
      ? PLACEHOLDER_PRODUCT_IMG_SRC
      : PLACEHOLDER_PROJECT_IMG_SRC;

  const [imgSrc, setImgSrc] = useState<string>(imageUrl || placeholderImgSrc);

  useEffect(() => {
    if (imageUrl && imageUrl !== placeholderImgSrc) {
      setImgSrc(imageUrl);
    }
  }, [imageUrl, placeholderImgSrc]);

  return (
    <img
      src={imgSrc}
      alt={alt || `${name}`}
      onError={() => setImgSrc(placeholderImgSrc)}
    />
  );
};

export default ImageWithPlaceholder;
