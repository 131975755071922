import { Organization, UserWithPermissions } from "gql/graphql";
import _ from "lodash";

// https://dmitripavlutin.com/timeout-fetch-request/
export const fetchWithTimeout = async (
  url: string,
  options: RequestInit,
  timeout = 5000
) => {
  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);

  const response = await fetch(url, {
    ...options,
    signal: controller.signal,
  });
  clearTimeout(id);
  return response;
};

export const isInternal = (currentUser: UserWithPermissions) => {
  return currentUser.email.endsWith("@tangiblematerials.com");
};

export const initializePendo = (userAndOrganizationInfo: {
  currentOrganization: Organization;
  currentUser: UserWithPermissions;
}) => {
  const { currentOrganization, currentUser } = userAndOrganizationInfo;
  // @ts-ignore because Pendo is globally defined
  pendo.initialize({
    // Please use Strings, Numbers, or Bools for value types.
    visitor: {
      id: currentUser.id, // Required if user is logged in, default creates anonymous ID
      email: currentUser.email, // Recommended if using Pendo Feedback, or NPS Email
      full_name: currentUser.name, // full name Recommended if using Pendo Feedback
      // role:         // Optional
      // You can add any additional visitor level key-values here,
      // as long as it's not one of the above reserved names.
      internal: isInternal(currentUser),
    },
    account: {
      id: currentOrganization.slug, // Required if using Pendo Feedback, default uses the value 'ACCOUNT-UNIQUE-ID'
      name: currentOrganization.name,
      // is_paying:    // Recommended if using Pendo Feedback
      // monthly_value:// Recommended if using Pendo Feedback
      // planLevel:    // Optional
      // planPrice:    // Optional
      // creationDate: // Optional

      // You can add any additional account level key-values here,
      // as long as it's not one of the above reserved names.
    },
  });
};
