import _ from "lodash";
import { fetchWithTimeout } from "./utils";
import { SSOConfig } from "pages/Auth/Login";

export const login = async (emailAddress: string, password: string) => {
  const response = await fetchWithTimeout(
    `${process.env.REACT_APP_API_V2_DOMAIN}/api/unstable/users/login`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({ user: { email: emailAddress, password } }),
    }
  );
  return response;
};

export const logout = async () => {
  const response = await fetchWithTimeout(
    `${process.env.REACT_APP_API_V2_DOMAIN}/api/unstable/users/logout`,
    {
      method: "DELETE",
      credentials: "include",
    }
  );
  return response.ok;
};

export const resetPassword = async (
  password: string,
  password_confirmation: string,
  token: string
) => {
  const response = await fetchWithTimeout(
    `${process.env.REACT_APP_API_V2_DOMAIN}/api/unstable/users/password`,
    {
      method: "PATCH",
      credentials: "include",
      headers: new Headers({
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({
        user: { password, password_confirmation, reset_password_token: token },
      }),
    }
  );

  return response;
};

export const requestResetPassword = async (email: string) => {
  const response = await fetchWithTimeout(
    `${process.env.REACT_APP_API_V2_DOMAIN}/api/unstable/users/password`,
    {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({ user: { email } }),
    }
  );

  return response.ok;
};

export const register = async ({
  email,
  name,
  organizationName,
  recaptchaToken,
}: {
  email: string;
  name: string;
  organizationName: string;
  recaptchaToken: string;
}) => {
  const response = await fetchWithTimeout(
    `${process.env.REACT_APP_API_V2_DOMAIN}/api/unstable/users`,
    {
      method: "POST",
      credentials: "include",
      headers: new Headers({
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({
        user: {
          name,
          email,
          organization_name: organizationName,
          recaptcha_response: recaptchaToken,
        },
      }),
    }
  );

  return response;
};

export const initiateLogin = async (email: string) => {
  const response = await fetchWithTimeout(
    `${process.env.REACT_APP_API_V2_DOMAIN}/api/unstable/login_info`,
    {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
      }),
      credentials: "include",
      body: JSON.stringify({ email }),
    }
  );

  return response;
};

const addHiddenField = (form: HTMLFormElement, name: string, value: string) => {
  const hiddenField = document.createElement("input");
  hiddenField.type = "hidden";
  hiddenField.name = name;
  hiddenField.value = value;
  form.appendChild(hiddenField);
};

export const triggerSso = async (token: string, ssoConfig: SSOConfig) => {
  const form = document.createElement("form");
  form.method = "POST";
  form.action = `${process.env.REACT_APP_API_V2_DOMAIN}${ssoConfig.path}`;

  addHiddenField(form, "authenticity_token", token);
  addHiddenField(form, "idp_config_id", ssoConfig.idp_config_id);

  document.body.appendChild(form);
  form.submit();
};
