import _ from "lodash";
import { Fragment, Dispatch, SetStateAction } from "react";
import { PerformanceAttributeFilter, ProductFilterInput } from "gql/graphql";
import NumericalCatalogFilter from "./NumericalFilter";
import CollapsableFiltersSection from "./CollapsableFiltersSection";
import CheckboxFilterCollection from "./CheckboxFilterCollection";
import MultiSelectFilter from "./MultiSelectFilter";
import CollapsableFilterSubSection from "./CollapsableFiltersSubSection";

interface Props {
  filtersAvailable: PerformanceAttributeFilter[];
  filtersState: ProductFilterInput;
  sectionTitle: string;
  setFiltersState: Dispatch<SetStateAction<ProductFilterInput>>;
}

const SubSectionHeader = ({
  children,
  hide = false,
  ...props
}: {
  children: React.ReactNode;
  collectionName: string;
  hide?: boolean;
}) => {
  if (hide) return <Fragment>{children}</Fragment>;
  return (
    <CollapsableFilterSubSection {...props}>
      {children}
    </CollapsableFilterSubSection>
  );
};

const PerformanceFiltersSection = ({
  filtersAvailable,
  filtersState,
  sectionTitle,
  setFiltersState,
}: Props) => {
  const {
    SCALAR: scalarRangeFilters,
    BOOLEAN: checkboxFilters,
    STRING: stringFilters,
  } = _.groupBy(filtersAvailable, "type");

  return (
    <CollapsableFiltersSection sectionTitle={sectionTitle}>
      {/* We provide a separate header for instances where either checkboxes or multiselects are the first filter.
          We hide it when there are no checkboxes or multiselects, so that the range filters, which share the same style and are each themselves collapsible,
          don't have a repeated collapsible header above them.
      */}
      <SubSectionHeader
        collectionName="Performance Attributes"
        hide={_.isEmpty(checkboxFilters) && _.isEmpty(stringFilters)}
      >
        <Fragment key="multiSelectFilters">
          {_.map(stringFilters, (filter) => (
            <MultiSelectFilter
              key={filter.displayName}
              taxonomyType={filter.attributeType!}
              taxonomyName={filter.displayName!}
              filterStateKey="performanceAttributes"
              filter={filter}
              filtersState={filtersState}
              setFiltersState={setFiltersState}
            />
          ))}
        </Fragment>
        <CheckboxFilterCollection
          filterStateKey="performanceAttributes"
          filters={checkboxFilters}
          filtersState={filtersState}
          setFiltersState={setFiltersState}
        />
        {_.map(scalarRangeFilters, (scalarRangeFilter) => (
          <NumericalCatalogFilter
            // How are we dealing with UNITS?
            key={scalarRangeFilter.displayName}
            filter={scalarRangeFilter}
            filtersState={filtersState}
            setFiltersState={setFiltersState}
          />
        ))}
      </SubSectionHeader>
    </CollapsableFiltersSection>
  );
};

export default PerformanceFiltersSection;
