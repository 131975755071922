import _ from "lodash";
import {
  DataGrid,
  GridRowModel,
  GridPaginationModel,
  GridSortModel,
  GridCellParams,
  GridTreeNode,
  GridCellModesModel,
  GridCellModes,
  GridRowParams,
  GridActionsCellItem,
  GridDeleteIcon,
} from "@mui/x-data-grid";
import { CopyAll as CopyIcon } from "@mui/icons-material";
import Box from "@mui/material/Box";
import { gql, useApolloClient, useMutation } from "@apollo/client";

import {
  ADD_PRODUCTS_TO_PROJECT,
  REMOVE_PROJECT_PRODUCTS,
  UPDATE_PROJECT_PRODUCT,
} from "graphql/mutations/projects";
import {
  Category,
  Maybe,
  PageInfo,
  Product,
  ProjectProductConnection,
  ProjectProjectProductEdge,
  ProjectProductSortColumn,
  ProjectProductSortInput,
  Sort,
  SustainabilityAttribute,
  TotalImpactByStage,
  Warning,
} from "gql/graphql";
import "./ProductTable.scss";

import {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { PaginationConfig } from "components/Pagination";
import { PAGINATION_RESULT_COUNT } from "../../../constants";
import { ProductTableFieldName, columns } from "./utils";

export interface ProductQuantityConfig {
  quantity: number;
  rValue: string;
  unit: string;
}
interface Props {
  currentPage: MutableRefObject<number>;
  loading: boolean;
  onPaginate: (pagination: PaginationConfig) => void;
  onSort: (sort: ProjectProductSortInput) => void;
  paginationConfig: PaginationConfig;
  productConnection: ProjectProductConnection;
  projectSlug: string;
  refetchReportDetails: () => void;
}

interface ProductRowDataWithoutProduct extends GridRowModel {
  buildingPart?: Maybe<string>;
  carbonChange?: Maybe<number>;
  carbonPerUnit?: Maybe<number>;
  carbonWarnings?: Maybe<Warning[]>;
  category?: Maybe<Category>;
  createdAt?: Maybe<string>;
  id?: Maybe<string>;
  isAboveBaseline?: Maybe<boolean>;
  isCarbonSequestering?: Maybe<boolean>;
  isLowCarbon?: Maybe<boolean>;
  projectProductId?: Maybe<string>;
  quantity?: Maybe<number>;
  rValue?: Maybe<number>;
  sustainabilityAttributes?: Maybe<SustainabilityAttribute[]>;
  totalCarbonImpact?: Maybe<number>;
  totalImpactByStage?: Maybe<TotalImpactByStage[]>;
}

export type ProductRowModel = ProductRowDataWithoutProduct & Product;

const ProductsTable = ({
  productConnection,
  projectSlug,
  refetchReportDetails,
  onPaginate,
  onSort,
  currentPage,
  loading,
}: Props) => {
  const client = useApolloClient();
  const productEdges = productConnection?.edges as ProjectProjectProductEdge[];

  const products: ProductRowModel[] = _.map(productEdges, (edge) => {
    const carbonImpact = edge.environmentalImpact?.carbon;
    const comparedToBaseline = carbonImpact?.comparedToBaseline;
    const ratioToBaseline = comparedToBaseline?.ratioToBaseline;
    const totalImpactByStage =
      edge.environmentalImpact?.carbon?.kgCo2e?.totalImpactByStage;

    return {
      ...(_.pick(edge, [
        "buildingPart",
        "quantity",
        "rValue",
        "category",
        "createdAt",
      ]) as Pick<
        ProjectProjectProductEdge,
        "buildingPart" | "quantity" | "rValue" | "category" | "createdAt"
      >),
      ...edge.node,
      projectProductId: edge.id,
      totalCarbonImpact: edge.environmentalImpact?.carbon?.kgCo2e?.total,
      totalImpactByStage,
      isAboveBaseline: comparedToBaseline?.isAboveBaseline,
      isCarbonSequestering: comparedToBaseline?.isCarbonSequestering,
      isLowCarbon: comparedToBaseline?.isLowCarbon,
      isAverage: comparedToBaseline?.isAverage,
      carbonPerUnit: carbonImpact?.kgCo2e?.perUnit,
      carbonChange: ratioToBaseline ? 1 - ratioToBaseline : null,
      sustainabilityAttributes: _.filter(
        edge.node?.sustainabilityAttributes,
        (sA: any) =>
          sA.__typename === "BooleanSustainabilityAttribute" && sA.value
      ) as Maybe<SustainabilityAttribute[]>,
      carbonWarnings: comparedToBaseline?.warnings,
    };
  });

  const formattedRows = _.map(products, (p) => ({
    ...p,
    id: p.projectProductId,
  }));

  const [updateProjectProduct] = useMutation(UPDATE_PROJECT_PRODUCT, {
    onCompleted: refetchReportDetails,
  });

  const processRowUpdate = async (newRow: GridRowModel, _oldRow: any) => {
    const ingestNumber = (value: string) => {
      if (value === null) return null;
      if (value === "") return null;
      return Number(value);
    };
    const newProjectProductValues = {
      categoryId: newRow.category.id,
      quantity: ingestNumber(newRow.quantity),
      rValue: ingestNumber(newRow.rValue),
      buildingPart: newRow.buildingPart,
    };
    // update cache before mutation to avoid values jittering in table
    client.writeFragment({
      id: `ProjectProjectProductEdge:${newRow.projectProductId}`,
      fragment: gql`
        fragment NewProjectProduct on ProjectProjectProductEdge {
          buildingPart
          quantity
          rValue # Keep this updated with ProjectProductValues
        }
      `,
      data: {
        ...newProjectProductValues,
      },
    });

    await updateProjectProduct({
      variables: {
        input: {
          slug: projectSlug,
          products: [
            {
              id: newRow.projectProductId,
              ...newProjectProductValues,
            },
          ],
        },
      },
    });

    refetchReportDetails();

    return newRow;
  };

  const mapPageToNextCursor = useRef<{ [page: number]: PageInfo }>({});
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: currentPage.current,
    pageSize: PAGINATION_RESULT_COUNT,
  });

  useEffect(() => {
    mapPageToNextCursor.current[paginationModel.page] =
      productConnection?.pageInfo;
  }, [paginationModel, mapPageToNextCursor, productConnection?.pageInfo]);

  const handlePaginationModelChange = (
    newPaginationModel: GridPaginationModel
  ) => {
    // We have the cursor, we can allow the page transition.
    const pageInfo = mapPageToNextCursor.current[newPaginationModel.page - 1];
    currentPage.current = newPaginationModel.page;

    if (newPaginationModel.page === 0 || pageInfo) {
      setPaginationModel(newPaginationModel);
      onPaginate({
        first: PAGINATION_RESULT_COUNT,
        after: pageInfo?.endCursor,
        before: "",
        last: null,
      });
    }
  };

  const FIELD_NAME_TO_SORT_COLUMN = {
    [ProductTableFieldName.AbsoluteCarbon]:
      ProjectProductSortColumn.AbsoluteCarbon,
    [ProductTableFieldName.CarbonReduction]:
      ProjectProductSortColumn.CarbonReduction,
    [ProductTableFieldName.Category]: ProjectProductSortColumn.Category,
    [ProductTableFieldName.Added]: ProjectProductSortColumn.CreatedAt,
    [ProductTableFieldName.Manufacturer]: ProjectProductSortColumn.Manufacturer,
    [ProductTableFieldName.Product]: ProjectProductSortColumn.Name,
    [ProductTableFieldName.BuildingPart]: ProjectProductSortColumn.BuildingPart,
    [ProductTableFieldName.Quantity]: ProjectProductSortColumn.Quantity,
  };

  const handleSortModelChange = (newSortModel: GridSortModel) => {
    const sortModel = newSortModel[0];
    if (sortModel) {
      const sortField =
        FIELD_NAME_TO_SORT_COLUMN[
          sortModel.field as keyof typeof FIELD_NAME_TO_SORT_COLUMN
        ];
      const sortDirection =
        sortModel.sort === "asc" ? Sort.Ascending : Sort.Descending;
      onSort({
        by: sortField,
        direction: sortDirection,
      });
    }
  };

  const onProcessRowUpdateError = (error: any) => {
    console.error(error);
  };

  // From https://mui.com/x/react-data-grid/recipes-editing/#single-click-editing
  const [cellModesModel, setCellModesModel] = useState<GridCellModesModel>({});

  const handleCellClick = useCallback(
    (params: GridCellParams, event: React.MouseEvent) => {
      if (!params.isEditable) {
        return;
      }

      // Ignore portal
      if (!event.currentTarget.contains(event.target as Element)) {
        return;
      }

      setCellModesModel((prevModel) => {
        return {
          // Revert the mode of the other cells from other rows
          ...Object.keys(prevModel).reduce(
            (acc, id) => ({
              ...acc,
              [id]: Object.keys(prevModel[id]).reduce(
                (acc2, field) => ({
                  ...acc2,
                  [field]: { mode: GridCellModes.View },
                }),
                {}
              ),
            }),
            {}
          ),
          [params.id]: {
            // Revert the mode of other cells in the same row
            ...Object.keys(prevModel[params.id] || {}).reduce(
              (acc, field) => ({
                ...acc,
                [field]: { mode: GridCellModes.View },
              }),
              {}
            ),
            [params.field]: { mode: GridCellModes.Edit },
          },
        };
      });
    },
    []
  );

  const handleCellModesModelChange = useCallback(
    (newModel: GridCellModesModel) => {
      setCellModesModel(newModel);
    },
    []
  );

  const isCellEditable = (
    params: GridCellParams<any, any, any, GridTreeNode>
  ) => {
    if (params.field === ProjectProductSortColumn.Category) {
      return params.row.categories.length > 1;
    }
    return params.colDef.editable || false;
  };

  const [addProductsToProject] = useMutation(ADD_PRODUCTS_TO_PROJECT, {
    onCompleted: refetchReportDetails,
  });
  const [removeProjectProducts] = useMutation(REMOVE_PROJECT_PRODUCTS, {
    onCompleted: refetchReportDetails,
  });

  const duplicateRow = async (params: GridRowParams) => {
    await addProductsToProject({
      variables: {
        input: {
          slug: projectSlug,
          products: [
            {
              slug: params.row.slug,
            },
          ],
        },
      },
    });
  };

  const deleteRow = async (params: GridRowParams) => {
    await removeProjectProducts({
      variables: {
        input: {
          slug: projectSlug,
          products: [
            {
              id: params.row.projectProductId,
            },
          ],
        },
      },
    });
  };

  const actions = [
    {
      field: "actions",
      type: "actions",
      maxWidth: 60,
      minWidth: 60,
      getActions: (params: GridRowParams) => [
        <GridActionsCellItem
          key="duplicate"
          label="Duplicate"
          onClick={() => duplicateRow(params)}
          icon={<CopyIcon />}
          showInMenu
        />,
        <GridActionsCellItem
          key="remove"
          label="Remove"
          onClick={() => deleteRow(params)}
          icon={<GridDeleteIcon />}
          showInMenu
        />,
      ],
    },
  ];

  if (!productConnection) return null;

  return (
    <Box id="reporting-products-table">
      <DataGrid
        rows={formattedRows}
        columns={_.concat(columns, actions)}
        editMode="cell"
        processRowUpdate={processRowUpdate}
        rowCount={productConnection?.totalCount || 0}
        paginationModel={paginationModel}
        paginationMode="server"
        onPaginationModelChange={handlePaginationModelChange}
        pageSizeOptions={[PAGINATION_RESULT_COUNT]}
        onSortModelChange={handleSortModelChange}
        sortingMode="server"
        disableColumnFilter
        disableColumnMenu
        disableRowSelectionOnClick // https://mui.com/x/react-data-grid/clipboard/#clipboard-copy
        isCellEditable={isCellEditable}
        loading={loading}
        initialState={{
          sorting: {
            sortModel: [{ field: "product", sort: "asc" }],
          },
        }}
        onProcessRowUpdateError={onProcessRowUpdateError}
        cellModesModel={cellModesModel}
        onCellModesModelChange={handleCellModesModelChange}
        onCellClick={handleCellClick}
      />
    </Box>
  );
};

export default ProductsTable;
