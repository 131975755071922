import _ from "lodash";
import { Link, Typography } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import clsx from "clsx";

import { ComparedToBaseline, Maybe } from "gql/graphql";
import { formatCarbonReduction } from "utils/transforms";
import { roundToDigits } from "utils/formatting";
import { BASELINE_INFO_SOURCE, ExternalLinks } from "../constants";
import CarbonChip from "./Chips";
import "./CarbonChangeChip.scss";

interface Props {
  carbonRatioToBaseline: Maybe<number> | undefined;
  asChip?: boolean;
  includeSuffix?: boolean;
  percentageOnly?: boolean; // Always show percentage, never 'Average Impact'
  showTooltip?: boolean;
  tooltipContent?: string;
}

export const CarbonChangeInformation = ({
  carbonRatioToBaseline: ratio,
  tooltipContent,
  asChip = false,
  includeSuffix = true,
  showTooltip = true,
  percentageOnly = false,
}: Props) => {
  if (_.isNumber(ratio)) {
    // We sometimes have baselines that are extremely close to 1 and would render 0% change
    const carbonRatioToBaseline = roundToDigits(ratio, 2);
    return (
      <div
        className={clsx({
          chip: asChip,
          "carbon-increase": carbonRatioToBaseline > 1,
          "carbon-reduction": carbonRatioToBaseline < 1,
          "carbon-average": carbonRatioToBaseline === 1,
        })}
      >
        {carbonRatioToBaseline !== 1 && (
          <Icon className="material-icons-outlined md-18">
            {carbonRatioToBaseline < 1 ? "arrow_downward" : "arrow_upward"}
          </Icon>
        )}
        <span className="label-3">
          {carbonRatioToBaseline === 1 && !percentageOnly
            ? "Average Impact"
            : formatCarbonReduction(carbonRatioToBaseline, includeSuffix, {
                digits: percentageOnly ? 0 : 2,
              })}
        </span>
        {showTooltip && (
          <Tooltip
            placement="bottom-start"
            classes={{ tooltip: "tooltip-wide" }}
            title={
              <div className="tooltip-inner">
                <Typography variant="body2">
                  {tooltipContent}{" "}
                  <Link
                    href={ExternalLinks.Methodology}
                    target="_blank"
                    className="link"
                  >
                    Learn more
                  </Link>
                </Typography>
              </div>
            }
          >
            <Icon fontSize="small">info_outlined</Icon>
          </Tooltip>
        )}
      </div>
    );
  } else {
    return null;
  }
};

const CarbonChangeChip = (props: Props) => (
  <CarbonChangeInformation asChip {...props} />
);

// This version of the chip is used for carbon change at the product level
export const ProductCarbonRatioToBaselineChip = ({
  comparedToBaseline,
  primaryCategoryName,
}: {
  comparedToBaseline: Maybe<ComparedToBaseline> | undefined;
  primaryCategoryName: string;
}) => {
  const tooltipContent = _.get(
    BASELINE_INFO_SOURCE,
    primaryCategoryName,
    BASELINE_INFO_SOURCE.generic
  );

  return comparedToBaseline?.isCarbonSequestering ? (
    <CarbonChip isCarbonSequestering />
  ) : (
    <CarbonChangeChip
      carbonRatioToBaseline={comparedToBaseline?.ratioToBaseline}
      tooltipContent={tooltipContent}
    />
  );
};

// This version of the chip is used at the project / portfolio report level
export const ReportCarbonChangeChip = ({
  carbonReductionRelative,
  includeSuffix,
  showTooltip,
  percentageOnly,
  fallback = <Typography variant="body1">Carbon baseline N/A</Typography>,
}: {
  carbonReductionRelative: number | undefined | null;
  fallback?: JSX.Element | null;
  includeSuffix?: boolean;
  percentageOnly?: boolean;
  showTooltip?: boolean;
}) => {
  return carbonReductionRelative ? (
    <CarbonChangeChip
      carbonRatioToBaseline={1 - carbonReductionRelative}
      tooltipContent={BASELINE_INFO_SOURCE.generic}
      includeSuffix={includeSuffix}
      showTooltip={showTooltip}
      percentageOnly={percentageOnly}
    />
  ) : (
    fallback
  );
};
