import { useContext } from "react";
import UserAndOrganizationContext from "context/UserAndOrganizationContext";
import { UserPermissionsEnum, UserWithPermissions } from "gql/graphql";

const checkUserPermissions = (
  user: UserWithPermissions | undefined,
  action: UserPermissionsEnum
): boolean => {
  if (!user) return false;
  const { permissions } = user;
  if (!permissions.length) return false;
  return permissions.includes(action);
};

export const useUserPermissions = (action: UserPermissionsEnum): boolean => {
  const [state] = useContext(UserAndOrganizationContext);
  return checkUserPermissions(state.currentUser, action);
};

type Props = {
  children: JSX.Element;
  to: UserPermissionsEnum;
  fallback?: JSX.Element;
};

const PermissionRestricted = ({ to, fallback, children }: Props) => {
  const isAllowedTo = useUserPermissions(to);

  if (isAllowedTo) {
    return children;
  }

  if (fallback) {
    return fallback;
  }

  return null;
};

export default PermissionRestricted;
