import { Box, FormControl, FormHelperText, InputLabel } from "@mui/material";
import { ProjectStructureTypeEnum, PropertyUse } from "gql/graphql";
import _ from "lodash";
import { useFormContext } from "react-hook-form";

import {
  HorizontalRadioGroup,
  makeFirstRadioID,
} from "pages/CreateProject/Components/Form/HorizontalRadioGroup";
import { PROPERTY_USE_ICONS } from "pages/CreateProject/GuidedProjectOnboarding/constants";
import FloorGroupsInputFields from "components/CreateEditProjectForm/components/FloorGroupsInputFields";
import { GrossFloorAreaText } from "components/CreateEditProjectForm/components/GrossFloorAreaText";

import "./Page2.scss";
import { safeComputeGFA } from "utils/formatting";

const StructureTypes = [
  ProjectStructureTypeEnum.WoodFrame,
  ProjectStructureTypeEnum.MassTimber,
  ProjectStructureTypeEnum.ReinforcedConcrete,
  ProjectStructureTypeEnum.CompositeSteelFrame,
  ProjectStructureTypeEnum.HybridConcreteSteelHighRise,
];

const StructureTypeKeys: { [ProjectStructureTypeEnum: string]: string } = {
  [ProjectStructureTypeEnum.HybridConcreteSteelHighRise]:
    "Hybrid Concrete-Steel (High Rise)",
  [ProjectStructureTypeEnum.WoodFrame]: "Light Wood Frame",
};

export const Page2 = () => {
  const { watch } = useFormContext();

  // area and unit for live-computed gfa
  const area = safeComputeGFA(
    watch("aboveGroundFloors"),
    watch("belowGroundFloors")
  );
  const unit = watch("aboveGroundFloors.areaPerFloor.units");

  return (
    <>
      <section id="structureType">
        {/* ID is generated within HorizontalRadioGroup as below for first radio - 
            `guided-project-radio-group-${name}`
            It's a janky way to make a11y work for these labels
        */}
        <InputLabel htmlFor={makeFirstRadioID("structureType")}>
          Primary structural system
        </InputLabel>
        <FormHelperText>
          This design decision has an outsized impact on the total carbon
          emissions of a building.
        </FormHelperText>
        <FormControl fullWidth>
          <HorizontalRadioGroup
            name="structureType"
            labels={StructureTypeKeys}
            values={StructureTypes}
            rules={{ required: true }}
          />
        </FormControl>
      </section>

      <section id="propertyUse">
        <InputLabel htmlFor={makeFirstRadioID("propertyUse")}>
          Asset type
        </InputLabel>
        <FormHelperText>
          The typical designs of different asset types can affect the total
          carbon emissions.
        </FormHelperText>
        <FormControl fullWidth>
          <HorizontalRadioGroup
            name="propertyUse"
            values={_.values(PropertyUse)}
            icons={PROPERTY_USE_ICONS}
            rules={{ required: true }}
          />
        </FormControl>
      </section>

      <section id="floor-groups-input-fields">
        <InputLabel htmlFor="aboveGroundFloors-count">
          <GrossFloorAreaText area={area} unit={unit} />
        </InputLabel>
        <FormHelperText>
          To calculate embodied carbon intensity using only above ground floors.
        </FormHelperText>
        <Box pb={3} className="floor-groups-input-fields">
          <FloorGroupsInputFields hideCalculation />
        </Box>
      </section>
    </>
  );
};
