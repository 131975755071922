import clsx from "clsx";
import { Button, Icon } from "@mui/material";

import "./ButtonWithSubmissionState.scss";
import _ from "lodash";

export enum SubmissionState {
  "none",
  "submitting",
  "success",
  "error",
}

type ButtonWithSubmissionStateOptions = {
  entity?: string;
  gerund?: string;
  past?: string;
  verb?: string;
};

const defaultSubmissionStateText = Object.freeze({
  entity: "changes",
  verb: "save",
  gerund: "saving",
  past: "saved",
});

export const getSubmissionStateText = (
  options?: ButtonWithSubmissionStateOptions
) => {
  const { entity, verb, gerund, past } = _.merge(
    _.clone(defaultSubmissionStateText),
    options
  );
  return {
    [SubmissionState.none]: `${_.capitalize(verb)} ${entity}`,
    [SubmissionState.submitting]: `${_.capitalize(gerund)} ${entity}`,
    [SubmissionState.error]: `${_.capitalize(
      gerund
    )} ${entity} failed. Try again.`,
    [SubmissionState.success]: `${_.capitalize(entity)} ${past}`,
  };
};

interface Props {
  disabled: boolean;
  submissionState: SubmissionState;
  entity?: string;
  icons?: Partial<{ [key in SubmissionState]: string | null }>;
  stateTextOptions?: ButtonWithSubmissionStateOptions;
  uppercase?: boolean;
}

const defaultIcons = {
  [SubmissionState.none]: "arrow_forward",
  [SubmissionState.submitting]: null,
  [SubmissionState.error]: "error_outlined",
  [SubmissionState.success]: "check",
};

const ButtonWithSubmissionState = ({
  disabled,
  submissionState,
  entity,
  stateTextOptions = {},
  uppercase = true,
  icons: iconsProp,
}: Props) => {
  const submissionStateText = getSubmissionStateText({
    ...stateTextOptions,
    entity,
  });
  const icons = _.merge(defaultIcons, iconsProp);

  const startIcon =
    submissionState === SubmissionState.error
      ? icons[SubmissionState.error] && (
          <Icon className="material-icons md-18">
            {icons[SubmissionState.error]}
          </Icon>
        )
      : null;

  const endIcon =
    submissionState === SubmissionState.success
      ? icons[SubmissionState.success] && (
          <Icon className="material-icons md-18">
            {icons[SubmissionState.success]}
          </Icon>
        )
      : submissionState === SubmissionState.none
      ? icons[SubmissionState.none] && (
          <Icon className="material-icons md-18">
            {icons[SubmissionState.none]}
          </Icon>
        )
      : null;

  return (
    <Button
      disableElevation
      type="submit"
      disabled={disabled}
      variant="contained"
      className={clsx(
        "button-with-submission-state",
        "primary",
        SubmissionState[submissionState],
        { uppercase: uppercase }
      )}
      startIcon={startIcon}
      endIcon={endIcon}
    >
      {submissionStateText[submissionState]}
    </Button>
  );
};

export default ButtonWithSubmissionState;
