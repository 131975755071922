import dayjs from "dayjs";
import "./LastUpdated.scss";
import { differenceOfTimeInWords } from "utils/date";

const LastUpdated = ({ lastUpdatedDate }: { lastUpdatedDate: string }) => {
  return (
    <p
      className="last-updated body-2"
      title={dayjs(lastUpdatedDate).toString()}
    >
      {lastUpdatedDate &&
        `Last updated: ${differenceOfTimeInWords(
          dayjs(lastUpdatedDate).toDate()
        )}`}
    </p>
  );
};

export default LastUpdated;
