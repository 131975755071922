import { useContext, useEffect } from "react";
import { FormControl, InputLabel, TextField } from "@mui/material";
import { useFormContext } from "react-hook-form";

import AddressInputFields, {
  streetAddressInputId,
} from "components/CreateEditProjectForm/components/AddressInputFields";
import FieldWithError from "components/FieldWithError";
import { CustomErrorMessages } from "pages/CreateProject/Components/Form/CustomErrorMessages";

import "./Page3.scss";
import { BlockInteractionContext } from "context/BlockInteractionContext";

export const Page3 = () => {
  const {
    register,
    formState: { errors },
    setFocus,
  } = useFormContext();
  useEffect(() => {
    setFocus("name");
  }, [setFocus]);
  const { blocked: interactionBlocked } = useContext(BlockInteractionContext);
  return (
    <>
      <section id="name">
        <InputLabel htmlFor="project-name-id">Project name</InputLabel>
        <FieldWithError
          fieldElement={
            <FormControl className="project-name">
              <TextField
                size="small"
                autoComplete="off"
                id="project-name-id"
                placeholder="Project name"
                disabled={interactionBlocked}
                {...register("name", { required: true })}
              />
            </FormControl>
          }
        />
        <CustomErrorMessages holdPlace name="name" errors={errors} />
      </section>

      <section id="address">
        <InputLabel htmlFor={streetAddressInputId}>Address</InputLabel>
        <AddressInputFields hideLabel />
      </section>

      {/* <section id="cover-image">
        <InputLabel htmlFor="project-address-id">
          Cover image <span>(optional)</span>
        </InputLabel>
        <ImageInputFields hideLabel />
      </section> */}
    </>
  );
};
