import _ from "lodash";
import {
  AttributeTypeEnum,
  Maybe,
  ProductFilter,
  ProductFilterInput,
  ProductFilterValues,
} from "gql/graphql";
import { TaxonomyFilter } from "./MultiSelectFilter/types";

const getIdFromNode = (
  node: Maybe<ProductFilterValues>,
  attributeType: AttributeTypeEnum
) =>
  _.get(
    node?.asAttribute,
    attributeType === AttributeTypeEnum.Id ? "idValue" : "stringValue"
  );

export const extractMultiSelectOptions = (
  multiSelectFilter: ProductFilter
): TaxonomyFilter[] =>
  _.map(
    _.reject(multiSelectFilter.availableValues?.nodes, (node) =>
      _.isEmpty(node?.displayName)
    ),
    (node) =>
      ({
        name: node?.displayName!,
        id: getIdFromNode(node, multiSelectFilter.type),
        group: node?.groupBy,
      } as unknown as TaxonomyFilter)
  );

export const formatNewFiltersState = (
  multiSelectFilterType: string,
  filtersState: ProductFilterInput,
  selectedOptions: any[]
) => {
  const key = _.toLower(multiSelectFilterType);

  if (_.isEmpty(selectedOptions)) {
    return _.omit(filtersState, [key]);
  } else {
    return {
      ...filtersState,
      [key]: {
        in: _.map(selectedOptions, (f: { id: string }) => f.id),
      },
    };
  }
};

export const getSelectedValues = (
  multiSelectFilter: ProductFilter,
  filtersState: ProductFilterInput
) => {
  const multiSelectFilterName = multiSelectFilter.displayName;

  const selector = [_.lowerCase(multiSelectFilterName as string), "in"];

  const asIds = _.get(filtersState, selector || []);

  const attributeValueField =
    multiSelectFilter.type === AttributeTypeEnum.Id ? "idValue" : "stringValue";

  const asFormattedObjs = _.map(asIds, (id) => {
    const obj = _.find(
      multiSelectFilter.availableValues?.nodes,
      (n) => _.get(n?.asAttribute, attributeValueField) === id
    );
    return {
      id: _.get(obj, ["asAttribute", attributeValueField]),
      name: obj?.displayName,
    };
  });

  return asFormattedObjs as TaxonomyFilter[];
};
