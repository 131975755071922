import _ from "lodash";
import { Dispatch, SetStateAction, useCallback, useState } from "react";
import Slider from "@mui/material/Slider";
import "./NumericalFilter.scss";
import {
  PerformanceAttributeFilter,
  ProductFilterInput,
  SustainabilityAttributeFilter,
} from "gql/graphql";
import numeral from "numeral";

type AvailableScalarAttributeFilter =
  | PerformanceAttributeFilter
  | SustainabilityAttributeFilter;
interface Props {
  filter: AvailableScalarAttributeFilter;
  filtersState: ProductFilterInput;
  setFiltersState: Dispatch<SetStateAction<ProductFilterInput>>;
}
const NumericalCatalogFilter = ({
  filter: {
    attributeType,
    availableValues,
    displayName,
    __typename: filterTypeName,
  },
  filtersState,
  setFiltersState,
}: Props) => {
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const filterStateSelection =
    filterTypeName === "SustainabilityAttributeFilter"
      ? "sustainabilityAttributes"
      : "performanceAttributes";

  const [min, max] = _.map(
    availableValues?.nodes,
    (n) =>
      (n as AvailableScalarAttributeFilter & { scalarValue: number })
        .scalarValue
  );

  const units = _.get(availableValues, "nodes[0].units");

  const activeFilters = filtersState[filterStateSelection];

  // Hitting some strange type errors here - https://github.com/DefinitelyTyped/DefinitelyTyped/issues/25758
  const activeFilter = _.find(activeFilters, {
    type: attributeType,
  }) as any;

  const [[localMin, localMax], setLocalMinMax] = useState<[number, number]>([
    activeFilter?.gt || activeFilter?.gte || min,
    activeFilter?.lt || activeFilter?.lte || max,
  ]);

  const applyRangeFilter = useCallback(() => {
    if (localMin !== min || localMax !== max) {
      setFiltersState({
        ...filtersState,
        [filterStateSelection]: [
          ..._.reject(filtersState[filterStateSelection], {
            type: attributeType,
          }),
          {
            gte: localMin,
            lte: localMax,
            type: attributeType,
          },
        ],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localMin, localMax, filtersState]);

  return _.isUndefined(min) || _.isUndefined(max) ? null : (
    <div className="numerical-filter">
      <p
        onClick={() => {
          setCollapsed(!collapsed);
        }}
      >
        {displayName}{" "}
        <i className="material-icons md-18">
          {`expand_${collapsed ? "more" : "less"}`}
        </i>
      </p>
      {!collapsed && !_.isNull(localMin) && !_.isNull(localMax) && (
        <Slider
          className="slider-component"
          color="secondary"
          getAriaLabel={() => "Temperature range"}
          value={[localMin, localMax]}
          min={min}
          max={max}
          step={(max - min) / 100}
          valueLabelFormat={(v) =>
            formatSliderLabel(v, max, filterTypeName, units)
          }
          onChange={(e, value: number | number[]) => {
            if (_.isArray(value)) {
              setLocalMinMax(value as [number, number]);
            }
          }}
          valueLabelDisplay="auto"
        />
      )}
      <p className="caption" onClick={applyRangeFilter}>
        Apply filter
      </p>
    </div>
  );
};

function formatSliderLabel(v: number, max: number, type: any, units?: string) {
  let format = "0,0";
  if (max <= 1) {
    if (type === "SustainabilityAttributeFilter") {
      format = "0%";
    } else {
      format = "0.00";
    }
  }
  const results = numeral(v).format(format);
  const formattedNumber = results === "NaN" ? v.toFixed(2) : results;
  if (units) return `${formattedNumber} ${units}`;
  else return `${formattedNumber}`;
}

export default NumericalCatalogFilter;
