import { useNavigate } from "react-router-dom";
import { Button, Typography } from "@mui/material";
import { Search, TipsAndUpdates } from "@mui/icons-material";

import concreteIllustrationSrc from "imgs/concrete_illustration.svg";
import { AsyncStatusEnum } from "gql/graphql";
import WithLoadingState from "components/Reporting/WithLoadingState";

import "./EmptyRecommendations.scss";
import { useMemo } from "react";

export const NoConcreteProducts = ({ reason }: { reason: string }) => {
  const navigate = useNavigate();
  return (
    <div className="empty-recommendations">
      <img src={concreteIllustrationSrc} alt="concrete illustration" />
      <Typography variant="body2" sx={{ mb: 1 }}>
        {reason}
      </Typography>

      <Button
        disableElevation
        variant="outlined"
        color="primary"
        onClick={() => navigate("/products")}
        startIcon={<Search />}
      >
        Search
      </Button>
    </div>
  );
};

export const RecommendationsError = ({ reason }: { reason: string }) => (
  <div className="empty-recommendations">
    <img src={concreteIllustrationSrc} alt="concrete illustration" />
    <Typography variant="body2">{reason}</Typography>
  </div>
);

export const NoMoreRecommendations = ({ reason }: { reason: string }) => (
  <div className="empty-recommendations">
    <img src={concreteIllustrationSrc} alt="concrete illustration" />
    <Typography variant="body2">{reason}</Typography>
  </div>
);

export const GeneratingRecommendations = () => (
  <div className="empty-recommendations">
    <TipsAndUpdates fontSize="large" />
    <Typography variant="body2">
      New recommendations are currently being generated for your project. Please
      wait.
    </Typography>
  </div>
);

interface Props {
  asyncStatus: AsyncStatusEnum;
  reason: string; // TODO: use enum
}

const EmptyRecommendations = ({ reason, asyncStatus }: Props) => {
  const component = useMemo(() => {
    if (/lowest carbon concrete products available/.test(reason)) {
      return <NoMoreRecommendations reason={reason} />;
    } else if (/Add concrete products/.test(reason)) {
      return <NoConcreteProducts reason={reason} />;
    }
    return <GeneratingRecommendations />;
  }, [reason]);

  const loading =
    !asyncStatus ||
    asyncStatus == AsyncStatusEnum.Processing ||
    asyncStatus == AsyncStatusEnum.Queued;
  return (
    <WithLoadingState height={400} isLoading={loading} timeout={5000}>
      {component}
    </WithLoadingState>
  );
};

export default EmptyRecommendations;
