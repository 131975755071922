import { gql } from "@apollo/client";
import { PROJECT_FILES_FRAGMENT, PROJECT_FRAGMENT } from "graphql/fragments";

export const CREATE_PROJECT = gql`
  ${PROJECT_FRAGMENT}
  mutation CreateProject($input: CreateProjectInput!) {
    project {
      create(input: $input) {
        project {
          ...ProjectFragment
        }
        errors {
          message
        }
      }
    }
  }
`;

export const UPDATE_PROJECT = gql`
  ${PROJECT_FRAGMENT}
  mutation UpdateProject($input: UpdateProjectInput!) {
    project {
      update(input: $input) {
        project {
          ...ProjectFragment
        }
        errors {
          message
        }
      }
    }
  }
`;

export const UPDATE_PROJECT_PRODUCT = gql`
  mutation UpdateProjectProduct($input: UpdateProductsInProjectInput!) {
    project {
      updateProducts(input: $input) {
        project {
          slug
        }
        errors {
          message
        }
      }
    }
  }
`;

export const REMOVE_PROJECT_PRODUCTS = gql`
  mutation RemoveProjectProducts($input: RemoveProductsFromProjectInput!) {
    project {
      removeProducts(input: $input) {
        project {
          slug
        }
        errors {
          message
        }
      }
    }
  }
`;

export const REMOVE_ALL_PRODUCT_FROM_PROJECT = gql`
  mutation RemoveProductFromProject(
    $input: RemoveAllProductsFromProjectInput!
  ) {
    project {
      removeAllProducts(input: $input) {
        project {
          slug
          products {
            nodes {
              name
            }
          }
        }
        errors {
          message
        }
      }
    }
  }
`;

export const UPSERT_PRODUCT_TO_PROJECT = gql`
  mutation UpsertProductToProject($input: UpsertProductsToProjectInput!) {
    project {
      upsertProducts(input: $input) {
        project {
          slug
          products {
            nodes {
              name
            }
          }
        }
        errors {
          message
        }
      }
    }
  }
`;

export const ADD_PRODUCTS_TO_PROJECT = gql`
  mutation AddProductSToProject($input: AddProductsToProjectInput!) {
    project {
      addProducts(input: $input) {
        project {
          slug
          products {
            nodes {
              name
            }
          }
        }
        errors {
          message
        }
      }
    }
  }
`;

export const DELETE_PROJECT = gql`
  mutation deleteProject($input: DeleteProjectInput!) {
    project {
      delete(input: $input) {
        project {
          slug
          name
        }
        errors {
          message
        }
      }
    }
  }
`;

export const DUPLICATE_PROJECT = gql`
  mutation duplicateProject($input: CloneProjectInput!) {
    project {
      clone(input: $input) {
        project {
          slug
          name
        }
        errors {
          message
        }
      }
    }
  }
`;

export const ACCEPT_RECOMMENDATION_SCENARIO = gql`
  mutation AcceptRecommendationScenario(
    $input: AcceptRecommendationScenarioInput!
  ) {
    project {
      acceptRecommendationScenario(input: $input) {
        project {
          slug
        }
        errors {
          message
        }
      }
    }
  }
`;

export const ADD_PROJECT_FILES = gql`
  mutation AddProjectFiles($input: AddFilesInput!) {
    project {
      addFiles(input: $input) {
        project {
          slug
          ...ProjectFilesFragment
        }
        errors {
          message
        }
      }
    }
  }
  ${PROJECT_FILES_FRAGMENT}
`;

export const REMOVE_PROJECT_FILES = gql`
  mutation RemoveProjectFiles($input: RemoveFilesInput!) {
    project {
      removeFiles(input: $input) {
        project {
          slug
        }
        errors {
          message
        }
      }
    }
  }
`;
