import React, { useCallback, useState } from "react";
import _ from "lodash";
import { useMutation } from "@apollo/client";
import clsx from "clsx";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { DropzoneOptions, FileRejection, useDropzone } from "react-dropzone";
import { CheckCircle, InsertDriveFileOutlined } from "@mui/icons-material";

import { UploadedFile } from "components/UploadedFile";
import useFileUploadProgress from "context/FileUploadProgress";
import { DirectUploadEnum, FileInfoForUpload } from "gql/graphql";
import { ADD_PROJECT_FILES } from "graphql/mutations/projects";
import "./UploadBuildingFilesModal.scss";

interface Props {
  closeModal: () => void;
  open: boolean;
  projectSlug: string;
}

const UploadBuildingFilesModal = ({ projectSlug, closeModal, open }: Props) => {
  const [successfulMutation, setSuccessfulMutation] = useState<boolean>(false);
  const [uploadError, setUploadError] = useState<string>("");

  const [files, setFiles] = React.useState<FileInfoForUpload[]>([]);

  const [fileDragInProgress, setFileDragInProgress] = React.useState(false);
  const setFileDragging = useCallback(() => {
    setFileDragInProgress(true);
  }, []);
  const setFileNotDragging = useCallback(() => {
    setFileDragInProgress(false);
  }, []);

  const progressBarRef = React.useRef<HTMLDivElement>(null);

  const onSuccessfulUpload = useCallback(
    (filesForUpload: FileInfoForUpload[]) => {
      // So that we can see the UplaodedFile previews before submitting the mutation
      setFiles([...files, ...filesForUpload]);
    },
    [setFiles, files]
  );
  const { performUpload, uploading } = useFileUploadProgress({
    handleError: () => {
      // TODO: handle error
      console.error("Error uploading file");
    },
    onSuccessfulUpload,
    progressBarRef,
    uploadType: DirectUploadEnum.TakeoffFile,
  });

  const onDropAccepted = useCallback(
    async (acceptedFiles: File[]) => {
      setFileNotDragging();
      if (acceptedFiles.length > 0) {
        performUpload(acceptedFiles);
      }
    },
    [performUpload, setFileNotDragging]
  );

  const onDropRejected = useCallback(
    (fileRejections: Array<FileRejection>) => {
      const { errors } = fileRejections[0];

      if (errors[0].code === "file-invalid-type") {
        setUploadError(
          "Invalid file type. Please upload a .pdf, .xlsx, or .rvt file."
        );
      } else {
        setUploadError(JSON.stringify(errors));
      }
      setFileNotDragging();
    },
    [setFileNotDragging]
  );

  const [addFilesToProject] = useMutation(ADD_PROJECT_FILES, {
    onError: (e: any) => {
      setUploadError(JSON.stringify(e));
    },
    onCompleted: () => setSuccessfulMutation(true),
  });

  const uploadProjectFiles = useCallback(() => {
    addFilesToProject({
      variables: {
        input: {
          slug: projectSlug,
          files: _.map(files, (f) => ({ id: f.id })),
        },
      },
    });
  }, [projectSlug, files, addFilesToProject]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const closeModalAndClearState = useCallback(() => {
    setFiles([]);
    setUploadError("");
    setSuccessfulMutation(false);
    closeModal();
  }, [setFiles, setSuccessfulMutation, closeModal]);

  return (
    <Dialog
      fullWidth
      aria-labelledby="upload-building-files-modal-title"
      aria-describedby="upload-building-files-modal-description"
      id="upload-building-files-modal"
      maxWidth="md"
      open={open}
      onClose={closeModalAndClearState}
    >
      <DialogTitle
        color="primary"
        variant="h5"
        id="upload-building-files-modal-title"
      >
        Upload building files
      </DialogTitle>
      <DialogContent>
        <Typography
          variant="body1"
          id="upload-building-files-modal-description"
        >
          These will only be visible to people in your company’s workspace.
        </Typography>
        {/* TODO: maxFiles? accept (filetypes)? maxFileSize? */}
        {/* RVT excel pdf */}
        {!successfulMutation && (
          <DropZone
            uploadErrorMessage={uploadError}
            fileDragInProgress={fileDragInProgress}
            onError={_.noop}
            onDragEnter={setFileDragging}
            onDragLeave={setFileNotDragging}
            onDropAccepted={onDropAccepted}
            onDropRejected={onDropRejected}
            accept={{
              "application/pdf": [".pdf"],
              "application/vnd.ms-excel": [".xls"],
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                [".xlsx"],
              "application/vnd.autodesk.revit": [".rvt", ".rfa", ".rte"],
            }}
          />
        )}
        {successfulMutation && <SuccessState />}

        {uploading && (
          <div
            id="progress-bar-container"
            className={clsx({ uploading: true })}
          >
            <div id="progress" ref={progressBarRef} />
          </div>
        )}
        {/* only show files before we submit the mutation */}
        {!successfulMutation &&
          _.map(files, (file) => (
            <UploadedFile
              filename={file.filename}
              key={file.id}
              // TODO: remove file from state on cancel (x) button
              onCancel={() => setFiles(_.reject(files, { id: file.id }))}
            />
          ))}
      </DialogContent>
      <Buttons
        successfulMutation={successfulMutation}
        closeModalAndClearState={closeModalAndClearState}
        uploadProjectFiles={uploadProjectFiles}
        files={files}
        uploading={uploading}
      />
    </Dialog>
  );
};

export default UploadBuildingFilesModal;

const Buttons = ({
  successfulMutation,
  closeModalAndClearState,
  uploadProjectFiles,
  files,
  uploading,
}: {
  closeModalAndClearState: () => void;
  files: FileInfoForUpload[];
  successfulMutation: boolean;
  uploadProjectFiles: () => void;
  uploading: boolean;
}) => (
  <DialogActions>
    {!successfulMutation && (
      <Button
        disableElevation
        disableRipple
        color="secondary"
        onClick={closeModalAndClearState}
        disabled={false}
        variant="contained"
      >
        Cancel
      </Button>
    )}
    {successfulMutation ? (
      <Button
        disableElevation
        disableRipple
        color="primary"
        onClick={closeModalAndClearState}
        disabled={false}
        variant="contained"
      >
        Done
      </Button>
    ) : (
      <Button
        disableElevation
        disableRipple
        color="primary"
        onClick={uploadProjectFiles}
        disabled={files.length === 0 || uploading}
        variant="contained"
      >
        Upload
      </Button>
    )}
  </DialogActions>
);

const SuccessState = () => (
  <Grid
    container
    flexDirection="column"
    id="success-state"
    alignItems="center"
    justifyContent="center"
    rowGap="16px"
  >
    <Grid item>
      <CheckCircle fontSize="large" />
    </Grid>
    <Grid item>
      <Typography variant="h6">Got them!</Typography>
      <Typography variant="body1">
        We&apos;ll email you when your results are live.
      </Typography>
    </Grid>
  </Grid>
);

const DropZone = ({
  uploadErrorMessage,
  fileDragInProgress,
  ...dropZoneProps
}: {
  fileDragInProgress: boolean;
  uploadErrorMessage: string;
} & DropzoneOptions) => {
  const { getRootProps, getInputProps } = useDropzone(dropZoneProps);
  return (
    <div
      className={clsx("upload-file-dropzone", {
        dragging: fileDragInProgress,
      })}
      {...getRootProps()}
    >
      <InsertDriveFileOutlined fontSize="large" />
      <Typography variant="h6">Drag your building files here</Typography>
      <Typography variant="body1">
        {" "}
        Add BIM files and drawings (Structural and Architectural).
      </Typography>
      <Typography variant="body1">Supports .rvt, .pdf, .xlsx</Typography>
      <input {...getInputProps()} />
      <Button disableRipple variant="text">
        Or browse to upload
      </Button>
      <Typography variant="body2" className="error">
        {uploadErrorMessage}
      </Typography>
    </div>
  );
};
