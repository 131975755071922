import _ from "lodash";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { Routes as ReactRouterRoutes, useLocation } from "react-router-dom";
import { useApolloClient } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import Sidebar from "components/Sidebar";
import UserAndOrganizationContext from "context/UserAndOrganizationContext";
import { GuidedProjectCache } from "pages/CreateProject/GuidedProjectOnboarding/GuidedProjectCache";
import { Show } from "utils/UtilityComponents";
import CustomSnackbar from "./CustomSnackbar";
import {
  CLEAR_SITE_DATA_QUERY_PARAM,
  ClearSiteDataValues,
  CSRF_TOKEN_QUERY_PARAM,
} from "./constants";
import routes from "./routes";
import { initializePendo } from "fetches/utils";

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(ReactRouterRoutes);

export const SetSidebarDisplayContext = createContext<(value: boolean) => void>(
  _.noop
);

export const ReadSidebarDisplayContext = createContext<boolean>(false);

const Main = () => {
  const location = useLocation();
  const client = useApolloClient();

  const [sidebarIsOpen, setSidebarIsOpen] = useState(false);

  const [pendoInitialized, setPendoInitialized] = useState<boolean>(false);
  const [sentryInitialized, setSentryInitialized] = useState<boolean>(false);

  const [userAndOrganizationInfo] = useContext(UserAndOrganizationContext);

  useEffect(() => {
    // Set the CSRF token in local storage if it's in the URL
    const searchParams = new URLSearchParams(location.search);

    const csrfToken = searchParams.get(CSRF_TOKEN_QUERY_PARAM);
    if (csrfToken) {
      localStorage.setItem("X-CSRF-Token", csrfToken || "");
    }

    // Clear site data if the query param is present
    const clearSiteData = searchParams.get(
      CLEAR_SITE_DATA_QUERY_PARAM
    ) as ClearSiteDataValues; // This is reusing terminology from https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Clear-Site-Data
    if (
      clearSiteData &&
      (clearSiteData === "*" || clearSiteData.split(",").includes("storage"))
    ) {
      client?.clearStore();
      // Hard reload to /portfolio
      window.location.href = "/portfolio";
    }
  }, [client, location.search]);

  /*

  PENDO

  */
  useEffect(
    function _initializePendo() {
      if (pendoInitialized || process.env.NODE_ENV == "development") return;

      if (
        userAndOrganizationInfo?.currentOrganization &&
        userAndOrganizationInfo?.currentUser
      ) {
        const { currentUser, currentOrganization } = userAndOrganizationInfo;
        const getUserInfoData = async () => {
          initializePendo({
            currentOrganization,
            currentUser,
          });
          setPendoInitialized(true);
        };
        getUserInfoData();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [userAndOrganizationInfo, pendoInitialized]
  );

  useEffect(
    function _setSentryUser() {
      if (sentryInitialized) return;
      if (
        userAndOrganizationInfo?.currentOrganization &&
        userAndOrganizationInfo?.currentUser
      ) {
        const { currentUser, currentOrganization } = userAndOrganizationInfo;
        Sentry.setUser({
          id: currentUser.id,
          email: currentUser.email,
          organization: currentOrganization.slug,
        });
        setSentryInitialized(true);
      }
    },
    [userAndOrganizationInfo, sentryInitialized]
  );

  /*
   Clean up create-project-form on navigate
   TODO: Get this out of main...
  */
  const guidedProjectCache = useMemo(
    () => new GuidedProjectCache(client),
    [client]
  );
  useEffect(() => {
    if (!location.pathname.startsWith("/my-projects/create")) {
      // clear locally cached project so it's not weird when they resume edit after leaving
      guidedProjectCache.clear();
    }
  }, [location.pathname, guidedProjectCache]);

  useEffect(() => {
    if (
      process.env.NODE_ENV !== "development" ||
      document.getElementById("mini-profiler")
    )
      return;
    const script = document.createElement("script");
    script.async = true;
    script.src = `${process.env.REACT_APP_API_V2_DOMAIN}/mini-profiler-resources/includes.js?v=12b4b45a3c42e6e15503d7a03810ff33`;
    script.type = "text/javascript";
    script.id = "mini-profiler";
    script.setAttribute(
      "data-css-url",
      `${process.env.REACT_APP_API_V2_DOMAIN}/mini-profiler-resources/includes.css?v=12b4b45a3c42e6e15503d7a03810ff33`
    );
    script.setAttribute("data-version", "12b4b45a3c42e6e15503d7a03810ff33");
    script.setAttribute(
      "data-path",
      `${process.env.REACT_APP_API_V2_DOMAIN}/mini-profiler-resources/`
    );
    script.setAttribute("data-horizontal-position", "left");
    script.setAttribute("data-vertical-position", "top");
    script.setAttribute("data-ids", "");
    script.setAttribute("data-trivial", "false");
    script.setAttribute("data-children", "false");
    script.setAttribute("data-max-traces", "20");
    script.setAttribute("data-controls", "false");
    script.setAttribute("data-total-sql-count", "false");
    script.setAttribute("data-authorized", "true");
    script.setAttribute("data-toggle-shortcut", "Alt+P");
    script.setAttribute("data-start-hidden", "false");
    script.setAttribute("data-collapse-results", "true");
    script.setAttribute("data-hidden-custom-fields", "");
    script.setAttribute("data-html-container", "#root");
    document.head.appendChild(script);
  });

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div id="App">
        <CustomSnackbar />
        <SetSidebarDisplayContext.Provider value={setSidebarIsOpen}>
          <ReadSidebarDisplayContext.Provider value={sidebarIsOpen}>
            <Show when={userAndOrganizationInfo?.currentUser}>
              <Sidebar />
            </Show>
          </ReadSidebarDisplayContext.Provider>
        </SetSidebarDisplayContext.Provider>

        <div id="main-content">
          <Show
            when={userAndOrganizationInfo?.currentUser}
            otherwise={routes.LoggedOut}
            wrapper={SentryRoutes}
          >
            {routes.LoggedIn}
          </Show>
        </div>
      </div>
    </LocalizationProvider>
  );
};

export default Main;
