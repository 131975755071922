import { SyntheticEvent, useEffect, useMemo, useState } from "react";
import _ from "lodash";
import { Link, useLocation } from "react-router-dom";
import { Button, Icon, Tab, Tabs, Typography } from "@mui/material";

import "./MyProjects.scss";
import { pluralizer } from "utils/transforms";
import { OperationVariables, useLazyQuery } from "@apollo/client";
import { GET_PROJECTS } from "graphql/queries/projects";
import { CustomPagination, PaginationConfig } from "components/Pagination";
import {
  AreaUnit,
  ProjectSortColumn,
  Sort,
  UserPermissionsEnum,
} from "../../gql/graphql";
import { PAGINATION_RESULT_COUNT } from "../../constants";
import SearchBar from "components/SearchBar";
import PermissionRestricted from "components/PermissionRestricted";
import { GridOn, ViewListOutlined } from "@mui/icons-material";
import ProjectsGalleryView from "./ProjectsGalleryView";
import ProjectsList from "pages/Portfolio/ProjectsList";
import TabPanel, { a11yProps } from "components/CustomTabPanel";
import { DataLoadingContext } from "context/DataLoadingContext";

const tabNames = ["gallery", "table"] as const;
type TabNames = (typeof tabNames)[number];
const setCurrentTab = (newValue: TabNames) => {
  window.location.hash = newValue as TabNames;
};

const DEFAULT_TAB: TabNames = "gallery";
const extractHashTab = (hash: string) => {
  if (hash.length > 1) {
    return hash.split("?")[0].replace(/^#/, "") as TabNames;
  }
  return null;
};

const MyProjects = () => {
  const { hash, search } = useLocation();
  const currentTab = useMemo(() => extractHashTab(hash) || DEFAULT_TAB, [hash]);
  const initialSort = useMemo(() => {
    const params = new URLSearchParams(search);
    const by =
      (params.get("by") as ProjectSortColumn) || ProjectSortColumn.StartedAt;
    const direction = (params.get("direction") as Sort) || Sort.Ascending;
    return { by, direction };
  }, [search]);

  const wrappedRefetch = async (variables?: Partial<OperationVariables>) => {
    if (variables?.sort) {
      const { by, direction } = variables.sort;
      const pathWithSearch = new URLSearchParams(search);
      pathWithSearch.set("by", by);
      pathWithSearch.set("direction", direction);
      window.history.replaceState(
        {},
        "",
        `?${pathWithSearch.toString()}#table`
      );
    }
    return refetchProjects(variables);
  };

  const [searchTerm, setSearchTerm] = useState<string>("");

  const [getProjects, { data, refetch: refetchProjects, loading, called }] =
    useLazyQuery(GET_PROJECTS, {
      variables: {
        areaUnit: AreaUnit.M2,
        sort: { by: initialSort.by, direction: initialSort.direction },
      },
    });

  const [paginationConfig, setPaginationConfig] = useState<PaginationConfig>({
    first: PAGINATION_RESULT_COUNT,
  });

  useEffect(() => {
    const variables = _.isEmpty(searchTerm)
      ? {}
      : {
          filter: { q: searchTerm },
        };

    const fetchProjects = async () => {
      await getProjects({
        variables: { ...paginationConfig, ...variables },
      });
    };

    fetchProjects();
  }, [getProjects, paginationConfig, searchTerm]);

  const handleTabChange = (_event: SyntheticEvent, newValue: TabNames) => {
    setCurrentTab(newValue);
  };

  const allMyProjects =
    _.map(data?.projects.nodes, (node) => ({
      ...node,
      productCount: node?.products?.totalCount,
      area: node?.area?.area,
      areaUnits: node?.area?.units,
    })) || [];

  return (
    <>
      <header>
        <div>
          <h5>Projects</h5>
        </div>
        <div>
          <SearchBar
            onSearch={(searchTerm: string) => {
              setSearchTerm(searchTerm);
            }}
            placeholder="Search projects"
          />
          <PermissionRestricted to={UserPermissionsEnum.CreateProject}>
            <Link to="/my-projects/create">
              <Button disableElevation variant="outlined">
                <Icon>add</Icon> Create new project
              </Button>
            </Link>
          </PermissionRestricted>
        </div>
      </header>
      <div id="my-projects">
        <div>
          <Typography variant="h6">
            {data?.projects.totalCount}{" "}
            {pluralizer("Project", _.size(allMyProjects))}
          </Typography>
          <Tabs
            value={currentTab}
            onChange={handleTabChange}
            aria-label="gallery / table view"
          >
            <Tab
              disableRipple
              label="Gallery"
              icon={<GridOn fontSize="small" />}
              iconPosition="start"
              value="gallery"
              {...a11yProps<TabNames>({
                parentId: "my-projects",
                tabName: "gallery",
              })}
            />
            <Tab
              disableRipple
              label="Table"
              icon={<ViewListOutlined fontSize="small" />}
              iconPosition="start"
              value="table"
              {...a11yProps<TabNames>({
                parentId: "my-projects",
                tabName: "table",
              })}
            />
          </Tabs>
        </div>
        <TabPanel<TabNames>
          currentTab={currentTab}
          tabName="gallery"
          parentId="my-projects"
        >
          <ProjectsGalleryView
            projects={allMyProjects}
            refetchProjects={refetchProjects}
            loading={loading}
          />
        </TabPanel>
        <TabPanel<TabNames>
          currentTab={currentTab}
          tabName="table"
          parentId="my-projects"
        >
          <DataLoadingContext.Provider
            value={{ dataLoading: loading || !called }}
          >
            <ProjectsList
              allowSorting
              projectNodes={data?.projects.nodes}
              refetchProjects={wrappedRefetch}
            />
          </DataLoadingContext.Provider>
        </TabPanel>
      </div>
      <CustomPagination
        entity="project"
        connection={data?.projects}
        onPaginate={setPaginationConfig}
      />
    </>
  );
};

export default MyProjects;
