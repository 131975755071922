import _ from "lodash";
import {
  BaseSyntheticEvent,
  FormEvent,
  useCallback,
  useContext,
  useState,
} from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  TextField,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { LockOutlined } from "@mui/icons-material";
import { ErrorMessage } from "@hookform/error-message";

import UserAndOrganizationContext from "context/UserAndOrganizationContext";
import { SnackbarContext } from "context/SnackbarContext";
import { initiateLogin, requestResetPassword, triggerSso } from "fetches/auth";
import { ResetPasswordEmailForm } from "./ResetPasswordEmailForm";
import { TANGIBLE_AWS_BUCKET_URL } from "../../constants";
import { Footer } from "./Footer";

import "./Login.scss";

export interface SSOConfig {
  enabled: boolean;
  idp_config_id: string;
  method?: string;
  path?: string;
}

const DEBOUNCE_TIME_MS = 750;

const Login = () => {
  const [invalidLogin, setInvalidLogin] = useState<boolean>(false);
  const [ssoConfig, setSsoConfig] = useState<SSOConfig | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [showResetPasswordModal, setShowResetPasswordModal] =
    useState<boolean>(false);

  const [token, setToken] = useState<string>("");

  const [, { login }] = useContext(UserAndOrganizationContext);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onBlur" });

  const onSubmit = async (data: any, event: any) => {
    if (ssoConfig?.enabled) {
      // This submit function is only for email / password login type
      return;
    }
    if (ssoConfig == null) {
      event.preventDefault();
      handleEmailFieldBlur({ target: { value: data.emailAddress } });
      return;
    }
    try {
      setLoading(true);
      const response = await login(data.emailAddress, data.password);
      setInvalidLogin(!response.ok);
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  const { flashMessage } = useContext(SnackbarContext);
  const triggerResetPassword = async (email: string) => {
    const result = await requestResetPassword(email);
    setShowResetPasswordModal(false);
    if (result) {
      flashMessage(
        "If you have an account with us, a link has been sent to your email.",
        { severity: "success" }
      );
    } else {
      flashMessage("There was an error resetting password. Please try again.", {
        severity: "error",
      });
    }
  };

  const getLoginInfo = useCallback(async (e: any) => {
    const email = e.target.value;

    if (!/\S+@\S+\.\S+/.test(email)) {
      return;
    }

    const response = await initiateLogin(email);

    const loginConfig = await response.json();

    setSsoConfig(loginConfig.sso);

    setToken(response.headers.get("X-Csrf-token") || "");
  }, []);

  const handleEmailFieldBlur = useCallback(
    (e: any) => {
      getLoginInfo(e);
    },
    [getLoginInfo]
  );

  const handleEmailFieldChange = useCallback(
    _.debounce((e: any) => {
      getLoginInfo(e);
    }, DEBOUNCE_TIME_MS),
    []
  );

  handleEmailFieldChange.cancel();

  const showStandardLogin = ssoConfig?.enabled === false;

  return (
    <div id="login-page">
      <header className="header">
        <img
          src={`${TANGIBLE_AWS_BUCKET_URL}/tangible_red_logo_large.png`}
          alt="tangible logo"
        />
      </header>
      <form onSubmit={handleSubmit(onSubmit)} className="default-shadow">
        <h3>Log in</h3>
        {invalidLogin && !ssoConfig?.enabled && (
          <small className="error-message overall">
            The e-mail address and/or password you specified are not correct.
          </small>
        )}
        <div>
          <TextField
            fullWidth
            required
            type="email"
            className="email-field"
            label="Email address"
            variant="outlined"
            {...register("emailAddress", {
              onBlur: handleEmailFieldBlur,
              onChange: handleEmailFieldChange,
            })}
          />
          {ssoConfig?.enabled && (
            <Typography variant="body2" className="sso-enabled">
              <LockOutlined />
              Single sign-on enabled
            </Typography>
          )}
          <ErrorMessage
            errors={errors}
            name="emailAddress"
            message="Email must be formatted correctly."
            as={<small className="error-message" />}
          />
        </div>
        {showStandardLogin && (
          <>
            <TextField
              fullWidth
              required
              label="Password"
              variant="outlined"
              type="password"
              {...register("password")}
            />
            <Button
              disableElevation
              type="submit"
              variant="contained"
              className="primary"
              disabled={loading}
            >
              Log in
            </Button>
          </>
        )}
        {ssoConfig?.enabled && (
          <Button
            disableElevation
            variant="contained"
            className="primary"
            onClick={() => triggerSso(token, ssoConfig)}
            disabled={!token}
          >
            Continue
          </Button>
        )}
      </form>
      {showStandardLogin && (
        <Button
          disableRipple
          className="forgot-password tertiary"
          variant="text"
          onClick={() => setShowResetPasswordModal(true)}
          disabled={loading}
        >
          Forgot password?
        </Button>
      )}

      <Dialog open={showResetPasswordModal}>
        <DialogContent>
          <ResetPasswordEmailForm
            {...{ triggerResetPassword }}
            onClickBack={() => setShowResetPasswordModal(false)}
          />
        </DialogContent>
      </Dialog>
      <Footer />
    </div>
  );
};

export default Login;
