import clsx from "clsx";
import { Tab, Tabs } from "@mui/material";
import BarChartIcon from "@mui/icons-material/BarChart";
import TableRowsIcon from "@mui/icons-material/TableRowsOutlined";
import UploadIcon from "@mui/icons-material/Upload";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesomeOutlined";
import InfoIcon from "@mui/icons-material/InfoOutlined";

import { a11yProps } from "components/CustomTabPanel";
import { TabNames } from "./ProjectReport";
import { useUserPermissions } from "components/PermissionRestricted";
import { UserPermissionsEnum } from "gql/graphql";
import { InsertDriveFileOutlined } from "@mui/icons-material";

interface Props {
  changeTab: (tabName: TabNames) => void;
  currentTab: TabNames;
  hasRecommendations: boolean;
  hasUploadedReports: boolean;
  totalProductCount: number;
}

const ProjectReportTabs = ({
  currentTab,
  changeTab,
  totalProductCount,
  hasRecommendations,
  hasUploadedReports,
}: Props) => {
  const canViewCatalog = useUserPermissions(UserPermissionsEnum.ViewCatalog);
  return (
    <Tabs
      value={currentTab}
      onChange={(_event: React.SyntheticEvent, tabName: TabNames) =>
        changeTab(tabName)
      }
    >
      <Tab
        disableRipple
        label="Dashboard"
        iconPosition="start"
        // eslint-disable-next-line react/jsx-no-undef
        icon={<BarChartIcon fontSize="small" />}
        value="dashboard"
        {...a11yProps<TabNames>({
          tabName: "dashboard",
          parentId: "project-report",
        })}
      />
      {canViewCatalog && (
        <Tab
          disableRipple
          label={`Products${` (${totalProductCount})`}`}
          // eslint-disable-next-line react/jsx-no-undef
          icon={<TableRowsIcon fontSize="small" />}
          iconPosition="start"
          value="products"
          {...a11yProps<TabNames>({
            tabName: "products",
            parentId: "project-report",
          })}
        />
      )}
      {!hasUploadedReports && (
        <Tab
          disableRipple
          label="Recommendations"
          icon={<AutoAwesomeIcon fontSize="small" />}
          iconPosition="start"
          value="recommendations"
          className={clsx("recommendations-tab", {
            "has-recommendations": hasRecommendations,
          })}
          {...a11yProps({
            tabName: "recommendations",
            parentId: "project-report",
          })}
        />
      )}
      <Tab
        disableRipple
        label="Details"
        icon={<InfoIcon fontSize="small" />}
        iconPosition="start"
        value="details"
        {...a11yProps<TabNames>({
          tabName: "details",
          parentId: "project-report",
        })}
      />
      {hasUploadedReports && (
        <Tab
          disableRipple
          label="Report Uploads"
          icon={<UploadIcon fontSize="small" />}
          iconPosition="start"
          value="uploads"
          {...a11yProps<TabNames>({
            tabName: "uploads",
            parentId: "project-report",
          })}
        />
      )}
      <Tab
        disableRipple
        label="Documents"
        icon={<InsertDriveFileOutlined fontSize="small" />}
        iconPosition="start"
        value="documents"
        {...a11yProps<TabNames>({
          tabName: "documents",
          parentId: "project-report",
        })}
      />
    </Tabs>
  );
};

export default ProjectReportTabs;
