import { Theme, createTheme, lighten } from "@mui/material/styles";
import { COLOR_PALETTE, TYPEFACES } from "../constants";
import { customTypography } from "./app_dot_tangible_theme/typography";

import check from "./app_dot_tangible_theme/assets/check.svg";

const defaultTheme = createTheme();

const bodySmall = {
  fontFamily: TYPEFACES.body,
  fontSize: "14px",
};

export const theme: Theme = {
  ...defaultTheme,
  palette: {
    ...defaultTheme.palette,
    text: {
      ...defaultTheme.palette.text,
      primary: COLOR_PALETTE.almostBlack,
    },
    primary: {
      // TODO: We should change these to lighten, darken, etc. per design folks
      ...defaultTheme.palette.primary,
      main: COLOR_PALETTE.almostBlack,
    },
    secondary: {
      ...defaultTheme.palette.secondary,
      main: COLOR_PALETTE.sand,
      light: COLOR_PALETTE.lightSand,
      dark: COLOR_PALETTE.darkSand,
    },
    info: {
      ...defaultTheme.palette.info,
      main: COLOR_PALETTE.darkGrey,
    },
    warning: {
      ...defaultTheme.palette.warning,
      main: COLOR_PALETTE.flagRed,
    },
    background: {
      ...defaultTheme.palette.background,
      paper: COLOR_PALETTE.white,
    },
  },
  components: {
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          "& .MuiChip-root": {
            backgroundColor: COLOR_PALETTE.sand,
            padding: "4px 6px 4px 8px",
            height: "21px",
            cursor: "pointer",
            "&.MuiChip-clickable:hover": {
              backgroundColor: lighten(COLOR_PALETTE.sand, 0.5),
            },
          },
          "& .MuiChip-root span": {
            padding: 0,
            ...bodySmall,
            fontWeight: 400,
            lineHeight: "14.52px",
            fontSize: "12px",
          },
          "& .MuiChip-root .MuiChip-deleteIcon": {
            margin: "0 0 0 5px",
            fontSize: "14px",
            color: COLOR_PALETTE.darkGrey,
            "&:hover": {
              color: COLOR_PALETTE.darkGrey,
            },
          },
          "& .MuiInputLabel-root": {
            ...bodySmall,
          },
          "& .MuiAutocomplete-inputRoot.MuiInputBase-adornedEnd": {
            // When dropdown button appears on right of input
            paddingRight: 39,
          },
          "& .MuiAutocomplete-tag": {
            // When '+1' (or more) appears
            ...bodySmall,
            fontSize: "12px",
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: ({ theme }) => ({
          "&.MuiAlert-standardError": {
            outline: `1px solid ${theme.palette.error.main}`,
          },
          "&.MuiAlert-standardInfo": {
            outline: `1px solid ${theme.palette.info.main}`,
          },
          "&.MuiAlert-standardSuccess": {
            outline: `1px solid ${theme.palette.success.main}`,
          },
          "&.MuiAlert-standardWarning": {
            outline: `1px solid ${theme.palette.warning.main}`,
          },
        }),
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: TYPEFACES.heading,
          fontWeight: 600,
          borderRadius: 8,
          paddingTop: 6,
          paddingBottom: 6,
          textTransform: "none",
          letterSpacing: "initial",

          "&.MuiButton-text:after": {
            content: '""',
            position: "absolute",
            bottom: 4,
            left: 8,
            right: 8,
            height: 2,
            backgroundColor: COLOR_PALETTE.darkGrey,
          },

          "&.MuiButton-text.no-underline:after, &.MuiButton-text[aria-haspopup=true]:after":
            {
              display: "none",
            },

          "&.MuiButton-text.Mui-disabled:after": {
            backgroundColor: COLOR_PALETTE.lightGrey,
          },

          "&.MuiButton-text:hover": {
            backgroundColor: "transparent",
          },

          "&.MuiButton-text:hover:after": {
            backgroundColor: COLOR_PALETTE.mediumGrey,
          },

          "& .MuiButton-startIcon": {
            position: "relative",
            left: "5px",
          },

          "&.MuiButton-containedPrimary": {
            "&:hover": {
              backgroundColor: COLOR_PALETTE.almostBlack,
            },
          },

          "&.MuiButton-outlinedPrimary": {
            color: COLOR_PALETTE.darkGrey,
          },

          "&.MuiButton-outlinedSecondary": {
            color: COLOR_PALETTE.darkGrey,
            borderColor: COLOR_PALETTE.lightGrey,
            "&.Mui-disabled": {
              color: COLOR_PALETTE.lightGrey,
              borderColor: COLOR_PALETTE.lightGrey,
            },
          },

          "&.MuiButton-containedSecondary": {
            color: COLOR_PALETTE.darkGrey,
            backgroundColor: COLOR_PALETTE.superLightGrey,
            borderColor: COLOR_PALETTE.superLightGrey,
            "&:hover": {
              backgroundColor: COLOR_PALETTE.lightGrey,
            },

            "& .MuiButton-startIcon svg": {
              fill: COLOR_PALETTE.almostBlack,
            },
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: ({ theme }) => ({
          fontWeight: 500,
          borderRadius: 4,
          color: theme.palette.primary.main,
          textTransform: "uppercase",
          fontSize: 12,
          backgroundColor: COLOR_PALETTE.superLightGrey,
          fontFamily: TYPEFACES.contrast,
          "&.MuiChip-colorSecondary": {
            backgroundColor: theme.palette.secondary.main,
          },
          "&.carbon-intensity": {
            fontSize: 12,
            borderRadius: 24,
            padding: "7px 10px 3px 10px",
            "& .MuiChip-label": {
              textTransform: "none",
              fontWeight: 500,
              color: COLOR_PALETTE.darkGrey,
              padding: 0,
              "& span.bold": {
                color: theme.palette.primary.main,
                fontWeight: 600,
              },
            },
          },
        }),
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          "&.MuiMenu-paper.MuiPaper-root.MuiPopover-paper": {
            "& .MuiMenuItem-root .MuiTypography-body1": {
              fontSize: "14px",
            },
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: "white",
          borderRadius: "4px",
          padding: "10px",
          boxShadow: "0px 0px 3px 0px rgba(0, 0, 0, 0.3);",
          color: "black",
          fontSize: "14px",
          fontFamily: TYPEFACES.body,
          fontWeight: 400,

          "& a, & a:hover, & a:visited, & a:focus": {
            color: COLOR_PALETTE.almostBlack,
          },

          "&.tooltip-wide": {
            maxWidth: "600px !important",
          },

          "p.editorial-body": {
            fontSize: "14px",
            lineHeight: "1.4",
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          backgroundColor: "white",
          ...bodySmall,

          "&:not(.MuiInputLabel-formControl):not(.MuiInputLabel-outlined)": {
            fontFamily: TYPEFACES.heading,
            fontSize: "14px",
            fontWeight: 600,
            lineHeight: "17.64px",
            color: COLOR_PALETTE.almostBlack,
            marginBottom: "10px",
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        outlined: {
          "&.MuiInputBase-input": {
            ...bodySmall,
            lineHeight: "1.4375em",
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          fontFamily: TYPEFACES.body,
          width: "32px",
          "& .MuiSwitch-thumb": {
            color: COLOR_PALETTE.lightFill,
            width: "6px",
            height: "6px",
            transform: "translate(5px, 5px)",
            position: "relative",
          },
          "& .MuiTouchRipple-root": {
            display: "none",
          },
          "& .MuiSwitch-switchBase.Mui-checked": {
            transform: "translateX(8px)",
          },
        },
        switchBase: {
          "&:hover": {
            backgroundColor: "transparent",
          },
          "&.Mui-checked+.MuiSwitch-track": {
            backgroundColor: COLOR_PALETTE.almostBlack,
            opacity: 1,
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          ...bodySmall,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          "& .MuiTouchRipple-ripple": {
            color: COLOR_PALETTE.sand,
          },
          "&:hover": {
            backgroundColor: COLOR_PALETTE.lightSand,
          },
          "&:focus": {
            backgroundColor: COLOR_PALETTE.lightSand,
          },
          "&.Mui-selected": {
            backgroundColor: COLOR_PALETTE.white,
            // Add a checkmark to selected state.
            "&:after": {
              content: "''",
              backgroundImage: `url(${check})`,
              width: "24px",
              height: "24px",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center right",
              position: "absolute",
              right: "8px",
            },
          },
          "&.Mui-selected:hover": {
            backgroundColor: COLOR_PALETTE.lightSand,
          },
          "&.Mui-selected.Mui-focusVisible": {
            backgroundColor: COLOR_PALETTE.white,
          },
          "&.Mui-selected.Mui-focusVisible:hover": {
            backgroundColor: COLOR_PALETTE.superLightGrey,
          },
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          "& .MuiMenuItem-root": {
            ...bodySmall,
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          ...bodySmall,
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: COLOR_PALETTE.mediumGrey,
          fontFamily: TYPEFACES.body,
          letterSpacing: "unset",
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: ({ theme }) => ({
          "&.MuiSlider-colorSecondary": {
            color: theme.palette.secondary.dark,
          },
        }),
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: 10,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: "39px",
          borderBottom: `1px solid ${COLOR_PALETTE.lightGrey}`,
          marginBottom: "24px",
          width: "100%",
          display: "flex",
          alignSelf: "stretch",
          "& button": {
            textTransform: "none",
          },
          "& .MuiTab-root": {
            fontFamily: TYPEFACES.body,
            textTransform: "none",
            minHeight: "24px",
            fontSize: "15px",
            fontWeight: 600,
            letterSpacing: 0,
            color: COLOR_PALETTE.mediumGrey,
          },
          "& .MuiTab-root.Mui-selected": {
            color: COLOR_PALETTE.almostBlack,
          },

          // this is for the underline on the active tab
          "& .MuiTabs-indicator": {
            backgroundColor: COLOR_PALETTE.almostBlack,
          },
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          popperTitle: "h3",
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: "rgba(0, 0, 0, 0.2)",
        },
      },
    },
  },
  typography: {
    ...defaultTheme.typography,
    subtitle1: { fontFamily: TYPEFACES.heading, fontWeight: 700 },
    body1: { fontFamily: TYPEFACES.body },
    body2: { fontFamily: TYPEFACES.body },
    h4: { fontFamily: TYPEFACES.heading, fontWeight: 700 },
    h5: { fontFamily: TYPEFACES.heading, fontWeight: 700 },
    h6: { fontFamily: TYPEFACES.heading, fontWeight: 700 },
    ...customTypography,
  },
};

export default theme;
