import { MutableRefObject } from "react";
import { Box, InputLabel, TextField } from "@mui/material";
import { Organization, User } from "gql/graphql";

import "./UserInputFields.scss";

export const UserInputFields = ({
  creating,
  firstFieldRef,
  organization,
  user,
}: {
  creating?: boolean;
  firstFieldRef?: MutableRefObject<HTMLInputElement | undefined>;
  organization?: Partial<Organization>;
  user?: Partial<User>;
}) => (
  <Box
    className="user-input-fields"
    display="grid"
    gridTemplateColumns="150px 1fr"
    rowGap={1}
    alignItems="end"
  >
    <InputLabel htmlFor="name">Name</InputLabel>
    <TextField
      required
      id="name"
      size="small"
      defaultValue={user?.name}
      name="name"
      autoComplete="off"
      inputRef={firstFieldRef}
    />

    <InputLabel htmlFor="email">Email</InputLabel>
    <TextField
      required
      disabled={!creating}
      id="email"
      type="email"
      size="small"
      value={user?.email}
      name="email"
    />

    {organization?.name ? (
      <>
        <InputLabel htmlFor="company-name">Company name</InputLabel>
        <TextField
          required
          disabled
          id="company-name"
          size="small"
          value={organization?.name}
          name="organizationName"
        />
      </>
    ) : null}
  </Box>
);
