import { gql } from "@apollo/client";
import {
  CATEGORY_FRAGMENT,
  DOCUMENT_FRAGMENT,
  PAGINATION_FRAGMENT,
  PRODUCT_CARD_FRAGMENT,
} from "../fragments";

export const GET_CATALOG_PRODUCTS = gql`
  ${PRODUCT_CARD_FRAGMENT}
  ${PAGINATION_FRAGMENT}
  query GetCatalogProducts(
    $first: Int
    $last: Int
    $sort: ProductSortInput
    $after: String
    $before: String
    $filter: ProductFilterInput
  ) {
    catalog {
      products(
        first: $first
        last: $last
        after: $after
        before: $before
        sort: $sort
        filter: $filter
      ) {
        totalCount
        edges {
          node {
            ...ProductCardFragment
            geographies {
              name
            }
          }
          cursor
        }
        pageInfo {
          ...PaginationFragment
        }
        availableFilters {
          productFilters {
            displayName # e.g. Category
            type
            availableValues(first: 300) {
              nodes {
                displayName
                groupBy
                asAttribute {
                  ... on IdProductFilterAttribute {
                    idValue: value
                  }
                  ... on StringProductFilterAttribute {
                    stringValue: value
                  }
                }
              }
            }
          }
          sustainabilityAttributeFilters {
            displayName
            attributeType
            type
            availableValues(first: 50) {
              nodes {
                ... on BooleanSustainabilityAttribute {
                  booleanValue: value
                }
                ... on ScalarSustainabilityAttribute {
                  scalarValue: value
                  units
                }
              }
            }
          }
          performanceAttributeFilters {
            displayName
            attributeType
            type
            availableValues(first: 50) {
              nodes {
                attributeType
                displayName
                ... on BooleanPerformanceAttribute {
                  booleanValue: value
                }
                ... on ScalarPerformanceAttribute {
                  units
                  scalarValue: value
                }
                ... on StringPerformanceAttribute {
                  stringValue: value
                }
              }
            }
          }
        }
      }
    }
    projects {
      nodes {
        name
        slug
      }
    }
  }
`;

export const GET_PRODUCT_DETAILS = gql`
  ${PRODUCT_CARD_FRAGMENT}
  ${DOCUMENT_FRAGMENT}
  ${CATEGORY_FRAGMENT}
  query GetProductDetails($slug: String!) {
    catalog {
      product(slug: $slug) {
        ...ProductCardFragment
        description
        primaryCategory {
          ...CategoryFragment
        }
        manufacturer {
          name
          address {
            displayAddress: city
          }
        }
        environmentalImpact {
          carbon {
            kgCo2e {
              perUnit
            }
            comparedToBaseline {
              baseline {
                kgCo2e
                name
              }
              ratioToBaseline
              isSustainable
              warnings {
                message
              }
            }
            declaredUnit
          }
        }
        performanceAttributes {
          attributeType
          displayName

          ... on BooleanPerformanceAttribute {
            booleanValue: value
          }

          ... on ScalarPerformanceAttribute {
            scalarValue: value
            units
          }

          ... on StringPerformanceAttribute {
            stringValue: value
          }
        }

        sustainabilityAttributes {
          attributeType
          displayName

          ... on BooleanSustainabilityAttribute {
            booleanValue: value
          }

          ... on ScalarSustainabilityAttribute {
            scalarValue: value
          }
        }

        certifications {
          issuedAt
          expiresAt
          documents {
            ...DocumentFragment
          }
        }

        documents {
          ...DocumentFragment
        }
      }
    }
  }
`;

export const GET_PROJECTS_ASSOCIATED_WITH_PRODUCT = gql`
  query GetProductProjects($slug: String!, $q: String) {
    catalog {
      product(slug: $slug) {
        projects {
          nodes {
            name
            slug
          }
        }
      }
    }
    projects(filter: { q: $q }) {
      nodes {
        name
        slug
      }
    }
  }
`;
