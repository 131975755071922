import { AlertProps } from "@mui/material/Alert";
import React, { useCallback, useMemo, useState } from "react";

type SnackbarOptions = {
  severity: AlertProps["severity"];
  duration?: number;
};

type ContextType = {
  dismiss: () => void;
  flashMessage: (message: string, ephemeralOptions?: SnackbarOptions) => void;
  message: string | null;
  options: SnackbarOptions;
  visible: boolean;
};

const defaultSnackbarOptions: SnackbarOptions = {
  duration: 6000,
  severity: "info",
};

const defaultSnackbarConfig: ContextType = {
  dismiss: () => {},
  flashMessage: () => {},
  message: null,
  visible: false,
  options: defaultSnackbarOptions,
};

export const SnackbarContext = React.createContext<ContextType>(
  defaultSnackbarConfig
);

export const useSnackbar = (
  snackbarOptions: SnackbarOptions = defaultSnackbarOptions
) => {
  const [message, setMessage] = useState<string | null>(null);
  const [visible, setVisible] = useState<boolean>(false);

  const initialOptions = useMemo(
    () => ({
      ...defaultSnackbarOptions,
      ...snackbarOptions,
    }),
    [snackbarOptions]
  );
  const [options, setOptions] = useState<SnackbarOptions>(initialOptions);

  const flashMessage = useCallback(
    (
      message: string,
      ephemeralOptions: SnackbarOptions = defaultSnackbarOptions
    ) => {
      setMessage(message);
      setVisible(true);
      setOptions((prevOptions: SnackbarOptions) => ({
        ...prevOptions,
        ...ephemeralOptions,
      }));
      setTimeout(() => {
        setVisible(false);
        setOptions(initialOptions);
      }, options.duration);
    },
    [initialOptions, options.duration]
  );

  const dismiss = useCallback(() => {
    setVisible(false);
  }, []);

  return {
    message,
    options,
    flashMessage,
    dismiss,
    visible,
  };
};
