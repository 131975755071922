import {
  HTMLAttributes,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from "react";
import "./WithLoadingState.scss";
import clsx from "clsx";
import _ from "lodash";

export const LOADING_CLASS_NAME = "loading-data";

export const WithLoadingState = ({
  children,
  isLoading,
  customLoadingElement,
  height,
  className,
  marginBottom,
  timeout,
  ...props
}: PropsWithChildren<{
  isLoading: boolean;
  customLoadingElement?: ReactNode;
  height?: number | string;
  marginBottom?: number;
  timeout?: number;
}> &
  HTMLAttributes<HTMLDivElement>) => {
  // If set, timeout will cause the loading state to be removed after the specified time
  const [timedOut, setTimedOut] = useState(false);
  const setTimedOutWithLoading = useCallback(() => {
    if (!isLoading) return;
    setTimedOut(true);
  }, [setTimedOut, isLoading]);
  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (timeout && timeout > 0) {
      timeoutId = setTimeout(setTimedOutWithLoading, timeout);
    }
    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [timeout, isLoading]);

  if (!isLoading || timedOut) {
    return <>{children}</>;
  }

  const defaultMarginBottom = _.isString(height)
    ? 10
    : Math.min(Number(height) / 3, 10);

  const heightSpecifiedStyle = height
    ? { height, marginBottom: marginBottom || defaultMarginBottom }
    : {};

  return customLoadingElement ? (
    <>{customLoadingElement}</>
  ) : (
    <div
      role="progressbar"
      className={clsx(LOADING_CLASS_NAME, className)}
      style={heightSpecifiedStyle}
      {...props}
    />
  );
};

export default WithLoadingState;
