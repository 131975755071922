import _ from "lodash";
import { useMemo, useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const defaultTabHooks = {
  prepareToChange: _.noop,
  validate: async () => true,
  onChange: _.noop,
  onInvalid: _.noop,
};

// Hooks to (1) set up tabs and (2) allow changing tabs.
export const useTabs = <T = any>({
  tabsCount,
  initialValues,
  determineHighestValidTab: determineHighestValidTabProp,
  events = defaultTabHooks,
  urlParameterPrefix = "tab-",
}: {
  events: {
    onChange: () => void;
    onInvalid: () => void;
    prepareToChange: () => void;
    validate: () => Promise<boolean>;
  };
  initialValues: any;
  tabsCount: number;
  determineHighestValidTab?: (initialValues: T) => number;
  urlParameterPrefix?: string;
}) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const [mounted, setMounted] = useState(false);

  function _setTab(v: number) {
    navigate(
      pathname.replace(
        new RegExp(`${urlParameterPrefix}?\\d+`),
        `${urlParameterPrefix}${v}`
      )
    );
  }

  const determineHighestValidTab = useMemo(
    () => determineHighestValidTabProp || (() => tabsCount),
    [tabsCount, determineHighestValidTabProp]
  );

  const highestValidTab = useMemo(
    () => determineHighestValidTab(initialValues),
    [initialValues, determineHighestValidTab]
  );
  const tabParam = useMemo(
    () => Number(params.page?.replace(/\D+/, "")),
    [params.page]
  );
  const currentTab = tabParam || 1;
  const canNavigateToTab = useCallback(
    (newValue: number) => newValue < currentTab || newValue < highestValidTab,
    [currentTab, highestValidTab]
  );

  const setTab = (v: number) => {
    _setTab(Math.min(Math.max(v, 1), tabsCount));
  };

  useEffect(
    function disallowURLNavigationToInaccessibleTabs() {
      if (mounted) return;
      setMounted(true); // only perform on initial render
      if (currentTab > highestValidTab) {
        const replaced = pathname.replace(
          new RegExp(`${urlParameterPrefix}?\\d+`, ""), // remove the page prefix from path
          `${urlParameterPrefix}${highestValidTab}`
        );
        navigate(replaced);
      }
    },
    [
      setMounted,
      mounted,
      currentTab,
      navigate,
      pathname,
      highestValidTab,
      urlParameterPrefix,
    ]
  );

  const validateAndSetTab = async (
    newValue: number,
    validate: boolean = true
  ) => {
    if (!validate || canNavigateToTab(newValue)) {
      setTab(newValue);
      return;
    }

    events.prepareToChange();
    // Reset context if valid - trigger returns true/false
    if (await events.validate()) {
      events.onChange();
      setTab(newValue);
    } else {
      events.onInvalid();
    }
  };

  return {
    setTab,
    currentTab,
    highestValidTab,
    canNavigateToTab,
    validateAndSetTab,
  };
};
