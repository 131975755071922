import _ from "lodash";
import "./BuildingImpactSection.scss";
import Typography from "@mui/material/Typography";
import { KGCO2E } from "utils/formatting";
import CarbonByLifecycleStageStackedBarChart from "./Visualizations/CarbonByLifecycleStageStackedBarChart";
import CarbonByBuildingElementStackedBarChart from "./Visualizations/CarbonByBuildingElementStackedBarChart";
import CarbonImpactByProductCategory from "./Visualizations/CarbonImpactByCategory";
import { ProjectReportData } from "../ProjectReport";
import { ReportCategoryEdge } from "gql/graphql";

interface Props {
  data?: ProjectReportData;
}
const BuildingImpactSection = ({ data }: Props) => {
  const lifecycleStageEdges = data?.carbon?.lifecycleStages?.edges;
  const buildingElementEdges = data?.carbon?.buildingElements?.edges;
  const categoryEdges = data?.carbon?.categories?.edges;
  return (
    <div id="building-impact" className="border visualization-block">
      <div className="title">
        <Typography variant="h6">Building Impact</Typography>
        <Typography variant="body2" className="subtitle">
          <KGCO2E />
        </Typography>
      </div>
      {!_.isEmpty(lifecycleStageEdges) && (
        <CarbonByLifecycleStageStackedBarChart
          data={_.map(data?.carbon?.lifecycleStages.edges, "node")}
        />
      )}
      {!_.isEmpty(buildingElementEdges) && (
        <CarbonByBuildingElementStackedBarChart
          data={_.map(buildingElementEdges, "node")}
        />
      )}
      {!_.isEmpty(categoryEdges) && (
        <CarbonImpactByProductCategory
          data={categoryEdges as ReportCategoryEdge[]}
        />
      )}
    </div>
  );
};

export default BuildingImpactSection;
