import React, { ForwardedRef } from "react";
import { Popper, PopperProps } from "@mui/material";

import { PopperComponentProps } from "./hooks";
import { defaultProps } from "./constants";
import { LifecycleStagesTooltip } from "./LifecycleStagesTooltip";

import "./LifecycleStagesPopper.scss";
import clsx from "clsx";

export const LifecycleStagesPopper = React.forwardRef(
  (
    { open, anchorEl, ...props }: PopperComponentProps & PopperProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => (
    <Popper
      keepMounted
      {...defaultProps}
      {...props}
      className={clsx(
        defaultProps.className,
        "LifecycleStagesPopper-popper",
        props.className
      )}
      anchorEl={anchorEl}
      open={open}
      ref={ref}
    >
      <LifecycleStagesTooltip />
    </Popper>
  )
);
