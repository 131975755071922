import UserAndOrganizationContext from "context/UserAndOrganizationContext";
import { useContext, useEffect, useRef } from "react";

export const LogoutComponent = () => {
  const [, { logout }] = useContext(UserAndOrganizationContext);
  const ref = useRef(false);
  useEffect(() => {
    if (ref.current) return;
    logout();
    ref.current = true;
  }, [logout]);

  return null;
};
