import React from "react";

export type InvalidContextType = {
  count: number;
  date: Date | false;
};

export const InvalidContextDefault: InvalidContextType = {
  date: false,
  count: 0,
};

export const InvalidContext = React.createContext<InvalidContextType>(
  InvalidContextDefault
);
