import _ from "lodash";
import { useEffect, useMemo, useState } from "react";
import { COLOR_PALETTE } from "../constants";

const inMemoryIconCache: { [key: string]: string } = {};

export const UserIcon = ({
  name,
  email,
  fill = "#ADCAD2",
  size = 90,
}: {
  email?: string;
  fill?: string;
  name?: string;
  size?: number;
}) => {
  const [url, setUrl] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);

  const initials = useMemo(() => getInitials(name), [name]);

  useEffect(() => {
    (async function () {
      if (!email) return;

      if (inMemoryIconCache[email]) {
        setUrl(inMemoryIconCache[email]);
        setLoading(false);
        return;
      }

      const hash = await getSHA256Hash(email);
      if (!hash) return;

      if (!inMemoryIconCache[email]) {
        setLoading(true);
        const checkedUrl = await fetch(
          `https://www.gravatar.com/avatar/${hash}?d=404&size=90`
        );
        if (checkedUrl.ok) {
          const base64 = await getBase64Blob(await checkedUrl.blob());
          inMemoryIconCache[email] = base64;
          setUrl(base64);
        } else {
          setUrl(null);
        }
      }
      setLoading(false);
    })();
  }, [email, initials, fill, size, setLoading, setUrl]);

  if (loading) {
    return (
      <div
        data-testid="loading"
        style={{
          width: size,
          height: size,
          backgroundColor: COLOR_PALETTE.lightFill,
          borderRadius: size,
        }}
      />
    );
  }

  if (loading) {
    return (
      <div
        data-testid="loading"
        style={{
          width: size,
          height: size,
          backgroundColor: COLOR_PALETTE.lightFill,
          borderRadius: size,
        }}
      />
    );
  }

  if (url) {
    return (
      <img
        width={size}
        height={size}
        style={{ borderRadius: size }}
        src={url}
        alt={name}
      />
    );
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width={size}
      height={size}
      data-testid={`svg ${name}`}
      viewBox={`0 0 ${size} ${size}`}
    >
      <g transform={`matrix(1 0 0 1 ${size / 2} ${size / 2})`}>
        <circle cx="0" cy="0" r={size / 2} fill={fill} />
      </g>
      <g transform={`matrix(1 0 0 1 0 ${size / 2})`}>
        <text
          fontFamily="Epilogue, sans-serif"
          fontStyle="normal"
          fill="#fff"
          fontSize={size / 3}
          fontWeight="600"
          transform={`matrix(1 0 0 1 ${size / 2} ${-(size / 2.75)})`}
          textAnchor="middle"
        >
          <tspan x="0" y={size / 2}>
            {initials}
          </tspan>
        </text>
      </g>
    </svg>
  );
};

const getSHA256Hash = async (input: string) => {
  if (!window.crypto) return;
  const hashBuffer = await window.crypto.subtle.digest(
    "SHA-256",
    new TextEncoder().encode(input)
  );
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hash = hashArray
    .map((item) => item.toString(16).padStart(2, "0"))
    .join("");
  return hash;
};

export const getInitials = (name?: string) => {
  if (!name) {
    return "";
  }
  const parts = name.split(" ");
  const firstPart = parts[0];
  if (parts.length > 1) {
    const lastPart = parts[parts.length - 1];
    return _.upperCase(firstPart[0] + lastPart[0]);
  }
  return _.upperCase(firstPart[0]);
};

const getBase64Blob = async (blob: Blob) => {
  return new Promise<string>((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result;
      resolve(base64data as string);
    };
  });
};
