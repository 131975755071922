import { COLOR_PALETTE, TYPEFACES } from "../../constants";

declare module "@mui/material/styles" {
  interface TypographyVariants {
    popperTitle: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    popperTitle?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    popperTitle: true;
  }
}

export const customTypography = {
  popperTitle: {
    fontFamily: TYPEFACES.heading,
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "19.2px",
    marginBottom: "16px",
    color: COLOR_PALETTE.almostBlack,
  },
};
