import _ from "lodash";
import { User, UserPermissionsEnum } from "gql/graphql";
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableProps,
  TableRow,
} from "@mui/material";
import { UserCell } from "./UserCell";
import {
  EditOutlined as EditIcon,
  DeleteOutline as DeleteIcon,
} from "@mui/icons-material";

import { UserManagementMode } from "../Users";

import "./UsersTable.scss";
import { useCallback, useContext } from "react";
import UserAndOrganizationContext from "context/UserAndOrganizationContext";
import { useUserPermissions } from "components/PermissionRestricted";

export const UsersTable = ({
  onUserAction,
  users,
  ...tableProps
}: {
  onUserAction: (user: User, mode: UserManagementMode) => void;
  users: User[];
} & TableProps) => {
  const [{ currentUser }] = useContext(UserAndOrganizationContext);
  const handleDelete = useCallback(
    (user: User) => {
      if (user.id === currentUser?.id) {
        return undefined;
      }
      return () => {
        onUserAction(user, UserManagementMode.Delete);
      };
    },
    [currentUser, onUserAction]
  );
  const onEdit = useCallback(
    (user: User) => () => onUserAction(user, UserManagementMode.Edit),
    [onUserAction]
  );
  const onShow = useCallback(
    (user: User) => () => onUserAction(user, UserManagementMode.Show),
    [onUserAction]
  );
  return (
    <Table id="users-table" {...tableProps}>
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell width={50}>Action</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {_.map(users, (user) => (
          <TableRow key={user.id}>
            <TableCell>
              <UserCell {...user} onClick={onShow(user)} />
            </TableCell>
            <TableCell>
              <ActionCell onEdit={onEdit(user)} onDelete={handleDelete(user)} />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default UsersTable;

const ActionCell = ({
  onEdit,
  onDelete,
}: {
  onEdit: () => void;
  onDelete?: () => void;
}) => {
  const canDeleteUsers = useUserPermissions(UserPermissionsEnum.DeleteUsers);
  const canUpdateUsers = useUserPermissions(UserPermissionsEnum.UpdateUsers);
  return (
    <Box display="flex" justifyContent="space-between">
      {canUpdateUsers ? (
        <IconButton onClick={onEdit}>
          <EditIcon />
        </IconButton>
      ) : null}
      {canDeleteUsers ? (
        <IconButton disabled={!onDelete} onClick={onDelete}>
          <DeleteIcon />
        </IconButton>
      ) : null}
    </Box>
  );
};
