import React from "react";
import "./Listbox.scss";
import clsx from "clsx";

export { GroupHeader as ListboxGroupHeader } from "./Listbox/GroupHeader";
export { GroupList as ListboxGroupList } from "./Listbox/GroupList";

type Props = {
  children?: React.ReactNode;
  className?: string;
};

// forward ref ul element
export const Listbox = React.forwardRef<HTMLUListElement, Props>(
  (props, ref) => {
    return (
      <ul ref={ref} {...props} className={clsx("Listbox", props.className)} />
    );
  }
);

export default Listbox;
