import _, { DebouncedFunc } from "lodash";
import { useRef, useState, useMemo, useCallback, useEffect } from "react";
import { VirtualElement } from "@popperjs/core";
import clsx from "clsx";

export type PopperComponentProps = {
  anchorEl: VirtualElement;
  open: boolean;
};

type Status = "in" | "out" | "closing";

type Statuses = {
  header: Status;
  popper: Status;
};

const allOut: Statuses = { header: "out", popper: "out" };

const MAX_DEBOUNCES = 10;

export const useHeaderPopper = (
  headerRef: React.MutableRefObject<any>,
  Component: React.ElementType<PopperComponentProps>,
  options = { scrollOutAllowanceMS: 500 }
) => {
  const popperRef = useRef<HTMLDialogElement | null>(null);

  const [status, setStatus] = useState<Statuses>({
    header: "out",
    popper: "out",
  });
  // Open if any in `in` state
  const open = useMemo(() => _.some(status, (s) => s === "in"), [status]);
  const closing = useMemo(
    () => _.some(status, (s) => s === "closing"),
    [status]
  );

  const activeDebounces = useRef<Array<DebouncedFunc<() => void>>>([]);

  const updateStatus = useCallback(
    (which: "header" | "popper", ref: React.RefObject<HTMLElement>) => {
      const { current } = ref;
      const { current: debounces } = activeDebounces;
      if (!current) return;

      const el = current as HTMLElement;

      const resetEvents = () => {
        debounces.forEach((d) => d.cancel());
        activeDebounces.current = [];
      };

      const enter = () => {
        resetEvents();
        setStatus({ ...allOut, [which]: "in" });
      };
      const close = () => {
        setStatus((prev) => ({ ...prev, [which]: "closing" }));
        const closeHandler = _.debounce(() => {
          setStatus((prev) => ({ ...prev, [which]: "out" }));
        }, 200);
        debounces.push(closeHandler);
        closeHandler();
      };
      const out = (event: MouseEvent) => {
        resetEvents();
        const { clientY } = event;
        const rect = headerRef.current.getBoundingClientRect();
        const currentPlacement = popperRef.current?.getAttribute(
          "data-popper-placement"
        );

        let mousedOutInDirectionOfTooltip: boolean;
        switch (currentPlacement) {
          case "top":
            mousedOutInDirectionOfTooltip = clientY < rect.top;
            break;
          case "bottom":
            mousedOutInDirectionOfTooltip = clientY > rect.bottom;
            break;
          // left and right not needed on header
          default:
            mousedOutInDirectionOfTooltip = false;
        }

        // Might be going to another tooltip'd item within the header, so don't wait
        const scrollOutAllowanceMS =
          mousedOutInDirectionOfTooltip && which === "header"
            ? options.scrollOutAllowanceMS
            : 0;

        const outHandler = _.debounce(() => {
          close();
        }, scrollOutAllowanceMS);

        // Super simple garbage collection
        if (debounces.length > MAX_DEBOUNCES) {
          debounces.shift();
        }
        debounces.push(outHandler);
        outHandler();
      };

      el.addEventListener("mouseenter", enter);
      el.addEventListener("mouseleave", out);

      return () => {
        el.removeEventListener("mouseenter", enter);
        el.removeEventListener("mouseleave", out);
        // Call all active debounces. It's ok to call after they're executed.
        // We leave them around, but limit the total size to 10. This is kind of a
        // pre-op, I just hate those warnings about setState on unmounted components.
        debounces.forEach((d) => d.flush());
      };
    },
    [setStatus, headerRef, popperRef, options.scrollOutAllowanceMS]
  );

  // Mount, with unmount return values
  useEffect(() => {
    return updateStatus("header", headerRef);
  }, [headerRef, updateStatus]);
  useEffect(() => {
    return updateStatus("popper", popperRef);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popperRef, popperRef, popperRef.current, updateStatus]);
  /*
    Line 128:6:  React Hook useEffect has an unnecessary dependency: 'popperRef.current'
    Don't full understand why, but current must be a dep. This effect hook will never run again
    after ref is set. I suspect it's related to forwardRef.
  */

  return (
    <Component
      ref={popperRef}
      anchorEl={headerRef.current}
      open={open || closing}
      className={clsx({ "MuiPopper-popperClosing": closing && !open })}
    />
  );
};
