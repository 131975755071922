import { ApolloClient, gql } from "@apollo/client";
import { ProjectCreationInput } from "gql/graphql";

import { PROJECT_FRAGMENT } from "graphql/fragments";
import { safeAddressFields } from "./utils";

export const READ_PROJECT_FROM_CACHE = gql`
  ${PROJECT_FRAGMENT}
  query ReadProject($slug: String!) {
    project(slug: $slug) {
      ...ProjectFragment
    }
  }
`;

const slug = "**LOCAL**";

export class GuidedProjectCache {
  client: ApolloClient<any>;

  static slug = slug;
  static query = READ_PROJECT_FROM_CACHE;

  constructor(client: ApolloClient<any>) {
    this.client = client;
  }

  clear() {
    this.client.writeQuery({
      query: READ_PROJECT_FROM_CACHE,
      data: { project: null },
      variables: {
        slug,
      },
    });
  }

  read() {
    return this.client.readQuery({
      query: READ_PROJECT_FROM_CACHE,
      variables: {
        slug,
      },
    });
  }

  save(values: Partial<ProjectCreationInput>) {
    const data = {
      __typename: "Project",
      slug,
      ...values,
      area: null,
    };
    return this.client.writeQuery({
      query: READ_PROJECT_FROM_CACHE,
      data: {
        // Contains the data to write
        project: {
          ...data,
          address: safeAddressFields(data.address),
        },
      },
      variables: {
        slug,
      },
    });
  }
}
