import { NetworkStatus, useQuery } from "@apollo/client";
import Tooltip from "@mui/material/Tooltip";
import _ from "lodash";
import { PropsWithChildren, useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";

import ImageWithPlaceholder from "components/ImageWithPlaceholder";
import { ProductDetailActions } from "components/ProductActions/ProductActions";
import ReachOutLink from "components/ReachOutLink";
import {
  CarbonImpact,
  Certification,
  Document,
  GetProductDetailsQuery,
  Maybe,
  ProductGeography,
  Project,
} from "gql/graphql";
import { GET_PRODUCT_DETAILS } from "graphql/queries/products";
import { GET_PROJECTS } from "graphql/queries/projects";
import { getFormattedGeographies } from "utils/geography";
import { PLACEHOLDER_PRODUCT_IMG_SRC } from "../../constants";
import CarbonImpactSection from "./CarbonImpactSection";
import ProductDocument from "./Document";
import PerformanceAttributes, {
  ModifiedPerformanceAttribute,
} from "./PerformanceAttributes";
import "./ProductDetails.scss";
import SustainabilityAttributes from "./SustainabilityAttributes";
import { useTitle } from "context/TitleContext";
import { Icon } from "@mui/material";

const linkToProductsCatalogFiltered = (categoryGlobalId: string) =>
  "/products/all?" +
  new URLSearchParams({
    "filter.category.in.0": categoryGlobalId,
  }).toString();

const BreadcrumbLabel = ({ children }: PropsWithChildren) => (
  <span className="breadcrumb">{children}</span>
);
const BreadcrumbLink = ({
  children,
  to,
}: PropsWithChildren & { to: string }) => (
  <Link to={to} className="link">
    {children}
  </Link>
);

const MAX_GEO_CHARS = 84; // approximate guess to limit to 1 line (~ 1400px screen width)
const ProductDetails = () => {
  const params = useParams();

  const { error, data, networkStatus } = useQuery<GetProductDetailsQuery>(
    GET_PRODUCT_DETAILS,
    {
      variables: { slug: params.slug },
    }
  );

  const product = data?.catalog?.product;

  useEffect(() => {
    if (
      networkStatus === NetworkStatus.ready &&
      data?.catalog?.product === null
    ) {
      throw new Error("Not Found");
    }
  }, [networkStatus, data?.catalog?.product]);

  const {
    certifications,
    description,
    documents,
    environmentalImpact,
    imageUrl,
    geographies,
    hasIwEpd,
    manufacturer,
    name,
    performanceAttributes,
    sustainabilityAttributes,
    primaryCategory,
  } = product || {};
  const { data: myProjectsData } = useQuery(GET_PROJECTS);

  useTitle(data?.catalog?.product?.name || "Product Details");

  const [imgSrc, setImgSrc] = useState<string>(
    imageUrl || PLACEHOLDER_PRODUCT_IMG_SRC
  );

  useEffect(() => {
    if (imageUrl) {
      setImgSrc(imageUrl);
    }
  }, [imageUrl]);

  if (error) throw error;

  const categoryName = _.get(primaryCategory, "name", "") as string;
  const categoryGlobalId = _.get(primaryCategory, "id", "") as string;

  const certificationDocuments = _.flatMap(certifications, (certification) =>
    _.map(certification.documents, (document) => ({
      ...document,
      expiresAt: certification.expiresAt,
      issuedAt: certification.issuedAt,
    }))
  );

  const allDocuments = _.concat(
    documents,
    certificationDocuments
  ) as (Document & Certification)[];

  return (
    <div className={`product-details ${hasIwEpd ? "iw-epd" : ""}`}>
      <p className="body-4 breadcrumbs">
        <BreadcrumbLink to="/products/all">Products</BreadcrumbLink>
        <Icon className="material-icons md-18">chevron_right</Icon>
        {categoryGlobalId ? (
          <BreadcrumbLink to={linkToProductsCatalogFiltered(categoryGlobalId)}>
            {categoryName}
          </BreadcrumbLink>
        ) : (
          <BreadcrumbLabel>{categoryName}</BreadcrumbLabel>
        )}
        &nbsp;
        <Icon className="material-icons md-18">chevron_right</Icon>{" "}
        <BreadcrumbLabel>{name}</BreadcrumbLabel>
      </p>
      <div className="overview default-shadow default-border">
        <div className={`img-container ${hasIwEpd ? "" : "default-shadow"}`}>
          <ImageWithPlaceholder
            entity="product"
            imageUrl={imgSrc}
            name={name as string}
          />
        </div>
        <div>
          <span className="body-3"> {categoryName}</span>
          <Tooltip
            placement="right"
            title={(name as string) || ""}
            componentsProps={{
              popper: { style: { width: 200 } },
            }}
          >
            <h4>{name}</h4>
          </Tooltip>
          <div className="manufacturer-and-location">
            {manufacturer && (
              <p className="body-1">
                <Icon className="material-icons-outlined md-16">person</Icon>
                {manufacturer?.name}
              </p>
            )}
            {manufacturer?.address?.displayAddress && (
              <p className="body-1">
                <Icon className="material-icons-outlined md-16">
                  location_on
                </Icon>
                {manufacturer?.address?.displayAddress}
              </p>
            )}
            <p className="body-1">
              <Icon className="material-icons-outlined md-16">location_on</Icon>
              {getFormattedGeographies(
                geographies as Maybe<ProductGeography[]>,
                MAX_GEO_CHARS
              )}
            </p>
          </div>
        </div>

        {product && product.slug && (
          <ProductDetailActions productSlug={product.slug} />
        )}
      </div>
      {hasIwEpd && (
        <div className="iw-epd-info">
          <p className="subtitle-1">This is an industry-wide EPD.</p>
          <p className="body">
            This information does not represent a specific product or
            manufacturer, but is rather an estimation for this product type
            developed by the industry. We recommend you use this information
            only as a placeholder or where you are unable to find the specific
            product you plan to use.
          </p>
        </div>
      )}
      <div className="content">
        <div className="default-border default-shadow">
          <CarbonImpactSection
            carbonImpact={environmentalImpact?.carbon as CarbonImpact}
            declaredUnit={primaryCategory?.declaredUnit as string}
            primaryCategoryName={primaryCategory?.name as string}
          />
          {!_.isEmpty(sustainabilityAttributes) && (
            <SustainabilityAttributes
              sustainabilityAttributes={sustainabilityAttributes}
            />
          )}
          {!_.isEmpty(performanceAttributes) && (
            <PerformanceAttributes
              performanceAttributes={
                performanceAttributes as ModifiedPerformanceAttribute[]
              }
            />
          )}
          <div className="product-detail-about-section section">
            <p className="subtitle-1">About</p>
            <p className="body-1">{description}</p>
          </div>
        </div>
        <div className="right-col">
          <div className="documents default-shadow default-border">
            <p className="subtitle-1">Documents</p>

            <section>
              {_.map(allDocuments, (d, index) => (
                <ProductDocument key={index} {...d} />
              ))}
            </section>
            {_.get(manufacturer, "emailAddress") && (
              <section>
                <div className="row">
                  <a
                    className="link pt-1"
                    key="manufacturer-email"
                    href={`mailto:${_.get(manufacturer, "emailAddress")}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span>Contact Manufacturer</span>
                  </a>
                  <span
                    className="material-icons menu md-18"
                    style={{ position: "relative", left: "1px" }} // I have no idea why, but this icon has random padding on the right
                  >
                    mail
                  </span>
                </div>
              </section>
            )}
          </div>
          <ReachOutLink />
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;
