import clsx from "clsx";
import { IconButton } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import "./UploadedFile.scss";

export const UploadedFile = ({
  filename,
  onCancel,
}: {
  filename: string;
  onCancel: () => void;
}) => {
  return (
    <div className="uploaded-file">
      <CheckCircleIcon />
      <span className="filename">{filename}</span>
      <IconButton
        className={clsx("material-icons", "close-button", "md-18")}
        onClick={onCancel}
      >
        close
      </IconButton>
    </div>
  );
};
