import { ComparedToBaseline } from "gql/graphql";
import "./Chips.scss";
import clsx from "clsx";

const TAG_AND_CHIP_CONFIG = {
  average: {
    className: "average",
    chipCopy: "Average",
  },
  above_baseline: {
    className: "above_baseline",
    chipCopy: "Above Average",
    chipIcon: "arrow_upward",
  },
  carbon_sequestering: {
    className: "carbon_sequestering",
    chipCopy: "Carbon Sink",
    chipIcon: "eco",
  },
  is_low_carbon: {
    className: "low_carbon",
    chipCopy: "Low Carbon",
    chipIcon: "arrow_downward",
  },
};

const Chip = ({
  isAverage,
  isAboveBaseline,
  isLowCarbon,
  isCarbonSequestering,
  tagVariant,
}: ComparedToBaseline & { tagVariant?: boolean }) => {
  if (!isAboveBaseline && !isLowCarbon && !isCarbonSequestering && !isAverage) {
    return null;
  }

  let chipConfig: { chipCopy: string; chipIcon?: string; className?: string } =
    {
      className: "",
      chipCopy: "",
    };

  switch (true) {
    case isAverage:
      chipConfig = TAG_AND_CHIP_CONFIG.average;
      break;
    case isAboveBaseline:
      chipConfig = TAG_AND_CHIP_CONFIG.above_baseline;
      break;
    case isCarbonSequestering:
      chipConfig = TAG_AND_CHIP_CONFIG.carbon_sequestering;
      break;
    case isLowCarbon:
      chipConfig = TAG_AND_CHIP_CONFIG.is_low_carbon;
      break;
    default:
  }

  return (
    <span
      className={clsx(
        tagVariant ? "tag" : "chip",
        chipConfig.className,
        "unselectable"
      )}
    >
      <i className="material-icons md-14">{chipConfig.chipIcon}</i>
      {chipConfig.chipCopy}
    </span>
  );
};

Chip.willDisplay = (chipProps: ComparedToBaseline) => {
  return Boolean(
    chipProps.isAboveBaseline ||
      chipProps.isLowCarbon ||
      chipProps.isCarbonSequestering ||
      chipProps.isAverage
  );
};

export default Chip;
