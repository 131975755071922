import { Navigate, Route } from "react-router-dom";

import { Title } from "context/TitleContext";
import Login from "pages/Auth/Login";
import ResetPassword from "pages/Auth/ResetPassword";
import Register from "pages/Auth/Register";
import RegisterSuccess from "pages/Auth/RegisterSuccess";

export const LoggedOut = (
  <>
    <Route
      path="login"
      element={
        <Title title="Login">
          <Login />
        </Title>
      }
    />
    <Route
      path="register"
      element={
        <Title title="Sign Up">
          <Register />
        </Title>
      }
    />
    <Route
      path="register-success"
      element={
        <Title title="Registration Successful">
          <RegisterSuccess />
        </Title>
      }
    />
    <Route
      path="reset-password/:uid/:token"
      element={
        <Title title="Reset Password">
          <ResetPassword />
        </Title>
      }
    />
    <Route path="*" element={<Navigate to="/login" />} />
  </>
);
