import { Fragment } from "react";
import _ from "lodash";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import clsx from "clsx";

import ProductActions from "./ProductActions/ProductActions";
import CarbonChip from "./Chips";
import ImageWithPlaceholder from "./ImageWithPlaceholder";
import { GET_PROJECTS } from "graphql/queries/projects";
import "./SingleProduct.scss";
import { Manufacturer, Product, ProductGeography } from "gql/graphql";
import { getFormattedGeographies } from "utils/geography";

const MAX_CHARS_FOR_GEOS = 26;

const ProductInfo = ({
  name,
  hasIwEpd,
  manufacturer,
  productGeographies,
  hasChip = false,
  ratioToBaseline,
}: {
  hasIwEpd: boolean;
  manufacturer: Manufacturer;
  name: string;
  productGeographies: ProductGeography[];
  ratioToBaseline: number;
  hasChip?: boolean;
}) => {
  /* We are using this hacky method to clamp at ~3 lines for now because AFAIK, there is now support across browsers for line-clamp */
  const truncateToTwoLines = hasChip && manufacturer;
  const limitedWidthName = _.truncate(name, {
    length: truncateToTwoLines
      ? productNameLengthLimits.short
      : productNameLengthLimits.long,
  });
  return (
    <>
      <p title={name} className="body-4">
        {limitedWidthName}
      </p>
      {manufacturer && (
        <p className="more-info ">{_.get(manufacturer, "name")}</p>
      )}
      {hasIwEpd && <p className="more-info">Industry Average</p>}
      <p className="more-info">
        {getFormattedGeographies(productGeographies, MAX_CHARS_FOR_GEOS)}
      </p>
    </>
  );
};

const productNameLengthLimits = { long: 80, short: 60 };

type Props = Product & {
  hasIwEpd: boolean;
  manufacturer: Manufacturer;
  ratioToBaseline: number;
  isAboveBaseline?: boolean;
  isAverage?: boolean;
  isCarbonSequestering?: boolean;
  isLowCarbon?: boolean;
  projectSlug?: string;
};

const SingleProduct = ({
  isAboveBaseline,
  isAverage,
  isCarbonSequestering,
  primaryCategory,
  imageUrl,
  geographies,
  hasIwEpd,
  manufacturer,
  name,
  slug,
  projectSlug,
  ratioToBaseline,
  isLowCarbon,
}: Props) => {
  const { data } = useQuery(GET_PROJECTS);

  const myProjects = data?.projects.nodes;

  const chipProps = {
    isAboveBaseline,
    isLowCarbon,
    isCarbonSequestering,
    isAverage,
  };

  return (
    <Fragment>
      <div
        className={clsx([
          "single-product-container",
          {
            "iw-epd-card-container": hasIwEpd,
          },
        ])}
      >
        <ProductActions productSlug={slug as string} />
        <Link
          to={`/products/${slug}`}
          className="single-product"
          style={{ textDecoration: "none" }}
          target="_blank"
          rel="noopener noreferrer"
        >
          <ImageWithPlaceholder
            entity="product"
            imageUrl={imageUrl as string}
            name={name as string}
          />
          <div className="product-info">
            <p className="category">{_.get(primaryCategory, "name")}</p>
            <ProductInfo
              name={name as string}
              hasIwEpd={hasIwEpd}
              manufacturer={manufacturer}
              productGeographies={geographies as ProductGeography[]}
              hasChip={CarbonChip.willDisplay(chipProps)}
              ratioToBaseline={ratioToBaseline}
            />
            <CarbonChip {...chipProps} tagVariant />
          </div>
        </Link>
      </div>
    </Fragment>
  );
};

export default SingleProduct;
