import _ from "lodash";
import { ResolverResult } from "react-hook-form";

import { ResetPasswordForm } from "pages/Auth/ResetPassword";

const required = (val: string | number | boolean) => !!val;

export const resetPasswordValidate = (values: ResetPasswordForm) => {
  const errors = {
    password1: !required(values.password1) && "Password is required.",
    password2: values.password1 !== values.password2 && "Passwords must match.",
  };

  return {
    errors: _.pickBy(errors, (errorBool: boolean) => !!errorBool),
    values,
  } as ResolverResult<ResetPasswordForm>;
};
