import { useCallback, useEffect, useMemo, useContext, MouseEvent } from "react";
import _ from "lodash";
import dayjs, { Dayjs } from "dayjs";
import clsx from "clsx";
import saveAs from "file-saver";
import { Download } from "@mui/icons-material";
import {
  Button,
  Divider,
  FormControl,
  FormGroup,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";

import PortfolioFiltersContext from "context/PortfolioFiltersContext";
import {
  AreaUnit,
  DateRange,
  ProjectDataFidelityEnum,
  QueryPortfolioArgs,
} from "gql/graphql";
import { downloadPortfolioReport } from "fetches/reports";
import {
  DATE_PICKER_FORMAT,
  DATE_STRING,
  formatAreaUnit,
} from "utils/formatting";

import "./PortfolioOverview.scss";
import UserAndOrganizationContext from "context/UserAndOrganizationContext";
interface Props {
  dateRange: DateRange | undefined;
  refetchPortfolioDetails: (variables: Partial<QueryPortfolioArgs>) => void;
  targetDate: Dayjs;
}

const PortfolioOverview = ({
  dateRange,
  refetchPortfolioDetails,
  targetDate,
}: Props) => {
  const [{ currentOrganization }] = useContext(UserAndOrganizationContext);

  const {
    filters: { areaUnit, startDate, endDate, dataFidelity },
    status: { loading, interacted },
    setFilters,
    setInitialFilters,
  } = useContext(PortfolioFiltersContext);

  const downloadReport = useCallback(async () => {
    if (!startDate || !endDate || loading) return;

    const downloadedReport = await downloadPortfolioReport({
      areaUnit,
      startDate,
      endDate,
    });
    saveAs(
      downloadedReport,
      `portfolio-report(${dayjs().format(DATE_STRING)})-${areaUnit}.xlsx`
    );
  }, [loading, startDate, endDate, areaUnit]);

  const fetchVariables = useMemo(
    () => ({
      ...formatDates({ startDate, endDate }),
      areaUnit,
      dataFidelity,
    }),
    [startDate, endDate, areaUnit, dataFidelity]
  );

  useEffect(() => {
    if (startDate || endDate || !dateRange) return; // Only call this once

    setInitialFilters({
      startDate: safeDayjs(dateRange.startDate),
      endDate:
        _.max([safeDayjs(dateRange?.startDate), targetDate]) || targetDate,
    });
  }, [
    dateRange,
    endDate,
    fetchVariables,
    setInitialFilters,
    startDate,
    targetDate,
  ]);

  useEffect(() => {
    if (!startDate || !endDate || !interacted) return;
    refetchPortfolioDetails(fetchVariables);
  }, [refetchPortfolioDetails, fetchVariables, startDate, endDate, interacted]);

  return (
    <div className="overview-info">
      <h4>{currentOrganization?.name} Portfolio</h4>
      <Button
        disableElevation
        variant="contained"
        onClick={downloadReport}
        startIcon={<Download />}
      >
        Download data
      </Button>
      <FormGroup row className="settings-form-group">
        <DatePicker
          disabled={loading || !startDate}
          className={clsx("date-picker", {
            loading: loading || !startDate,
          })}
          label={startDate ? "From" : undefined}
          value={startDate || dayjs()} // Start with initial date to avoid animation
          views={["month", "year"]}
          format={DATE_PICKER_FORMAT}
          onAccept={(value) => setFilters({ startDate: value })}
          slotProps={{
            textField: {
              // Should only fire on typing
              onBlur: (e: any) => {
                const value = e.target.value;
                if (value) {
                  setFilters({ startDate: dayjs(value) });
                }
              },
            },
          }}
        />
        <DatePicker
          disabled={loading || !endDate}
          className={clsx("date-picker", {
            loading: loading || !endDate,
          })}
          slotProps={{
            textField: {
              error: Boolean(startDate && endDate && endDate < startDate),
              onBlur: (e: any) => {
                const value = e.target.value;
                if (value) {
                  setFilters({ endDate: dayjs(value) });
                }
              },
            },
          }}
          label={endDate ? "Until" : undefined}
          value={_.max([endDate, targetDate])}
          views={["month", "year"]}
          format={DATE_PICKER_FORMAT}
          onAccept={(value) => setFilters({ endDate: value })}
        />
        <FormControl size="small">
          <DataFidelitySelect />
        </FormControl>
        <FormControl size="small">
          <AreaUnitSelect />
        </FormControl>
      </FormGroup>
    </div>
  );
};

export default PortfolioOverview;

// Utils
const formatDates = (object: { [key: string]: Dayjs | null }) => {
  return _.mapValues(object, (value) => value?.format(DATE_STRING));
};

const safeDayjs = (date: string | null) => (date ? dayjs(date) : dayjs());

const formatDataFidelity = (dataFidelity: ProjectDataFidelityEnum) => {
  return {
    [ProjectDataFidelityEnum.ProductBased]: "EPDs",
    [ProjectDataFidelityEnum.UserUploaded]: "Report uploads",
    [ProjectDataFidelityEnum.Estimated]: "Estimations",
  }[dataFidelity];
};

// Components
const DataFidelitySelect = () => {
  const {
    setFilters,
    filters,
    status: { loading },
  } = useContext(PortfolioFiltersContext);

  const handleDataFidelityChange = useCallback(
    (e: SelectChangeEvent<ProjectDataFidelityEnum[]>) => {
      setFilters({
        dataFidelity: e.target.value as ProjectDataFidelityEnum[],
      });
    },
    [setFilters]
  );

  const allFidelitiesActive =
    filters.dataFidelity.length === 0 ||
    filters.dataFidelity.length === Object.keys(ProjectDataFidelityEnum).length;
  const allFidelities: ProjectDataFidelityEnum[] = Object.values(
    ProjectDataFidelityEnum
  );

  const handleToggleAllClick = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();

    if (filters.dataFidelity.length === allFidelities.length) return;

    setFilters({
      dataFidelity: allFidelities,
    });
  };

  return (
    <Select
      disabled={loading}
      multiple
      className={clsx("data-fidelity-select", {
        loading,
      })}
      labelId="data-fidelity-select-label"
      id="data-fidelity-select"
      name="data-fidelity"
      value={
        _.isEmpty(filters.dataFidelity) ? allFidelities : filters.dataFidelity
      }
      renderValue={(selected) => {
        if (_.isEmpty(selected) || selected.length === allFidelities.length) {
          return "All sources";
        }
        return _.map(selected, formatDataFidelity).join(" + ");
      }}
      aria-label="Data source"
      onChange={handleDataFidelityChange}
    >
      <MenuItem onClick={handleToggleAllClick} selected={allFidelitiesActive}>
        All sources
      </MenuItem>
      <Divider />
      {_.map(ProjectDataFidelityEnum, (dataFidelity) => (
        <MenuItem key={dataFidelity} value={dataFidelity}>
          {formatDataFidelity(dataFidelity)}
        </MenuItem>
      ))}
    </Select>
  );
};

const AreaUnitSelect = () => {
  const {
    filters: { areaUnit },
    setFilters,
    status: { loading },
  } = useContext(PortfolioFiltersContext);
  return (
    <Select
      disabled={loading}
      className={clsx("unit-select", "loading-small", {
        loading,
      })}
      labelId=""
      id="unit-select"
      value={areaUnit}
      aria-label="Unit"
      name="unit"
      onChange={(e) => {
        const newAreaUnit = e.target.value as AreaUnit;
        setFilters({ areaUnit: newAreaUnit });
      }}
    >
      {_.map(AreaUnit, (unit) => (
        <MenuItem key={unit} value={unit}>
          {formatAreaUnit(unit)}
        </MenuItem>
      ))}
    </Select>
  );
};
