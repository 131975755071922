import _ from "lodash";
import Button from "@mui/material/Button";
import { useCallback, useContext } from "react";

import {
  Box,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import { useMutation, useQuery } from "@apollo/client";
import {
  DeleteOutline,
  Download,
  FilePresent as GenericFileIcon,
  Upload,
} from "@mui/icons-material";

import { ModalContext } from "context/ModalContext";
import { GET_PROJECT_DOCUMENTS } from "graphql/queries/projects";
import { REMOVE_PROJECT_FILES } from "graphql/mutations/projects";
import { ProjectUploadedFile } from "gql/graphql";
import pdfFileIcon from "imgs/pdf_file_icon.png";
import xlsFileIcon from "imgs/xls_file_icon.png";
import rvtFileIcon from "imgs/rvt_file_icon.png";
import UploadBuildingFilesModal from "./UploadBuildingFilesModal";
import "./ProjectDocuments.scss";
interface Props {
  projectSlug?: string;
}

const ProjectDocuments = ({ projectSlug }: Props) => {
  const { data } = useQuery(GET_PROJECT_DOCUMENTS, {
    variables: { slug: projectSlug },
  });

  const [removeFile] = useMutation(REMOVE_PROJECT_FILES, {
    refetchQueries: [
      { query: GET_PROJECT_DOCUMENTS, variables: { slug: projectSlug } },
    ],
  });

  const removeFileHandler = useCallback(
    (fileId: string) => {
      removeFile({
        variables: { input: { slug: projectSlug, files: [{ id: fileId }] } },
      });
    },
    [removeFile, projectSlug]
  );

  const { getModal, modals } = useContext(ModalContext);

  const uploadBuildingFilesModalContext = getModal(
    UploadBuildingFilesModal,
    modals
  );

  const openBuildingFilesModal = useCallback(() => {
    uploadBuildingFilesModalContext?.open();
  }, [uploadBuildingFilesModalContext]);

  return (
    <Box id="project-documents" className="default-border">
      <Grid container flexDirection="row" justifyContent="space-between">
        <Typography variant="h6" className="heading">
          Documents
        </Typography>
        <Button
          disableElevation
          disableRipple
          color="secondary"
          className="add-docs"
          onClick={openBuildingFilesModal}
          startIcon={<Upload />}
          variant="contained"
        >
          Upload files
        </Button>
      </Grid>
      {_.isEmpty(data?.project?.files?.nodes) ? (
        <Typography variant="body2">
          You have not uploaded any files for this project yet.
        </Typography>
      ) : (
        <List>
          {_.map(data?.project?.files?.nodes, (node) => (
            <SingleDocument
              key={node.id}
              removeFileHandler={removeFileHandler}
              node={node}
            />
          ))}
        </List>
      )}
    </Box>
  );
};

export default ProjectDocuments;

const formatFileSize = (sizeInBytes: number) => {
  const sizeInMb = _.divide(sizeInBytes, 10 ** 6);
  if (sizeInMb < 1) {
    const sizeInKb = _.divide(sizeInBytes, 10 ** 3);
    return `${_.round(sizeInKb)} KB`;
  }
  return `${_.round(sizeInMb, 1)} MB`;
};

const FileIcon = ({ fileExtension }: { fileExtension: string | undefined }) => {
  if (_.includes(fileExtension, "pdf")) {
    // pdf, pdfa, pdfx, etc.
    return <img src={pdfFileIcon} alt="pdf" className="file-icon" />;
  } else if (_.includes(fileExtension, "xl")) {
    // xlsx, xls, xlsm, xlts, etc.
    return <img src={xlsFileIcon} alt="xls" className="file-icon" />;
  } else if (_.includes(fileExtension, "rvt")) {
    // rvt, rfa, rte, etc.
    return <img src={rvtFileIcon} alt="rvt" className="file-icon" />;
  }
  return <GenericFileIcon fontSize="large" className="file-icon" />;
};

const SingleDocument = ({
  removeFileHandler,
  node: { id, file },
}: {
  node: ProjectUploadedFile;
  removeFileHandler: (fileId: string) => void;
}) => (
  <ListItem key={id} className="doc default-border">
    <FileIcon fileExtension={_.last(_.split(file?.name, "."))} />
    <ListItemText className="filename">
      <Typography className="body2 ">{file?.name}&nbsp;</Typography>
    </ListItemText>
    <ListItemText className="filesize">
      <Typography className="body2  ">
        {formatFileSize(file?.byteSize ?? 0)}&nbsp;
      </Typography>
    </ListItemText>
    {file.url && (
      <IconButton disableRipple href={file.url} className="download">
        <Download fontSize="small" />
      </IconButton>
    )}
    <IconButton
      disableFocusRipple
      onClick={removeFileHandler.bind(null, id)}
      className="delete"
    >
      <DeleteOutline fontSize="small" />
    </IconButton>
  </ListItem>
);
