import { gql } from "@apollo/client";
import {
  PAGINATION_FRAGMENT,
  ESTIMATIONS_FRAGMENT,
  CATEGORY_FRAGMENT,
  PROJECT_FRAGMENT,
} from "../fragments";

const CARBON_REPORT_FRAGMENT = gql`
  fragment CarbonReportFragment on ReportCarbon {
    total {
      kgCo2e
      perArea {
        kgCo2e
        unit
      }
      reduction {
        relative
        absolute
      }
    }
  }
`;

const REPORT_PRODUCT_EDGE_FRAGMENT = gql`
  fragment ReportProductEdgeFragment on ReportProductEdge {
    proportion
    total {
      baseline
      kgCo2e
      reduction {
        absolute
        relative
        contribution
      }
    }

    node {
      name
      slug
      __typename
    }
  }
`;

const REPORT_UPLOAD_FRAGMENT = gql`
  fragment ReportUploadFragment on Report {
    uploads {
      edges {
        node {
          createdAt
          fileUrl
          title
          description
          source
        }
      }
    }
  }
`;

const BUILDING_ELEMENTS_FRAGMENT = gql`
  fragment BuildingElementsFragment on ReportCarbon {
    buildingElements {
      edges {
        node {
          buildingElements
          dataFidelity
          kgCo2e
          perArea {
            unit
            kgCo2e
          }
        }
      }
    }
  }
`;

export const GET_REPORT_DETAILS = gql`
  query GetReportDetails(
    $slug: String!
    $first: Int
    $last: Int
    $after: String
    $before: String
    $productSort: ProjectProductSortInput
  ) {
    project(slug: $slug) {
      ...ProjectFragment
      updatedAt
      recommendations {
        asyncStatus {
          status
        }
        totalCount
        edges {
          node {
            scenarios {
              totalCount
            }
          }
        }
      }
      products(
        sort: $productSort
        first: $first
        last: $last
        after: $after
        before: $before
      ) {
        totalCount
        edges {
          id
          createdAt
          quantity
          rValue
          buildingPart
          category {
            ...CategoryFragment
          }
          environmentalImpact {
            carbon {
              kgCo2e {
                total
                perUnit
                totalImpactByStage {
                  stage
                  kgCo2e
                }
              }
              comparedToBaseline {
                isAboveBaseline
                isAverage
                isCarbonSequestering
                isLowCarbon
                ratioToBaseline
                warnings {
                  message
                }
              }
            }
          }
          node {
            name
            slug
            primaryCategory {
              name
              declaredUnit
              additionalFields
            }
            categories {
              ...CategoryFragment
            }
            dataFidelity
            manufacturer {
              name
            }
            sustainabilityAttributes {
              attributeType
              displayName
              ... on BooleanSustainabilityAttribute {
                value
              }
            }
          }
        }
        pageInfo {
          ...PaginationFragment
        }
      }

      report {
        dataFidelity
        carbon {
          ...CarbonReportFragment
          total {
            baseline
            kgCo2e
            reduction {
              absolute
            }
            perArea {
              reduction {
                absolute
              }
            }
          }
          categories(sort: { by: PROPORTION, direction: DESCENDING }) {
            edges {
              proportion
              total {
                baseline
                kgCo2e
                reduction {
                  absolute
                  relative
                  __typename
                }
              }

              node {
                name
                defaultBuildingPart
              }
            }
          }

          ...BuildingElementsFragment

          lifecycleStages {
            edges {
              node {
                dataFidelity
                lifecycleStage
                kgCo2e
              }
            }
          }

          worstProducts: products(
            sort: { by: CONTRIBUTION, direction: ASCENDING }
            filter: { withBaseline: true }
            first: 7
          ) {
            edges {
              ...ReportProductEdgeFragment
            }
          }

          bestProducts: products(
            sort: { by: CONTRIBUTION, direction: DESCENDING }
            filter: { withBaseline: true }
            first: 7
          ) {
            edges {
              ...ReportProductEdgeFragment
            }
          }
        }
        sustainability {
          attributes {
            totalCount
            edges {
              productCount
              node {
                displayName
              }
            }
          }
        }
        ...ReportUploadFragment
      }
      estimations {
        ...EstimationsFragment
      }
    }
  }
  ${PAGINATION_FRAGMENT}
  ${CARBON_REPORT_FRAGMENT}
  ${REPORT_UPLOAD_FRAGMENT}
  ${ESTIMATIONS_FRAGMENT}
  ${REPORT_PRODUCT_EDGE_FRAGMENT}
  ${CATEGORY_FRAGMENT}
  ${PROJECT_FRAGMENT}
  ${BUILDING_ELEMENTS_FRAGMENT}
`;

export const GET_PORTFOLIO_DETAILS = gql`
  ${CARBON_REPORT_FRAGMENT}
  ${BUILDING_ELEMENTS_FRAGMENT}
  query getPortfolioDetails(
    $areaUnit: AreaUnit = M2
    $dataFidelity: [ProjectDataFidelityEnum!]
    $startDate: ISO8601Date
    $endDate: ISO8601Date
    $sort: ProjectSortInput
  ) {
    portfolio(
      areaUnit: $areaUnit
      dataFidelity: $dataFidelity
      startDate: $startDate
      endDate: $endDate
    ) {
      updatedAt
      report {
        carbon {
          ...CarbonReportFragment
          total {
            perArea {
              reduction {
                absolute
              }
            }
          }
        }
        timeSeries {
          date
          kgCo2e {
            total
            byDataFidelity {
              total
              type
            }
          }
          baseline
          projectCount
        }
      }
      projects(first: 300, sort: $sort) {
        dateRange {
          startDate
          endDate
        }

        nodes {
          products {
            totalCount
          }
          name
          slug
          dataFidelity
          completedAt
          startedAt
          address {
            city
            country
            postalCode
          }
          aboveGroundFloors {
            count
            areaPerFloor {
              area
              units
            }
          }
          report {
            carbon {
              ...CarbonReportFragment
              ...BuildingElementsFragment
            }
          }
        }
      }
    }
  }
`;
