import { useApolloClient, useMutation } from "@apollo/client";
import { SnackbarContext } from "context/SnackbarContext";
import { User } from "gql/graphql";
import { CREATE_USER } from "graphql/mutations/users";
import { GET_USERS } from "graphql/queries/users";
import { useCallback, useContext } from "react";

export const useCreateUser = () => {
  const { flashMessage } = useContext(SnackbarContext);
  const [createUser] = useMutation(CREATE_USER);
  const client = useApolloClient();
  const perform = useCallback(
    async (userToCreate: Partial<User>) => {
      await createUser({
        variables: { input: { user: userToCreate } },
      });
      flashMessage(`${userToCreate.name} was added to your workspace.`, {
        severity: "success",
      });
      client.refetchQueries({ include: [GET_USERS] });
    },
    [client, createUser, flashMessage]
  );
  return { perform };
};
