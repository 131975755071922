import dayjs from "dayjs";
import { pluralizer } from "./transforms";
import { DATE_STRING_NICE } from "./formatting";

export const differenceOfTimeInWords = (date: Date, presentDate?: Date) => {
  const p = pluralizer;

  const now = dayjs(presentDate || new Date());
  const then = dayjs(date);

  const secDiff = now.diff(then, "second");
  if (secDiff < 30) return "Just now";
  const minDiff = now.diff(then, "minute");
  if (minDiff < 1) return `${secDiff} ${p("second", secDiff)} ago`;
  const hourDiff = now.diff(then, "hour");
  if (hourDiff < 1) return `${minDiff} ${p("minute", minDiff)} ago`;
  const dayDiff = now.diff(then, "day");
  if (dayDiff < 1) return `${hourDiff} ${p("hour", hourDiff)} ago`;
  const weekDiff = now.diff(then, "week");
  if (dayDiff < 21) return `${dayDiff} ${p("day", dayDiff)} ago`;
  if (weekDiff < 4) return `${weekDiff} ${p("week", weekDiff)} ago`;
  const monthDiff = now.diff(then, "month");
  if (monthDiff < 12) return `${monthDiff} ${p("month", monthDiff)} ago`;
  const yearDiff = now.diff(then, "year");
  if (yearDiff < 3) return `${yearDiff} ${p("year", yearDiff)} ago`;
  return dayjs(date).format(DATE_STRING_NICE);
};
