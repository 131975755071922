import dayjs from "dayjs";
import {
  AddressInput,
  FloorGroupDefinition,
  FloorGroupDefinitionInput,
  Maybe,
  Project,
  ProjectUpdateInput,
} from "gql/graphql";
import _ from "lodash";
import { ChangeEvent } from "react";
import { ControllerRenderProps } from "react-hook-form";
import {
  DATE_STRING,
  MM_YYYY_STRING,
  YYYY_MM_STRING,
  stringToNumber,
} from "utils/formatting";

export type Props = {
  field: ControllerRenderProps<any, any>;
  trigger: (name: string) => void;
  shouldValidate?: (value: string) => boolean;
  uppercase?: boolean;
};

const defaultShouldValidate = () => true;

// Validate text fields only when not empty. `required` validations will handle those cases.
export const textFieldHandler =
  ({
    field,
    trigger,
    shouldValidate = defaultShouldValidate,
    uppercase = false,
  }: Props) =>
  (event: ChangeEvent<HTMLInputElement>) => {
    const newEvent = event;
    if (uppercase) {
      newEvent.target.value = event.target.value.toUpperCase();
    }
    const newValue = newEvent.target.value;
    field.onChange(newValue);
    if (newValue !== "" && shouldValidate(newValue)) {
      trigger(field.name);
    }
  };

export const floorsToInput = (
  floors: Maybe<FloorGroupDefinition> | undefined
) =>
  _.merge(floors, {
    areaPerFloor: {
      area: floors?.areaPerFloor?.area,
      units: floors?.areaPerFloor?.units,
    },
  });

export const projectToInput = (project?: Project) => {
  const input = {
    ..._.pick(project, [
      "stage",
      "description",
      "propertyUse",
      "structureType",
    ]),
    projectName: project?.name,
    startedAt: project?.startedAt
      ? dayjs(project?.startedAt).format(MM_YYYY_STRING)
      : undefined,
    completedAt: project?.completedAt
      ? dayjs(project?.completedAt).format(MM_YYYY_STRING)
      : undefined,
    address: {
      ...(project?.address || {}),
    },
    aboveGroundFloors: floorsToInput(project?.aboveGroundFloors),
    belowGroundFloors: floorsToInput(project?.belowGroundFloors),
    parkingFloors: floorsToInput(project?.parkingFloors),
  };

  return input;
};

export const formToInput = (vals: ProjectUpdateInput) => {
  return _.omitBy(
    {
      ..._.omit(
        vals,
        "slug",
        "__typename",
        "completedAt",
        "startedAt",
        "address",
        "area",
        "aboveGroundFloors",
        "belowGroundFloors",
        "parkingFloors"
      ),
      completedAt: vals.completedAt
        ? dayjs(vals.completedAt, YYYY_MM_STRING).format(DATE_STRING)
        : undefined,
      startedAt: vals.startedAt
        ? dayjs(vals.startedAt, YYYY_MM_STRING).format(DATE_STRING)
        : undefined,
      address: processAddress(vals.address),
      aboveGroundFloors: processFloorGroupDefinition(vals.aboveGroundFloors),
      belowGroundFloors: processFloorGroupDefinition(vals.belowGroundFloors),
      parkingFloors: processFloorGroupDefinition(vals.parkingFloors),
    },
    _.isNil
  ) as ProjectUpdateInput;
};

export const processAddress = (address?: Maybe<AddressInput>) => {
  return _.pick(address, [
    "city",
    "postalCode",
    "stateCode",
    "street1",
    "street2", // TODO: are we even using street2 on the backend?
    "countryCode",
    "latitude",
    "longitude",
  ]);
};

export const processFloorGroupDefinition = (
  area?: Maybe<FloorGroupDefinitionInput>,
  overrides: Partial<FloorGroupDefinitionInput> = {}
) => {
  if (!area) return undefined;
  if (!area.count) return {};
  if (!area.areaPerFloor) return {};

  return {
    count: Number(area.count),
    areaPerFloor: {
      units: area.areaPerFloor.units,
      area: stringToNumber(area.areaPerFloor.area),
    },
    ...overrides,
  };
};

export const safeAddressFields = (address?: Maybe<AddressInput>) => ({
  country: null,
  state: null,
  ..._.mapValues(address, (v) => v || null),
  __typename: "Address",
});
