import _ from "lodash";
import { Dayjs } from "dayjs";

import { AreaUnit } from "gql/graphql";
import { DATE_STRING } from "utils/formatting";
import { fetchWithTimeout } from "./utils";

export const downloadExcelReport = async (url: string): Promise<Blob> => {
  const response = await fetchWithTimeout(
    url,
    {
      method: "GET",
      headers: {
        Accept: "application/vnd.ms-excel",
        "Content-Type": "application/json",
        responseType: "blob",
        "Content-Disposition":
          'attachment; filename="report.xslx"; filename*="report.xslx"',
      },
      credentials: "include",
    },
    30000
  );
  return response.blob();
};

export const downloadProjectReport = async (
  projectSlug: string
): Promise<Blob> => {
  const url = `${process.env.REACT_APP_API_V2_DOMAIN}/api/unstable/reports/${projectSlug}/export`;
  return await downloadExcelReport(url);
};

export const downloadPortfolioReport = async ({
  areaUnit,
  endDate,
  startDate,
}: {
  areaUnit: AreaUnit;
  endDate: Dayjs;
  startDate: Dayjs;
}): Promise<Blob> => {
  const searchParams = new URLSearchParams({
    startDate: startDate.format(DATE_STRING),
    endDate: endDate.format(DATE_STRING),
    areaUnit: _.toLower(areaUnit),
  });

  const url = `${
    process.env.REACT_APP_API_V2_DOMAIN
  }/api/unstable/reports/portfolio/export?${searchParams.toString()}`;
  return downloadExcelReport(url);
};
