import { useState } from "react";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import numeral from "numeral";
import { GridColDef, GridRenderEditCellParams } from "@mui/x-data-grid";
import _ from "lodash";
import { KGCO2E } from "utils/formatting";
import {
  ChipProps,
  Box,
  Link as MUILink,
  Select,
  MenuItem,
} from "@mui/material";
import clsx from "clsx";

import CarbonChip from "components/Chips";
import { formatDeclaredUnit } from "utils/transforms";
import CarbonCell from "./CustomCells/CarbonCell";
import DataFidelityCell from "./CustomCells/DataFidelityCell";
import {
  CustomEditQuantityComponent,
  QuantityCell,
} from "./CustomCells/QuantityCell";
import CarbonChangeCell from "./CustomCells/CarbonChangeCell";
import { BuildingPartsEnum, LifecycleStage, Maybe } from "gql/graphql";
import { ProductQuantityConfig, ProductRowModel } from "./ProductTable";
import { LifecycleStagesPopper } from "./Tooltips/LifecycleStagesPopper";
import { HeaderTitleWithCaption } from "./CustomCells/HeaderTitleWithCaption";
import { ExternalLinks } from "../../../constants";
import { KeyboardArrowDown } from "@mui/icons-material";

dayjs.extend(relativeTime);

const KGCO2E_FORMAT = "0,0.00";
const kgCo2eValueFormatter = ({ value }: { value: number }) =>
  roundForDisplay(numeral(value).format(KGCO2E_FORMAT));
const getStage = (
  row: ProductRowModel,
  stage: LifecycleStage
): Maybe<number> | undefined =>
  _.find(row.totalImpactByStage, (r) => r.stage === stage)?.kgCo2e;

export const roundForDisplay = (numString: string) => {
  if (numString.endsWith(".00")) {
    return numString.replace(/\.00$/, "");
  }
  return numString;
};

export enum ProductTableFieldName {
  CarbonImpact = "carbonImpact",
  DataFidelity = "dataFidelity",
  Added = "createdAt",
  Product = "name",
  Manufacturer = "manufacturer",
  BuildingPart = "buildingPart",
  Category = "category",
  CarbonReduction = "carbonReduction",
  CarbonPerUnit = "carbonPerUnit",
  Quantity = "quantity",
  Production = "Production",
  Transportation = "Transportation",
  Construction = "Construction",
  AbsoluteCarbon = "totalCarbonImpact",
}

export const columns: GridColDef[] = [
  {
    field: ProductTableFieldName.CarbonImpact,
    headerName: "Carbon Impact",
    description: (
      <>
        Indicates a product as “Above Industry Average, “Low Carbon” for that
        product category. See our{" "}
        <MUILink href={ExternalLinks.Methodology} target="_blank">
          methodology
        </MUILink>
        .
      </>
    ) as unknown as string,
    minWidth: 136,
    renderCell: ({ row }) => {
      const props = {
        isAboveBaseline: row.isAboveBaseline,
        isLowCarbon: row.isLowCarbon,
        isCarbonSequestering: row.isCarbonSequestering,
        isAverage: row.isAverage,
        tagVariant: true,
      };
      return <CarbonChip {...props} />;
    },
    sortable: false,
  },
  {
    field: ProductTableFieldName.DataFidelity,
    headerName: "Data Fidelity",
    description: (
      <>
        Flags the source of product carbon data. Options include
        product-specific EPD (“Product EPD”), industry-wide EPD (“IW EPD”), or
        “N/A” if carbon data is not available for the product.
        <MUILink href={ExternalLinks.Methodology} target="_blank">
          Learn more
        </MUILink>
        .
      </>
    ) as unknown as string,
    resizable: false,
    sortable: false,
    minWidth: 128,
    renderCell: ({ row }: { row: ProductRowModel }) => {
      const chipProps: ChipProps = {
        size: "small",
      };
      const props = { ...chipProps, dataFidelity: row.dataFidelity };
      return <DataFidelityCell {...props} />;
    },
  },
  {
    field: ProductTableFieldName.Added,
    headerName: "Added",
    resizable: false,
    valueFormatter: (p) => dayjs(p.value).fromNow(),
    minWidth: 142,
    sortable: true,
  },
  {
    field: ProductTableFieldName.Product,
    headerName: "Product",
    minWidth: 300,
    resizable: false,
    sortable: true,
    flex: 0.5,
    renderCell: ({ row }) => (
      <Link
        to={`/products/${row.slug}`}
        target="_blank"
        title={row.name}
        rel="noopener noreferrer"
        className="truncate"
      >
        {row.name}
      </Link>
    ),
  },
  {
    field: ProductTableFieldName.Manufacturer,
    sortable: true,
    headerName: "Manufacturer",
    minWidth: 200,
    align: "left",
    headerAlign: "left",
    renderCell: ({ row }) => (
      <span className="truncate" title={row.manufacturer?.name}>
        {row.manufacturer?.name || "N/A"}
      </span>
    ),
  },
  {
    field: ProductTableFieldName.BuildingPart,
    headerName: "Part of building",
    sortable: true,
    minWidth: 155,
    type: "singleSelect",
    valueOptions: [
      ..._.map(Object.keys(BuildingPartsEnum), (k) => ({
        label: k,
        value: BuildingPartsEnum[k as keyof typeof BuildingPartsEnum],
      })),
    ],
    renderCell: ({ formattedValue }) => (
      <Box className={clsx("truncate", "editable")}>
        <div>{formattedValue}</div>
        <KeyboardArrowDown />
      </Box>
    ),
    renderEditCell: (params: GridRenderEditCellParams) => (
      <CustomSelectCell {...params} />
    ),
    editable: true,
  },
  {
    field: ProductTableFieldName.Category,
    headerName: "Category",
    sortable: true,
    minWidth: 170,
    type: "singleSelect",
    valueOptions: ({ row }) =>
      _.map(row.categories, (c) => ({
        label: c.name,
        value: c.id,
      })),
    valueGetter: ({ row }) => row.category.id,
    valueSetter: ({ row, value }) => {
      const category = _.find(row.categories, { id: value });
      return { ...row, category };
    },
    renderCell: ({ row, formattedValue }) => {
      return (
        <Box className={clsx("truncate", "editable")}>
          <div>{formattedValue}</div>
          {row.categories.length > 1 && <KeyboardArrowDown />}
        </Box>
      );
    },
    editable: true,
  },
  {
    field: ProductTableFieldName.CarbonReduction,
    headerName: "% Change",
    description:
      "Percentage carbon reduction (or increase) vs industry average.",
    renderCell: CarbonChangeCell,
    cellClassName: "carbon-change",
  },
  {
    field: ProductTableFieldName.CarbonPerUnit,
    headerName: "kgCO₂e / unit",
    align: "left",
    minWidth: 120,
    sortable: false,
  },
  {
    field: ProductTableFieldName.Quantity,
    headerName: "Amount",
    description:
      "Enter quantities of each product used on the project. Units are indicated inside the cell.",
    sortable: true,
    minWidth: 150,
    maxWidth: 200,
    renderCell: (p) => <QuantityCell {...p} />,
    editable: true,
    cellClassName: "quantity",
    valueGetter: ({ row }): ProductQuantityConfig => {
      return {
        quantity: row.quantity,
        unit: formatDeclaredUnit(row.category?.declaredUnit),
        rValue: row.rValue,
      };
    },
    valueSetter: ({ row, value }) => {
      return { ...row, quantity: value.quantity, rValue: value.rValue };
    },
    renderEditCell: (params: GridRenderEditCellParams) => (
      <CustomEditQuantityComponent {...params} />
    ),
  },

  // Product Manufacturing (A1-A3)
  {
    field: ProductTableFieldName.Production,
    renderHeader: (props) => (
      <HeaderTitleWithCaption
        label="Product Manufacturing"
        caption={
          <>
            A1-A3 <KGCO2E withParentheses />
          </>
        }
        columnWidth={props.colDef.computedWidth}
        tooltipElementType={LifecycleStagesPopper}
      />
    ),
    valueGetter: ({ row }: { row: ProductRowModel }) => {
      return getStage(row, LifecycleStage.A1A2A3);
    },
    renderCell: (props) => {
      return <CarbonCell align="left" showWarnings={false} {...props} />;
    },
    valueFormatter: kgCo2eValueFormatter,
    sortable: false,
    cellClassName: CarbonCell.className,
    minWidth: 170,
  },

  // Transportation (A4)
  {
    field: ProductTableFieldName.Transportation,
    renderHeader: (props) => (
      <HeaderTitleWithCaption
        label="Transportation"
        caption={
          <>
            A4 <KGCO2E withParentheses />
          </>
        }
        columnWidth={props.colDef.computedWidth}
        tooltipElementType={LifecycleStagesPopper}
      />
    ),
    minWidth: 120,
    valueGetter: ({ row }: { row: ProductRowModel }) => {
      return getStage(row, LifecycleStage.A4);
    },
    renderCell: (props) => {
      return <CarbonCell align="left" showWarnings={false} {...props} />;
    },
    valueFormatter: kgCo2eValueFormatter,
    sortable: false,
    cellClassName: CarbonCell.className,
  },

  // Construction (A5)
  {
    field: ProductTableFieldName.Construction,
    renderHeader: (props) => (
      <HeaderTitleWithCaption
        label="Construction"
        caption={
          <>
            A5 <KGCO2E withParentheses />
          </>
        }
        columnWidth={props.colDef.computedWidth}
        tooltipElementType={LifecycleStagesPopper}
      />
    ),
    minWidth: 110,
    valueGetter: ({ row }: { row: ProductRowModel }) => {
      return getStage(row, LifecycleStage.A5);
    },
    renderCell: (props) => {
      return <CarbonCell align="left" showWarnings={false} {...props} />;
    },
    valueFormatter: kgCo2eValueFormatter,
    sortable: false, // TODO, should it be sortable?
    cellClassName: CarbonCell.className,
  },

  // Total upfront carbon (A1-A5)
  {
    field: ProductTableFieldName.AbsoluteCarbon,
    renderHeader: (props) => (
      <HeaderTitleWithCaption
        label="Total Upfront Carbon"
        caption={
          <>
            A1-A5 <KGCO2E withParentheses />
          </>
        }
        columnWidth={props.colDef.computedWidth}
        tooltipElementType={LifecycleStagesPopper}
      />
    ),
    minWidth: 160,
    valueFormatter: kgCo2eValueFormatter,
    sortable: true,
    renderCell: (props) => {
      return <CarbonCell align="left" {...props} />;
    },
    cellClassName: CarbonCell.className,
  },
];

const CustomSelectCell = (params: GridRenderEditCellParams) => {
  const [open, setOpen] = useState(true);
  return (
    <Select
      open={open}
      fullWidth
      disableUnderline
      size="small"
      IconComponent={KeyboardArrowDown}
      variant="filled"
      value={params.value}
      onClick={(e) => {
        if ((e.target as HTMLElement).ariaHasPopup) {
          setOpen(true);
        } else {
          // commit change
          setOpen(false);
          params.api.stopCellEditMode({
            id: params.id,
            field: params.field,
          });
        }
      }}
      onChange={(e) => {
        const value = e.target.value;
        params.api.setEditCellValue({
          id: params.id,
          field: params.field,
          value,
        });
      }}
    >
      {_.map(Object.keys(BuildingPartsEnum), (k) => (
        <MenuItem
          key={k}
          value={BuildingPartsEnum[k as keyof typeof BuildingPartsEnum]}
        >
          {k}
        </MenuItem>
      ))}
    </Select>
  );
};
