import _ from "lodash";
import dayjs from "dayjs";
import numeral from "numeral";
import { renderToString } from "react-dom/server";

import {
  ReportCarbonTimeSeries,
  ReportCarbonTotalTimeseries,
  ProjectDataFidelityEnum,
  Maybe,
} from "gql/graphql";
import { KGCO2E } from "utils/formatting";
import { NumberValue } from "d3-scale";

export const getTooltipContent = (
  d: ReportCarbonTimeSeries,
  baseline: ReportCarbonTimeSeries
) => {
  const diffFromBaseYear =
    (baseline?.kgCo2e?.total || 0) - (d?.kgCo2e?.total || 0);

  const dataByFidelity = d?.kgCo2e?.byDataFidelity;
  const estimatedCarbon =
    (dataByFidelity &&
      (dataByFidelity[ProjectDataFidelityEnum.Estimated as any] as number)) ||
    0;
  const productBasedCarbon =
    (dataByFidelity &&
      (dataByFidelity[
        ProjectDataFidelityEnum.ProductBased as any
      ] as number)) ||
    0;

  const onlyHaveEstimatedData = estimatedCarbon > 0 && productBasedCarbon === 0;

  return `<span>
      <strong>Year ${dayjs(d.date).year()}</strong>
    </span>
    ${
      !onlyHaveEstimatedData
        ? `<span>Base Year ${dayjs(baseline.date).year()}</span>`
        : ""
    }
    ${
      !onlyHaveEstimatedData
        ? `<span>
      ${diffFromBaseYear > 0 ? "Reduction" : "Increase"} from base year:
      ${renderToString(<KGCO2E kgCo2e={Math.abs(diffFromBaseYear)} />)}
    </span>`
        : ""
    }

    <span>
      Total Carbon:  ${renderToString(
        <KGCO2E kgCo2e={d?.kgCo2e?.total} bold />
      )}
    </span>
    <span>Projects <strong>${d.projectCount}</span>
  `;
};

export const transformTimeSeriesCarbonData = (
  carbonData: Maybe<ReportCarbonTotalTimeseries> | undefined
) => ({
  ...carbonData,
  byDataFidelity: _.chain(carbonData?.byDataFidelity)
    .keyBy("type")
    .mapValues("total")
    .value(),
});

export const formatYAxisTicks = (tickValue: NumberValue): string => {
  if (_.isUndefined(tickValue)) {
    return "N/A";
  }
  if (+tickValue < 1000) {
    return numeral(tickValue).format("0,0");
  }
  return numeral(tickValue).format("0a");
};
