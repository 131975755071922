import { TANGIBLE_AWS_BUCKET_URL } from "../constants";
import "./NoResults.scss";
import { InteractionComponent } from "types";

const NoResultsConfig = {
  [InteractionComponent.filter]: {
    message: (
      <p>
        Unfortunately, we couldn&apos;t find any products that match your
        search. Please adjust filters and try again.
      </p>
    ),
  },
  [InteractionComponent.none]: {
    message: <p>You have not interacted with the search or filters.</p>,
  },
  [InteractionComponent.search]: {
    message: (
      <>
        <p>
          {" "}
          Unfortunately, we couldn’t find any products that match your search.
        </p>
        <p>
          Our search results are optimized for product name. If you are
          searching by other attributes (like material) we recommend using
          search filters to do so!
        </p>
      </>
    ),
  },
};
const NoResults = ({ variant }: { variant: InteractionComponent }) => {
  return (
    <div id="empty-grid" className="error-state ">
      <img
        src={`${TANGIBLE_AWS_BUCKET_URL}/empty-state.png`}
        alt="no products to show"
      />
      <h3>No results</h3>
      <div className="message">
        {variant !== InteractionComponent.sort &&
          NoResultsConfig[variant].message}
      </div>
    </div>
  );
};
export default NoResults;
