import { useMemo, useState } from "react";
import _ from "lodash";
import dayjs, { Dayjs } from "dayjs";
import { OperationVariables, useQuery } from "@apollo/client";

import {
  AreaUnit,
  GetPortfolioDetailsQueryVariables,
  Portfolio,
  ProjectDataFidelityEnum,
  ProjectSortColumn,
  Sort,
} from "gql/graphql";
import {
  TitleWithKGCO2E,
  EmissionsText,
  REPORTING_STAT_FORMAT,
} from "utils/formatting";
import { GET_PORTFOLIO_DETAILS } from "graphql/queries/reports";
import { DataLoadingContext } from "context/DataLoadingContext";
import { PortfolioFiltersProvider } from "context/PortfolioFiltersContext";
import { DirectionalChange } from "pages/ProjectReport/Dashboards/Visualizations/DirectionalChange";

import CarbonReductionOpportunities from "./CarbonReductionOpportunities";
import PortfolioOverview from "./PortfolioOverview";
import ProjectsList from "./ProjectsList";
import StatBlock from "./StatBlock";
import BuildingLeaderboardStackedBarChart from "./Visualizations/BuildingLeaderboard/BuildingLeaderboardStackedBarChart";
import EmbodiedCarbonIntensityDotPlot from "./Visualizations/EmbodiedCarbonIntensityDotPlot";
import TotalAbsoluteCarbonBarChart from "./Visualizations/TotalAbsoluteCarbonBarChart";

import "./Portfolio.scss";
import { Button, Stack, Typography } from "@mui/material";
import { EmptyPortfolio } from "./Visualizations/EmptyPortfolio";
import { Add } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

type PortfolioDetailsQueryResponse = {
  portfolio: Portfolio;
};

const MAX_BUILDINGS_TO_SHOW = 10;

const PortfolioReport = ({
  targetDate = dayjs("2050-12-31"),
}: {
  targetDate?: Dayjs;
}) => {
  // Query
  const [refetching, setRefetching] = useState(false);
  const { data, refetch, loading } = useQuery<
    PortfolioDetailsQueryResponse,
    GetPortfolioDetailsQueryVariables
  >(GET_PORTFOLIO_DETAILS, {
    fetchPolicy: "no-cache",
    // These are just initial variables
    variables: {
      areaUnit: AreaUnit.M2,
      dataFidelity: [
        ProjectDataFidelityEnum.ProductBased,
        ProjectDataFidelityEnum.Estimated,
        ProjectDataFidelityEnum.UserUploaded,
      ],
      sort: {
        by: ProjectSortColumn.Intensity,
        direction: Sort.Descending,
      },
    },
  });
  const wrappedRefetch = useMemo(
    () => async (variables?: Partial<OperationVariables>) => {
      setRefetching(true);
      const result = await refetch(variables);
      setRefetching(false);
      return result;
    },
    [refetch]
  );
  // Aliases
  const carbonData = data?.portfolio?.report?.carbon?.total;

  /*
    we are taking right because we want to preference the most recent projects
    and we are already sorting by startedAt in the GET_PORTFOLIO_DETAILS query
    */
  const projects =
    data?.portfolio?.projects?.nodes?.slice(0, MAX_BUILDINGS_TO_SHOW) || [];

  return (
    <PortfolioFiltersProvider loading={loading} refetching={refetching}>
      <header>
        <h5>Portfolio</h5>
      </header>
      <div id="portfolio-report">
        <DataLoadingContext.Provider
          value={{
            dataLoading: loading || refetching,
          }}
        >
          <PortfolioOverview
            targetDate={targetDate}
            dateRange={data?.portfolio?.projects?.dateRange}
            refetchPortfolioDetails={wrappedRefetch}
          />

          <div
            className="visualization-block default-border"
            id="building-performance-section"
          >
            {_.isEmpty(projects) && !loading ? (
              <EmptyState />
            ) : (
              <>
                <BuildingLeaderboardStackedBarChart data={projects} />
                <ProjectsList
                  allowSorting
                  showSeeAllButton
                  projectNodes={projects}
                  refetchProjects={wrappedRefetch}
                />
              </>
            )}
          </div>
          <StatBlock
            title={<TitleWithKGCO2E title="Total Absolute Carbon" />}
            stat={carbonData?.kgCo2e}
            statFormat="0,0"
            subtitle={<EmissionsText from="A1" to="A5" />}
          />
          <StatBlock
            title={
              <TitleWithKGCO2E
                title="Embodied Carbon Intensity"
                unit={carbonData?.perArea?.unit}
                separator="/"
              />
            }
            stat={carbonData?.perArea?.kgCo2e}
            statFormat={(carbonData?.perArea?.kgCo2e || 0) < 1 ? "0.00" : "0,0"}
            subtitle={<EmissionsText from="A1" to="A5" />}
          />
          <StatBlock
            title={
              <TitleWithKGCO2E
                title={
                  (carbonData?.reduction?.relative || 0) > 0
                    ? "Carbon Avoided"
                    : "Carbon Increase"
                }
              />
            }
            // in this case, we want carbon increase to be + and carbon reduction to be -
            // even though it is expressed in the opposite way in the data / backend
            stat={(carbonData?.reduction?.absolute || 0) * -1}
            statFormat={`+${REPORTING_STAT_FORMAT}`}
            subtitle={
              <>
                <DirectionalChange
                  change={carbonData?.reduction?.relative || 0}
                />{" "}
                vs industry average products
              </>
            }
          />
          <TotalAbsoluteCarbonBarChart
            timeSeriesData={data?.portfolio?.report?.timeSeries || []}
          />
          <EmbodiedCarbonIntensityDotPlot
            projectsData={data?.portfolio?.projects?.nodes}
          />

          <CarbonReductionOpportunities />
        </DataLoadingContext.Provider>
      </div>
    </PortfolioFiltersProvider>
  );
};

export default PortfolioReport;

const EmptyState = () => {
  const navigate = useNavigate();
  return (
    <>
      <p className="subtitle-1">Building Performance</p>
      <Stack alignItems="center" spacing={2} mt={5} mb={5}>
        <EmptyPortfolio />
        <Typography variant="body1">
          Add projects to see their impact.
        </Typography>
        <Button
          disableElevation
          variant="outlined"
          color="secondary"
          startIcon={<Add />}
          onClick={() => navigate("/my-projects/create")}
        >
          Create a project
        </Button>
      </Stack>
    </>
  );
};
