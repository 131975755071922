import React, { Dispatch, ReactNode, SetStateAction, useMemo } from "react";
import _ from "lodash";
import FormControl from "@mui/material/FormControl";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import ClearIcon from "@mui/icons-material/Clear";
import { PerformanceAttributeFilter, ProductFilterInput } from "gql/graphql";

import CollapsableFilterSubSection from "./CollapsableFiltersSubSection";
import {
  MultiSelectFilterOption,
  TaxonomyFilter,
} from "./MultiSelectFilter/types";
import {
  getChipLabel,
  isOptionEqualToValue,
  makeOptions,
  makeSelectedOptions,
  optionToTaxonomyFilter,
  taxonomyFilterToOption,
} from "./MultiSelectFilter/utils";
import Listbox, {
  ListboxGroupHeader,
  ListboxGroupList,
} from "./MultiSelectFilter/Listbox";
import "./MultiSelectFilter.scss";

interface MultiSelectProps {
  // If you want a collapsible title above your <select>.
  collectionName?: string;
}

interface DirectlyControlledMultiSelectProps extends MultiSelectProps {
  // Discrete set of props (do not combine with AutoPopulatedMultiSelectProps)
  // used when you want to control this filter directly with `options`.
  // At present this is used in Category, Manufacturer, and Location filters.
  onChange?: (filters: TaxonomyFilter[]) => void;
  options?: TaxonomyFilter[];
  selectedOptions?: TaxonomyFilter[];
}

interface AutoPopulatedMultiSelectProps extends MultiSelectProps {
  // Only show options for this taxonomyType.
  taxonomyType: string;

  // Discrete set of props (do not combine with DirectlyControlledMultiSelectProps)
  // when you prefer this component to populate itself from filtersState.
  // Passing a particular filterStateKey
  // will mean that the filtersState fields will be used to populate
  // based on just their string value (e.g. 'Facing' => 'Paper') instead of gids.
  // In this case, the id and names of each option will be the same because
  // the backend currently doesn't pass gids for such filters.
  filter?: PerformanceAttributeFilter;
  filterStateKey?: keyof ProductFilterInput;

  // TODO: We should stop prop-drilling filtersState and setFiltersState and replace with context.
  filtersState?: ProductFilterInput;

  // TODO: We should stop prop-drilling filtersState and setFiltersState and replace with context.
  setFiltersState?: Dispatch<SetStateAction<ProductFilterInput>>;

  // Alternative label for the taxonomyType, which might be 'Cabling category' instead of 'CABLING_CATEGORY'
  taxonomyName?: string;
}

type Props = MultiSelectProps &
  (DirectlyControlledMultiSelectProps | AutoPopulatedMultiSelectProps);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const TextFieldProps = {
  sx: {
    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
  },
};

export const makeDefaultOnChange = ({
  setFiltersState,
  filtersState,
  filter,
}: {
  filter?: PerformanceAttributeFilter;
  filtersState?: ProductFilterInput;
  setFiltersState?: Dispatch<SetStateAction<ProductFilterInput>>;
}) => {
  if (!setFiltersState || !filtersState || !filter)
    return () => {
      throw new Error(
        "You must either implement onChange, or define setFiltersState, filtersState, and filter."
      );
    };

  return function (currentOptions: TaxonomyFilter[]) {
    const existingPerformanceAttributes = (
      filtersState.performanceAttributes || []
    ).filter((attr) => attr.type !== filter.attributeType);
    const newPerformanceAttributes = _.map(currentOptions, (option) => ({
      type: filter.attributeType!,
      stringValue: [option.name],
    }));
    const newState: ProductFilterInput = {
      ...filtersState,
      performanceAttributes: [
        // Eliminates other filters of the current type (e.g. 'Cabling category')
        ...existingPerformanceAttributes,
        ...newPerformanceAttributes,
      ],
    };
    setFiltersState(newState);
  };
};

const Container = ({
  children,
  collectionName,
}: {
  children: ReactNode;
  collectionName?: string;
}) =>
  collectionName && children ? (
    <CollapsableFilterSubSection
      collectionName={collectionName}
      children={children}
    />
  ) : (
    <>{children}</>
  );

const SEARCH_SEPARATOR = " > ";
const filterOptions = createFilterOptions({
  matchFrom: "any",
  // The string returned by stringify is never actually rendered anywhere.
  stringify: (option: MultiSelectFilterOption) =>
    _.compact([option.group, option.label]).join(SEARCH_SEPARATOR),
});

const MultiSelectFilter = (props: Props) => {
  const { collectionName } = props;
  const {
    filter,
    filtersState,
    setFiltersState,
    filterStateKey,
    taxonomyType,
    taxonomyName,
  } = props as AutoPopulatedMultiSelectProps;
  const {
    options = makeOptions(filter),
    selectedOptions = makeSelectedOptions({
      taxonomyType,
      filterStateKey,
      filtersState,
    }),
    onChange = makeDefaultOnChange({ setFiltersState, filtersState, filter }),
  } = props as DirectlyControlledMultiSelectProps;

  const autocompleteOptions = useMemo(
    (): MultiSelectFilterOption[] =>
      _.sortBy(
        _.map(_.filter(options, "id"), taxonomyFilterToOption),
        (item: any) => item.group || "."
      ) as MultiSelectFilterOption[],
    [options]
  );
  const safeSelectedOptions = useMemo(
    () => _.map(_.filter(selectedOptions, "id"), taxonomyFilterToOption),
    [selectedOptions]
  );
  const shouldGroup = useMemo(() => _.some(options, "group"), [options]);

  return (
    <Container collectionName={collectionName}>
      <FormControl className="multi-select-filter" size="small">
        <Autocomplete
          disablePortal
          disableClearable
          fullWidth
          multiple
          size="small"
          limitTags={3}
          id={`${_.kebabCase(taxonomyType)}-multi-select`}
          options={autocompleteOptions}
          value={safeSelectedOptions}
          onChange={(_e, selectedOptions) => {
            onChange(selectedOptions.map(optionToTaxonomyFilter));
          }}
          isOptionEqualToValue={isOptionEqualToValue}
          ListboxComponent={Listbox}
          groupBy={shouldGroup ? (option) => option.group! : undefined}
          renderGroup={(params) => (
            <li key={params.key}>
              {params.group && (
                <ListboxGroupHeader>{params.group}</ListboxGroupHeader>
              )}
              <ListboxGroupList group={params.group}>
                {params.children as React.ReactElement[]}
              </ListboxGroupList>
            </li>
          )}
          filterOptions={filterOptions}
          renderOption={(props, option) => (
            <Box
              component="li"
              {...props}
              key={option.value || (props as any).key} // If we have duplicate labels, we need to use the value (e.g. gid://) as the key.
            >
              {option.label}
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              {...TextFieldProps}
              label={taxonomyName || taxonomyType}
            />
          )}
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => {
              const tagProps = getTagProps({ index });
              return (
                <Chip
                  variant="filled"
                  color="secondary"
                  label={getChipLabel(option, taxonomyType)}
                  deleteIcon={<ClearIcon />}
                  {...tagProps}
                  onClick={tagProps.onDelete}
                />
              );
            })
          }
        />
      </FormControl>
    </Container>
  );
};

export default MultiSelectFilter;
