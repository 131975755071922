import { TANGIBLE_AWS_BUCKET_URL } from "../../constants";
import { Typography } from "@mui/material";

import "./Register.scss";
import { Footer } from "./Footer";

export const RegisterSuccess = () => {
  return (
    <div id="register-success-page">
      <header className="header">
        <img
          src={`${TANGIBLE_AWS_BUCKET_URL}/tangible_red_logo_large.png`}
          alt="tangible logo"
        />
      </header>
      <div className="box default-shadow">
        <h3>Nice!</h3>
        <Typography variant="body2" pb={1}>
          Your Tangible account has been created.
        </Typography>
        <Typography variant="body2">
          You will receive an email with a link to activate your account.
        </Typography>
      </div>
      <Footer />
    </div>
  );
};

export default RegisterSuccess;
