import { Box } from "@mui/material";

interface TabPanelProps {
  index: number;
  value: number;
  children?: React.ReactNode;
}

export const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box className="panel-inner">{children}</Box>}
    </div>
  );
};

export const tabAccessibilityProps = (index: number) => ({
  id: `tab-${index}`,
  "aria-controls": `tabpanel-${index}`,
});

export default TabPanel;
