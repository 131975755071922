import _ from "lodash";
import { ApolloError, useMutation } from "@apollo/client";
import { useCallback, useState } from "react";

import { DirectUploadEnum, FileInfoForUpload } from "gql/graphql";
import { CREATE_DIRECT_UPLOAD } from "graphql/mutations/directUploads";
import { getDirectUploadInfo, uploadFile } from "utils/fileUpload";

interface Props {
  handleError: (e: string) => void;
  onSuccessfulUpload: (filesForUpload: FileInfoForUpload[]) => void;
  progressBarRef: React.RefObject<HTMLDivElement>;
  uploadType: DirectUploadEnum;
}

/*

A hook that initiates direct uploads & then performs an upload.
It will update an arbitrary progress bar, as long as it has a ref to it.

*/

const useFileUploadProgress = ({
  handleError,
  onSuccessfulUpload,
  progressBarRef,
  uploadType,
}: Props) => {
  const [progress, setProgress] = useState<number>(0);
  const [uploading, setUploading] = useState<boolean>(false);

  const updateProgress = (progress: number) => {
    setProgress(progress);
  };

  const handleUploadProgress = useCallback(
    (percent: number) => {
      if (progressBarRef.current) {
        progressBarRef.current.style.width = `${percent}%`;
      }
    },
    [progressBarRef]
  );

  const [createDirectFileUpload] = useMutation(CREATE_DIRECT_UPLOAD, {
    onError: (error: ApolloError) => {
      setUploading(false);
      handleError(error.message);
    },
  });

  const performUpload = useCallback(
    async (files: File[]) => {
      setUploading(true);
      const { data: uploadData } = await getDirectUploadInfo({
        mutation: createDirectFileUpload,
        files,
        uploadType,
      });
      const { filesForUpload } = uploadData.createDirectUpload;

      try {
        await Promise.all(
          _.map(filesForUpload, async (file) => {
            /*
              need original file because Rails's ActiveStorage.BlobUpload expects the native JS File object to be passed in.
              whereas the `file` object here is a GraphQL object being returned from backend
            */
            const originalFile = _.find(files, { name: file.filename });

            if (originalFile) {
              return await uploadFile(originalFile, {
                url: file.url,
                headers: JSON.parse(file.headers),
                handleUploadProgress,
              });
            }
          })
        );
      } catch (e: any) {
        handleError(e.toString());
      }
      // send all files
      const timeoutId = setTimeout(() => {
        onSuccessfulUpload(filesForUpload);
        setUploading(false);
      }, 1000);
      // return () => clearTimeout(timeoutId);
    },
    [
      createDirectFileUpload,
      handleError,
      uploadType,
      handleUploadProgress,
      onSuccessfulUpload,
    ]
  );
  return { progress, updateProgress, uploading, performUpload };
};
export default useFileUploadProgress;
