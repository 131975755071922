import { Fragment, PropsWithChildren, useState } from "react";
import "./CollapsableFiltersSubSection.scss";

const CollapsableFilterSubSection = ({
  collectionName,
  children,
}: PropsWithChildren & { collectionName: string }) => {
  const [collapsed, setCollapsed] = useState<boolean>(false);

  return (
    <div className="collapsable-filter-sub-section">
      <p
        className="collection-name"
        onClick={() => {
          setCollapsed(!collapsed);
        }}
      >
        {collectionName}
        <i className="material-icons md-18">
          {`expand_${collapsed ? "more" : "less"}`}
        </i>
      </p>
      {!collapsed && <Fragment>{children}</Fragment>}
    </div>
  );
};

export default CollapsableFilterSubSection;
