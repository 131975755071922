const DiagonalHatchPattern = ({ id = "diagonalHatch", size = 6 }) => (
  <pattern
    id={id}
    width={size}
    height={size}
    patternTransform="rotate(135 0 0)"
    patternUnits="userSpaceOnUse"
    className="diagonal-hatch"
  >
    <line x1="0" y1="0" x2="0" y2={size} />
  </pattern>
);

export default DiagonalHatchPattern;
