import _ from "lodash";
import React, { ReactElement, ReactNode } from "react";
import { RoutesProps } from "react-router-dom";

export const Show = ({
  children,
  when,
  otherwise,
  wrapper: WrapWith,
}: {
  children: ReactNode;
  when: any;
  otherwise?: ReactElement<any>;
  wrapper?: ({
    children,
    location,
  }: RoutesProps) => ReactElement<
    any,
    string | React.JSXElementConstructor<any>
  > | null;
}): ReactElement<any, any> | null => {
  const Wrapper = WrapWith || React.Fragment;
  if (_.isNil(when) || when === false) {
    return otherwise ? <Wrapper>{otherwise}</Wrapper> : null;
  }

  return <Wrapper>{children}</Wrapper>;
};

// Using this pattern with any memoized descendants
// will prevent them from re-rendering without direct changes to their props.
// https://betterprogramming.pub/boost-performance-of-your-react-app-with-memoization-e414f4f64c05
export const FnRender = ({ children }: { children: () => ReactNode }) =>
  children() as any;
