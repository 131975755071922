import { Tab, Tabs } from "@mui/material";
import { People, Person } from "@mui/icons-material";
import { useCallback, useMemo } from "react";

import TabPanel from "components/CustomTabPanel";
import { useNavigate, useParams } from "react-router-dom";
import Profile from "pages/Settings/Profile/Profile";
import { Users } from "./Users/Users";
import { useUserPermissions } from "components/PermissionRestricted";
import { UserPermissionsEnum } from "gql/graphql";

import "./Settings.scss";

enum TabNames {
  Users = "users",
  Profile = "profile",
}

export const Settings = ({ defaultTab }: { defaultTab?: TabNames }) => {
  const params = useParams();
  const navigate = useNavigate();

  const canViewUsers = useUserPermissions(UserPermissionsEnum.ViewUsers);

  const currentTab = useMemo(() => {
    const tab = params["*"] as TabNames;
    if (tab && Object.values(TabNames).includes(tab)) return tab;
    return defaultTab || (canViewUsers ? TabNames.Users : TabNames.Profile);
  }, [params, defaultTab, canViewUsers]);

  const handleTabChange = useCallback(
    (_event: React.SyntheticEvent, newValue: TabNames) => {
      navigate(`/settings/${newValue}` as string);
    },
    [navigate]
  );

  return (
    <>
      <header>
        <h5>Settings</h5>
      </header>
      <div id="settings-page">
        <Tabs id="settings-tabs" value={currentTab} onChange={handleTabChange}>
          {canViewUsers ? (
            <Tab
              disableRipple
              label="Users"
              icon={<People fontSize="small" />}
              iconPosition="start"
              value="users"
            />
          ) : null}
          <Tab
            disableRipple
            label="Profile"
            icon={<Person fontSize="small" />}
            iconPosition="start"
            value="profile"
          />
        </Tabs>
        <div className="tabs">
          {canViewUsers ? (
            <TabPanel<TabNames>
              key="users"
              currentTab={currentTab}
              tabName={TabNames.Users}
              parentId="settings-tabs"
            >
              <Users />
            </TabPanel>
          ) : null}
          <TabPanel<TabNames>
            currentTab={currentTab}
            tabName={TabNames.Profile}
            parentId="settings-tabs"
          >
            <Profile />
          </TabPanel>
        </div>
      </div>
    </>
  );
};

export default Settings;
