import _ from "lodash";
import {
  PerformanceAttribute,
  PerformanceAttributeFilter,
  PerformanceAttributeFilterInput,
  ProductFilterInput,
} from "gql/graphql";
import { StringPerformanceAttributeAliased } from "../types";
import { MultiSelectFilterOption as Option, TaxonomyFilter } from "./types";

export const makeStringOption = (
  node: PerformanceAttribute
): TaxonomyFilter => {
  return {
    // At present, performanceAttributes/availableValues returns a list of
    // strings only, so their name and id are the same.
    id: (node as StringPerformanceAttributeAliased).stringValue!,
    name: (node as StringPerformanceAttributeAliased).stringValue!,
  };
};

export const makeFilterInput = (
  input: PerformanceAttributeFilterInput
): TaxonomyFilter => {
  const id = _.isArray(input.stringValue)
    ? input.stringValue[0]
    : input.stringValue;
  return {
    id: id!,
    name: input.type!,
  };
};

export const makeOptions = (
  filter?: PerformanceAttributeFilter
): TaxonomyFilter[] => {
  const safeNodes = _.compact(filter?.availableValues?.nodes);
  return safeNodes.map(makeStringOption);
};

export const makeSelectedOptions = ({
  taxonomyType,
  filterStateKey,
  filtersState,
}: {
  taxonomyType: string;
  filterStateKey?: keyof ProductFilterInput;
  filtersState?: ProductFilterInput;
}): TaxonomyFilter[] => {
  if (!filterStateKey || !filtersState) return [];
  const safeFilters = (filtersState as any)[filterStateKey];
  const filtered = _.filter(safeFilters, { type: taxonomyType });
  return filtered.map(makeFilterInput);
};

export const getChipLabel = (option: Option, taxonomyType: string): string => {
  if (option.label === taxonomyType) return option.value;
  return option.label;
};

export const isOptionEqualToValue = (option: Option, value: Option): boolean =>
  option.value === value.value;

export const taxonomyFilterToOption = (filter: TaxonomyFilter): Option => {
  const { id, name, group } = filter;
  return {
    group,
    label: name,
    value: id,
  };
};

export const optionToTaxonomyFilter = ({
  value,
  group,
}: Option): TaxonomyFilter => ({
  group,
  name: value,
  id: value,
});
