import { Box, Paper, Typography } from "@mui/material";
import { Link } from "@mui/material";

import "./LifecycleStagesPopper.scss";
import { ExternalLinks } from "../../../../constants";

export const LifecycleStagesTooltip = () => (
  <Paper elevation={2} className="MuiTooltip-tooltip">
    <Box>
      <Typography variant="popperTitle">How we calculate</Typography>
      <Typography variant="body2">
        <b>Product Manufacturing (A1-A3):</b> EPD impacts.
      </Typography>
      <Typography variant="body2">
        <b>Transportation (A4):</b> Calculated from product mass, transportation
        mode, and distance traveled.
      </Typography>
      <Typography variant="body2">
        <b>Construction (A5):</b> +8% of A1-A3, except insulation.
      </Typography>
      <Typography variant="body2">
        <Link href={ExternalLinks.Methodology} target="_blank">
          Learn more
        </Link>
        .
      </Typography>
    </Box>
  </Paper>
);
