import _ from "lodash";
import Tooltip from "@mui/material/Tooltip";
import Link from "@mui/material/Link";

import { GLOSSARY_TERMS, ExternalLinks } from "../../constants";
import { formatAttribute } from "utils/transforms";
import { SustainabilityAttributeTypeEnum } from "gql/graphql";

// // The correct type would be a union not intersection, but because of the optional chaining issue with TS
// // it was making the code very messy -- https://github.com/microsoft/TypeScript/issues/35263
export type ModifiedSustainabilityAttribute = {
  attributeType: SustainabilityAttributeTypeEnum;
  __typename?: "BooleanSustainabilityAttribute";
  booleanValue?: boolean;
  displayName?: string;
} & {
  attributeType: SustainabilityAttributeTypeEnum;
  __typename?: "ScalarSustainabilityAttribute";
  displayName?: string | null;
  scalarValue?: number;
  units?: string;
};

const SustainabilityAttributeDisplay = ({
  attributeType,
  displayName,
  booleanValue,
  scalarValue,
  units,
}: ModifiedSustainabilityAttribute) => {
  const valueDisplay = formatAttribute({
    attributeType,
    booleanValue,
    scalarValue,
  });

  if (scalarValue) {
    return (
      <p className="body-1" key={attributeType}>
        <span className="label">{displayName}:&nbsp;</span>
        <span>
          {valueDisplay} {units}
        </span>
      </p>
    );
  } else if (booleanValue && GLOSSARY_TERMS[attributeType]) {
    const { title, descriptionElement } = GLOSSARY_TERMS[attributeType];

    return (
      <div className="chip" key={attributeType}>
        <span>{title}</span>

        <Tooltip
          classes={{ tooltip: "tooltip-wide" }}
          title={
            <>
              {descriptionElement}
              <br />
              <Link href={ExternalLinks.Methodology} className="link">
                Learn more
              </Link>
            </>
          }
        >
          <span className="material-icons md-18">info_outline</span>
        </Tooltip>
      </div>
    );
  }
  return null;
};

interface Props {
  sustainabilityAttributes: any;
}
const SustainabilityAttributes = ({ sustainabilityAttributes }: Props) => {
  const booleanSA = _.filter(
    sustainabilityAttributes,
    (sA) => !!sA.booleanValue
  );
  const scalarSA = _.filter(sustainabilityAttributes, (sA) => !!sA.scalarValue);

  return (
    <>
      <div className="sustainability-criteria section">
        <div className="row criteria">
          <p className="subtitle-1">Sustainability Attributes</p>
          <div className="values">
            <div className="row" key="sustainability-boolean">
              {_.map(booleanSA, (sA) => (
                <SustainabilityAttributeDisplay
                  key={sA.attributeType}
                  {...sA}
                />
              ))}
            </div>
            <div className="row" key="sustainability-scalar">
              {_.map(scalarSA, (sA) => (
                <SustainabilityAttributeDisplay
                  key={sA.attributeType}
                  {...sA}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
      <hr />
    </>
  );
};

export default SustainabilityAttributes;
