import { FloorGroupDefinition } from "gql/graphql";
import numeral from "numeral";
import { Fragment } from "react";

export const AreaText = ({ area }: { area: FloorGroupDefinition }) => {
  const grossFloorArea = area.count * area.areaPerFloor.area!;
  return (
    <>
      <span className="stat">{numeral(grossFloorArea).format("0,0")}</span>{" "}
      <Fragment key="co2e">
        {area.areaPerFloor.units!.toLowerCase().replace(/2$/, "")}
        <sup>2</sup>
      </Fragment>
    </>
  );
};
