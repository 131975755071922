import { Link } from "react-router-dom";
import _ from "lodash";
import { useCallback, useContext, useMemo } from "react";
import { FetchResult, useMutation } from "@apollo/client";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { CompareArrows } from "@mui/icons-material";

import { ACCEPT_RECOMMENDATION_SCENARIO } from "graphql/mutations/projects";
import { CarbonChangeInformation } from "components/CarbonChangeChip";
import {
  AreaUnit,
  Maybe,
  RecommendationScenario,
  RecommendationScenarioSwaps,
} from "gql/graphql";
import "./RecommendationScenarioPreviewModal.scss";
import { KGCO2E, formatUnitWithScalar } from "utils/formatting";
import numeral from "numeral";
import { SnackbarContext } from "context/SnackbarContext";

interface Props {
  closeModal: () => void;
  onAccepted: (result: FetchResult<any>) => void;
  open: boolean;
  scenario: { scenario: RecommendationScenario | null; title: string | null };
  fromIntensity?: number;
}

const RecommendationScenarioPreviewModal = ({
  closeModal,
  onAccepted,
  open,
  scenario: scenarioInPreview,
  fromIntensity,
}: Props) => {
  const { scenario, title } = scenarioInPreview;
  const [acceptScenarioSwaps] = useMutation(ACCEPT_RECOMMENDATION_SCENARIO, {
    variables: { input: { scenarioId: scenario?.id } },
  });
  const { flashMessage } = useContext(SnackbarContext);
  const handleAcceptSwaps = useCallback(async () => {
    const result = await acceptScenarioSwaps();
    closeModal();
    flashMessage("Products have been swapped in your project.", {
      severity: "success",
    });
    onAccepted(result);
  }, [acceptScenarioSwaps, closeModal, onAccepted, flashMessage]);

  const toIntensity = useMemo(
    () => scenario?.carbon.perArea.kgCo2e,
    [scenario]
  );
  const unit = _.first(scenario?.swaps)?.from?.environmentalImpact?.carbon
    ?.declaredUnit;

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      id="recommendation-preview-modal"
      open={open}
      onClose={closeModal}
    >
      <DialogTitle component="h6">{title}</DialogTitle>
      <DialogContent>
        <div className="header-text">
          Project carbon intensity reduces
          <IntensityText
            fromIntensity={fromIntensity}
            toIntensity={toIntensity}
            unit={scenario?.carbon.perArea.unit}
          />
        </div>
        <Box>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left" width="20%">
                  <Typography variant="subtitle1">Current Product</Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography variant="body2">
                    GWP Limit{" "}
                    {unit ? (
                      <span>
                        <KGCO2E />/{formatUnitWithScalar(unit)}
                      </span>
                    ) : (
                      ""
                    )}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography variant="body2">vs average products</Typography>
                </TableCell>
                <TableCell align="center"></TableCell>
                <TableCell align="left">
                  <Typography variant="subtitle1">
                    Low Carbon Alternative
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography variant="body2">
                    GWP Limit{" "}
                    {unit ? (
                      <span>
                        <KGCO2E />/{formatUnitWithScalar(unit)}
                      </span>
                    ) : (
                      ""
                    )}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography variant="body2">vs average products</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {_.map(scenario?.swaps, (swap: RecommendationScenarioSwaps) => (
                <TableRow key={swap.from.node.name}>
                  <ProductInfoCell
                    name={swap.from.node?.name}
                    slug={swap.from.node?.slug}
                    location={swap.from?.geography?.name}
                    carbonRatioToBaseline={
                      swap.from?.environmentalImpact?.carbon?.comparedToBaseline
                        ?.ratioToBaseline
                    }
                    carbonPerUnit={
                      swap.from?.environmentalImpact?.carbon?.kgCo2e?.perUnit
                    }
                  />
                  <TableCell width="4%" align="center">
                    <CompareArrows className="compare-arswaps" />
                  </TableCell>
                  <ProductInfoCell
                    name={swap.to.node?.name}
                    slug={swap.to.node?.slug}
                    location={swap.to?.geography?.name}
                    carbonRatioToBaseline={
                      swap.to?.environmentalImpact?.carbon?.comparedToBaseline
                        ?.ratioToBaseline
                    }
                    carbonPerUnit={
                      swap.to?.environmentalImpact?.carbon?.kgCo2e?.perUnit
                    }
                  />
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          disableElevation
          variant="contained"
          onClick={closeModal}
          color="secondary"
        >
          Cancel
        </Button>
        <Button
          disableElevation
          onClick={handleAcceptSwaps}
          variant="contained"
        >
          Accept swaps
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RecommendationScenarioPreviewModal;
interface ProductConfig {
  carbonPerUnit: Maybe<number> | undefined;
  carbonRatioToBaseline: Maybe<number> | undefined;
  location: Maybe<string> | undefined;
  name: Maybe<string> | undefined;
  slug: Maybe<string> | undefined;
}

const ProductInfoCell = ({
  carbonPerUnit,
  carbonRatioToBaseline,
  location,
  name,
  slug,
}: ProductConfig) => {
  return (
    <>
      <TableCell align="left" className="product-cell">
        <Typography variant="body2" className="name">
          <Link to={`/products/${slug}`} target="_blank">
            {name}
          </Link>
        </Typography>
        <Typography variant="body2" className="location">
          {location || "Location N/A"}
        </Typography>
      </TableCell>
      <TableCell width="14%" align="right" className="reduction">
        {carbonPerUnit?.toFixed(0)}
      </TableCell>
      <TableCell width="14%" align="right" className="reduction">
        <CarbonChangeInformation
          carbonRatioToBaseline={carbonRatioToBaseline}
          showTooltip={false}
          includeSuffix={false}
        />
      </TableCell>
    </>
  );
};

const IntensityText = ({
  fromIntensity,
  toIntensity,
  unit,
}: {
  fromIntensity?: number;
  toIntensity?: number;
  unit?: Maybe<AreaUnit>;
}) => {
  if (!fromIntensity || !toIntensity) return <>.</>;
  return (
    <Typography variant="body1" className="intensity-text" component="span">
      {" "}
      from <span className="bold">
        {numeral(fromIntensity).format("0,0")}
      </span>{" "}
      to <KGCO2E bold kgCo2e={toIntensity} unit={unit} separator="/" />
    </Typography>
  );
};
