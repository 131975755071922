export const EmptyPortfolio = () => {
  return (
    <svg
      width="402"
      height="197"
      viewBox="0 0 402 197"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="20" y="84" width="34" height="113" fill="#D9D9D9" />
      <g clipPath="url(#clip0_5352_5334)">
        <rect
          width="32"
          height="31"
          transform="translate(84 21)"
          fill="white"
        />
        <rect
          x="-26.9106"
          y="44.0894"
          width="1.26304"
          height="184.699"
          transform="rotate(-45 -26.9106 44.0894)"
          fill="#D8D8D8"
        />
        <rect
          x="-24.2314"
          y="41.4102"
          width="1.26304"
          height="184.699"
          transform="rotate(-45 -24.2314 41.4102)"
          fill="#D8D8D8"
        />
        <rect
          x="-21.5522"
          y="38.731"
          width="1.26304"
          height="184.699"
          transform="rotate(-45 -21.5522 38.731)"
          fill="#D8D8D8"
        />
        <rect
          x="-18.8726"
          y="36.0513"
          width="1.26304"
          height="184.699"
          transform="rotate(-45 -18.8726 36.0513)"
          fill="#D8D8D8"
        />
        <rect
          x="-16.1934"
          y="33.3721"
          width="1.26304"
          height="184.699"
          transform="rotate(-45 -16.1934 33.3721)"
          fill="#D8D8D8"
        />
        <rect
          x="-13.5142"
          y="30.6929"
          width="1.26304"
          height="184.699"
          transform="rotate(-45 -13.5142 30.6929)"
          fill="#D8D8D8"
        />
        <rect
          x="-10.835"
          y="28.0137"
          width="1.26304"
          height="184.699"
          transform="rotate(-45 -10.835 28.0137)"
          fill="#D8D8D8"
        />
        <rect
          x="-8.15527"
          y="25.334"
          width="1.26304"
          height="184.699"
          transform="rotate(-45 -8.15527 25.334)"
          fill="#D8D8D8"
        />
        <rect
          x="-5.47607"
          y="22.6548"
          width="1.26304"
          height="184.699"
          transform="rotate(-45 -5.47607 22.6548)"
          fill="#D8D8D8"
        />
        <rect
          x="-2.79688"
          y="19.9756"
          width="1.26304"
          height="184.699"
          transform="rotate(-45 -2.79688 19.9756)"
          fill="#D8D8D8"
        />
        <rect
          x="-0.117676"
          y="17.2964"
          width="1.26304"
          height="184.699"
          transform="rotate(-45 -0.117676 17.2964)"
          fill="#D8D8D8"
        />
        <rect
          x="2.56201"
          y="14.6167"
          width="1.26303"
          height="184.699"
          transform="rotate(-45 2.56201 14.6167)"
          fill="#D8D8D8"
        />
        <rect
          x="5.24121"
          y="11.9375"
          width="1.26304"
          height="184.699"
          transform="rotate(-45 5.24121 11.9375)"
          fill="#D8D8D8"
        />
        <rect
          x="7.92041"
          y="9.2583"
          width="1.26304"
          height="184.699"
          transform="rotate(-45 7.92041 9.2583)"
          fill="#D8D8D8"
        />
        <rect
          x="10.5996"
          y="6.5791"
          width="1.26304"
          height="184.699"
          transform="rotate(-45 10.5996 6.5791)"
          fill="#D8D8D8"
        />
        <rect
          x="13.2788"
          y="3.8999"
          width="1.26303"
          height="184.699"
          transform="rotate(-45 13.2788 3.8999)"
          fill="#D8D8D8"
        />
        <rect
          x="15.9585"
          y="1.22021"
          width="1.26305"
          height="184.699"
          transform="rotate(-45 15.9585 1.22021)"
          fill="#D8D8D8"
        />
        <rect
          x="18.6377"
          y="-1.45898"
          width="1.26305"
          height="184.699"
          transform="rotate(-45 18.6377 -1.45898)"
          fill="#D8D8D8"
        />
        <rect
          x="21.3169"
          y="-4.13818"
          width="1.26304"
          height="184.699"
          transform="rotate(-45 21.3169 -4.13818)"
          fill="#D8D8D8"
        />
        <rect
          x="23.9961"
          y="-6.81738"
          width="1.26304"
          height="184.699"
          transform="rotate(-45 23.9961 -6.81738)"
          fill="#D8D8D8"
        />
        <rect
          x="26.6758"
          y="-9.49707"
          width="1.26303"
          height="184.699"
          transform="rotate(-45 26.6758 -9.49707)"
          fill="#D8D8D8"
        />
        <rect
          x="29.355"
          y="-12.1763"
          width="1.26304"
          height="184.699"
          transform="rotate(-45 29.355 -12.1763)"
          fill="#D8D8D8"
        />
        <rect
          x="32.0342"
          y="-14.8555"
          width="1.26304"
          height="184.699"
          transform="rotate(-45 32.0342 -14.8555)"
          fill="#D8D8D8"
        />
        <rect
          x="34.7134"
          y="-17.5347"
          width="1.26305"
          height="184.699"
          transform="rotate(-45 34.7134 -17.5347)"
          fill="#D8D8D8"
        />
        <rect
          x="40.0723"
          y="-22.8936"
          width="1.26304"
          height="184.699"
          transform="rotate(-45 40.0723 -22.8936)"
          fill="#D8D8D8"
        />
        <rect
          x="37.3926"
          y="-20.2139"
          width="1.26304"
          height="184.699"
          transform="rotate(-45 37.3926 -20.2139)"
          fill="#D8D8D8"
        />
        <rect
          x="42.7515"
          y="-25.5728"
          width="1.26305"
          height="184.699"
          transform="rotate(-45 42.7515 -25.5728)"
          fill="#D8D8D8"
        />
        <rect
          x="45.4307"
          y="-28.252"
          width="1.26304"
          height="184.699"
          transform="rotate(-45 45.4307 -28.252)"
          fill="#D8D8D8"
        />
        <rect
          x="48.1099"
          y="-30.9312"
          width="1.26304"
          height="184.699"
          transform="rotate(-45 48.1099 -30.9312)"
          fill="#D8D8D8"
        />
        <rect
          x="50.7896"
          y="-33.6108"
          width="1.26303"
          height="184.699"
          transform="rotate(-45 50.7896 -33.6108)"
          fill="#D8D8D8"
        />
        <rect
          x="53.4688"
          y="-36.29"
          width="1.26303"
          height="184.699"
          transform="rotate(-45 53.4688 -36.29)"
          fill="#D8D8D8"
        />
        <rect
          x="56.1479"
          y="-38.9692"
          width="1.26305"
          height="184.699"
          transform="rotate(-45 56.1479 -38.9692)"
          fill="#D8D8D8"
        />
        <rect
          x="58.8271"
          y="-41.6484"
          width="1.26304"
          height="184.699"
          transform="rotate(-45 58.8271 -41.6484)"
          fill="#D8D8D8"
        />
        <rect
          x="61.5068"
          y="-44.3281"
          width="1.26303"
          height="184.699"
          transform="rotate(-45 61.5068 -44.3281)"
          fill="#D8D8D8"
        />
        <rect
          x="64.186"
          y="-47.0073"
          width="1.26303"
          height="184.699"
          transform="rotate(-45 64.186 -47.0073)"
          fill="#D8D8D8"
        />
        <rect
          x="66.8652"
          y="-49.6865"
          width="1.26303"
          height="184.699"
          transform="rotate(-45 66.8652 -49.6865)"
          fill="#D8D8D8"
        />
        <rect
          x="69.5444"
          y="-52.3657"
          width="1.26305"
          height="184.699"
          transform="rotate(-45 69.5444 -52.3657)"
          fill="#D8D8D8"
        />
        <rect
          x="72.2236"
          y="-55.0449"
          width="1.26305"
          height="184.699"
          transform="rotate(-45 72.2236 -55.0449)"
          fill="#D8D8D8"
        />
        <rect
          x="74.9033"
          y="-57.7246"
          width="1.26303"
          height="184.699"
          transform="rotate(-45 74.9033 -57.7246)"
          fill="#D8D8D8"
        />
        <rect
          x="77.5825"
          y="-60.4038"
          width="1.26303"
          height="184.699"
          transform="rotate(-45 77.5825 -60.4038)"
          fill="#D8D8D8"
        />
        <rect
          x="80.2617"
          y="-63.083"
          width="1.26305"
          height="184.699"
          transform="rotate(-45 80.2617 -63.083)"
          fill="#D8D8D8"
        />
        <rect
          x="82.9409"
          y="-65.7622"
          width="1.26305"
          height="184.699"
          transform="rotate(-45 82.9409 -65.7622)"
          fill="#D8D8D8"
        />
        <rect
          x="85.6206"
          y="-68.4419"
          width="1.26303"
          height="184.699"
          transform="rotate(-45 85.6206 -68.4419)"
          fill="#D8D8D8"
        />
        <rect
          x="88.2998"
          y="-71.1211"
          width="1.26303"
          height="184.699"
          transform="rotate(-45 88.2998 -71.1211)"
          fill="#D8D8D8"
        />
        <rect
          x="90.979"
          y="-73.8003"
          width="1.26305"
          height="184.699"
          transform="rotate(-45 90.979 -73.8003)"
          fill="#D8D8D8"
        />
        <rect
          x="93.6582"
          y="-76.4795"
          width="1.26305"
          height="184.699"
          transform="rotate(-45 93.6582 -76.4795)"
          fill="#D8D8D8"
        />
        <rect
          x="96.3374"
          y="-79.1587"
          width="1.26303"
          height="184.699"
          transform="rotate(-45 96.3374 -79.1587)"
          fill="#D8D8D8"
        />
        <rect
          x="99.0171"
          y="-81.8384"
          width="1.26305"
          height="184.699"
          transform="rotate(-45 99.0171 -81.8384)"
          fill="#D8D8D8"
        />
        <rect
          x="101.696"
          y="-84.5176"
          width="1.26303"
          height="184.699"
          transform="rotate(-45 101.696 -84.5176)"
          fill="#D8D8D8"
        />
      </g>
      <path d="M84 55H116V123H84V55Z" fill="#D8D8D8" />
      <path d="M84 126H116V197H84V126Z" fill="#D8D8D8" />
      <g clipPath="url(#clip1_5352_5334)">
        <rect
          width="32"
          height="31"
          transform="translate(146 13)"
          fill="white"
        />
        <rect
          x="35.0894"
          y="36.0894"
          width="1.26304"
          height="184.699"
          transform="rotate(-45 35.0894 36.0894)"
          fill="#D8D8D8"
        />
        <rect
          x="37.7686"
          y="33.4102"
          width="1.26304"
          height="184.699"
          transform="rotate(-45 37.7686 33.4102)"
          fill="#D8D8D8"
        />
        <rect
          x="40.4478"
          y="30.731"
          width="1.26304"
          height="184.699"
          transform="rotate(-45 40.4478 30.731)"
          fill="#D8D8D8"
        />
        <rect
          x="43.1274"
          y="28.0513"
          width="1.26304"
          height="184.699"
          transform="rotate(-45 43.1274 28.0513)"
          fill="#D8D8D8"
        />
        <rect
          x="45.8066"
          y="25.3721"
          width="1.26304"
          height="184.699"
          transform="rotate(-45 45.8066 25.3721)"
          fill="#D8D8D8"
        />
        <rect
          x="48.4858"
          y="22.6929"
          width="1.26304"
          height="184.699"
          transform="rotate(-45 48.4858 22.6929)"
          fill="#D8D8D8"
        />
        <rect
          x="51.165"
          y="20.0137"
          width="1.26304"
          height="184.699"
          transform="rotate(-45 51.165 20.0137)"
          fill="#D8D8D8"
        />
        <rect
          x="53.8447"
          y="17.334"
          width="1.26304"
          height="184.699"
          transform="rotate(-45 53.8447 17.334)"
          fill="#D8D8D8"
        />
        <rect
          x="56.5239"
          y="14.6548"
          width="1.26304"
          height="184.699"
          transform="rotate(-45 56.5239 14.6548)"
          fill="#D8D8D8"
        />
        <rect
          x="59.2031"
          y="11.9756"
          width="1.26304"
          height="184.699"
          transform="rotate(-45 59.2031 11.9756)"
          fill="#D8D8D8"
        />
        <rect
          x="61.8823"
          y="9.29639"
          width="1.26304"
          height="184.699"
          transform="rotate(-45 61.8823 9.29639)"
          fill="#D8D8D8"
        />
        <rect
          x="64.562"
          y="6.6167"
          width="1.26303"
          height="184.699"
          transform="rotate(-45 64.562 6.6167)"
          fill="#D8D8D8"
        />
        <rect
          x="67.2412"
          y="3.9375"
          width="1.26304"
          height="184.699"
          transform="rotate(-45 67.2412 3.9375)"
          fill="#D8D8D8"
        />
        <rect
          x="69.9204"
          y="1.2583"
          width="1.26304"
          height="184.699"
          transform="rotate(-45 69.9204 1.2583)"
          fill="#D8D8D8"
        />
        <rect
          x="72.5996"
          y="-1.4209"
          width="1.26304"
          height="184.699"
          transform="rotate(-45 72.5996 -1.4209)"
          fill="#D8D8D8"
        />
        <rect
          x="75.2788"
          y="-4.1001"
          width="1.26303"
          height="184.699"
          transform="rotate(-45 75.2788 -4.1001)"
          fill="#D8D8D8"
        />
        <rect
          x="77.9585"
          y="-6.77979"
          width="1.26305"
          height="184.699"
          transform="rotate(-45 77.9585 -6.77979)"
          fill="#D8D8D8"
        />
        <rect
          x="80.6377"
          y="-9.45898"
          width="1.26305"
          height="184.699"
          transform="rotate(-45 80.6377 -9.45898)"
          fill="#D8D8D8"
        />
        <rect
          x="83.3169"
          y="-12.1382"
          width="1.26304"
          height="184.699"
          transform="rotate(-45 83.3169 -12.1382)"
          fill="#D8D8D8"
        />
        <rect
          x="85.9961"
          y="-14.8174"
          width="1.26304"
          height="184.699"
          transform="rotate(-45 85.9961 -14.8174)"
          fill="#D8D8D8"
        />
        <rect
          x="88.6758"
          y="-17.4971"
          width="1.26303"
          height="184.699"
          transform="rotate(-45 88.6758 -17.4971)"
          fill="#D8D8D8"
        />
        <rect
          x="91.355"
          y="-20.1763"
          width="1.26304"
          height="184.699"
          transform="rotate(-45 91.355 -20.1763)"
          fill="#D8D8D8"
        />
        <rect
          x="94.0342"
          y="-22.8555"
          width="1.26304"
          height="184.699"
          transform="rotate(-45 94.0342 -22.8555)"
          fill="#D8D8D8"
        />
        <rect
          x="96.7134"
          y="-25.5347"
          width="1.26305"
          height="184.699"
          transform="rotate(-45 96.7134 -25.5347)"
          fill="#D8D8D8"
        />
        <rect
          x="102.072"
          y="-30.8936"
          width="1.26304"
          height="184.699"
          transform="rotate(-45 102.072 -30.8936)"
          fill="#D8D8D8"
        />
        <rect
          x="99.3926"
          y="-28.2139"
          width="1.26304"
          height="184.699"
          transform="rotate(-45 99.3926 -28.2139)"
          fill="#D8D8D8"
        />
        <rect
          x="104.751"
          y="-33.5728"
          width="1.26305"
          height="184.699"
          transform="rotate(-45 104.751 -33.5728)"
          fill="#D8D8D8"
        />
        <rect
          x="107.431"
          y="-36.252"
          width="1.26304"
          height="184.699"
          transform="rotate(-45 107.431 -36.252)"
          fill="#D8D8D8"
        />
        <rect
          x="110.11"
          y="-38.9312"
          width="1.26304"
          height="184.699"
          transform="rotate(-45 110.11 -38.9312)"
          fill="#D8D8D8"
        />
        <rect
          x="112.79"
          y="-41.6108"
          width="1.26303"
          height="184.699"
          transform="rotate(-45 112.79 -41.6108)"
          fill="#D8D8D8"
        />
        <rect
          x="115.469"
          y="-44.29"
          width="1.26303"
          height="184.699"
          transform="rotate(-45 115.469 -44.29)"
          fill="#D8D8D8"
        />
        <rect
          x="118.148"
          y="-46.9692"
          width="1.26305"
          height="184.699"
          transform="rotate(-45 118.148 -46.9692)"
          fill="#D8D8D8"
        />
        <rect
          x="120.827"
          y="-49.6484"
          width="1.26304"
          height="184.699"
          transform="rotate(-45 120.827 -49.6484)"
          fill="#D8D8D8"
        />
        <rect
          x="123.507"
          y="-52.3281"
          width="1.26303"
          height="184.699"
          transform="rotate(-45 123.507 -52.3281)"
          fill="#D8D8D8"
        />
        <rect
          x="126.186"
          y="-55.0073"
          width="1.26303"
          height="184.699"
          transform="rotate(-45 126.186 -55.0073)"
          fill="#D8D8D8"
        />
        <rect
          x="128.865"
          y="-57.6865"
          width="1.26303"
          height="184.699"
          transform="rotate(-45 128.865 -57.6865)"
          fill="#D8D8D8"
        />
        <rect
          x="131.544"
          y="-60.3657"
          width="1.26305"
          height="184.699"
          transform="rotate(-45 131.544 -60.3657)"
          fill="#D8D8D8"
        />
        <rect
          x="134.224"
          y="-63.0449"
          width="1.26305"
          height="184.699"
          transform="rotate(-45 134.224 -63.0449)"
          fill="#D8D8D8"
        />
        <rect
          x="136.903"
          y="-65.7246"
          width="1.26303"
          height="184.699"
          transform="rotate(-45 136.903 -65.7246)"
          fill="#D8D8D8"
        />
        <rect
          x="139.583"
          y="-68.4038"
          width="1.26303"
          height="184.699"
          transform="rotate(-45 139.583 -68.4038)"
          fill="#D8D8D8"
        />
        <rect
          x="142.262"
          y="-71.083"
          width="1.26305"
          height="184.699"
          transform="rotate(-45 142.262 -71.083)"
          fill="#D8D8D8"
        />
        <rect
          x="144.941"
          y="-73.7622"
          width="1.26305"
          height="184.699"
          transform="rotate(-45 144.941 -73.7622)"
          fill="#D8D8D8"
        />
        <rect
          x="147.621"
          y="-76.4419"
          width="1.26303"
          height="184.699"
          transform="rotate(-45 147.621 -76.4419)"
          fill="#D8D8D8"
        />
        <rect
          x="150.3"
          y="-79.1211"
          width="1.26303"
          height="184.699"
          transform="rotate(-45 150.3 -79.1211)"
          fill="#D8D8D8"
        />
        <rect
          x="152.979"
          y="-81.8003"
          width="1.26305"
          height="184.699"
          transform="rotate(-45 152.979 -81.8003)"
          fill="#D8D8D8"
        />
        <rect
          x="155.658"
          y="-84.4795"
          width="1.26305"
          height="184.699"
          transform="rotate(-45 155.658 -84.4795)"
          fill="#D8D8D8"
        />
        <rect
          x="158.337"
          y="-87.1587"
          width="1.26303"
          height="184.699"
          transform="rotate(-45 158.337 -87.1587)"
          fill="#D8D8D8"
        />
        <rect
          x="161.017"
          y="-89.8384"
          width="1.26305"
          height="184.699"
          transform="rotate(-45 161.017 -89.8384)"
          fill="#D8D8D8"
        />
        <rect
          x="163.696"
          y="-92.5176"
          width="1.26303"
          height="184.699"
          transform="rotate(-45 163.696 -92.5176)"
          fill="#D8D8D8"
        />
      </g>
      <g clipPath="url(#clip2_5352_5334)">
        <rect
          width="32"
          height="31"
          transform="translate(146 47)"
          fill="white"
        />
        <rect
          x="35.0894"
          y="70.0894"
          width="1.26304"
          height="184.699"
          transform="rotate(-45 35.0894 70.0894)"
          fill="#D8D8D8"
        />
        <rect
          x="37.7686"
          y="67.4102"
          width="1.26304"
          height="184.699"
          transform="rotate(-45 37.7686 67.4102)"
          fill="#D8D8D8"
        />
        <rect
          x="40.4478"
          y="64.731"
          width="1.26304"
          height="184.699"
          transform="rotate(-45 40.4478 64.731)"
          fill="#D8D8D8"
        />
        <rect
          x="43.1274"
          y="62.0513"
          width="1.26304"
          height="184.699"
          transform="rotate(-45 43.1274 62.0513)"
          fill="#D8D8D8"
        />
        <rect
          x="45.8066"
          y="59.3721"
          width="1.26304"
          height="184.699"
          transform="rotate(-45 45.8066 59.3721)"
          fill="#D8D8D8"
        />
        <rect
          x="48.4858"
          y="56.6929"
          width="1.26304"
          height="184.699"
          transform="rotate(-45 48.4858 56.6929)"
          fill="#D8D8D8"
        />
        <rect
          x="51.165"
          y="54.0137"
          width="1.26304"
          height="184.699"
          transform="rotate(-45 51.165 54.0137)"
          fill="#D8D8D8"
        />
        <rect
          x="53.8447"
          y="51.334"
          width="1.26304"
          height="184.699"
          transform="rotate(-45 53.8447 51.334)"
          fill="#D8D8D8"
        />
        <rect
          x="56.5239"
          y="48.6548"
          width="1.26304"
          height="184.699"
          transform="rotate(-45 56.5239 48.6548)"
          fill="#D8D8D8"
        />
        <rect
          x="59.2031"
          y="45.9756"
          width="1.26304"
          height="184.699"
          transform="rotate(-45 59.2031 45.9756)"
          fill="#D8D8D8"
        />
        <rect
          x="61.8823"
          y="43.2964"
          width="1.26304"
          height="184.699"
          transform="rotate(-45 61.8823 43.2964)"
          fill="#D8D8D8"
        />
        <rect
          x="64.562"
          y="40.6167"
          width="1.26303"
          height="184.699"
          transform="rotate(-45 64.562 40.6167)"
          fill="#D8D8D8"
        />
        <rect
          x="67.2412"
          y="37.9375"
          width="1.26304"
          height="184.699"
          transform="rotate(-45 67.2412 37.9375)"
          fill="#D8D8D8"
        />
        <rect
          x="69.9204"
          y="35.2583"
          width="1.26304"
          height="184.699"
          transform="rotate(-45 69.9204 35.2583)"
          fill="#D8D8D8"
        />
        <rect
          x="72.5996"
          y="32.5791"
          width="1.26304"
          height="184.699"
          transform="rotate(-45 72.5996 32.5791)"
          fill="#D8D8D8"
        />
        <rect
          x="75.2788"
          y="29.8999"
          width="1.26303"
          height="184.699"
          transform="rotate(-45 75.2788 29.8999)"
          fill="#D8D8D8"
        />
        <rect
          x="77.9585"
          y="27.2202"
          width="1.26305"
          height="184.699"
          transform="rotate(-45 77.9585 27.2202)"
          fill="#D8D8D8"
        />
        <rect
          x="80.6377"
          y="24.541"
          width="1.26305"
          height="184.699"
          transform="rotate(-45 80.6377 24.541)"
          fill="#D8D8D8"
        />
        <rect
          x="83.3169"
          y="21.8618"
          width="1.26304"
          height="184.699"
          transform="rotate(-45 83.3169 21.8618)"
          fill="#D8D8D8"
        />
        <rect
          x="85.9961"
          y="19.1826"
          width="1.26304"
          height="184.699"
          transform="rotate(-45 85.9961 19.1826)"
          fill="#D8D8D8"
        />
        <rect
          x="88.6758"
          y="16.5029"
          width="1.26303"
          height="184.699"
          transform="rotate(-45 88.6758 16.5029)"
          fill="#D8D8D8"
        />
        <rect
          x="91.355"
          y="13.8237"
          width="1.26304"
          height="184.699"
          transform="rotate(-45 91.355 13.8237)"
          fill="#D8D8D8"
        />
        <rect
          x="94.0342"
          y="11.1445"
          width="1.26304"
          height="184.699"
          transform="rotate(-45 94.0342 11.1445)"
          fill="#D8D8D8"
        />
        <rect
          x="96.7134"
          y="8.46533"
          width="1.26305"
          height="184.699"
          transform="rotate(-45 96.7134 8.46533)"
          fill="#D8D8D8"
        />
        <rect
          x="102.072"
          y="3.10645"
          width="1.26304"
          height="184.699"
          transform="rotate(-45 102.072 3.10645)"
          fill="#D8D8D8"
        />
        <rect
          x="99.3926"
          y="5.78613"
          width="1.26304"
          height="184.699"
          transform="rotate(-45 99.3926 5.78613)"
          fill="#D8D8D8"
        />
        <rect
          x="104.751"
          y="0.427246"
          width="1.26305"
          height="184.699"
          transform="rotate(-45 104.751 0.427246)"
          fill="#D8D8D8"
        />
        <rect
          x="107.431"
          y="-2.25195"
          width="1.26304"
          height="184.699"
          transform="rotate(-45 107.431 -2.25195)"
          fill="#D8D8D8"
        />
        <rect
          x="110.11"
          y="-4.93115"
          width="1.26304"
          height="184.699"
          transform="rotate(-45 110.11 -4.93115)"
          fill="#D8D8D8"
        />
        <rect
          x="112.79"
          y="-7.61084"
          width="1.26303"
          height="184.699"
          transform="rotate(-45 112.79 -7.61084)"
          fill="#D8D8D8"
        />
        <rect
          x="115.469"
          y="-10.29"
          width="1.26303"
          height="184.699"
          transform="rotate(-45 115.469 -10.29)"
          fill="#D8D8D8"
        />
        <rect
          x="118.148"
          y="-12.9692"
          width="1.26305"
          height="184.699"
          transform="rotate(-45 118.148 -12.9692)"
          fill="#D8D8D8"
        />
        <rect
          x="120.827"
          y="-15.6484"
          width="1.26304"
          height="184.699"
          transform="rotate(-45 120.827 -15.6484)"
          fill="#D8D8D8"
        />
        <rect
          x="123.507"
          y="-18.3281"
          width="1.26303"
          height="184.699"
          transform="rotate(-45 123.507 -18.3281)"
          fill="#D8D8D8"
        />
        <rect
          x="126.186"
          y="-21.0073"
          width="1.26303"
          height="184.699"
          transform="rotate(-45 126.186 -21.0073)"
          fill="#D8D8D8"
        />
        <rect
          x="128.865"
          y="-23.6865"
          width="1.26303"
          height="184.699"
          transform="rotate(-45 128.865 -23.6865)"
          fill="#D8D8D8"
        />
        <rect
          x="131.544"
          y="-26.3657"
          width="1.26305"
          height="184.699"
          transform="rotate(-45 131.544 -26.3657)"
          fill="#D8D8D8"
        />
        <rect
          x="134.224"
          y="-29.0449"
          width="1.26305"
          height="184.699"
          transform="rotate(-45 134.224 -29.0449)"
          fill="#D8D8D8"
        />
        <rect
          x="136.903"
          y="-31.7246"
          width="1.26303"
          height="184.699"
          transform="rotate(-45 136.903 -31.7246)"
          fill="#D8D8D8"
        />
        <rect
          x="139.583"
          y="-34.4038"
          width="1.26303"
          height="184.699"
          transform="rotate(-45 139.583 -34.4038)"
          fill="#D8D8D8"
        />
        <rect
          x="142.262"
          y="-37.083"
          width="1.26305"
          height="184.699"
          transform="rotate(-45 142.262 -37.083)"
          fill="#D8D8D8"
        />
        <rect
          x="144.941"
          y="-39.7622"
          width="1.26305"
          height="184.699"
          transform="rotate(-45 144.941 -39.7622)"
          fill="#D8D8D8"
        />
        <rect
          x="147.621"
          y="-42.4419"
          width="1.26303"
          height="184.699"
          transform="rotate(-45 147.621 -42.4419)"
          fill="#D8D8D8"
        />
        <rect
          x="150.3"
          y="-45.1211"
          width="1.26303"
          height="184.699"
          transform="rotate(-45 150.3 -45.1211)"
          fill="#D8D8D8"
        />
        <rect
          x="152.979"
          y="-47.8003"
          width="1.26305"
          height="184.699"
          transform="rotate(-45 152.979 -47.8003)"
          fill="#D8D8D8"
        />
        <rect
          x="155.658"
          y="-50.4795"
          width="1.26305"
          height="184.699"
          transform="rotate(-45 155.658 -50.4795)"
          fill="#D8D8D8"
        />
        <rect
          x="158.337"
          y="-53.1587"
          width="1.26303"
          height="184.699"
          transform="rotate(-45 158.337 -53.1587)"
          fill="#D8D8D8"
        />
        <rect
          x="161.017"
          y="-55.8384"
          width="1.26305"
          height="184.699"
          transform="rotate(-45 161.017 -55.8384)"
          fill="#D8D8D8"
        />
        <rect
          x="163.696"
          y="-58.5176"
          width="1.26303"
          height="184.699"
          transform="rotate(-45 163.696 -58.5176)"
          fill="#D8D8D8"
        />
      </g>
      <path d="M146 81H178V197H146V81Z" fill="#D8D8D8" />
      <g clipPath="url(#clip3_5352_5334)">
        <rect
          width="32"
          height="31"
          transform="translate(208 21)"
          fill="white"
        />
        <rect
          x="97.0894"
          y="44.0894"
          width="1.26304"
          height="184.699"
          transform="rotate(-45 97.0894 44.0894)"
          fill="#D8D8D8"
        />
        <rect
          x="99.7686"
          y="41.4102"
          width="1.26304"
          height="184.699"
          transform="rotate(-45 99.7686 41.4102)"
          fill="#D8D8D8"
        />
        <rect
          x="102.448"
          y="38.731"
          width="1.26304"
          height="184.699"
          transform="rotate(-45 102.448 38.731)"
          fill="#D8D8D8"
        />
        <rect
          x="105.127"
          y="36.0513"
          width="1.26304"
          height="184.699"
          transform="rotate(-45 105.127 36.0513)"
          fill="#D8D8D8"
        />
        <rect
          x="107.807"
          y="33.3721"
          width="1.26304"
          height="184.699"
          transform="rotate(-45 107.807 33.3721)"
          fill="#D8D8D8"
        />
        <rect
          x="110.486"
          y="30.6929"
          width="1.26304"
          height="184.699"
          transform="rotate(-45 110.486 30.6929)"
          fill="#D8D8D8"
        />
        <rect
          x="113.165"
          y="28.0137"
          width="1.26304"
          height="184.699"
          transform="rotate(-45 113.165 28.0137)"
          fill="#D8D8D8"
        />
        <rect
          x="115.845"
          y="25.334"
          width="1.26304"
          height="184.699"
          transform="rotate(-45 115.845 25.334)"
          fill="#D8D8D8"
        />
        <rect
          x="118.524"
          y="22.6548"
          width="1.26304"
          height="184.699"
          transform="rotate(-45 118.524 22.6548)"
          fill="#D8D8D8"
        />
        <rect
          x="121.203"
          y="19.9756"
          width="1.26304"
          height="184.699"
          transform="rotate(-45 121.203 19.9756)"
          fill="#D8D8D8"
        />
        <rect
          x="123.882"
          y="17.2964"
          width="1.26304"
          height="184.699"
          transform="rotate(-45 123.882 17.2964)"
          fill="#D8D8D8"
        />
        <rect
          x="126.562"
          y="14.6167"
          width="1.26303"
          height="184.699"
          transform="rotate(-45 126.562 14.6167)"
          fill="#D8D8D8"
        />
        <rect
          x="129.241"
          y="11.9375"
          width="1.26304"
          height="184.699"
          transform="rotate(-45 129.241 11.9375)"
          fill="#D8D8D8"
        />
        <rect
          x="131.92"
          y="9.2583"
          width="1.26304"
          height="184.699"
          transform="rotate(-45 131.92 9.2583)"
          fill="#D8D8D8"
        />
        <rect
          x="134.6"
          y="6.5791"
          width="1.26304"
          height="184.699"
          transform="rotate(-45 134.6 6.5791)"
          fill="#D8D8D8"
        />
        <rect
          x="137.279"
          y="3.8999"
          width="1.26303"
          height="184.699"
          transform="rotate(-45 137.279 3.8999)"
          fill="#D8D8D8"
        />
        <rect
          x="139.958"
          y="1.22021"
          width="1.26305"
          height="184.699"
          transform="rotate(-45 139.958 1.22021)"
          fill="#D8D8D8"
        />
        <rect
          x="142.638"
          y="-1.45898"
          width="1.26305"
          height="184.699"
          transform="rotate(-45 142.638 -1.45898)"
          fill="#D8D8D8"
        />
        <rect
          x="145.317"
          y="-4.13818"
          width="1.26304"
          height="184.699"
          transform="rotate(-45 145.317 -4.13818)"
          fill="#D8D8D8"
        />
        <rect
          x="147.996"
          y="-6.81738"
          width="1.26304"
          height="184.699"
          transform="rotate(-45 147.996 -6.81738)"
          fill="#D8D8D8"
        />
        <rect
          x="150.676"
          y="-9.49707"
          width="1.26303"
          height="184.699"
          transform="rotate(-45 150.676 -9.49707)"
          fill="#D8D8D8"
        />
        <rect
          x="153.355"
          y="-12.1763"
          width="1.26304"
          height="184.699"
          transform="rotate(-45 153.355 -12.1763)"
          fill="#D8D8D8"
        />
        <rect
          x="156.034"
          y="-14.8555"
          width="1.26304"
          height="184.699"
          transform="rotate(-45 156.034 -14.8555)"
          fill="#D8D8D8"
        />
        <rect
          x="158.713"
          y="-17.5347"
          width="1.26305"
          height="184.699"
          transform="rotate(-45 158.713 -17.5347)"
          fill="#D8D8D8"
        />
        <rect
          x="164.072"
          y="-22.8936"
          width="1.26304"
          height="184.699"
          transform="rotate(-45 164.072 -22.8936)"
          fill="#D8D8D8"
        />
        <rect
          x="161.393"
          y="-20.2139"
          width="1.26304"
          height="184.699"
          transform="rotate(-45 161.393 -20.2139)"
          fill="#D8D8D8"
        />
        <rect
          x="166.751"
          y="-25.5728"
          width="1.26305"
          height="184.699"
          transform="rotate(-45 166.751 -25.5728)"
          fill="#D8D8D8"
        />
        <rect
          x="169.431"
          y="-28.252"
          width="1.26304"
          height="184.699"
          transform="rotate(-45 169.431 -28.252)"
          fill="#D8D8D8"
        />
        <rect
          x="172.11"
          y="-30.9312"
          width="1.26304"
          height="184.699"
          transform="rotate(-45 172.11 -30.9312)"
          fill="#D8D8D8"
        />
        <rect
          x="174.79"
          y="-33.6108"
          width="1.26303"
          height="184.699"
          transform="rotate(-45 174.79 -33.6108)"
          fill="#D8D8D8"
        />
        <rect
          x="177.469"
          y="-36.29"
          width="1.26303"
          height="184.699"
          transform="rotate(-45 177.469 -36.29)"
          fill="#D8D8D8"
        />
        <rect
          x="180.148"
          y="-38.9692"
          width="1.26305"
          height="184.699"
          transform="rotate(-45 180.148 -38.9692)"
          fill="#D8D8D8"
        />
        <rect
          x="182.827"
          y="-41.6484"
          width="1.26304"
          height="184.699"
          transform="rotate(-45 182.827 -41.6484)"
          fill="#D8D8D8"
        />
        <rect
          x="185.507"
          y="-44.3281"
          width="1.26303"
          height="184.699"
          transform="rotate(-45 185.507 -44.3281)"
          fill="#D8D8D8"
        />
        <rect
          x="188.186"
          y="-47.0073"
          width="1.26303"
          height="184.699"
          transform="rotate(-45 188.186 -47.0073)"
          fill="#D8D8D8"
        />
        <rect
          x="190.865"
          y="-49.6865"
          width="1.26303"
          height="184.699"
          transform="rotate(-45 190.865 -49.6865)"
          fill="#D8D8D8"
        />
        <rect
          x="193.544"
          y="-52.3657"
          width="1.26305"
          height="184.699"
          transform="rotate(-45 193.544 -52.3657)"
          fill="#D8D8D8"
        />
        <rect
          x="196.224"
          y="-55.0449"
          width="1.26305"
          height="184.699"
          transform="rotate(-45 196.224 -55.0449)"
          fill="#D8D8D8"
        />
        <rect
          x="198.903"
          y="-57.7246"
          width="1.26303"
          height="184.699"
          transform="rotate(-45 198.903 -57.7246)"
          fill="#D8D8D8"
        />
        <rect
          x="201.583"
          y="-60.4038"
          width="1.26303"
          height="184.699"
          transform="rotate(-45 201.583 -60.4038)"
          fill="#D8D8D8"
        />
        <rect
          x="204.262"
          y="-63.083"
          width="1.26305"
          height="184.699"
          transform="rotate(-45 204.262 -63.083)"
          fill="#D8D8D8"
        />
        <rect
          x="206.941"
          y="-65.7622"
          width="1.26305"
          height="184.699"
          transform="rotate(-45 206.941 -65.7622)"
          fill="#D8D8D8"
        />
        <rect
          x="209.621"
          y="-68.4419"
          width="1.26303"
          height="184.699"
          transform="rotate(-45 209.621 -68.4419)"
          fill="#D8D8D8"
        />
        <rect
          x="212.3"
          y="-71.1211"
          width="1.26303"
          height="184.699"
          transform="rotate(-45 212.3 -71.1211)"
          fill="#D8D8D8"
        />
        <rect
          x="214.979"
          y="-73.8003"
          width="1.26305"
          height="184.699"
          transform="rotate(-45 214.979 -73.8003)"
          fill="#D8D8D8"
        />
        <rect
          x="217.658"
          y="-76.4795"
          width="1.26305"
          height="184.699"
          transform="rotate(-45 217.658 -76.4795)"
          fill="#D8D8D8"
        />
        <rect
          x="220.337"
          y="-79.1587"
          width="1.26303"
          height="184.699"
          transform="rotate(-45 220.337 -79.1587)"
          fill="#D8D8D8"
        />
        <rect
          x="223.017"
          y="-81.8384"
          width="1.26305"
          height="184.699"
          transform="rotate(-45 223.017 -81.8384)"
          fill="#D8D8D8"
        />
        <rect
          x="225.696"
          y="-84.5176"
          width="1.26303"
          height="184.699"
          transform="rotate(-45 225.696 -84.5176)"
          fill="#D8D8D8"
        />
      </g>
      <g clipPath="url(#clip4_5352_5334)">
        <rect
          width="32"
          height="83"
          transform="translate(208 55)"
          fill="white"
        />
        <rect
          x="97.0894"
          y="78.0894"
          width="1.26304"
          height="184.699"
          transform="rotate(-45 97.0894 78.0894)"
          fill="#D8D8D8"
        />
        <rect
          x="99.7686"
          y="75.4102"
          width="1.26304"
          height="184.699"
          transform="rotate(-45 99.7686 75.4102)"
          fill="#D8D8D8"
        />
        <rect
          x="102.448"
          y="72.731"
          width="1.26304"
          height="184.699"
          transform="rotate(-45 102.448 72.731)"
          fill="#D8D8D8"
        />
        <rect
          x="105.127"
          y="70.0513"
          width="1.26304"
          height="184.699"
          transform="rotate(-45 105.127 70.0513)"
          fill="#D8D8D8"
        />
        <rect
          x="107.807"
          y="67.3721"
          width="1.26304"
          height="184.699"
          transform="rotate(-45 107.807 67.3721)"
          fill="#D8D8D8"
        />
        <rect
          x="110.486"
          y="64.6929"
          width="1.26304"
          height="184.699"
          transform="rotate(-45 110.486 64.6929)"
          fill="#D8D8D8"
        />
        <rect
          x="113.165"
          y="62.0137"
          width="1.26304"
          height="184.699"
          transform="rotate(-45 113.165 62.0137)"
          fill="#D8D8D8"
        />
        <rect
          x="115.845"
          y="59.334"
          width="1.26304"
          height="184.699"
          transform="rotate(-45 115.845 59.334)"
          fill="#D8D8D8"
        />
        <rect
          x="118.524"
          y="56.6548"
          width="1.26304"
          height="184.699"
          transform="rotate(-45 118.524 56.6548)"
          fill="#D8D8D8"
        />
        <rect
          x="121.203"
          y="53.9756"
          width="1.26304"
          height="184.699"
          transform="rotate(-45 121.203 53.9756)"
          fill="#D8D8D8"
        />
        <rect
          x="123.882"
          y="51.2964"
          width="1.26304"
          height="184.699"
          transform="rotate(-45 123.882 51.2964)"
          fill="#D8D8D8"
        />
        <rect
          x="126.562"
          y="48.6167"
          width="1.26303"
          height="184.699"
          transform="rotate(-45 126.562 48.6167)"
          fill="#D8D8D8"
        />
        <rect
          x="129.241"
          y="45.9375"
          width="1.26304"
          height="184.699"
          transform="rotate(-45 129.241 45.9375)"
          fill="#D8D8D8"
        />
        <rect
          x="131.92"
          y="43.2583"
          width="1.26304"
          height="184.699"
          transform="rotate(-45 131.92 43.2583)"
          fill="#D8D8D8"
        />
        <rect
          x="134.6"
          y="40.5791"
          width="1.26304"
          height="184.699"
          transform="rotate(-45 134.6 40.5791)"
          fill="#D8D8D8"
        />
        <rect
          x="137.279"
          y="37.8999"
          width="1.26303"
          height="184.699"
          transform="rotate(-45 137.279 37.8999)"
          fill="#D8D8D8"
        />
        <rect
          x="139.958"
          y="35.2202"
          width="1.26305"
          height="184.699"
          transform="rotate(-45 139.958 35.2202)"
          fill="#D8D8D8"
        />
        <rect
          x="142.638"
          y="32.541"
          width="1.26305"
          height="184.699"
          transform="rotate(-45 142.638 32.541)"
          fill="#D8D8D8"
        />
        <rect
          x="145.317"
          y="29.8618"
          width="1.26304"
          height="184.699"
          transform="rotate(-45 145.317 29.8618)"
          fill="#D8D8D8"
        />
        <rect
          x="147.996"
          y="27.1826"
          width="1.26304"
          height="184.699"
          transform="rotate(-45 147.996 27.1826)"
          fill="#D8D8D8"
        />
        <rect
          x="150.676"
          y="24.5029"
          width="1.26303"
          height="184.699"
          transform="rotate(-45 150.676 24.5029)"
          fill="#D8D8D8"
        />
        <rect
          x="153.355"
          y="21.8237"
          width="1.26304"
          height="184.699"
          transform="rotate(-45 153.355 21.8237)"
          fill="#D8D8D8"
        />
        <rect
          x="156.034"
          y="19.1445"
          width="1.26304"
          height="184.699"
          transform="rotate(-45 156.034 19.1445)"
          fill="#D8D8D8"
        />
        <rect
          x="158.713"
          y="16.4653"
          width="1.26305"
          height="184.699"
          transform="rotate(-45 158.713 16.4653)"
          fill="#D8D8D8"
        />
        <rect
          x="164.072"
          y="11.1064"
          width="1.26304"
          height="184.699"
          transform="rotate(-45 164.072 11.1064)"
          fill="#D8D8D8"
        />
        <rect
          x="161.393"
          y="13.7861"
          width="1.26304"
          height="184.699"
          transform="rotate(-45 161.393 13.7861)"
          fill="#D8D8D8"
        />
        <rect
          x="166.751"
          y="8.42725"
          width="1.26305"
          height="184.699"
          transform="rotate(-45 166.751 8.42725)"
          fill="#D8D8D8"
        />
        <rect
          x="169.431"
          y="5.74805"
          width="1.26304"
          height="184.699"
          transform="rotate(-45 169.431 5.74805)"
          fill="#D8D8D8"
        />
        <rect
          x="172.11"
          y="3.06885"
          width="1.26304"
          height="184.699"
          transform="rotate(-45 172.11 3.06885)"
          fill="#D8D8D8"
        />
        <rect
          x="174.79"
          y="0.38916"
          width="1.26303"
          height="184.699"
          transform="rotate(-45 174.79 0.38916)"
          fill="#D8D8D8"
        />
        <rect
          x="177.469"
          y="-2.29004"
          width="1.26303"
          height="184.699"
          transform="rotate(-45 177.469 -2.29004)"
          fill="#D8D8D8"
        />
        <rect
          x="180.148"
          y="-4.96924"
          width="1.26305"
          height="184.699"
          transform="rotate(-45 180.148 -4.96924)"
          fill="#D8D8D8"
        />
        <rect
          x="182.827"
          y="-7.64844"
          width="1.26304"
          height="184.699"
          transform="rotate(-45 182.827 -7.64844)"
          fill="#D8D8D8"
        />
        <rect
          x="185.507"
          y="-10.3281"
          width="1.26303"
          height="184.699"
          transform="rotate(-45 185.507 -10.3281)"
          fill="#D8D8D8"
        />
        <rect
          x="188.186"
          y="-13.0073"
          width="1.26303"
          height="184.699"
          transform="rotate(-45 188.186 -13.0073)"
          fill="#D8D8D8"
        />
        <rect
          x="190.865"
          y="-15.6865"
          width="1.26303"
          height="184.699"
          transform="rotate(-45 190.865 -15.6865)"
          fill="#D8D8D8"
        />
        <rect
          x="193.544"
          y="-18.3657"
          width="1.26305"
          height="184.699"
          transform="rotate(-45 193.544 -18.3657)"
          fill="#D8D8D8"
        />
        <rect
          x="196.224"
          y="-21.0449"
          width="1.26305"
          height="184.699"
          transform="rotate(-45 196.224 -21.0449)"
          fill="#D8D8D8"
        />
        <rect
          x="198.903"
          y="-23.7246"
          width="1.26303"
          height="184.699"
          transform="rotate(-45 198.903 -23.7246)"
          fill="#D8D8D8"
        />
        <rect
          x="201.583"
          y="-26.4038"
          width="1.26303"
          height="184.699"
          transform="rotate(-45 201.583 -26.4038)"
          fill="#D8D8D8"
        />
        <rect
          x="204.262"
          y="-29.083"
          width="1.26305"
          height="184.699"
          transform="rotate(-45 204.262 -29.083)"
          fill="#D8D8D8"
        />
        <rect
          x="206.941"
          y="-31.7622"
          width="1.26305"
          height="184.699"
          transform="rotate(-45 206.941 -31.7622)"
          fill="#D8D8D8"
        />
        <rect
          x="209.621"
          y="-34.4419"
          width="1.26303"
          height="184.699"
          transform="rotate(-45 209.621 -34.4419)"
          fill="#D8D8D8"
        />
        <rect
          x="212.3"
          y="-37.1211"
          width="1.26303"
          height="184.699"
          transform="rotate(-45 212.3 -37.1211)"
          fill="#D8D8D8"
        />
        <rect
          x="214.979"
          y="-39.8003"
          width="1.26305"
          height="184.699"
          transform="rotate(-45 214.979 -39.8003)"
          fill="#D8D8D8"
        />
        <rect
          x="217.658"
          y="-42.4795"
          width="1.26305"
          height="184.699"
          transform="rotate(-45 217.658 -42.4795)"
          fill="#D8D8D8"
        />
        <rect
          x="220.337"
          y="-45.1587"
          width="1.26303"
          height="184.699"
          transform="rotate(-45 220.337 -45.1587)"
          fill="#D8D8D8"
        />
        <rect
          x="223.017"
          y="-47.8384"
          width="1.26305"
          height="184.699"
          transform="rotate(-45 223.017 -47.8384)"
          fill="#D8D8D8"
        />
        <rect
          x="225.696"
          y="-50.5176"
          width="1.26303"
          height="184.699"
          transform="rotate(-45 225.696 -50.5176)"
          fill="#D8D8D8"
        />
      </g>
      <path d="M208 141H240V197H208V141Z" fill="#D8D8D8" />
      <path d="M270 0H302V23H270V0Z" fill="#D8D8D8" />
      <path d="M270 26H302V41H270V26Z" fill="#D8D8D8" />
      <path d="M270 44H302V197H270V44Z" fill="#D8D8D8" />
      <path d="M332 27H364V50H332V27Z" fill="#D8D8D8" />
      <path d="M332 53H364V68H332V53Z" fill="#D8D8D8" />
      <path d="M332 71H364V197H332V71Z" fill="#D8D8D8" />
      <defs>
        <clipPath id="clip0_5352_5334">
          <rect
            width="32"
            height="31"
            fill="white"
            transform="translate(84 21)"
          />
        </clipPath>
        <clipPath id="clip1_5352_5334">
          <rect
            width="32"
            height="31"
            fill="white"
            transform="translate(146 13)"
          />
        </clipPath>
        <clipPath id="clip2_5352_5334">
          <rect
            width="32"
            height="31"
            fill="white"
            transform="translate(146 47)"
          />
        </clipPath>
        <clipPath id="clip3_5352_5334">
          <rect
            width="32"
            height="31"
            fill="white"
            transform="translate(208 21)"
          />
        </clipPath>
        <clipPath id="clip4_5352_5334">
          <rect
            width="32"
            height="83"
            fill="white"
            transform="translate(208 55)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
