import { useEffect, useState, useMemo } from "react";
import _ from "lodash";
import dayjs from "dayjs";
import { FormContainer } from "react-hook-form-mui";
import { useFormContext } from "react-hook-form";
import { useMutation } from "@apollo/client";

import ButtonWithSubmissionState, {
  SubmissionState,
} from "./ButtonWithSubmissionState";
import { GET_PROJECTS, GET_PROJECT_DETAILS } from "graphql/queries/projects";
import { UPDATE_PROJECT } from "graphql/mutations/projects";
import { Project, ProjectUpdateInput } from "../gql/graphql";
import { DATE_STRING, MM_YYYY_STRING } from "utils/formatting";
import PropertyDetailsFields from "./CreateEditProjectForm/components/PropertyDetailsFields";
import FloorGroupsInputFields from "./CreateEditProjectForm/components/FloorGroupsInputFields";
import { ProjectMetadataFields } from "./CreateEditProjectForm/components/ProjectMetadataFields";
import DateInputFields from "./CreateEditProjectForm/components/DateInputFields";
import AddressInputFields from "./CreateEditProjectForm/components/AddressInputFields";
import { ServerErrors } from "./forms/ServerErrors";

import {
  processAddress,
  processFloorGroupDefinition,
  floorsToInput,
} from "pages/CreateProject/GuidedProjectOnboarding/utils";
import "./CreateEditProjectForm.scss";
import { DISABLE_DEFAULT_GRAPHQL_ERROR_HANDLING } from "ApolloClient/links";

const projectToInput = (project?: Project) => {
  const input = {
    ..._.pick(project, [
      "stage",
      "description",
      "propertyUse",
      "structureType",
      "includeInPortfolio",
    ]),
    projectName: project?.name,
    startedAt: project?.startedAt
      ? dayjs(project?.startedAt).format(MM_YYYY_STRING)
      : undefined,
    completedAt: project?.completedAt
      ? dayjs(project?.completedAt).format(MM_YYYY_STRING)
      : undefined,
    address: {
      ...(project?.address || {}),
    },
    aboveGroundFloors: floorsToInput(project?.aboveGroundFloors),
    belowGroundFloors: floorsToInput(project?.belowGroundFloors),
    parkingFloors: floorsToInput(project?.parkingFloors),
  };

  return input;
};

const formToInput = (vals: FormInput) => {
  return _.omitBy(
    {
      ..._.omit(
        vals,
        "projectName",
        "address",
        "aboveGroundFloors",
        "belowGroundFloors",
        "parkingFloors"
      ),
      name: vals.projectName,
      completedAt: vals.completedAt
        ? dayjs(vals.completedAt, MM_YYYY_STRING).format(DATE_STRING)
        : undefined,
      startedAt: vals.startedAt
        ? dayjs(vals.startedAt, MM_YYYY_STRING).format(DATE_STRING)
        : undefined,
      address: processAddress(vals.address),
      aboveGroundFloors: processFloorGroupDefinition(vals.aboveGroundFloors),
      belowGroundFloors: processFloorGroupDefinition(vals.belowGroundFloors),
      parkingFloors: processFloorGroupDefinition(vals.parkingFloors),
    },
    _.isNil
  ) as ProjectUpdateInput;
};

type EditProjectFormProps = {
  afterErrors: () => void;
  afterSave: () => void;
  project: Project;
};

export const EditProjectForm = ({
  afterErrors,
  afterSave,
  project,
  ...rest
}: EditProjectFormProps) => {
  const [mutation] = useMutation(UPDATE_PROJECT, {
    refetchQueries: [
      { query: GET_PROJECT_DETAILS, variables: { slug: project?.slug } },
      { query: GET_PROJECTS },
    ],
    context: { [DISABLE_DEFAULT_GRAPHQL_ERROR_HANDLING]: true },
  });
  const [serverErrors, setServerErrors] = useState<Error[]>([]);

  const onSuccess = async (vals: ProjectUpdateInput) => {
    try {
      setServerErrors([]);
      const result = await mutation({
        variables: {
          input: {
            project: {
              ...formToInput(vals),
              slug: project?.slug,
            },
          },
        },
      });
      const mutationResponses = _.values(_.get(result, "data.project"));
      const errorContainingObject = _.find(
        mutationResponses,
        (project) => project.errors
      );
      if (!_.isEmpty(errorContainingObject?.errors)) {
        setServerErrors(errorContainingObject.errors);
      } else {
        afterSave();
      }
    } catch (error) {
      setServerErrors([error as Error]);
    }
  };

  useEffect(() => {
    if (!_.isEmpty(serverErrors)) {
      afterErrors();
    }
  }, [serverErrors, afterErrors]);

  const defaultValues = useMemo(() => hydrateFormValues(project), [project]);

  return (
    <FormContainer
      FormProps={{
        id: "create-edit-project-form",
        className: "create-edit-project",
        autoComplete: "off",
      }}
      {...{ onSuccess, defaultValues }}
    >
      <ServerErrors {...{ serverErrors }} />
      <CreateEditProjectForm {...{ project, serverErrors }} {...rest} />
    </FormContainer>
  );
};

type Props = {
  project?: Project;
  serverErrors?: Error[];
};

// we use an alias for name so 1password etc don't try to autocomplete
type FormInput = ProjectUpdateInput & { projectName?: string };

const DEFAULT_VALUES = Object.freeze({
  projectName: "",
  structureType: "",
  propertyUse: "",
  address: Object.freeze({
    postalCode: "",
  }),
  stage: "",
}) as unknown as ProjectUpdateInput;

const hydrateFormValues = (project?: Project) =>
  _.merge(_.cloneDeep(DEFAULT_VALUES), projectToInput(project));

const CreateEditProjectForm = ({ project, serverErrors }: Props) => {
  const form = useFormContext();
  const { formState } = form;

  const { isSubmitted, isSubmitting, errors } = formState;

  const submissionState = useMemo(() => {
    if (isSubmitting) return SubmissionState.submitting;
    if (!_.isEmpty(errors) || !_.isEmpty(serverErrors)) {
      return SubmissionState.none;
    }
    if (isSubmitted) {
      return SubmissionState.success;
    }
    return SubmissionState.none;
  }, [isSubmitting, isSubmitted, errors, serverErrors]);

  return (
    <div className="grid">
      <ProjectMetadataFields />

      <FloorGroupsInputFields />

      <AddressInputFields validateLatitude={false} />

      <PropertyDetailsFields />

      <DateInputFields />

      {/* <ImageInputFields /> */}

      <div className="submit-button-container">
        <ButtonWithSubmissionState
          disabled={formState.isSubmitting || !_.isEmpty(errors)}
          uppercase={false}
          submissionState={submissionState}
          icons={{ [SubmissionState.none]: null }}
          entity={project ? "changes" : "project"}
        />
      </div>
    </div>
  );
};
