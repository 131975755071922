import { useState, useEffect, useMemo, useCallback } from "react";
import MenuItem from "@mui/material/MenuItem";
import { ListItemIcon, ListItemText, TextField } from "@mui/material";
import { useQuery, useMutation } from "@apollo/client";
import _ from "lodash";
import {
  UPSERT_PRODUCT_TO_PROJECT,
  REMOVE_ALL_PRODUCT_FROM_PROJECT,
} from "graphql/mutations/projects";
import { GET_PROJECTS_ASSOCIATED_WITH_PRODUCT } from "graphql/queries/products";
import { onError } from "@apollo/client/link/error";
import WithLoadingState from "components/Reporting/WithLoadingState";

import "./AddToProject.scss";

type ProjectForAction = any; // TODO: Redo when we redo this page

interface AddProps {
  closeMenu: () => void;
  productSlug: string;
  project: ProjectForAction;
  projectsForProductData: any;
}

const AddToProject = ({
  closeMenu,
  project,
  productSlug,
  projectsForProductData,
}: AddProps) => {
  const projectsForProduct = useMemo(() => {
    return projectsForProductData?.catalog?.product?.projects?.nodes || [];
  }, [projectsForProductData]);

  const [inProject, setInProject] = useState<Boolean>(false);

  useEffect(() => {
    setInProject(!!_.find(projectsForProduct, { slug: project.slug }));
  }, [projectsForProduct, project.slug]);

  const [saveProductToProject] = useMutation(UPSERT_PRODUCT_TO_PROJECT, {
    onCompleted: () => {
      setInProject(true);
    },
    onError: closeMenu,
  });

  const [deleteProductFromProject] = useMutation(
    REMOVE_ALL_PRODUCT_FROM_PROJECT,
    {
      onCompleted: () => {
        setInProject(false);
      },
      onError: closeMenu,
    }
  );

  const addProductToProject = async (projectSlugToAddTo: string) => {
    await saveProductToProject({
      variables: {
        input: {
          slug: projectSlugToAddTo,
          products: [
            {
              slug: productSlug,
            },
          ],
        },
      },
    });
  };

  const removeProductFromProject = async (projectSlugToRemoveFrom: string) => {
    await deleteProductFromProject({
      variables: {
        input: {
          slug: projectSlugToRemoveFrom,
          products: [
            {
              slug: productSlug,
            },
          ],
        },
      },
    });
  };

  return (
    <MenuItem
      onClick={() => {
        if (inProject) {
          removeProductFromProject(project.slug);
        } else {
          addProductToProject(project.slug);
        }
      }}
      className={`add-to-project-option ${inProject && "is-currently-added"}`}
    >
      <ListItemText>{project.name}</ListItemText>
      {inProject && (
        <ListItemIcon className="check-mark">
          <i className="material-icons md-18">check</i>
        </ListItemIcon>
      )}
    </MenuItem>
  );
};

interface Props {
  closeMenu: () => void;
  productSlug: string;
  searchTerm?: string;
}

export const AddToProjectList = ({
  closeMenu,
  productSlug,
  searchTerm,
}: Props) => {
  const { data: projectsForProductData, loading } = useQuery<any>(
    GET_PROJECTS_ASSOCIATED_WITH_PRODUCT,
    { variables: { slug: productSlug, q: searchTerm }, fetchPolicy: "no-cache" }
  );

  const myProjects = projectsForProductData?.projects?.nodes || [];

  return (
    <div className="add-to-project-list">
      <li className="label add-to-project">Add to Project</li>
      <WithLoadingState isLoading={loading}>
        <div>
          {_.map(myProjects, (p) => (
            <AddToProject
              key={p.slug}
              project={p}
              productSlug={productSlug}
              projectsForProductData={projectsForProductData}
              closeMenu={closeMenu}
            />
          ))}
        </div>
      </WithLoadingState>
    </div>
  );
};
