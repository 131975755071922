import { Box, ButtonBase, Typography } from "@mui/material";
import { User } from "gql/graphql";
import { UserIcon } from "icons/UserIcon";

import "./UserCell.scss";
import { PropsWithChildren, memo, useCallback, useMemo } from "react";
import _ from "lodash";

interface UserCellProps extends Partial<User> {
  clickable?: boolean;
  onClick?: React.MouseEventHandler<HTMLAnchorElement> | undefined;
  size?: "compact" | "large";
}

export const UserCell = memo(
  function UserCell({
    name,
    email,
    clickable = true,
    onClick = _.noop,
    size = "compact",
  }: UserCellProps) {
    const iconSize = useMemo(() => (size === "compact" ? 45 : 90), [size]);

    const Wrapper = useCallback(
      ({ children }: PropsWithChildren) => {
        if (clickable) {
          return (
            <ButtonBase
              disableRipple
              className="user-cell"
              onClick={onClick}
              component="a"
            >
              {children}
            </ButtonBase>
          );
        } else {
          return <Box className="user-cell">{children}</Box>;
        }
      },
      [clickable, onClick]
    );

    return (
      <Wrapper>
        <UserIcon size={iconSize} name={name} email={email} />
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          ml={2}
        >
          {size === "large" ? (
            <>
              <Typography variant="h6">{name}</Typography>
              <Typography variant="body1">{email}</Typography>
            </>
          ) : size === "compact" ? (
            <>
              <Typography variant="body2">{name}</Typography>
              <Typography variant="body2">{email}</Typography>
            </>
          ) : null}
        </Box>
      </Wrapper>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.name === nextProps.name &&
      prevProps.email === nextProps.email &&
      prevProps.clickable === nextProps.clickable &&
      prevProps.size === nextProps.size
    );
  }
);
