import { PaginationConfig } from "components/Pagination";
import { ProductFilterInput } from "gql/graphql";

export enum SortCatalogBy {
  dateAdded = "Recently Added",
  absCarbonLoToHi = "Absolute Carbon: Low to High",
  absCarbonHiToLo = "Absolute Carbon: High to Low",
  percentCarbonReductionLoToHi = "Percent Carbon Reduction: Low to High",
  percentCarbonReductionHiToLo = "Percent Carbon Reduction: High to Low",
}

// Deserialized (i.e. ready for <Products /> local state)
// version of ProductsCatalogQueryParams.
export type DeserializedProductsCatalogQueryParams = PaginationConfig & {
  filter: ProductFilterInput;
  sortKey: SortCatalogBy;
  q?: string;
};
