import React, { PropsWithChildren } from "react";

type RegisteredModal = {
  close: () => void;
  isOpen: boolean;
  open: () => void;
};

interface NamedObject {
  name: string;
}

interface ComponentProps extends PropsWithChildren, NamedObject {
  addToProjectAfterUpload: { projectSlug?: string };
  closeModal: () => void;
  onComplete: () => void;
  open: boolean;
  projectSlug: string;
}

type Component = (props: ComponentProps) => JSX.Element;

type ModalContextType = {
  getModal: (
    componentClass: Component,
    modals: ModalContextModals
  ) => RegisteredModal | null;
  modals: ModalContextModals;
};

type ModalContextModals = {
  [key: string]: RegisteredModal;
};

export const ModalContext = React.createContext<ModalContextType>({
  getModal: (componentClass: Component, modals: ModalContextModals) =>
    modals[componentClass.name],
  modals: {},
});

type ModalDefinition = {
  close: Function;
  isOpen: boolean;
  modal: NamedObject;
  open: Function;
};

// NB: You still need to render your modal(s), likely at the same
// level you render this provider. This context exists
// to provide open/close methods to descendants.
// NB: It indexes modals by name, so the name property must be unique.
// The easiest way to ensure this is to use an ElementClass (i.e., a component class)
// which has a static `name` property by default. (Portfolio.name = "Portfolio", in other words)
export const ModalContextProvider = ({
  children,
  modals,
}: PropsWithChildren & { modals: ModalDefinition[] }) => {
  const contextModals: ModalContextModals = {};
  modals.forEach(({ modal, open, close, isOpen }) => {
    contextModals[modal.name] = {
      open,
      close,
      isOpen,
    } as RegisteredModal;
  });
  const context: ModalContextType = {
    getModal: (componentClass: Component, modals: ModalContextModals) =>
      modals[componentClass.name],
    modals: contextModals,
  };
  return (
    <ModalContext.Provider value={context}>{children}</ModalContext.Provider>
  );
};
