import _ from "lodash";
import { useCallback, useRef, useState } from "react";
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import {
  CopyAll as CopyIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
} from "@mui/icons-material";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";

import { UserPermissionsEnum } from "gql/graphql";
import { DELETE_PROJECT, DUPLICATE_PROJECT } from "graphql/mutations/projects";
import PermissionRestricted, {
  useUserPermissions,
} from "components/PermissionRestricted";
import { Show } from "utils/UtilityComponents";
import DeleteProjectModal from "./DeleteProjectModal";

import "./SingleProjectMenu.scss";
import { GET_PROJECTS } from "graphql/queries/projects";

type Actions = ("delete" | "edit" | "duplicate")[];

type Props = {
  icon: "expand_more" | "more_horiz";
  productCount: number;
  project: { slug: string; name?: string };
  refetchProjects: () => void;
  actions?: Actions;
  afterDelete?: (slug?: string) => void;
  afterDuplicate?: (slug?: string) => void;
};

export const SingleProjectMenu = ({
  icon,
  productCount,
  project: { slug, name: projectName },
  refetchProjects,
  actions = ["delete", "edit", "duplicate"],
  afterDelete = undefined,
  afterDuplicate = undefined,
}: Props) => {
  const [mutation] = useMutation(DUPLICATE_PROJECT);
  const [deleteSingleProject] = useMutation(DELETE_PROJECT, {
    refetchQueries: [GET_PROJECTS],
  });

  const [menuOpen, _setMenuOpen] = useState<boolean>(false);
  const openMenu = () => _setMenuOpen(true);
  const closeMenu = () => _setMenuOpen(false);

  const anchorButtonRef = useRef<HTMLButtonElement | null>(null);

  const onDelete = useCallback(async () => {
    setActionError(undefined);
    try {
      await deleteSingleProject({
        variables: { input: { project: { slug } } },
      });

      if (afterDelete) afterDelete(slug);
    } catch (e) {
      setActionError("delete");
    }
  }, [deleteSingleProject, slug, afterDelete]);

  async function duplicateProject(slug: string) {
    return await mutation({
      variables: { input: { project: { slug } } },
    });
  }
  const duplicateSingleProject = async () => {
    setMenuDisabled();
    setActionError(undefined);
    try {
      const { data } = await duplicateProject(slug);

      if (!_.size(_.get(data, "data.project.clone.errors"))) {
        refetchProjects();
        closeMenu();

        if (afterDuplicate) {
          afterDuplicate(data.project?.clone?.project?.slug);
        }
      } else {
        setActionError("duplicate");
      }
    } catch (e) {
      setActionError("duplicate");
    } finally {
      setMenuEnabled();
    }
  };

  const navigate = useNavigate();
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [menuDisabled, _setMenuDisabled] = useState<boolean>(false);
  const setMenuDisabled = () => _setMenuDisabled(true);
  const setMenuEnabled = () => _setMenuDisabled(false);
  const setDeleteModalClosed = () => setDeleteModalOpen(false);
  const [actionError, setActionError] = useState<
    "delete" | "duplicate" | undefined
  >();

  const canDeleteProject = useUserPermissions(
    UserPermissionsEnum.DeleteProject
  );

  const canDuplicateProject = useUserPermissions(
    UserPermissionsEnum.CreateProject
  );

  const availableActions = {
    delete: _.includes(actions, "delete") && canDeleteProject,
    edit: _.includes(actions, "edit"), // available to all
    duplicate: _.includes(actions, "duplicate") && canDuplicateProject,
  };

  return (
    <Show when={_.some(availableActions)}>
      <IconButton
        disableRipple
        ref={anchorButtonRef}
        aria-controls="single-project-menu"
        aria-haspopup="true"
        aria-expanded={menuOpen ? "true" : undefined}
        className={clsx("material-icons", "md-18", {
          "expand-button": icon === "expand_more",
        })}
        onClick={openMenu}
      >
        {icon}
      </IconButton>
      <Menu
        className={clsx("action-menu", "single-project-menu", {
          disabled: menuDisabled,
        })}
        anchorEl={anchorButtonRef.current}
        open={menuOpen}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {actions.includes("duplicate") && (
          <PermissionRestricted to={UserPermissionsEnum.CreateProject}>
            <MenuItem
              disabled={menuDisabled}
              onClick={() => duplicateSingleProject()}
            >
              <ListItemIcon>
                <CopyIcon />
              </ListItemIcon>
              <ListItemText>Duplicate</ListItemText>
            </MenuItem>
          </PermissionRestricted>
        )}
        {actions.includes("edit") && (
          <MenuItem
            disabled={menuDisabled}
            onClick={() => navigate(`/my-projects/${slug}/details`)}
          >
            <ListItemIcon>
              <EditIcon />
            </ListItemIcon>
            <ListItemText>Edit</ListItemText>
          </MenuItem>
        )}
        {actions.includes("delete") && (
          <PermissionRestricted to={UserPermissionsEnum.DeleteProject}>
            <MenuItem
              disabled={menuDisabled}
              onClick={() => {
                closeMenu();
                setDeleteModalOpen(true);
              }}
            >
              <ListItemIcon>
                <DeleteIcon />
              </ListItemIcon>
              <ListItemText>Delete</ListItemText>
            </MenuItem>
          </PermissionRestricted>
        )}
      </Menu>
      <DeleteProjectModal
        projectName={projectName as string}
        onDelete={onDelete}
        open={deleteModalOpen}
        productCount={productCount}
        error={actionError === "delete"}
        handleClose={setDeleteModalClosed}
      />
    </Show>
  );
};
