import FieldWithError from "components/FieldWithError";
import { useFormContext } from "react-hook-form";

import {
  FormControl,
  InputLabel,
  TextField,
  TextFieldProps,
} from "@mui/material";
import { standardErrorMessage, ariaProps } from "utils/forms";

const textFieldProps: Partial<TextFieldProps> = {
  size: "small",
  placeholder: "MM/YYYY",
  inputProps: {
    pattern: "[0-9]{2}/[0-9]{4}",
    title: "Please enter a date as MM/YYYY, e.g. 01/2030",
  },
};

export const DateInputFields = () => {
  const {
    formState: { errors },
    register,
  } = useFormContext();
  return (
    <>
      <FieldWithError
        className="date-input-field"
        errorMessage={standardErrorMessage(errors, "startedAt")}
        fieldElement={
          <>
            <InputLabel
              htmlFor="startedAt"
              id="started-at-label"
              className="body-4"
            >
              Start date
            </InputLabel>
            <FormControl fullWidth>
              <TextField
                id="startedAt"
                placeholder="Enter month and year"
                {...textFieldProps}
                inputProps={{
                  ...ariaProps(errors, "startedAt"),
                  maxLength: textFieldProps.placeholder!.length,
                  "aria-labelledby": "started-at-label",
                }}
                {...register("startedAt", { required: true })}
              />
            </FormControl>
          </>
        }
      />
      <FieldWithError
        className="date-input-field col2"
        errorMessage={standardErrorMessage(errors, "completedAt")}
        fieldElement={
          <>
            <InputLabel
              id="completed-at-label"
              className="body-4"
              htmlFor="completedAt"
            >
              Completion date
            </InputLabel>
            <FormControl fullWidth>
              <TextField
                id="completedAt"
                placeholder="Enter month and year"
                {...textFieldProps}
                inputProps={{
                  ...ariaProps(errors, "completedAt"),
                  maxLength: textFieldProps.placeholder!.length,
                  "aria-labelledby": "completed-at-label",
                }}
                {...register("completedAt", { required: true })}
              />
            </FormControl>
          </>
        }
      />
    </>
  );
};

export default DateInputFields;
