import { useLazyQuery } from "@apollo/client";
import { Project, ProjectRecommendationEdge } from "gql/graphql";
import { GET_PROJECT_RECOMMENDATIONS } from "graphql/queries/projects";
import _ from "lodash";
import { useCallback, useEffect, useState } from "react";

type HookProps = {
  projectSlug: string;
  maxTries?: number;
  pollInterval?: number;
};

const DEFAULT_POLL_INTERVAL = 6000;
const MAX_TRIES = 3;

export const useRecommendationsTabStatus = ({
  projectSlug,
  maxTries = MAX_TRIES,
  pollInterval = DEFAULT_POLL_INTERVAL,
}: HookProps) => {
  // State
  const [hasRecommendations, setHasRecommendations] = useState(false);
  const [recommendationsPollTimeout, setRecommendationsPollTimeout] =
    useState<NodeJS.Timeout>();

  const [_getRecommendationsTabStatus, { data, stopPolling, startPolling }] =
    useLazyQuery<Project>(GET_PROJECT_RECOMMENDATIONS, {
      variables: { slug: projectSlug },
    });

  // Effects
  useEffect(() => {
    if (hasRecommendations) {
      stopPolling();
    }
  }, [hasRecommendations, stopPolling]);

  useEffect(() => {
    setHasRecommendations(Boolean(data && getHasRecommendations(data)));
  }, [data, setHasRecommendations]);

  // Callbacks
  const setPollingTimeout = useCallback(() => {
    startPolling(pollInterval);
    setRecommendationsPollTimeout(
      setTimeout(() => {
        stopPolling();
      }, pollInterval * maxTries)
    );
  }, [
    setRecommendationsPollTimeout,
    startPolling,
    stopPolling,
    maxTries,
    pollInterval,
  ]);

  const getRecommendationsTabStatus = useCallback(
    (options = { poll: false }) => {
      _getRecommendationsTabStatus();
      if (options.poll) {
        setPollingTimeout();
      }
    },
    [_getRecommendationsTabStatus, setPollingTimeout]
  );

  // Cleanup
  useEffect(() => {
    return () => {
      if (recommendationsPollTimeout) {
        clearTimeout(recommendationsPollTimeout);
      }
    };
  }, [recommendationsPollTimeout]);

  return { hasRecommendations, getRecommendationsTabStatus };
};

const getHasRecommendations = (data: Project) => {
  const recCount = _.get(data, "project.recommendations.totalCount", 0);

  if (recCount < 1) return false;

  const scenarioEdges: ProjectRecommendationEdge[] = _.get(
    data,
    "project.recommendations.edges",
    []
  );
  const scenarioCount = _.reduce(
    scenarioEdges,
    (acc: number, edge: ProjectRecommendationEdge) => {
      return acc + _.get(edge, "node.scenarios.totalCount", 0);
    },
    0
  );
  return scenarioCount > 0;
};
