import _ from "lodash";
import clsx from "clsx";
import { Alert, Link, Typography } from "@mui/material";

import StatBlock from "./StatBlockAlternate";
import {
  REPORTING_STAT_FORMAT,
  TitleWithKGCO2E,
  EmissionsText,
} from "utils/formatting";
import CarbonChangeImpactByProduct from "./Visualizations/CarbonImpactByProduct";
import {
  AreaUnit,
  ProjectDataFidelityEnum,
  ProjectProjectProductEdge,
} from "gql/graphql";
import { ProjectReportData } from "../ProjectReport";
import { DirectionalChange } from "./Visualizations/DirectionalChange";
import "./ReportDashboard.scss";
import LastUpdated from "components/Reporting/LastUpdated";
import BuildingImpactSection from "./BuildingImpactSection";
import { useContext } from "react";
import { DataLoadingContext } from "context/DataLoadingContext";

export type Props = {
  hasProducts: boolean;
  lastUpdatedDate: string;
  data?: ProjectReportData;
  projectProducts?: ProjectProjectProductEdge[];
};

const SKELETON_CARBON_DATA = {
  kgCo2e: 0,
  reduction: {
    absolute: 0,
    relative: 0,
  },
  perArea: {
    kgCo2e: 0,
    reduction: {
      absolute: 0,
      relative: 0,
    },
    unit: AreaUnit.M2,
  },
};

export const ReportDashboard = ({
  lastUpdatedDate,
  projectProducts,
  data,
}: Props) => {
  const noProductsWithQuantities = _.every(projectProducts, { quantity: null });
  const someProductsWithoutQuantities = _.some(projectProducts, {
    quantity: null,
  });

  const carbonData = data?.carbon?.total || SKELETON_CARBON_DATA;

  const hasProducts = _.size(projectProducts) > 0;
  const showReduction =
    data?.dataFidelity === ProjectDataFidelityEnum.ProductBased;
  const { dataLoading } = useContext(DataLoadingContext);

  return (
    <div
      id="project-report-dashboard"
      className={clsx({
        "some-quantities-missing-warning":
          !dataLoading &&
          someProductsWithoutQuantities &&
          !noProductsWithQuantities,
      })}
    >
      {!dataLoading &&
        someProductsWithoutQuantities &&
        !noProductsWithQuantities && (
          <Alert severity="warning">
            Add quantities to products to get more complete results.
          </Alert>
        )}
      <div id="high-level-stats" className="visualization-block border">
        <Typography variant="h6">Results</Typography>
        <LastUpdated lastUpdatedDate={lastUpdatedDate} />
        <div>
          <StatBlock
            title={<TitleWithKGCO2E title="Total Absolute Carbon" />}
            stat={carbonData?.kgCo2e}
            statFormat={REPORTING_STAT_FORMAT}
            subtitle={<EmissionsText from="A1" to="A5" />}
          />
          <StatBlock
            title={
              <TitleWithKGCO2E
                title="Embodied Carbon Intensity"
                unit={
                  carbonData?.perArea?.unit
                    ? carbonData?.perArea?.unit
                    : undefined
                }
                separator="/"
              />
            }
            stat={carbonData?.perArea?.kgCo2e}
            statFormat={REPORTING_STAT_FORMAT}
            subtitle={<EmissionsText from="A1" to="A5" />}
          />
          {showReduction && (
            <StatBlock
              title={
                <TitleWithKGCO2E
                  title={
                    (carbonData?.reduction?.relative || 0) > 0
                      ? "Carbon Reduction"
                      : "Carbon Increase"
                  }
                />
              }
              stat={Math.abs(carbonData?.reduction?.absolute || 0)}
              statFormat={REPORTING_STAT_FORMAT}
              subtitle={
                <>
                  <DirectionalChange
                    change={carbonData?.reduction?.relative || 0}
                  />{" "}
                  vs average products
                </>
              }
            />
          )}
          <Typography pt={1} variant="body2" className="powered-by">
            Estimates powered by{" "}
            <Link target="_blank" href="https://docs.cscale.io/">
              c.scale
            </Link>
            .
          </Typography>
        </div>
      </div>
      <BuildingImpactSection data={data} />
      <div style={{ gridColumn: "span 2" }}>
        <CarbonChangeImpactByProduct
          dataLoading={dataLoading}
          data={data}
          hasProducts={hasProducts}
          noProductsWithQuantities={noProductsWithQuantities}
        />
      </div>
    </div>
  );
};

export default ReportDashboard;
