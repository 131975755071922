import { ProjectDataFidelityEnum, Maybe } from "gql/graphql";
import { pluralizer } from "utils/transforms";

export const DataSourceText = ({
  dataFidelity,
  totalProductCount = 0,
}: {
  dataFidelity: Maybe<ProjectDataFidelityEnum> | undefined;
  totalProductCount: number;
}) => {
  switch (true) {
    case dataFidelity === ProjectDataFidelityEnum.ProductBased:
      return (
        <>
          {totalProductCount} {pluralizer("product", totalProductCount)}
        </>
      );
    case dataFidelity === ProjectDataFidelityEnum.UserUploaded:
      return <>Uploaded report</>;
    case dataFidelity === ProjectDataFidelityEnum.Estimated:
    default:
      return <>Estimated</>;
  }
};
