import { useContext, useRef } from "react";
import _ from "lodash";
import dayjs from "dayjs";
import { max } from "d3-array";
import { axisBottom, axisLeft } from "d3-axis";
import { scaleBand, scaleLinear } from "d3-scale";
import { select } from "d3-selection";

import {
  ReportCarbonTimeSeries,
  Maybe,
  ProjectDataFidelityEnum,
} from "gql/graphql";
import "./TotalAbsoluteCarbonBarChart.scss";
import { KGCO2E } from "utils/formatting";
import WithLoadingState from "components/Reporting/WithLoadingState";
import { ContainerDimensions } from "types";
import { useContainerDimensions } from "utils/CustomHooks";
import {
  formatYAxisTicks,
  getTooltipContent,
  transformTimeSeriesCarbonData,
} from "pages/Portfolio/Visualizations/utils";
import DiagonalHatchPattern from "components/Reporting/DiagonalHatchPattern";
import VisualizationTooltip from "components/Reporting/VisualizationTooltip";
import { DataLoadingContext } from "context/DataLoadingContext";
import { setupTooltip } from "utils/visualizations";

interface Props {
  timeSeriesData: Maybe<ReportCarbonTimeSeries[]>;
}

const HATCHED_PATTERN_ID = "diagonal-hatch-portfolio-estimated-carbon";

const MARGINS = {
  top: 0,
  left: 44,
  bottom: 30,
  right: 10,
};

const MAX_YEAR = 2050;

type BuildViz = (
  dims: ContainerDimensions,
  data: Array<ReportCarbonTimeSeries>
) => void;

const buildVisualization: BuildViz = (
  { width: containerWidth, height: containerHeight },
  data = []
) => {
  const transformedData = _.map(data, (d) => ({
    ...d,
    kgCo2e: transformTimeSeriesCarbonData(d.kgCo2e),
  }));
  const vizWidth = containerWidth - MARGINS.left - MARGINS.right;
  const vizHeight = containerHeight - MARGINS.top - MARGINS.bottom;

  const minYear = dayjs(_.first(transformedData)?.date).year();
  const xDomain = _.map(_.range(minYear, MAX_YEAR + 1), _.toString); // domains must be strings for scaleBand
  const xScale = scaleBand()
    .domain(xDomain)
    .range([0, vizWidth])
    .paddingInner(0.1)
    .paddingOuter(0.5);

  const maxCarbon = max(transformedData, (d) => d?.kgCo2e?.total);
  const yDomain = [0, maxCarbon || 0];
  const yScale = scaleLinear()
    .domain(yDomain as [number, number])
    .range([vizHeight, 0])
    .unknown(vizHeight);

  const container = select("#absolute-carbon-by-year-bar-chart");

  const svg = container
    .select("svg")
    .attr("width", "100%")
    .attr("height", containerHeight);

  svg
    .select(".y-axis")
    .call(
      axisLeft(yScale)
        .ticks(3)
        .tickFormat(formatYAxisTicks as any) as any
    )
    .call((selection) => {
      // customizing the y axis
      selection.selectAll(".domain").remove();
      selection.selectAll(".tick line").attr("x2", vizWidth); // making full grid lines
    });

  const stackedBarGroups = svg
    .select("g.bars")
    .selectAll("g.stacked-bar")
    .filter((d: any) => !!d.kgCo2e.total)
    .data(transformedData)
    .join("g")
    .attr("class", "stacked-bar")
    .attr("transform", (d) => {
      const year = dayjs(d.date).year();
      const xPos = xScale(_.toString(year)) || 0;
      return `translate(${xPos},0)`;
    });

  // draw the rectangles (product-based and estimated)
  stackedBarGroups.each(function (groupData) {
    const barGroup = select(this);
    return barGroup
      .selectAll("rect")
      .data(
        _.map(groupData.kgCo2e.byDataFidelity, (v, k) => ({
          dataFidelity: k,
          carbonValue: v,
        }))
      )
      .join("rect")
      .attr("class", (d) => _.kebabCase(d.dataFidelity))
      .attr("y", (d) => {
        // if this is an estimation bar, we need to start the bar where the product-based bar ends
        if (d.dataFidelity === ProjectDataFidelityEnum.Estimated) {
          const estimatedValue = d.carbonValue as number;
          const productBasedValue =
            groupData.kgCo2e.byDataFidelity[
              ProjectDataFidelityEnum.ProductBased
            ] || 0;

          return yScale((estimatedValue + productBasedValue) as number) || 0;
        } else {
          return yScale(d.carbonValue as number) || 0;
        }
      })
      .attr("height", (d) => vizHeight - yScale(d.carbonValue as number) || 0)
      .attr("width", xScale.bandwidth())
      .attr(
        "fill",
        (d) =>
          d.dataFidelity === ProjectDataFidelityEnum.Estimated
            ? `url(#${HATCHED_PATTERN_ID})`
            : "unset" // set in CSS
      );
  });

  const firstYearWithEmissions = _.first(
    _.filter(
      transformedData,
      (d: ReportCarbonTimeSeries) => d?.kgCo2e?.total && d?.kgCo2e?.total > 0
    )
  ) as ReportCarbonTimeSeries;

  const tooltip = container.select(".tooltip");

  setupTooltip(
    [stackedBarGroups],
    tooltip,
    (_d) => getTooltipContent(_d, firstYearWithEmissions),
    {
      width: containerWidth,
      height: containerHeight,
    }
  );

  svg
    .select(".x-axis")
    .attr("transform", `translate(${MARGINS.left}, ${vizHeight})`)
    .call(
      axisBottom(xScale).tickValues(
        xScale.domain().filter(function (d, i) {
          return +d % 5 === 0 || i === 0;
        })
      ) as any
    )
    .call((selection) => {
      // customize the x-axis
      selection.selectAll(".domain").remove();
      selection.selectAll(".tick line").remove();
    });

  // baseline is a horizontal line set to the emissions of the oldest project with emissions
  svg
    .select("line.baseline")
    .attr("x2", MARGINS.left + (xScale(MAX_YEAR.toString()) || 0))
    .attr("y1", yScale(firstYearWithEmissions?.kgCo2e?.total || 0))
    .attr("y2", yScale(firstYearWithEmissions?.kgCo2e?.total || 0));

  svg
    .select("line.net-zero")
    .attr(
      "x1",
      MARGINS.left +
        (xScale(_.toString(dayjs(firstYearWithEmissions?.date).year())) || 0)
    )
    .attr("x2", MARGINS.left + (xScale(MAX_YEAR.toString()) || 0))
    .attr("y1", yScale(maxCarbon || 0))
    .attr("y2", yScale(0));
};

const TotalAbsoluteCarbonBarChart = ({ timeSeriesData }: Props) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const { dataLoading } = useContext(DataLoadingContext);

  useContainerDimensions<BuildViz>({
    containerRef,
    buildVisualization,
    buildVisualizationArgs: [timeSeriesData],
    updateOnResize: true,
  });

  if (!timeSeriesData) {
    return null;
  }

  return (
    <div
      id="absolute-carbon-by-year-bar-chart"
      className="visualization-block border"
    >
      <p className="subtitle-1">Total Absolute Carbon</p>
      <p>{<KGCO2E />}, project year of completion</p>
      <div
        role="figure"
        style={{ width: "100%", flex: 1, minHeight: 0 }}
        ref={containerRef}
      >
        <WithLoadingState isLoading={dataLoading}>
          <VisualizationTooltip />
          <svg width="100%">
            <g className="x-axis" />
            <g
              className="y-axis"
              transform={`translate(${MARGINS.left}, ${MARGINS.top})`}
            />
            <line className="baseline" x1={MARGINS.left} />
            <line className="net-zero" />
            <g
              className="bars"
              transform={`translate(${MARGINS.left}, ${MARGINS.top})`}
            />
            <DiagonalHatchPattern id={HATCHED_PATTERN_ID} size={4} />
          </svg>
        </WithLoadingState>
      </div>
      <div className="legend">
        <div className="baseline">
          <hr />
          <span>Baseline</span>
        </div>
        <div className="product-based-emissions">
          <div className="circle" />
          <span>Product-based data</span>
        </div>
        <div className="net-zero">
          <hr />
          <span>Net Zero by 2050</span>
        </div>
        <div className="uploaded-data-emissions">
          <div className="circle" />
          <span>Uploaded report</span>
        </div>
        <div className="estimated-emissions">
          <div className="circle" />
          <span>Estimated data</span>
        </div>
      </div>
    </div>
  );
};

export default TotalAbsoluteCarbonBarChart;
