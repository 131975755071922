import { TextField, Button, Icon, Box, Typography } from "@mui/material";
import { FormEvent, MouseEventHandler, useCallback } from "react";

import "./ResetPasswordEmailForm.scss";

export const ResetPasswordEmailForm = ({
  onClickBack,
  triggerResetPassword,
}: {
  onClickBack: MouseEventHandler<any>;
  triggerResetPassword: (email: string) => void;
}) => {
  return (
    <form
      id="reset-password-email-form"
      onSubmit={useCallback(
        (e: FormEvent) => {
          e.preventDefault();
          const data = new FormData(e.target as any);
          triggerResetPassword(data.get("email") as string);
        },
        [triggerResetPassword]
      )}
    >
      <h5>Reset Password</h5>
      <TextField
        required
        label="Email address"
        type="email"
        name="email"
        className="email-input"
      />
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignSelf="stretch"
      >
        <Button
          disableElevation
          variant="contained"
          color="secondary"
          disableRipple
          onClick={useCallback(
            (e: React.MouseEvent) => {
              // no form validation
              e.preventDefault();
              onClickBack(e);
            },
            [onClickBack]
          )}
        >
          Cancel
        </Button>
        <Button disableElevation type="submit" variant="contained">
          Send reset email
        </Button>
      </Box>
    </form>
  );
};
