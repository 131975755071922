import { LOADING_CLASS_NAME } from "components/Reporting/WithLoadingState";
import _ from "lodash";
import {
  MutableRefObject,
  RefObject,
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
} from "react";
import { ContainerDimensions } from "types";

export const buildVisualizationWithDimensions = <T extends Function>(
  buildVisualization: T,
  containerDimensions: ContainerDimensions,
  ...otherArgs: any
) => buildVisualization(containerDimensions, ...otherArgs);

interface ContainerDimensionsConfig<T> {
  buildVisualization: T;
  buildVisualizationArgs: (string | number | any)[];
  containerRef: RefObject<HTMLDivElement>;
  onBuilt?: Function;
  updateOnResize?: boolean;
}

const THROTTLE_MS = 10; // TODO: Might need to adjust for performance

export const useContainerDimensions = <T extends Function>(
  config: ContainerDimensionsConfig<T>
): void => {
  /*
    Get width and height of the ref container and pass it to the buildVisualization function
    If there is a value returned from buildVisualization, pass it to onBuilt function
  */
  const {
    buildVisualization,
    buildVisualizationArgs,
    containerRef,
    onBuilt,
    updateOnResize,
  } = config;

  const buildVizForSize = useCallback(
    ({ width, height, transition }: ContainerDimensions) =>
      buildVisualizationWithDimensions<T>(
        buildVisualization,
        { width, height, transition },
        ...buildVisualizationArgs
      ),
    [buildVisualization, buildVisualizationArgs]
  );

  const observer: MutableRefObject<ResizeObserver | null> = useRef(null);

  const buildVizForSizeThrottled = useMemo(
    () => _.throttle((d) => buildVizForSize(d), THROTTLE_MS),
    [buildVizForSize]
  );
  useLayoutEffect(() => {
    if (containerRef.current) {
      const results = buildVizForSize({
        width: containerRef.current.clientWidth,
        height: containerRef.current.clientHeight,
      });

      if (updateOnResize && containerRef.current) {
        const isLoading = Boolean(
          containerRef.current.querySelector(`.${LOADING_CLASS_NAME}`)
        );
        if (isLoading) return;

        observer.current = new ResizeObserver((entries) => {
          const { width, height } = entries[0].contentRect;
          buildVizForSizeThrottled({ width, height, transition: false });
        });
        observer.current.observe(containerRef.current);
      }

      if (onBuilt) onBuilt(results);
    }
    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, [
    buildVisualization,
    buildVisualizationArgs,
    buildVizForSizeThrottled,
    containerRef,
    containerRef.current?.clientWidth,
    containerRef.current?.clientHeight,
    onBuilt,
    buildVizForSize,
    updateOnResize,
  ]);
};

export const useScrollToTop = (
  options: {
    scrollToTopOnInit?: boolean;
  } = { scrollToTopOnInit: true }
) => {
  const scrollToTop = useCallback(() => window.scrollTo(0, 0), []);

  useLayoutEffect(() => {
    if (options.scrollToTopOnInit) {
      scrollToTop();
    }
  }, [options.scrollToTopOnInit, scrollToTop]);

  return {
    scrollToTop,
  };
};

export const useScrollTo = (ref: RefObject<HTMLElement> | null) => {
  return useMemo(
    () => ({
      scrollToElement: (options = {}) => {
        ref?.current?.scrollTo({
          ...{ behavior: "smooth", top: 0 },
          ...options,
        });
      },
    }),
    [ref]
  );
};
