import { Box, Button, Divider, Paper, Typography } from "@mui/material";

import { ProfileForm } from "./components/ProfileForm";
import { ProfileImage } from "./components/ProfileImage";
import UserAndOrganizationContext from "context/UserAndOrganizationContext";
import { useContext, useMemo } from "react";
import { GET_CURRENT_USER } from "graphql/queries/users";
import { useApolloClient } from "@apollo/client";
import { User } from "gql/graphql";
import { SnackbarContext } from "context/SnackbarContext";

import "./Profile.scss";

export const Profile = () => {
  const [{ currentUser, currentOrganization }, { logout }] = useContext(
    UserAndOrganizationContext
  );
  const { flashMessage } = useContext(SnackbarContext);
  const formProps = {
    id: currentUser?.id,
    name: currentUser?.name,
    email: currentUser?.email,
    organizationName: currentOrganization?.name,
  };
  const client = useApolloClient();
  const afterUpdate = useMemo(
    () => (data: Partial<User>) => {
      flashMessage("Changes saved.", { severity: "success" });

      if (currentUser?.id === data.id) {
        client.writeQuery({
          query: GET_CURRENT_USER,
          data: {
            currentUser: { ...currentUser, ...data },
            currentOrganization,
          },
        });
      }
    },
    [client, currentUser, currentOrganization, flashMessage]
  );

  return (
    <Paper id="profile" elevation={0}>
      <Box p={2}>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h6">Profile</Typography>
          <Button
            disableElevation
            variant="contained"
            color="secondary"
            onClick={logout}
          >
            Logout
          </Button>
        </Box>
        <ProfileImage mt={3} mb={3} {...formProps} />
        <Divider />
        <ProfileForm mt={3} mb={6} afterUpdate={afterUpdate} {...formProps} />
      </Box>
    </Paper>
  );
};

export default Profile;
