import {
  Controller,
  FieldError,
  FieldErrors,
  useFormContext,
} from "react-hook-form";
import FieldWithError from "components/FieldWithError";
import {
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import _ from "lodash";
import { ProjectStage } from "gql/graphql";
import { standardErrorMessage, ariaProps } from "utils/forms";
import { TextFieldElement, TextFieldElementProps } from "react-hook-form-mui";
import { CheckBox } from "@mui/icons-material";

const DefaultTextFieldElement = (props: TextFieldElementProps) => (
  <TextFieldElement fullWidth variant="outlined" size="small" {...props} />
);

export const ProjectMetadataFields = () => {
  const {
    register,
    formState: { errors },
    control,
  } = useFormContext();

  return (
    <>
      <div className="project-metadata-field">
        <InputLabel htmlFor="project-name">Project Name</InputLabel>
        <DefaultTextFieldElement
          required
          name="projectName"
          autoComplete="off"
          id="project-name"
        />
      </div>

      <div className="project-metadata-field">
        <InputLabel htmlFor="project-description">Description</InputLabel>
        <FormControl fullWidth>
          <FieldWithError
            errorMessage={standardErrorMessage(errors, "description")}
            fieldElement={
              <TextField
                fullWidth
                id="project-description"
                variant="outlined"
                placeholder="Description"
                helperText="Optional"
                size="small"
                inputProps={{
                  ...ariaProps(errors, "description"),
                }}
                {...register("description")}
              />
            }
          />
        </FormControl>
      </div>

      <div className="project-metadata-field">
        <FormControl fullWidth>
          <FieldWithError
            errorMessage={standardErrorMessage(errors, "includeInPortfolio")}
            fieldElement={
              <Controller
                name="includeInPortfolio"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Switch
                        id="project-include-in-portfolio"
                        checked={field.value}
                        size="small"
                        inputProps={{
                          ...ariaProps(errors, "includeInPortfolio"),
                        }}
                        {...register("includeInPortfolio")}
                      />
                    }
                    label="Include in Portfolio Dashboard"
                  />
                )}
              />
            }
          />
        </FormControl>
      </div>

      <FieldWithError
        className="project-metadata-field"
        errorMessage={standardErrorMessage(errors, "propertyUse")}
        fieldElement={
          <Controller
            name="stage"
            control={control}
            render={({ field }) => (
              <>
                <InputLabel htmlFor="stage-select" id="stage-select-label">
                  Stage of Construction Project
                </InputLabel>
                <FormControl fullWidth size="small">
                  <InputLabel htmlFor="stage-select">Building Use</InputLabel>
                  <Select
                    inputProps={{
                      id: "stage-select",
                    }}
                    labelId="stage-select-label"
                    size="small"
                    {...field}
                    {...register("stage", { required: true })}
                  >
                    {_.map(
                      [
                        ProjectStage.Concept,
                        ProjectStage.Design,
                        ProjectStage.Construction,
                      ],
                      (use) => (
                        <MenuItem key={use} value={use}>
                          {_.capitalize(use)}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
              </>
            )}
          />
        }
      />
    </>
  );
};

export default ProjectMetadataFields;
