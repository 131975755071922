import { getChangePercentageChipConfig } from "../StatBlockAlternate";
import { Maybe } from "gql/graphql";
import numeral from "numeral";

import "./DirectionalChange.scss";

const down = "↓";
const up = "↑";
export const CHANGE_THRESHOLD = 0.001;

const englishIndefiniteArticleText = (text: string) =>
  // Numbers in english that you would read with `an` instead of `a`,
  // basically that start with e
  ["8%", "11%", "18%", "80%", "800%", "1800%", "1100%"].includes(text)
    ? "an"
    : "a";

export const DirectionalChange = ({
  change,
  includeWord = false,
  indefiniteArticle = false,
  textOnly = false,
  format = getChangePercentageChipConfig(0).format,
  showArrow = true,
}: {
  change?: Maybe<number>;
  format?: string;
  includeWord?: boolean;
  indefiniteArticle?: boolean;
  showArrow?: boolean;
  textOnly?: boolean;
}) => {
  let safeChange = change || 0;
  if (change && Math.abs(change) < CHANGE_THRESHOLD) {
    safeChange = 0;
  }
  const asciiArrow = safeChange < 0 ? up : down; // negative is increase, positive is reduction - this is because it's titled "reduction"
  const indicator = !showArrow || safeChange === 0 ? "" : asciiArrow;
  const text = numeral(
    indicator ? Math.abs(safeChange) : safeChange * -1
  ).format(format);
  const reductionWord = safeChange < 0 ? "increase" : "reduction";
  const includedWord = includeWord ? <> {reductionWord}</> : "";

  if (textOnly) {
    return (
      <>
        {indefiniteArticle && englishIndefiniteArticleText(text)}
        {indicator}
        {text}
      </>
    );
  }

  if (change === 0) {
    return <span className="directional-change">no change</span>;
  }

  if (safeChange === 0) {
    return (
      <span className="directional-change">no significant difference</span>
    );
  }

  return (
    <span className="directional-change">
      {indefiniteArticle && (
        <>
          <span className="indefinite-article">
            {englishIndefiniteArticleText(text)}
          </span>{" "}
        </>
      )}
      <span className={reductionWord}>
        <span className="arrow">{indicator}</span>
        {text}
        {includedWord}
      </span>
    </span>
  );
};
