import React, { PropsWithChildren } from "react";
import { useEffect } from "react";

export const useTitle = (title?: string | null) => {
  useEffect(() => {
    document.title = `Tangible Materials, Inc. | ${title}`;
  }, [title]);
};

export const TitleContext = React.createContext({
  title: "",
});

export const Title: React.FC<PropsWithChildren & { title: string }> = ({
  title,
  children,
}) => {
  useTitle(title);
  return (
    <TitleContext.Provider value={{ title }}>
      {children || null}
    </TitleContext.Provider>
  );
};
