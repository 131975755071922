import React from "react";
import _ from "lodash";
import SingleProduct from "./SingleProduct";
import "./ProductGrid.scss";
import NoResults from "./NoResults";
import { Product } from "gql/graphql";
import { InteractionComponent } from "types";
interface Props {
  productNodes: Product[];
  activeInteractionComponent?: InteractionComponent;
  projectSlug?: string;
}

const ProductGrid = ({
  activeInteractionComponent,
  productNodes,
  projectSlug,
}: Props) => {
  const products = _.map(productNodes, (product: any) => {
    const comparedToBaseline =
      product.environmentalImpact?.carbon?.comparedToBaseline;
    return {
      ...product,
      isAboveBaseline: comparedToBaseline?.isAboveBaseline,
      isCarbonSequestering: comparedToBaseline?.isCarbonSequestering,
      isLowCarbon: comparedToBaseline?.isLowCarbon,
      isAverage: comparedToBaseline?.isAverage,
      ratioToBaseline: comparedToBaseline?.ratioToBaseline,
    };
  });

  return (
    <div className="product-catalog" data-testid="product-catalog">
      {!_.isEmpty(products) ? (
        <div className="product-grid">
          {_.map(products, (p: Product) => {
            const props = {
              ...p,
              manufacturer: p.manufacturer as any,
              hasIwEpd: (p as any).hasIwEpd || false,
              ratioToBaseline: (p as any).ratioToBaseline || 0,
              projectSlug,
            };
            return <SingleProduct {...props} key={p.slug} />;
          })}
        </div>
      ) : activeInteractionComponent ? (
        <NoResults variant={activeInteractionComponent} />
      ) : (
        ""
      )}
    </div>
  );
};

export default ProductGrid;
