import {
  Dialog,
  DialogContent,
  Typography,
  DialogActions,
  Button,
  Box,
} from "@mui/material";
import { User } from "gql/graphql";
import _ from "lodash";
import { UserInputFields } from "pages/Users/UserInputFields";
import { FormEvent, useCallback, useEffect, useRef } from "react";

import "./CreateUserDialog.scss";
import { Add } from "@mui/icons-material";

export const CreateUserDialog = ({
  handleCreate,
  onClose,
  open,
}: {
  handleCreate: (userFields: Partial<User>) => void;
  onClose: () => void;
  open: boolean;
}) => {
  const firstFieldRef = useRef<HTMLInputElement>();
  useEffect(() => {
    if (open) {
      _.debounce(() => {
        firstFieldRef.current?.focus();
      }, 100)();
    }
  }, [firstFieldRef, open]);
  const handleSubmit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      const values = new FormData(e.target as HTMLFormElement);
      const user = Object.fromEntries(values);
      handleCreate(user as Partial<User>);
      onClose();
    },
    [handleCreate, onClose]
  );
  return (
    <Dialog open={open} onClose={onClose}>
      <form name="create-user" onSubmit={handleSubmit}>
        <DialogContent>
          <Typography variant="h6">Invite new user</Typography>
          <Box mt={3}>
            <UserInputFields creating firstFieldRef={firstFieldRef} />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            disableElevation
            variant="contained"
            color="secondary"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            disableElevation
            type="submit"
            variant="contained"
            color="primary"
            startIcon={<Add />}
          >
            Create
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
