import _ from "lodash";
import { ReactNode, useCallback } from "react";

import "./CarbonReductionOpportunities.scss";
import { Link } from "@mui/material";

const SOURCE_LINKS = {
  RMI: "https://rmi.org/insight/reducing-embodied-carbon-in-buildings/",
  "Branch Pattern":
    "https://branchpattern.com/wp-content/uploads/2023/05/BranchPattern-Embodied-Carbon-in-U.S.-Industrial-Real-Estate-Report.pdf",
};

const carbonReductionOpportunities = [
  {
    content: (
      <p>
        Optimize <strong>concrete mixes</strong> for up to{" "}
        <strong>14%-33% reduction in emissions</strong> with a no or low cost
        premium.
      </p>
    ),
    filteredCatalogLink:
      "/products/all?filter.category.in.0=gid%3A%2F%2Ftangible%2FCatalog%3A%3ACategory%2F8&filter.certifiedWith.0.type=INDUSTRY_WIDE_EPD&first=36&sort=percentCarbonReductionHiToLo",
    key: "sustainable-concrete",
    source: "RMI",
    sourceLink: SOURCE_LINKS.RMI,
  },
  {
    content: (
      <p>
        Use high <strong>recycled content rebar</strong> for a{" "}
        <strong>4%-10% reduction</strong> with a no or low cost premium.
      </p>
    ),
    filteredCatalogLink:
      "/products/all?filter.category.in.0=gid%3A%2F%2Ftangible%2FCatalog%3A%3ACategory%2F51&sort=percentCarbonReductionHiToLo",
    key: "recycled-content-rebar",
    source: "RMI",
    sourceLink: SOURCE_LINKS.RMI,
  },
  {
    content: (
      <p>
        Select low or no embodied carbon <strong>insulation</strong> products
        for a <strong>16% reduction</strong> with no cost premium.
      </p>
    ),
    filteredCatalogLink:
      "/products/all?filter.category.in.0=gid%3A%2F%2Ftangible%2FCatalog%3A%3ACategory%2F133&first=36&sort=percentCarbonReductionHiToLo",
    key: "sustainable-insulation",
    source: "RMI",
    sourceLink: SOURCE_LINKS.RMI,
  },
];

interface RecommendationProps {
  content: ReactNode;
  filteredCatalogLink: string;
  source: string;
  sourceLink: string;
}
const Recommendation = ({
  content,
  filteredCatalogLink,
  source,
  sourceLink,
}: RecommendationProps) => {
  const goToCatalogWithFilter = useCallback(() => {
    window.open(filteredCatalogLink, "_blank");
  }, [filteredCatalogLink]);

  return (
    <div className="recommendation" onClick={goToCatalogWithFilter}>
      <span className="material-symbols-outlined swap">
        swap_horizontal_circle
      </span>
      {content}
      <span className="source">
        Source:{" "}
        {/*
          We had to use a button in order to stop event propagation
          because the link needed to be clickable WITHIN a clickable div
          (and to meet a11y requirements: cannot have an anchor tag without href)
        */}
        <Link className="source-link" href={sourceLink} target="_blank">
          {source}
        </Link>
      </span>
      <span className="material-symbols-outlined arrow">chevron_right</span>
    </div>
  );
};

const CarbonReductionOpportunities = () => {
  return (
    <div className="dashboard-block border" id="reduction-opportunities">
      <p className="subtitle-1">Carbon Reduction Opportunities</p>
      {_.map(carbonReductionOpportunities, (recommendation) => (
        <Recommendation {...recommendation} />
      ))}
    </div>
  );
};

export default CarbonReductionOpportunities;
