import _ from "lodash";
import { useApolloClient } from "@apollo/client";
import { Box, Tab, Tabs } from "@mui/material";
import { FormEvent, useCallback, useMemo, useRef, useState } from "react";
import {
  useForm,
  SubmitHandler,
  FormProvider,
  SubmitErrorHandler,
} from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { ProjectCreationInput, ProjectUpdateInput } from "gql/graphql";
import {
  InvalidContext,
  InvalidContextDefault,
  InvalidContextType,
} from "./Context/InvalidContext";
import { Page2 } from "./Pages/Page2";
import { Page1 } from "./Pages/Page1";
import { Page3 } from "./Pages/Page3";

import "./GuidedProjectOnboarding.scss";
import TabPanel, {
  tabAccessibilityProps,
} from "./GuidedProjectOnboarding/TabPanel";
import NavButtons from "./GuidedProjectOnboarding/NavButtons";
import {
  determineHighestValidTab,
  useCache,
  useDefaultValues,
  useSetServerErrors,
  useSubmit,
  scrollToHiddenInvalidError,
} from "./GuidedProjectOnboarding/form";
import { useTabs } from "./GuidedProjectOnboarding/tabs";
import { BlockInteractionContext } from "context/BlockInteractionContext";

const WAIT_FOR_ESTIMATION_MS = 3000;

export const GuidedProjectOnboarding = ({ tabsCount = 3 }) => {
  // STATE
  const navigate = useNavigate();

  // Separate from useForm's isSubmitting, used by NavButtons
  const [submitting, setSubmitting] = useState(false);

  const [invalidContext, setInvalidContext] = useState<InvalidContextType>(
    InvalidContextDefault
  );

  // CACHE
  const { cachedData, cache, setCachedData } = useCache<ProjectCreationInput>(
    useApolloClient()
  );

  // FORM -> DEFAULT FROM CACHE
  const defaultValues = useDefaultValues(cachedData?.project || {});
  const formRef = useRef<HTMLFormElement>(null);

  // USEFORM
  const form = useForm<Partial<ProjectUpdateInput>>({
    defaultValues,
    criteriaMode: "all",
  });
  const {
    trigger,
    handleSubmit,
    clearErrors,
    setError,
    getValues,
    formState: { errors },
  } = form;

  // FORM -> VALIDATION
  const setServerErrors = useSetServerErrors<ProjectUpdateInput>(setError);
  const setInvalidAndIncrement = useCallback(() => {
    setInvalidContext((prevState) => ({
      date: new Date(),
      // We increment the count so that context consumers know how many times the
      // form has been submitted invalidly.
      count: prevState.count + 1,
    }));

    // scroll to invalid error
    scrollToHiddenInvalidError({
      selector: "form .custom-error-messages .MuiTypography-root",
      bottomOffset: document.querySelector(".bottom-bar")?.clientHeight,
    });
  }, [setInvalidContext]);

  // TABS
  const { currentTab, validateAndSetTab } = useTabs<ProjectUpdateInput>({
    determineHighestValidTab,
    tabsCount,
    initialValues: cachedData?.project || getValues(),
    events: {
      prepareToChange: () => {
        clearErrors();
      },
      validate: async () => {
        formRef.current?.checkValidity();
        const isValid = await trigger();
        return isValid;
      },
      onChange: () => {
        setInvalidContext(InvalidContextDefault);
        setCachedData(getValues());
      },
      onInvalid: () => {
        setInvalidAndIncrement();
      },
    },
    urlParameterPrefix: "page-",
  });

  const submitFn = useSubmit();

  // FORM -> SUBMIT
  const submitHandler: SubmitHandler<ProjectCreationInput> = useCallback(
    async (data) => {
      if (!_.isEmpty(errors)) return;
      setSubmitting(true);
      try {
        const { errors: serverErrors, project } = await submitFn(data);
        if (!_.isEmpty(serverErrors)) {
          setServerErrors(serverErrors);
          setSubmitting(false);
        } else {
          const path = project?.slug;
          _.debounce(() => {
            setSubmitting(false);
            navigate("/my-projects" + (path ? `/${path}` : ""));
            cache.clear();
          }, WAIT_FOR_ESTIMATION_MS)();
          // TODO: Hacky- we're debouncing to wait for estimate to have completed.
          // TODO: Poll the project query to ensure estimate is complete
        }
      } catch (error) {
        setServerErrors([error as Error]);
        setSubmitting(false);
        return false;
      }
    },
    [cache, navigate, setServerErrors, submitFn, errors]
  );

  const memoizedSubmit = useMemo(
    () =>
      handleSubmit(submitHandler, function invalidHandler(error) {
        // shake the submit button - validation failed
        setInvalidAndIncrement();
      } as SubmitErrorHandler<ProjectCreationInput>),
    [handleSubmit, submitHandler, setInvalidAndIncrement]
  );
  const submit = useCallback(
    (event: FormEvent) => {
      event.preventDefault();
      if (currentTab < tabsCount) {
        validateAndSetTab(currentTab + 1);
        return;
      }
      memoizedSubmit();
    },
    [currentTab, tabsCount, memoizedSubmit, validateAndSetTab]
  );

  return (
    <FormProvider {...form}>
      <InvalidContext.Provider value={invalidContext}>
        <form name="new_project" onSubmit={submit} ref={formRef}>
          <Box id="guided-project-onboarding">
            <TabPanel value={currentTab} index={1}>
              <Page1 />
            </TabPanel>
            <TabPanel value={currentTab} index={2}>
              <Page2 />
            </TabPanel>
            <TabPanel value={currentTab} index={3}>
              <BlockInteractionContext.Provider value={{ blocked: submitting }}>
                <Page3 />
              </BlockInteractionContext.Provider>
            </TabPanel>
            <Box className="estimation-sidebar"></Box>
            <Box className="bottom-bar">
              <Tabs value={currentTab - 1}>
                {_.map(_.range(1, tabsCount + 1), (index) => (
                  <Tab
                    key={index}
                    disabled
                    disableRipple
                    {...tabAccessibilityProps(index)}
                  />
                ))}
              </Tabs>
              <NavButtons
                value={currentTab}
                prev={() => validateAndSetTab(currentTab - 1)}
                disabled={submitting}
                submitting={submitting}
                tabsCount={tabsCount}
                afterAnimation={() => {
                  setInvalidContext((prev) => ({ ...prev, date: false }));
                }}
                errors={errors}
              />
            </Box>
          </Box>
        </form>
      </InvalidContext.Provider>
    </FormProvider>
  );
};

export default GuidedProjectOnboarding;
