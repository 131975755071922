import _ from "lodash";
import {
  FormControlLabel,
  Icon,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import clsx from "clsx";
import {
  FieldPath,
  FieldValues,
  RegisterOptions,
  useController,
  useFormContext,
} from "react-hook-form";

import { CustomErrorMessages } from "./CustomErrorMessages";
import "./HorizontalRadioGroup.scss";

type OptionStringValues<Enum extends string | number | symbol> = Partial<{
  [key in Enum]: string;
}>;

export interface HorizontalRadioGroupProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
  TEnum extends string = string
> {
  name: string;
  // You can send custom captions (small text) for each value: { STEEL_FRAME: "My Label" }
  captions?: OptionStringValues<TEnum>;
  // You can send icons for each value: { STEEL_FRAME: "My Label" }
  icons?: OptionStringValues<TEnum>;
  // Use for htmlFor; if omitted, will be generated as `guided-project-radio-group-${name}`
  id?: string;
  // You can send custom labels for each value: { STEEL_FRAME: "My Label" }
  labels?: OptionStringValues<TEnum>;
  // Send as { required: true }
  rules?: Omit<
    RegisterOptions<TFieldValues, TName>,
    "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
  >;
  values?: TEnum[];
}

export const makeFirstRadioID = (name: string) =>
  `horizontal-radio-group-first-id__${name}`;

export const HorizontalRadioGroup = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
  TEnum extends string
>({
  id,
  name,
  labels,
  icons,
  captions,
  values,
  rules = {},
}: HorizontalRadioGroupProps<TFieldValues, TName, TEnum>) => {
  try {
    const { control, trigger } = useFormContext();
    const {
      field,
      formState: { errors },
    } = useController({
      name,
      control,
      rules: rules as Omit<
        RegisterOptions<FieldValues, string>,
        "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
      >,
    });

    const options = _.map(values, (value, index) => {
      const label = (
        <>
          {icons && <Icon>{icons[value]}</Icon>}
          <Typography variant="body1">
            {_.get(labels, value, _.startCase(_.toLower(value)))}
          </Typography>
          {captions && (
            <Typography variant="caption">{captions[value]}</Typography>
          )}
        </>
      );
      return (
        <FormControlLabel
          key={index}
          label={label}
          value={value}
          control={
            <Radio
              disableFocusRipple
              id={
                // Add id to first input
                index === 0 ? id || makeFirstRadioID(name) : undefined
              }
            />
          }
        />
      );
    });

    return (
      <div className="horizontal-radio-group">
        <RadioGroup
          className={clsx({ "with-icons": icons })}
          {...field}
          onChange={(e) => {
            field.onChange(e);
            trigger(name);
          }}
        >
          {options}
        </RadioGroup>
        <CustomErrorMessages errors={errors} name={name} />
      </div>
    );
  } catch (e) {
    throw new Error("HorizontalRadioGroup: Must be used within a FormContext");
  }
};
