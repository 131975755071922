import _ from "lodash";
import { useCallback, useMemo, useEffect } from "react";
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Alert,
  Button,
} from "@mui/material";
import { useLazyQuery, useMutation, ApolloError } from "@apollo/client";
import { DatePicker } from "@mui/x-date-pickers";
import { Controller, useForm, SubmitHandler } from "react-hook-form";

import FieldWithError from "components/FieldWithError";
import {
  GetAllCategoriesQuery,
  LciaMethodEnum,
  Maybe,
  Mutation,
} from "gql/graphql";
import { GET_ALL_CATEGORIES } from "graphql/queries/categories";
import { UPLOAD_EPD } from "graphql/mutations/products";
import { lciaMethodFormatLookup } from "utils/formatting";
import { ariaProps, standardErrorMessage } from "utils/forms";
import { formatDeclaredUnit } from "utils/transforms";
import { UploadEpdInput, transformProductInputForMutation } from "./transforms";
import AttachFile from "./AttachFile";
import "./UploadEPDForm.scss";
import { DISABLE_DEFAULT_GRAPHQL_ERROR_HANDLING } from "ApolloClient/links";
import { useAddProduct } from "utils/hooks/useAddProduct";

// NOTE: consider using "useFieldArray" if we have to support performance criteria
// or multiple certifications in the future

// For now, we are intentionally using a schema that is not the exact same as
// the graphql's ProductCreateInput type
// because we are only allowing input of A1A2A3 impact & one certification

const DEFAULT_VALUES = Object.freeze({
  name: "",
  carbon: { lciaMethod: "" },
  certification: {
    certificationType: "",
    issuedAt: null,
    expiresAt: null,
    epdFileId: "",
  },
  description: "",
  makePublicInFuture: true,
  primaryCategoryId: "",
}) as UploadEpdInput;

interface Props {
  closeModal: () => void;
  open: boolean;
  addToProjectAfterUpload?: {
    projectSlug?: string;
  };
  onComplete?: (productSlug?: Maybe<string>) => void;
}

const UploadEPDForm = ({
  closeModal,
  open,
  onComplete,
  addToProjectAfterUpload,
}: Props) => {
  const formProps = useForm<UploadEpdInput>({
    defaultValues: DEFAULT_VALUES,
    mode: "onSubmit",
    reValidateMode: "onBlur",
  });
  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
    control,
    reset,
    setError,
    clearErrors,
    setValue,
  } = formProps;

  const closeModalAndResetForm = useCallback(() => {
    reset();
    closeModal();
  }, [reset, closeModal]);

  // only fetch the categories once the modal is open, not just rendered (uzeLazyQuery)
  const [getAllCategories, { data: categoriesData }] =
    useLazyQuery<GetAllCategoriesQuery>(GET_ALL_CATEGORIES, {
      onError: (error: ApolloError) => {
        setError("root", {
          message: `Error loading categories: ${error.message}`,
        });
      },
    });

  useEffect(() => {
    if (open) {
      getAllCategories();
    }
  }, [open, getAllCategories]);

  const categoriesList = useMemo(
    () => categoriesData?.catalog?.categories?.nodes || [],
    [categoriesData]
  );

  const { addProduct, loading: loadingAddProduct } = useAddProduct({
    projectSlug: addToProjectAfterUpload?.projectSlug,
  });

  const finalize = useCallback(
    (productSlug?: Maybe<string>) => {
      closeModalAndResetForm();
      if (onComplete) onComplete(productSlug);
    },
    [closeModalAndResetForm, onComplete]
  );

  const [uploadEpd, { loading: loadingUpload }] = useMutation(UPLOAD_EPD, {
    onCompleted: async ({ product }: Mutation) => {
      const productSlug = product?.create?.product?.slug;
      if (addToProjectAfterUpload && productSlug) {
        await addProduct(productSlug);
      }
      finalize(productSlug);
    },
    onError: (error: ApolloError) => {
      setError("root", { message: error.message });
    },
    context: { [DISABLE_DEFAULT_GRAPHQL_ERROR_HANDLING]: true },
  });

  const displayCategoryDeclaredUnit = useCallback(
    (categoryId: string) => {
      const category = _.find(categoriesList, { id: categoryId });
      const unit = _.get(category, "declaredUnit", "unit");
      return formatDeclaredUnit(unit as string);
    },
    [categoriesList]
  );

  const onSubmit: SubmitHandler<UploadEpdInput> = async (vals) => {
    if (!vals.certification.epdFileId) {
      setError("certification.epdFileId", {
        message: "Please attach a PDF file.",
      });
      return;
    }
    try {
      await uploadEpd({
        variables: {
          input: {
            product: transformProductInputForMutation(vals),
          },
        },
      });
    } catch (e: any) {
      setError("root", { message: e?.message });
    }
  };

  const onKeyDown: React.KeyboardEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      if (event.key === "Escape") closeModalAndResetForm();
    },
    [closeModalAndResetForm]
  );

  return (
    <Dialog
      fullWidth
      open={open}
      id="upload-epd-form"
      aria-describedby="upload-epd-form-description"
      aria-labelledby="upload-epd-form-title"
      onKeyDown={onKeyDown}
    >
      <h5 id="upload-epd-form-title">Add a product to your workspace</h5>
      <DialogContent>
        <p id="upload-epd-form-description">
          This will only be visible to people in your company&apos;s workspace.
        </p>
        <form>
          {/* ref={formRef} */}
          <FieldWithError
            errorMessage={standardErrorMessage(errors, "name")}
            fieldElement={
              <TextField
                fullWidth
                id="product-name"
                label="Product name"
                size="small"
                {...register("name", { required: true })}
                inputProps={{ ...ariaProps(errors, "name") }}
              />
            }
          />
          <fieldset className="row">
            <FieldWithError
              errorMessage={standardErrorMessage(errors, "primaryCategoryId")}
              fieldElement={
                <Controller
                  name="primaryCategoryId"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <FormControl fullWidth size="small">
                      <InputLabel id="category-select-label">
                        Category
                      </InputLabel>
                      <Select
                        fullWidth
                        inputProps={{
                          ...ariaProps(errors, "primaryCategoryId"),
                        }}
                        label="Category"
                        labelId="category-select-label"
                        {...field}
                      >
                        {_.map(categoriesList, (category) => (
                          <MenuItem
                            key={category?.id}
                            value={category?.id as string}
                          >
                            {category?.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
              }
            />
            <FieldWithError
              errorMessage={standardErrorMessage(
                errors,
                "certification.certificationType"
              )}
              fieldElement={
                <Controller
                  name="certification.certificationType"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <FormControl fullWidth size="small">
                      <InputLabel id="epd-type-select-label">Type</InputLabel>
                      <Select
                        fullWidth
                        label="Type"
                        labelId="epd-type-select-label"
                        inputProps={{
                          ...ariaProps(
                            errors,
                            "certification.certificationType"
                          ),
                        }}
                        {...field}
                      >
                        <MenuItem
                          key="INDUSTRY_WIDE_EPD"
                          value="INDUSTRY_WIDE_EPD"
                        >
                          Industry-wide EPD
                        </MenuItem>

                        <MenuItem key="PRODUCT_EPD" value="PRODUCT_EPD">
                          Product-specific EPD
                        </MenuItem>
                      </Select>
                    </FormControl>
                  )}
                />
              }
            />
          </fieldset>
          <FieldWithError
            errorMessage={standardErrorMessage(errors, "carbon.kgCo2e")}
            fieldElement={
              <TextField
                id="gwp-input"
                fullWidth
                label="GWP (A1 - A3 only)"
                size="small"
                type="number"
                {...register("carbon.kgCo2e", {
                  required: true,
                  min: 1,
                  valueAsNumber: true,
                })}
                inputProps={{ ...ariaProps(errors, "carbon.kgCo2e"), min: 1 }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      sx={{ fontSize: 14 }}
                      disableTypography
                    >
                      kg CO₂e /{" "}
                      {displayCategoryDeclaredUnit(watch("primaryCategoryId"))}
                    </InputAdornment>
                  ),
                }}
              />
            }
          />
          <fieldset className="row">
            <FieldWithError
              errorMessage={standardErrorMessage(
                errors as any,
                "certification.issuedAt"
              )}
              fieldElement={
                <Controller
                  name="certification.issuedAt"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <DatePicker
                      disableFuture
                      label="Date issued"
                      slotProps={{
                        textField: {
                          size: "small",
                        },
                      }}
                      inputRef={field.ref}
                      {...field}
                    />
                  )}
                />
              }
            />
            <FieldWithError
              errorMessage={standardErrorMessage(
                errors as any,
                "certification.expiresAt"
              )}
              fieldElement={
                <Controller
                  name="certification.expiresAt"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <DatePicker
                      label="Expiry date"
                      slotProps={{
                        textField: {
                          size: "small",
                        },
                      }}
                      inputRef={field.ref}
                      {...field}
                    />
                  )}
                />
              }
            />
          </fieldset>
          <FieldWithError
            fieldElement={
              <Controller
                name="carbon.lciaMethod"
                control={control}
                render={({ field }) => (
                  <FormControl fullWidth size="small">
                    <InputLabel id="lcai-method-select-label">
                      LCIA method (optional)
                    </InputLabel>
                    <Select
                      fullWidth
                      id="lcia-method-select"
                      inputProps={{ ...ariaProps(errors, "carbon.lciaMethod") }}
                      label="LCIA method (optional)"
                      labelId="lcia-method-select-label"
                      {...field}
                    >
                      {_.map(LciaMethodEnum, (method) => (
                        <MenuItem key={method} value={method}>
                          {lciaMethodFormatLookup[method]}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
            }
          />
          <FieldWithError
            errorMessage={standardErrorMessage(errors, "description")}
            fieldElement={
              <TextField
                fullWidth
                multiline
                inputProps={{
                  ...ariaProps(errors, "description"),
                  maxLength: 8000,
                }}
                label="Description"
                rows={4}
                size="small"
                error={!!errors.description}
                {...register("description", {
                  required: true,
                  maxLength: 8000,
                })}
              />
            }
          />
          <FieldWithError
            errorMessage={errors.certification?.epdFileId?.message}
            className="attach-file"
            fieldElement={
              <AttachFile
                handleError={(e: string) => {
                  setError("certification.epdFileId", {
                    message: `Error uploading file: ${e}`,
                  });
                }}
                onSuccessfulUpload={(epdFileBlobId: string) => {
                  setValue("certification.epdFileId", epdFileBlobId);
                  clearErrors("certification.epdFileId");
                }}
                onFileRemove={() => setValue("certification.epdFileId", "")}
              />
            }
          />
          <FormControlLabel
            control={
              <Controller
                name="makePublicInFuture"
                control={control}
                render={({ field }) => (
                  <Checkbox disableRipple {...field} checked={field.value} />
                )}
              />
            }
            label="Allow others on Tangible to use this EPD in the future."
          />
        </form>
      </DialogContent>
      <DialogActions>
        {errors.root && (
          <Alert severity="error">Server Error: {errors.root.message}</Alert>
        )}
        <Button
          disabled={loadingUpload || loadingAddProduct}
          disableElevation
          variant="outlined"
          onClick={closeModalAndResetForm}
          className="cancel"
        >
          Cancel
        </Button>
        <Button
          disabled={loadingUpload || loadingAddProduct}
          disableElevation
          variant="contained"
          className="primary"
          type="submit"
          onClick={handleSubmit(onSubmit)}
        >
          Upload
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UploadEPDForm;
