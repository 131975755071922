import { Link, useNavigate } from "react-router-dom";
import _ from "lodash";
import { QueryResult } from "@apollo/client";
import { Button, Chip, Typography, Hidden } from "@mui/material";
import { Download } from "@mui/icons-material";
import { saveAs } from "file-saver";

import { GrossFloorAreaText } from "components/CreateEditProjectForm/components/GrossFloorAreaText";
import { SingleProjectMenu } from "components/ProjectActions/SingleProjectMenu";
import PermissionRestricted from "components/PermissionRestricted";
import ProjectMap from "components/ProjectMap";
import { ReportCarbonPerArea, Project, UserPermissionsEnum } from "gql/graphql";
import { downloadProjectReport } from "fetches/reports";
import { KGCO2E, safeComputeGFA } from "utils/formatting";
import ProjectActionsMenu from "./ProjectActionsMenu";
import "./ProjectOverview.scss";

type ProjectMetadata = Partial<Project>;

const formatFloorCounts = (
  aboveGroundFloors: Project["aboveGroundFloors"],
  belowGroundFloors: Project["belowGroundFloors"],
  parkingFloors: Project["parkingFloors"]
) => {
  const pl = (count: number) => (count === 1 ? "floor" : "floors");

  const text = [
    aboveGroundFloors &&
      `${aboveGroundFloors.count} above ground ${pl(aboveGroundFloors.count)}`,
    belowGroundFloors &&
      `${belowGroundFloors.count} below ground ${pl(belowGroundFloors.count)}`,
    parkingFloors &&
      `${parkingFloors.count} parking ${pl(parkingFloors.count)}`,
  ];
  return _.join(_.compact(text), ", ");
};

interface Props {
  carbonPerArea: ReportCarbonPerArea;
  projectMetadata: ProjectMetadata;
  refetchReportDetails: QueryResult["refetch"];
  totalProductCount: number;
  slug?: string;
}

const ProjectOverview = ({
  carbonPerArea,
  refetchReportDetails,
  projectMetadata,
  totalProductCount,
}: Props) => {
  const downloadReport = async (slug: string): Promise<void> => {
    const downloadedReport = await downloadProjectReport(slug);
    saveAs(downloadedReport, `report-${slug}.xlsx`);
  };

  const navigate = useNavigate();
  const {
    name,
    slug,
    propertyUse,
    structureType,
    aboveGroundFloors,
    belowGroundFloors,
    parkingFloors,
    address,
  } = projectMetadata || {};

  if (!slug) {
    return null;
  }

  return (
    <div className="project-header default-border">
      <Chip
        color="secondary"
        className="carbon-intensity"
        label={
          <KGCO2E
            bold
            kgCo2e={carbonPerArea.kgCo2e}
            unit={carbonPerArea.unit}
            separator="/"
          />
        }
      />

      <ProjectMap address={address} />
      <div className="project-metadata">
        <Link to={`/my-projects/${slug}`}>
          <Typography variant="h4" color="primary">
            {name}
          </Typography>
        </Link>
        {address?.city && (
          <Typography variant="body1">
            {address?.city}, {address.stateCode || address.country}
          </Typography>
        )}
        <div className="attributes">
          {propertyUse && (
            <Typography variant="body1">
              <span>{_.capitalize(propertyUse)}</span>
            </Typography>
          )}
          {aboveGroundFloors?.areaPerFloor?.units && (
            <Typography variant="body1" className="area">
              <GrossFloorAreaText
                label={false}
                area={safeComputeGFA(aboveGroundFloors, belowGroundFloors)}
                unit={aboveGroundFloors.areaPerFloor.units}
              />
            </Typography>
          )}
          {structureType && (
            <Typography variant="body1">
              <span>{_.capitalize(_.lowerCase(structureType))}</span>
            </Typography>
          )}
          <Typography variant="body1">
            <span>
              {formatFloorCounts(
                aboveGroundFloors,
                belowGroundFloors,
                parkingFloors
              )}
            </span>
          </Typography>
        </div>
      </div>
      {slug && (
        <div className="action-buttons">
          <Button
            disableElevation
            variant="contained"
            onClick={() => downloadReport(slug)}
            startIcon={<Download />}
          >
            <Hidden mdDown>Download data</Hidden>
          </Button>
          <PermissionRestricted to={UserPermissionsEnum.ViewCatalog}>
            <ProjectActionsMenu
              label={
                <>
                  Add <Hidden mdDown>data</Hidden>
                </>
              }
            />
          </PermissionRestricted>
          <SingleProjectMenu
            icon="more_horiz"
            project={{ name, slug }}
            refetchProjects={refetchReportDetails}
            productCount={totalProductCount}
            actions={["duplicate", "delete"]}
            afterDuplicate={(slug) => {
              if (slug) {
                navigate(`/my-projects/${slug}`);
              }
            }}
            afterDelete={() => {
              navigate(`/my-projects`);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default ProjectOverview;

export const ProjectOverviewLoadingState = () => (
  <div className="project-header loading">
    <div className="map loading-data" />
    <div className="project-metadata">
      <div className="heading-row loading-data"></div>
      <div className="subheading-row loading-data"></div>
      <div className="row loading-data"></div>
      <div className="row loading-data"></div>
      <div className="row loading-data"></div>
      <div className="row loading-data"></div>
    </div>
    <div className="action-buttons loading-data" />
  </div>
);
