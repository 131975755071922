import _ from "lodash";
import { Dispatch, Fragment } from "react";
import { ProductFilterInput, SustainabilityAttributeFilter } from "gql/graphql";
import CollapsableFiltersSection from "./CollapsableFiltersSection";
import NumericalCatalogFilter from "./NumericalFilter";
import CheckboxFilterCollection from "./CheckboxFilterCollection";
import { sustainabilityFiltersShuffle } from "../utils";

interface Props {
  filtersAvailable: SustainabilityAttributeFilter[];
  filtersState: ProductFilterInput;
  sectionTitle: string;
  setFiltersState: Dispatch<React.SetStateAction<ProductFilterInput>>;
}

const SustainabilityFiltersSection = ({
  filtersAvailable,
  filtersState,
  sectionTitle,
  setFiltersState,
}: Props) => {
  const { SCALAR: scalarRangeFilters, BOOLEAN: checkboxFilters } = _.groupBy(
    sustainabilityFiltersShuffle(filtersAvailable),
    "type"
  );

  return (
    <CollapsableFiltersSection sectionTitle={sectionTitle}>
      <Fragment>
        <CheckboxFilterCollection
          collectionName="Sustainability Attributes"
          filterStateKey="sustainabilityAttributes"
          filters={checkboxFilters}
          filtersState={filtersState}
          setFiltersState={setFiltersState}
        />
        {_.map(scalarRangeFilters, (sRF) => {
          return (
            <NumericalCatalogFilter
              // How are we dealing with UNITS?
              key={sRF.displayName}
              filter={sRF}
              filtersState={filtersState}
              setFiltersState={setFiltersState}
            />
          );
        })}
      </Fragment>
    </CollapsableFiltersSection>
  );
};

export default SustainabilityFiltersSection;
