import { gql } from "@apollo/client";
import { CATEGORY_FRAGMENT } from "../fragments";

export const GET_ALL_CATEGORIES = gql`
  ${CATEGORY_FRAGMENT}
  query getAllCategories {
    catalog {
      categories(first: 300) {
        nodes {
          ...CategoryFragment
        }
      }
    }
  }
`;
