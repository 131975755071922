import { Chip, ChipProps } from "@mui/material";

import { ProductDataFidelityEnum } from "gql/graphql";

import "./DataFidelityCell.scss";

interface Props extends ChipProps {
  dataFidelity: ProductDataFidelityEnum;
}
const labels = {
  [ProductDataFidelityEnum.IndustryWideEpd]: "Industry EPD",
  [ProductDataFidelityEnum.ProductEpd]: "Product EPD",
  [ProductDataFidelityEnum.UserUploaded]: "Uploaded",
  [ProductDataFidelityEnum.NoData]: "N/A",
};
const DataFidelityCell = ({ dataFidelity, ...chipProps }: Props) => (
  <Chip
    className="data-fidelity"
    {...chipProps}
    label={labels[dataFidelity]}
    style={{ borderRadius: 4 }}
    title={
      dataFidelity === ProductDataFidelityEnum.NoData
        ? "EPD Not Available"
        : undefined
    }
  />
);

export default DataFidelityCell;
