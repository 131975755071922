import _ from "lodash";
import numeral from "numeral";
import {
  AreaUnit,
  PerformanceAttributeTypeEnum,
  SustainabilityAttributeTypeEnum,
} from "gql/graphql";

export const carbonFootprintDisplay = (
  cradleToGateCarbon: number,
  declaredUnit: string | undefined
) =>
  `${cradleToGateCarbon.toFixed(2)} kg CO₂e / ${
    declaredUnit || "unit not available"
  }`;

export const pluralizer = (word: string, count: number) =>
  count === 1 ? word : `${word}s`;

export const formatCarbonReduction = (
  ratioToBaseline: number,
  includeSuffix: boolean = true,
  { digits } = { digits: 2 }
) => {
  const zeroes = _.repeat("0", digits);
  const format = _.inRange(ratioToBaseline, 0.99, 1) ? `0.${zeroes}%` : "0,0%";
  const formattedChange = numeral(Math.abs(1 - ratioToBaseline)).format(format);
  const suffix = ratioToBaseline > 1 ? "increase" : "reduction";
  return `${formattedChange} ${includeSuffix ? suffix : ""}`;
};

export const formatAttribute = ({
  attributeType,
  stringValue,
  booleanValue,
  scalarValue,
}: {
  attributeType: PerformanceAttributeTypeEnum | SustainabilityAttributeTypeEnum;
  booleanValue?: boolean;
  scalarValue?: number;
  stringValue?: string;
}): string => {
  let valueDisplay = stringValue;

  if (booleanValue !== undefined) {
    valueDisplay = _.capitalize(_.toString(booleanValue));
  } else if (scalarValue !== undefined) {
    let format = "0,0.00";
    if (
      attributeType === "PRE_CONSUMER_RECYCLED" ||
      attributeType === "POST_CONSUMER_RECYCLED" ||
      attributeType === "BIO_CONTENT" ||
      attributeType === "SCM_CONTENT"
    ) {
      format = "0,0%";
    }
    valueDisplay = _.toString(numeral(scalarValue).format(format));

    if (valueDisplay === "0.00" && scalarValue !== 0) {
      valueDisplay = scalarValue.toFixed(6);
    }
  }

  return valueDisplay as string;
};

export const formatDeclaredUnit = (unit?: string): string => {
  if (!unit) {
    return "";
  }

  const leadingNumber = _.first(unit.match(/^\d+/));

  if (!leadingNumber || +leadingNumber > 1) {
    return unit;
  }

  return _.trim(unit.replace(leadingNumber, ""));
};

export const promisify = <T>(fn: Function) => {
  return (...args: any[]) => {
    return new Promise<T>((resolve, reject) => {
      const customCallback = (err: any, ...results: any[]) => {
        if (err) {
          return reject(err);
        }
        return resolve(results.length === 1 ? results[0] : results);
      };
      args.push(customCallback);
      fn.call(this, ...args);
    });
  };
};

type SupportedUnits = AreaUnit.Ft2 | AreaUnit.M2;

const M2_TO_FT2 = 10.7639;
const convertArea = (
  value: number,
  fromUnit: SupportedUnits,
  toUnit: SupportedUnits
) => {
  if (fromUnit === toUnit) {
    return value;
  }
  if (fromUnit === AreaUnit.M2) {
    return value * M2_TO_FT2;
  }
  return value / M2_TO_FT2;
};

export const convertIntensity = (
  value: number,
  fromUnit: SupportedUnits,
  toUnit: SupportedUnits
) => convertArea(value, toUnit, fromUnit);
