import { Fragment } from "react";
import { getChangePercentageChipConfig } from "../../StatBlockAlternate";
import { ContainerDimensions } from "types";
import clsx from "clsx";
import { axisLeft } from "d3-axis";
import { scaleBand, scaleLinear } from "d3-scale";
import { select } from "d3-selection";
import _, { map, min } from "lodash";
import numeral from "numeral";
import { renderToString } from "react-dom/server";
import { CHANGE_THRESHOLD } from "../DirectionalChange";
import { KGCO2E } from "utils/formatting";
import { pluralizer } from "utils/transforms";
import { setupTooltip } from "utils/visualizations";
import {
  ReportCarbon,
  Maybe,
  ReportProductConnection,
  ReportProductEdge,
} from "gql/graphql";
import { ProjectReportData } from "pages/ProjectReport/ProjectReport";
import { VIZ_ID } from "../CarbonImpactByProduct";
import { extent } from "d3-array";

export interface CarbonReductionDatum {
  absoluteCarbon: number;
  absoluteContribution: number;
  name: string;
  percentContribution: number;
  relativeReduction: number;
  slug: string;
  otherProducts?: CarbonReductionDatum[];
}

const MARGINS = { top: 0, bottom: 10, left: 0, right: 0 };
const LABEL_BUFFER = 500;
const BARS_X_POS = LABEL_BUFFER + 20;
const CONTAINER_PADDING = 0;
const BAR_HEIGHT = 32;
const BAR_SPACING = 16;
const LEGEND_HEIGHT = 24;
const LEGEND_GAP = 9;

// given d in data, returns the (ordinal) y-value
const y = (d: CarbonReductionDatum) => d.slug;
const reductionConfig = getChangePercentageChipConfig(0);

export const buildVisualization = (
  {
    width: containerWidth,
    height: containerHeight,
    transition: shouldTransition = true,
  }: ContainerDimensions,
  unprocessedData: CarbonReductionDatum[]
) => {
  const vizWidth = containerWidth - 2 * CONTAINER_PADDING;

  const data = _.chain(unprocessedData)
    .map((d) => ({
      ...d,
      percentContribution: d.percentContribution * -1,
    }))
    .value();

  const yMax = _.size(data) * (BAR_HEIGHT + BAR_SPACING) - MARGINS.bottom;
  // Construct scales and axes.
  const yRange = [MARGINS.top, yMax];
  // object with keys as slugs and values as names
  const names = _.chain(data)
    .map((d) => [d.slug, d.name])
    .fromPairs()
    .value();
  const yScale = scaleBand().domain(map(data, y)).range(yRange);

  const percentReductionRange = extent(data, (d) => d.percentContribution);

  const xScale = scaleLinear([BARS_X_POS, vizWidth])
    .domain(percentReductionRange as [number, number])
    .clamp(true);

  const yAxis = axisLeft(yScale).tickSizeOuter(0);

  const container = select(`#${VIZ_ID}`);
  const svg = container
    .select("svg")
    .attr("width", "100%")
    .attr("height", yMax + LEGEND_HEIGHT + LEGEND_GAP + MARGINS.bottom);

  svg
    .select("line.divider")
    .attr("x2", vizWidth)
    .attr("y1", yMax + 8)
    .attr("y2", yMax + 8);

  svg
    .select("g.key")
    .attr("transform", `translate(${0},${LEGEND_HEIGHT + LEGEND_GAP})`);

  svg
    .select("g.sum-total")
    .attr("transform", `translate(${0},${yMax + LEGEND_HEIGHT + LEGEND_GAP})`)
    .select("text.total-percentage")
    .attr("x", LABEL_BUFFER);

  // render bars
  const getBarWidth = (d: CarbonReductionDatum) =>
    min([xScale(d.percentContribution || 0), xScale(0)] as [
      number,
      number
    ]) as number;

  const bars = svg
    .select("g.bars")
    .selectAll("rect")
    .data<CarbonReductionDatum>(data)
    .join("rect")
    .attr("class", (d) =>
      d?.percentContribution && d?.percentContribution > 0
        ? "increase"
        : "reduction"
    )
    .attr("x", (d) => (shouldTransition ? xScale(0) : getBarWidth(d))) // transition from middle
    .attr("y", (d) => {
      const halfYBand = yScale.bandwidth() / 2;
      const halfBarHeight = BAR_HEIGHT / 2;
      return (yScale(d.slug) as number) + halfYBand - halfBarHeight;
    })
    .attr("height", BAR_HEIGHT);

  bars
    .transition()
    .duration(shouldTransition ? 500 : 0)
    .attr("width", (d, i) => {
      return Math.abs(xScale(d.percentContribution as number) - xScale(0));
    })
    .attr("x", (d) => getBarWidth(d));

  //  render reduction percentages
  const percentages = svg
    .select("g.percentages")
    .attr("transform", `translate(${LABEL_BUFFER},${MARGINS.top - 1})`)
    .selectAll("text")
    .data<CarbonReductionDatum>(data)
    .join("text")
    .attr("class", (d) =>
      clsx("body-1", {
        increase: d?.percentContribution && d?.percentContribution > 0,
        reduction: d?.percentContribution && d?.percentContribution < 0,
        "no-change":
          Math.abs(Number(d?.percentContribution)) <= CHANGE_THRESHOLD,
      })
    )
    .attr("dx", -8)
    .attr("dy", "0.35em")
    .attr("y", (d) => {
      const halfYBand = yScale.bandwidth() / 2;
      return (yScale(d.slug) as number) + halfYBand;
    })
    .text((d) => {
      const formattedText =
        !_.isNil(d.percentContribution) &&
        Math.abs(d.percentContribution) > CHANGE_THRESHOLD
          ? numeral(d.percentContribution).format(reductionConfig.format)
          : `< ${numeral(CHANGE_THRESHOLD).format(reductionConfig.format)}`;
      return formattedText;
    });

  svg
    .select("g.y-axis")
    .call(yAxis as any)
    .call((g) => g.select(".domain").remove())
    .call((g) => g.selectAll(".tick line").remove())
    .call((g) =>
      g
        .selectAll(".tick text")
        .text((slug: any) => {
          const name = names[slug];
          return !name || _.isEmpty(name) || name.startsWith("Placeholder")
            ? "--"
            : _.truncate(name, { length: 60 });
        })
        .attr("class", (d) => clsx("subtitle-2", { "null-link": !d }))
        .attr("x", 0)
        .attr("dy", 4)
        // @ts-ignore
        .attr("onclick", (d) => {
          if (!d as any) return;
          return `window.open('/products/${d}', "_blank")`;
        })
    );

  svg
    .select("line.no-change")
    .attr("x1", xScale(0))
    .attr("x2", xScale(0))
    .attr("y1", MARGINS.top)
    .attr("y2", yMax)
    .raise();

  const tooltip = container.select(".tooltip");

  setupTooltip(
    [bars, percentages],
    tooltip,
    getTooltipContentForCarbonImpactByProduct,
    {
      width: containerWidth,
      height: containerHeight,
    }
  );

  return svg.node();
};

export const getTooltipContentForCarbonImpactByProduct = (
  datum: CarbonReductionDatum,
  maxOtherProducts = 2
) => {
  return renderToString(
    <TooltipContentForCarbonImpactByProduct
      datum={datum}
      maxOtherProducts={maxOtherProducts}
    />
  );
};

type ReductionClassName = "reduction" | "increase" | "no-change";

const _tooltipContentForCarbonImpactByProduct = (
  datum: CarbonReductionDatum
) => {
  let reductionWord: ReductionClassName =
    datum.percentContribution > 0 ? "increase" : "reduction";
  let reductionDirectionWord =
    datum.percentContribution > 0 ? "above" : "below";
  let prefix = reductionWord === "increase" ? "+" : "-";

  const noChange = Math.abs(datum.percentContribution) < CHANGE_THRESHOLD;
  if (noChange) {
    reductionWord = "no-change";
    reductionDirectionWord = "";
    prefix = "";
  }

  const name = (
    <p className="product-name">
      <strong>{datum.name}</strong>
    </p>
  );
  const value = (
    <p>
      <span>Total Carbon:</span>{" "}
      <span>
        <KGCO2E bold kgCo2e={datum.absoluteCarbon} />
      </span>
    </p>
  );
  const relativeToIndustryAverage = (
    <p className={reductionWord}>
      {noChange
        ? "At"
        : `${prefix}${numeral(Math.abs(datum.relativeReduction)).format(
            "0.0%"
          )}`}{" "}
      {reductionDirectionWord} industry average {noChange ? "" : "product"}
    </p>
  );

  const contributionToTotalChange = (
    <p>
      {noChange
        ? ""
        : `${numeral(Math.abs(datum.percentContribution)).format("0.0%")}`}{" "}
      of project carbon change
    </p>
  );
  return (
    <Fragment key={datum.slug}>
      {name}
      {value}
      {relativeToIndustryAverage}
      {contributionToTotalChange}
    </Fragment>
  );
};

export const TooltipContentForCarbonImpactByProduct = ({
  datum,
  maxOtherProducts = 2,
}: {
  datum: CarbonReductionDatum;
  maxOtherProducts?: number;
}) => {
  const products = datum.otherProducts?.slice(0, maxOtherProducts) || [datum];

  const content = products.map(_tooltipContentForCarbonImpactByProduct);

  if (_.size(datum.otherProducts) > maxOtherProducts) {
    const moreCt = datum.otherProducts!.length - maxOtherProducts;
    content.push(
      <p className="product-tooltip-other-products" key="other-products">
        and {moreCt} other {pluralizer("product", moreCt)}
      </p>
    );
  }

  return <div className="product-tooltip">{content}</div>;
};

const toCarbonReductionDatum = (edges: Maybe<ReportProductEdge>[]) =>
  _.chain(edges).compact().map(mapEdge).uniqBy("slug").value();

export type ModifiedCarbonReport = Omit<ReportCarbon, "products"> & {
  bestProducts?: ReportProductConnection;
  worstProducts?: ReportProductConnection;
};

const mapEdge = (edge: ReportProductEdge): CarbonReductionDatum => ({
  name: edge.node.name!,
  slug: edge.node.slug!,
  percentContribution: edge?.total?.reduction?.contribution || 0,
  absoluteContribution: edge.total?.reduction?.absolute || 0,
  relativeReduction: edge.total?.reduction?.relative || 0,
  absoluteCarbon: edge.total?.kgCo2e || 0,
});

const safeEdge = (edge: Maybe<ReportProductEdge>): boolean =>
  Boolean(edge?.node?.slug && edge.node?.name);

const generateOtherMaterialsDatum = (
  worstBestProducts: CarbonReductionDatum[],
  otherProducts: CarbonReductionDatum[],
  carbon: ModifiedCarbonReport
): CarbonReductionDatum => {
  const worstBestTotalContribution = _.sumBy(
    worstBestProducts,
    (edge) => edge?.percentContribution || 0
  );
  return {
    name: "Other materials",
    slug: "",
    absoluteCarbon: carbon.total?.kgCo2e || 0,
    percentContribution: Number(
      (carbon.total?.reduction?.relative || 0) - worstBestTotalContribution
    ),
    absoluteContribution: _.sumBy(
      worstBestProducts,
      (edge) => edge.absoluteContribution || 0
    ),
    relativeReduction: _.sumBy(
      worstBestProducts,
      (edge) => edge.relativeReduction || 0
    ),
    otherProducts: _.map(otherProducts, (edge) => ({
      ...edge,
      percentContribution: edge.percentContribution * -1,
    })),
  };
};

const NUM_TO_TAKE_BEST_WORST = 4;

export const formatData = (
  data?: ProjectReportData,
  numToTake = NUM_TO_TAKE_BEST_WORST
): CarbonReductionDatum[] => {
  const carbon: ModifiedCarbonReport =
    (data?.carbon as ModifiedCarbonReport) || {
      bestProducts: [],
      worstProducts: [],
    };
  const absoluteProjectReduction = data?.carbon?.total?.reduction?.absolute;

  if (_.isNil(absoluteProjectReduction)) {
    return [];
  }

  // TODO: This is very, very unoptimized
  const worstProducts = _.chain(carbon.worstProducts?.edges)
    .filter(safeEdge)
    .compact()
    .value();
  const bestProducts: ReportProductEdge[] = _.chain(carbon.bestProducts?.edges)
    .filter(safeEdge)
    .compact()
    .value();

  // _.chunk divides into sizes, so index 1 is products past those to show
  const worstChunks = _.chunk(worstProducts, numToTake);
  const bestChunks = _.chunk(bestProducts, numToTake);
  let productsToReturn: CarbonReductionDatum[] = toCarbonReductionDatum([
    ...(worstChunks[0] || []),
    ...(bestChunks[0] ? bestChunks[0].reverse() : []),
  ]);

  const usedSlugs = _.map(productsToReturn, "slug");

  const otherWorst = worstChunks[1];
  const otherBest = bestChunks[1];

  let otherProducts: CarbonReductionDatum[] = [];
  if (_.size(otherWorst) && _.size(otherBest)) {
    otherProducts = toCarbonReductionDatum(
      _.chain([...otherWorst, ...otherBest])
        .filter(safeEdge)
        // Reject all that already are used in worst/best
        .filter((edge) =>
          Boolean(edge?.node.slug && !usedSlugs.includes(edge.node.slug))
        )
        .orderBy((edge) => edge?.total?.reduction?.contribution, "asc")
        .value()
    );
  }

  if (otherProducts.length) {
    return mergeOtherMaterialsIntoProducts(
      productsToReturn,
      otherProducts,
      carbon
    );
  }

  return productsToReturn;
};

function mergeOtherMaterialsIntoProducts(
  products: CarbonReductionDatum[],
  otherProducts: CarbonReductionDatum[],
  carbon: ModifiedCarbonReport
) {
  if (_.isEmpty(products)) return [];

  const otherMaterials: CarbonReductionDatum = generateOtherMaterialsDatum(
    products,
    otherProducts,
    carbon
  );

  // Slot otherMaterials into the correct place
  let pivotPoint = 0;
  for (const [index, product] of products.entries()) {
    pivotPoint = index;
    if (product.percentContribution > otherMaterials.percentContribution) break;
  }

  let toModify = _.clone(products);

  // mutates
  toModify.splice(
    pivotPoint,
    0 /* deleting none means just inserting */,
    otherMaterials
  );

  return toModify;
}
