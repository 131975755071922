import _ from "lodash";
import {
  PropsWithChildren,
  useContext,
  useMemo,
  useState,
  MouseEvent,
  useCallback,
  ReactNode,
} from "react";
import { NavLink, NavLinkProps, useLocation } from "react-router-dom";
import { SetSidebarDisplayContext, ReadSidebarDisplayContext } from "Main";
import { TANGIBLE_AWS_BUCKET_URL, ExternalLinks } from "../constants";
import bigLogo from "icons/logo_large.svg";
import "./Sidebar.scss";
import { useQuery } from "@apollo/client";
import { GET_PROJECTS } from "graphql/queries/projects";
import { UserPermissionsEnum } from "gql/graphql";
import clsx from "clsx";
import { Icon } from "@mui/material";
import { OpenInNew as NewWindowIcon } from "@mui/icons-material";
import PermissionRestricted from "./PermissionRestricted";

const adminUrl = `${process.env.REACT_APP_API_V2_DOMAIN}/admin`;

const Sidebar = () => {
  const location = useLocation();
  const { data } = useQuery(GET_PROJECTS);

  const setIsOpen = useContext(SetSidebarDisplayContext);
  const isOpen = useContext(ReadSidebarDisplayContext);

  const [hovered, setHovered] = useState<boolean>(false);

  const myProjects = data?.projects.nodes;

  return (
    <div
      id="sidebar"
      className={`${isOpen ? "hover" : ""}`}
      onMouseEnter={() => {
        if (hovered) return;
        setHovered(true);
        setIsOpen(true);
      }}
      onMouseLeave={() => {
        setIsOpen(false);
        setHovered(false);
      }}
    >
      <div className="sidebar-content default-shadow default-border">
        <>
          <img src={bigLogo} alt="tangible-logo" id="logo-big" />
          <img
            src={`${TANGIBLE_AWS_BUCKET_URL}/favicon.png`}
            alt="tangible-logo"
            id="logo-small"
          />
          <NavLink
            className="sidebar-hit-target"
            to="/"
            title="Back to start page"
          ></NavLink>
        </>
        <div id="nav">
          <div className="align-top">
            <PermissionRestricted to={UserPermissionsEnum.ViewPortfolio}>
              <HoverableNavLink
                icon="bar_chart"
                route="portfolio"
                size={24}
                title="Portfolio"
              />
            </PermissionRestricted>

            <PermissionRestricted to={UserPermissionsEnum.ViewCatalog}>
              <HoverableNavLink
                title="products"
                icon="dashboard"
                route="products/all"
                size={24}
              />
            </PermissionRestricted>

            <HoverableNavLink
              icon="home_work"
              route="my-projects/"
              size={24}
              title="projects"
              additionalClassName={clsx({
                active: location.pathname.startsWith("/my-projects"),
              })}
            >
              {!_.isEmpty(myProjects) && (
                <div className="project-links-container">
                  {_.map(myProjects, ({ name, slug }) => (
                    <NavLink
                      key={slug}
                      to={`/my-projects/${slug}/dashboard`}
                      className={clsx("link")}
                    >
                      <span key={slug} className="single-project">
                        {name}
                      </span>
                    </NavLink>
                  ))}
                </div>
              )}
            </HoverableNavLink>
          </div>

          <div className="align-bottom">
            <HoverableNavLink
              icon="auto_awesome_rounded"
              href={ExternalLinks.WhatsNew}
              size={24}
              target={"_blank"}
              title={
                <span>
                  What&rsquo;s New <NewWindowIcon />
                </span>
              }
            />
            <HoverableNavLink
              icon="article"
              href={ExternalLinks.Methodology}
              size={24}
              target={"_blank"}
              title={
                <span>
                  Methodology <NewWindowIcon />
                </span>
              }
            />
            <HoverableNavLink
              icon="settings"
              route="settings"
              size={24}
              title="Settings"
            />
            <PermissionRestricted to={UserPermissionsEnum.Administer}>
              <HoverableNavLink
                icon="admin_panel_settings"
                route="admin"
                href={adminUrl}
                size={24}
                title="admin"
              />
            </PermissionRestricted>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;

type HoverableNavLinkProps = PropsWithChildren & {
  size: number;
  title: ReactNode;
  additionalClassName?: string;
  href?: string;
  icon?: string;
  route?: string;
  target?: string;
};

const HoverableNavLink = ({
  children,
  title,
  size,
  additionalClassName,
  href = undefined,
  icon = undefined,
  route = undefined,
  target = undefined,
}: HoverableNavLinkProps) => {
  const [hovered, setHovered] = useState<boolean>(false);
  const [mousedOver, setMousedOver] = useState<boolean>(false);
  const setSidebarIsOpen = useContext(SetSidebarDisplayContext);
  const handleClick = useCallback(() => {
    setHovered(false);
    setSidebarIsOpen(false);
  }, [setHovered, setSidebarIsOpen]);

  const onMouseEnter = useMemo(
    () => (_: MouseEvent) => {
      if (mousedOver) return;
      setHovered(true);
      setMousedOver(true);
    },
    [mousedOver, setHovered, setMousedOver]
  );

  const onMouseLeave = useMemo(
    () => (_: MouseEvent) => {
      setHovered(false);
      setMousedOver(false);
    },
    [setHovered, setMousedOver]
  );

  const commonProps = useMemo(
    () => ({
      className: clsx("subtitle-3", additionalClassName),
      onMouseEnter,
      onMouseLeave,
    }),
    [additionalClassName, onMouseEnter, onMouseLeave]
  );

  const LinkComponent = useCallback(
    ({ children }: Partial<NavLinkProps>) => {
      if (href) {
        return (
          <a href={href} target={target} {...commonProps}>
            {_.isFunction(children)
              ? children({ isActive: false })
              : children || null}
          </a>
        );
      } else if (route) {
        return (
          <NavLink to={`/${route}`} onClick={handleClick} {...commonProps}>
            {children}
          </NavLink>
        );
      } else {
        throw new Error("Must define route or href");
      }
    },
    [href, handleClick, route, commonProps, target]
  );

  return (
    <div className="link-holder">
      <>
        <span className="truncate-25">
          <LinkComponent>
            {({ isActive }) => (
              <>
                {icon && (
                  <Icon
                    className={clsx({
                      [`md-${size}`]: true,
                      "material-icons": hovered || isActive,
                      "material-icons-outlined": !hovered && !isActive,
                    })}
                    sx={{ fontFamily: "Material Icons Outlined" }}
                  >
                    {icon}
                  </Icon>
                )}
                {_.isString(title) ? <span>{_.capitalize(title)}</span> : title}
              </>
            )}
          </LinkComponent>
        </span>
        {children}
      </>
    </div>
  );
};
