import { useCallback, useContext, useMemo, useState } from "react";
import _ from "lodash";
import { Link, useLocation } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";
import {
  ArrowUpwardRounded as UpArrow,
  ArrowDownwardRounded as DownArrow,
} from "@mui/icons-material";

import { DataLoadingContext } from "context/DataLoadingContext";
import PortfolioFiltersContext from "context/PortfolioFiltersContext";
import { Maybe, Project, ProjectSortColumn, Sort } from "gql/graphql";
import { KGCO2E } from "utils/formatting";

import ProjectListRow from "./ProjectsListRow";
import "./ProjectsList.scss";
import { ApolloQueryResult, OperationVariables } from "@apollo/client";
import clsx from "clsx";

interface Props {
  projectNodes: Maybe<Array<Maybe<Project>>>;
  refetchProjects: (
    variables?: Partial<OperationVariables>
  ) => Promise<ApolloQueryResult<any>>;
  allowSorting?: boolean;
  showSeeAllButton?: boolean;
}

const SortDirection = ({
  column,
  show,
  sortColumn,
  sortDirection,
}: {
  column: string;
  show: boolean;
  sortColumn: ProjectSortColumn;
  sortDirection: Sort;
}) => {
  if (!show) return null;
  if (column === sortColumn) {
    return (
      <span>
        {sortDirection === Sort.Ascending ? (
          <UpArrow style={{ fontSize: "12px" }} />
        ) : (
          <DownArrow style={{ fontSize: "12px" }} />
        )}
      </span>
    );
  }
  return null;
};

const ProjectsList = ({
  projectNodes,
  refetchProjects,
  showSeeAllButton = false,
  allowSorting = false,
}: Props) => {
  // extract sort from search parameters
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const { dataLoading } = useContext(DataLoadingContext);

  const { filters } = useContext(PortfolioFiltersContext);

  const emptyNodes = useMemo(
    () => (dataLoading ? new Array(5).fill(null) : []),
    [dataLoading]
  );
  const projects = useMemo(
    () => (_.isEmpty(projectNodes) ? emptyNodes : projectNodes),
    [emptyNodes, projectNodes]
  );
  const [sortDirection, setSortDirection] = useState(
    (searchParams.get("direction") as Sort) || Sort.Descending
  );
  const [sortColumn, setSortColumn] = useState<ProjectSortColumn>(
    (searchParams.get("by") as ProjectSortColumn) || ProjectSortColumn.Intensity
  );
  const orderBy = useCallback(
    (by: ProjectSortColumn) => () => {
      if (!allowSorting) return;
      setSortColumn(by);
      const newDir =
        sortDirection === Sort.Ascending ? Sort.Descending : Sort.Ascending;
      setSortDirection(newDir);
      refetchProjects({ sort: { by, direction: newDir } });
    },
    [
      refetchProjects,
      setSortDirection,
      sortDirection,
      setSortColumn,
      allowSorting,
    ]
  );
  const portfolioTablePath = useMemo(() => {
    const sort = { by: sortColumn, direction: sortDirection };
    return {
      pathname: "/my-projects",
      search: new URLSearchParams(sort as any).toString(),
      hash: "#table",
    };
  }, [sortColumn, sortDirection]);

  return (
    <div className="projects-list">
      <>
        <table>
          <thead>
            <tr>
              <th
                key="a"
                style={{ width: "30%" }}
                className={clsx({ "has-sort": allowSorting })}
                onClick={orderBy(ProjectSortColumn.Name)}
              >
                Building
                <SortDirection
                  show={allowSorting}
                  column="NAME"
                  sortColumn={sortColumn}
                  sortDirection={sortDirection}
                />
              </th>
              <th
                key="b"
                style={{ width: "10%" }}
                onClick={orderBy(ProjectSortColumn.Area)}
                className={clsx({ "has-sort": allowSorting })}
              >
                Area
                <SortDirection
                  show={allowSorting}
                  column="AREA"
                  sortColumn={sortColumn}
                  sortDirection={sortDirection}
                />
              </th>
              <th
                key="c"
                className={clsx("intensity", { "has-sort": allowSorting })}
                style={{ width: "10%" }}
                onClick={orderBy(ProjectSortColumn.Intensity)}
              >
                <span>Carbon Intensity</span>
                <SortDirection
                  show={allowSorting}
                  column="INTENSITY"
                  sortColumn={sortColumn}
                  sortDirection={sortDirection}
                />
                <br />
                <KGCO2E withParentheses unit={filters.areaUnit} />
              </th>
              <th key="d" style={{ width: "10%" }}>
                Data Source
              </th>
              <th
                key="e"
                style={{ width: "20%" }}
                className={clsx({ "has-sort": allowSorting })}
                onClick={orderBy(ProjectSortColumn.RelativeReduction)}
              >
                vs. average products
                <SortDirection
                  show={allowSorting}
                  column="RELATIVE_REDUCTION"
                  sortColumn={sortColumn}
                  sortDirection={sortDirection}
                />
              </th>
            </tr>
          </thead>
          <tbody>
            {_.map(projects, (p, index) => (
              <ProjectListRow
                key={p?.slug || index}
                project={p}
                dataLoading={dataLoading}
              />
            ))}
          </tbody>
          {_.isEmpty(projects) && !dataLoading && (
            <tfoot>
              <tr>
                <td className="empty-row" colSpan={5}>
                  <Box display="flex" flexDirection="column" gap={1}>
                    <Typography variant="body2">No projects yet!</Typography>
                    <Box display="flex" justifyContent="center" pt={1}>
                      <Button
                        disableElevation
                        variant="outlined"
                        component={Link}
                        to="/my-projects/create"
                      >
                        Add your first project
                      </Button>
                    </Box>
                  </Box>
                </td>
              </tr>
            </tfoot>
          )}
        </table>
      </>

      {!_.isEmpty(projects) && showSeeAllButton && (
        <Box display="flex" justifyContent="center" pt={2}>
          <Button
            fullWidth
            disableElevation
            component={Link}
            to={portfolioTablePath}
            variant="contained"
            color="secondary"
          >
            See all
          </Button>
        </Box>
      )}
    </div>
  );
};

export default ProjectsList;
