import _ from "lodash";
import { ProductFilterInput, SustainabilityAttributeFilter } from "gql/graphql";
import { DeserializedProductsCatalogQueryParams, SortCatalogBy } from "./types";
import { PaginationConfig } from "components/Pagination";

export type KeyedStrings = {
  [key: string]: KeyedStrings | string;
};

const paramAsBool = (
  param: string | null | undefined
): boolean | string | null | undefined => {
  if (param === "true") return true;
  if (param === "false") return false;
  return undefined;
};

const parseNestedParams = (
  queryParams: URLSearchParams,
  objectKey: "filter"
): KeyedStrings => {
  const dot = ".";

  const result = {};
  for (const [key, value] of queryParams) {
    const prefix = `${objectKey}${dot}`;
    // Perf note: if these filters were much longer it'd make sense to `continue` once we were
    // alphabetically past `sort`, but probably not necessary
    if (key.startsWith(prefix)) {
      const [, ...paths] = key.split(dot);
      const path = paths.join(dot); // We could use a regex to remove the prefix, but I think this is more readable

      // It could be better to use a JSONValue kind of type,
      // but that seems excessive. https://github.com/microsoft/TypeScript/issues/1897
      let parsedValue: number | string | boolean | null | undefined =
        paramAsBool(value);
      if (parsedValue === undefined) {
        const asNumber = Number(value);
        if (!Number.isNaN(asNumber)) {
          parsedValue = asNumber;
        } else {
          parsedValue = value;
        }
      }

      // Example of path is something like filter.q = "name", but with prefix removed like by = "name",
      // and these paths use lodash's set syntax, which is basically just jsonpath [https://jsonpath.com/].
      // Thus, something like `filter.sustainabilityAttributes.0.type=foo`
      // becomes `{ filter: { sustainabilityAttributes: [{ type: "foo" }] } }`.
      _.set(result, path, parsedValue);
    }
  }

  return result;
};

export const safeParseInt = (str: string | null): number | undefined => {
  const parsed = parseInt(str || "");
  if (Number.isNaN(parsed)) {
    return undefined;
  }
  return parsed;
};

export const extractPagination = (
  queryParams: URLSearchParams,
  pairsOnly: boolean = true
): PaginationConfig => {
  const after = queryParams.get("after") || undefined;
  const before = queryParams.get("before") || undefined;

  const first: number | undefined = safeParseInt(queryParams.get("first"));
  const last: number | undefined = safeParseInt(queryParams.get("last"));

  if (!pairsOnly) {
    return _.omitBy(
      { first, after, last, before },
      _.isUndefined
    ) as PaginationConfig;
  }

  // TODO: I think it makes sense to limit to ONLY first and after or last and before;
  if (first && after) {
    return { first, after };
  }
  if (last && before) {
    return { last, before };
  }
  return {};
};

export const extractFilter = (
  queryParams: URLSearchParams
): ProductFilterInput => {
  return _.omitBy(
    {
      ...parseNestedParams(queryParams, "filter"),
      q: queryParams.get("q") || undefined,
    },
    _.isUndefined
  );
};

export const extractSort = (queryParams: URLSearchParams): SortCatalogBy => {
  const sort = queryParams.get("sort") || "dateAdded";
  return _.get(SortCatalogBy, sort) || SortCatalogBy.dateAdded;
};

export const deserializeQueryParams = (
  queryString: string
): DeserializedProductsCatalogQueryParams => {
  const queryParams = new URLSearchParams(queryString);

  const extracted: DeserializedProductsCatalogQueryParams = {
    filter: extractFilter(queryParams),
    sortKey: extractSort(queryParams),
    ...extractPagination(queryParams),
  };

  return extracted;
};

export const toJSONPath = (
  criteria: { [key: string]: any } | Array<any> | null,
  collectedCriteria: { [key: string]: any } = {},
  prefix = ""
) => {
  // _.toPairs is great because calling it on an
  // array means k is equal to the index.
  // It also returns an empty array for all types except null.
  _.toPairs(criteria as any).forEach(([k, v]) => {
    const key = `${prefix}.${k}`.replace(/^\./, "");
    if (typeof v === "object") {
      toJSONPath(v, collectedCriteria, key);
    } else {
      collectedCriteria[key] = v;
    }
  });
  return collectedCriteria;
};

function safeVal(key: string, val: any) {
  if (["gte", "lte"].includes(key)) {
    return parseFloat(val);
  }
  return val;
}

// Recursively collects and ensures all values are safe,
// i.e. safeVal is called and number strings are floats.
// We may need more conversions there in the future.
export const querySafeFilterCriteria = (
  criteria: { [key: string]: any } | Array<any> | null,
  safeCriteria: { [key: string]: any } = {}
) => {
  if (criteria == null) return safeCriteria;

  for (const [k, v] of Object.entries(criteria)) {
    if (Array.isArray(v)) {
      if (!Array.isArray(safeCriteria[k])) {
        safeCriteria[k] = [];
      }
      safeCriteria[k] = querySafeFilterCriteria(v, safeCriteria[k]);
    } else if (typeof v === "object") {
      safeCriteria[k] = querySafeFilterCriteria(v, safeCriteria[k]);
    } else {
      safeCriteria[k] = safeVal(k, v);
    }
  }
  return safeCriteria;
};

export const getDefinedParams = (
  params: any
): { [key: string]: string | object } =>
  _.omitBy(params, (p) => _.isNaN(p) || _.isNil(p) || p === "");

export const sustainabilityFiltersShuffle = (
  filters: SustainabilityAttributeFilter[]
): SustainabilityAttributeFilter[] => {
  const postConsumerElement = _.find(filters, {
    attributeType: "POST_CONSUMER_RECYCLED",
  });

  if (!postConsumerElement) {
    return filters;
  }

  const allButPostConsumerElement = _.filter(
    filters,
    (s) => s.attributeType !== "POST_CONSUMER_RECYCLED"
  );

  const indexPreConsumer = _.findIndex(
    allButPostConsumerElement,
    (f) => f.attributeType === "PRE_CONSUMER_RECYCLED"
  );

  const beforeAndIncludingPreConsumer = _.slice(
    allButPostConsumerElement,
    0,
    indexPreConsumer + 1
  );

  const afterPreConsumer = _.slice(
    allButPostConsumerElement,
    indexPreConsumer + 1
  );

  return [
    ...beforeAndIncludingPreConsumer,
    postConsumerElement,
    ...afterPreConsumer,
  ] as SustainabilityAttributeFilter[];
};
