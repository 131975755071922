import { ErrorResponse } from "@apollo/client/link/error";
import React from "react";

type ContextType = {
  setUnauthorizedResponse: (response?: ErrorResponse) => void;
  unauthorizedResponse: ErrorResponse | undefined;
};

export const UnauthorizedContext = React.createContext<ContextType>({
  setUnauthorizedResponse: () => undefined,
  unauthorizedResponse: undefined,
});
