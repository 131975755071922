export enum InteractionComponent {
  filter = "filter",
  none = "none",
  search = "search",
  sort = "sort",
}

export type DeepPartial<T> = T extends object
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : T;

export interface ContainerDimensions {
  height: number;
  width: number;
  transition?: boolean;
}
