import _ from "lodash";
import { Button, Typography } from "@mui/material";
import { FormContainer, TextFieldElement, useForm } from "react-hook-form-mui";
import { ErrorMessage } from "@hookform/error-message";
import { useCallback, useEffect, useState } from "react";
import { register as registerUser } from "fetches/auth";
import { useNavigate } from "react-router-dom";

import { TANGIBLE_AWS_BUCKET_URL } from "../../constants";
import { Footer } from "./Footer";

import "./Register.scss";

interface FieldValues {
  email: string;
  name: string;
  organizationName: string;
}
const SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
const scriptUrl = `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`;

export const Register = () => {
  const {
    formState: { errors, isSubmitting },
  } = useForm<FieldValues>({ mode: "onBlur" });
  const navigate = useNavigate();

  useEffect(() => {
    if (_.some(document.getElementsByTagName("script"), { src: scriptUrl })) {
      return;
    }
    const script = document.createElement("script");
    script.src = scriptUrl;
    script.async = true;
    document.body.appendChild(script);
  }, []);

  const [serverErrors, setServerErrors] = useState<{
    errors?: { [key: string]: string[] };
  }>();

  const submitRecaptcha = useCallback(async () => {
    const grecaptcha = (window as any).grecaptcha;
    if (!grecaptcha) {
      throw new Error("window.grecaptcha is undefined");
    }
    return new Promise((resolve) => {
      grecaptcha.ready(function () {
        grecaptcha
          .execute(SITE_KEY, { action: "register" })
          .then(function (token: string) {
            resolve(token);
          })
          .catch((error: Error) => {
            throw error;
          });
      });
    });
  }, []);

  const onSubmit = useCallback(
    async (data: any) => {
      setServerErrors(undefined);
      try {
        const recaptchaToken = await submitRecaptcha();
        const result = await registerUser({
          ...data,
          recaptchaToken,
        });
        if (!result.ok) {
          const json = await result.json();
          setServerErrors(json);
        } else {
          navigate("/register-success");
        }
      } catch (e) {
        setServerErrors({ errors: { root: [(e as Error).message] } });
      }
    },
    [submitRecaptcha, navigate]
  );

  return (
    <div id="register-page">
      <header className="header">
        <img
          src={`${TANGIBLE_AWS_BUCKET_URL}/tangible_red_logo_large.png`}
          alt="tangible logo"
        />
      </header>
      <FormContainer
        FormProps={{ className: "default-shadow" }}
        {...{ onSuccess: onSubmit }}
      >
        <h3>Create your free account</h3>
        <Typography variant="body2" align="center" sx={{ mb: 4 }}>
          Sign up to get a 1-week free trial of Tangible.
          <br />
          Already have an account? <a href="/login">Log in</a>
        </Typography>
        {serverErrors?.errors?.email && (
          <ErrorMessage
            errors={serverErrors.errors}
            name="email"
            message="There's already an account associated with this email address."
            as={<small className="error-message" />}
          />
        )}
        {serverErrors?.errors?.recaptcha && (
          <ErrorMessage
            errors={serverErrors.errors}
            name="recaptcha"
            message="Looks like there was a problem with your captcha. Please reload the page and try again."
            as={<small className="error-message" />}
          />
        )}
        {serverErrors?.errors?.root && (
          <ErrorMessage
            errors={serverErrors.errors}
            name="root"
            message="There was an error during registration. Please try again later or contact us for help."
            as={<small className="error-message" />}
          />
        )}

        <TextFieldElement
          required
          name="name"
          label="Full name"
          autoComplete="full-name"
        />
        <TextFieldElement
          required
          name="organizationName"
          label="Company name"
          autoComplete="organization"
          sx={{ mt: 2 }}
          validation={{
            minLength: {
              value: 2,
              message: "Company name is too short",
            },
          }}
        />
        <TextFieldElement
          required
          type="email"
          label="Email address"
          name="email"
          autoComplete="email"
          sx={{ mt: 2 }}
        />
        <ErrorMessage
          errors={errors}
          name="email"
          message="Email must be formatted correctly."
          as={<small className="error-message" />}
        />
        <Button
          disableElevation
          disabled={isSubmitting}
          color="primary"
          variant="contained"
          type="submit"
        >
          Sign up
        </Button>
      </FormContainer>
      <Footer />
    </div>
  );
};

export default Register;
