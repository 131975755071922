import clsx from "clsx";

interface Props {
  children: React.ReactNode;
  className?: string;
}

export const GroupHeader = ({ children, className }: Props) => {
  return <div className={clsx("GroupHeader", className)}>{children}</div>;
};

export default GroupHeader;
