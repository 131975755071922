import _ from "lodash";
import dayjs from "dayjs";
import Button from "@mui/material/Button";

import { ReportUploadEdge } from "gql/graphql";
import { DATE_STRING } from "utils/formatting";

import "./UploadedReports.scss";
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";

interface Props {
  uploadedReports: ReportUploadEdge[];
}

const UploadedReports = ({ uploadedReports }: Props) => (
  <Box className="project-report-uploads default-border">
    <List>
      {_.map(uploadedReports, ({ node }) => (
        <ListItem key={node?.fileUrl} className="report-upload">
          <ListItemText>
            <span className="title">{node?.title}&nbsp;</span>
            <span className="description">{node?.description}&nbsp;</span>
            <span className="uploaded-date">
              (Uploaded: {dayjs(node?.createdAt).format(DATE_STRING)}
              {node?.source && ` from ${node?.source}`})
            </span>
          </ListItemText>
          <Box>
            {node?.fileUrl && (
              <Button
                disableElevation
                variant="contained"
                href={node?.fileUrl}
                download
              >
                Download report
              </Button>
            )}
          </Box>
        </ListItem>
      ))}
    </List>
  </Box>
);

export default UploadedReports;
