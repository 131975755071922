import { PropsWithChildren, createContext, useState } from "react";

import { AreaUnit, ProjectDataFidelityEnum } from "gql/graphql";
import { Dayjs } from "dayjs";

type PortfolioFiltersContextType = {
  filters: PortfolioFiltersContextFilters;
  setFilters: (filters: Partial<PortfolioFiltersContextFilters>) => void;
  setInitialFilters: (filters: Partial<PortfolioFiltersContextFilters>) => void;
  status: {
    interacted: boolean;
    loading: boolean;
    refetching: boolean;
  };
};

type PortfolioFiltersContextFilters = {
  areaUnit: AreaUnit;
  dataFidelity: ProjectDataFidelityEnum[];
  endDate: Dayjs | null;
  startDate: Dayjs | null;
};

const initialState: PortfolioFiltersContextType = {
  filters: {
    areaUnit: AreaUnit.M2,
    dataFidelity: [
      ProjectDataFidelityEnum.Estimated,
      ProjectDataFidelityEnum.ProductBased,
      ProjectDataFidelityEnum.UserUploaded,
    ],
    endDate: null,
    startDate: null,
  },
  status: {
    interacted: false,
    loading: false,
    refetching: false,
  },
  setFilters: () => {
    throw new Error("setFilters function must be defined");
  },
  setInitialFilters: () => {
    throw new Error("setInitialFilters function must be defined");
  },
};

export const PortfolioFiltersContext =
  createContext<PortfolioFiltersContextType>(initialState);

export const PortfolioFiltersProvider = ({
  children,
  loading,
  refetching,
}: PropsWithChildren & {
  loading: boolean;
  refetching: boolean;
}) => {
  const [interacted, setInteracted] = useState(false);
  const [state, _setState] =
    useState<PortfolioFiltersContextType>(initialState);

  const setFilters = (
    filters: Partial<PortfolioFiltersContextFilters>,
    interacted = true
  ) => {
    setInteracted(interacted);
    _setState(() => ({
      ...state,
      filters: { ...state.filters, ...filters },
    }));
  };

  const setInitialFilters = (
    filters: Partial<PortfolioFiltersContextFilters>
  ) => {
    setFilters(filters, false);
  };

  return (
    <PortfolioFiltersContext.Provider
      value={{
        ...state,
        status: {
          interacted,
          loading,
          refetching,
        },
        setFilters,
        setInitialFilters,
      }}
    >
      {children}
    </PortfolioFiltersContext.Provider>
  );
};

export default PortfolioFiltersContext;
