import { gql } from "@apollo/client";

export const UPLOAD_EPD = gql`
  mutation CreateProduct($input: CreateProductInput!) {
    product {
      create(input: $input) {
        product {
          name
          slug
        }
        errors {
          message
        }
      }
    }
  }
`;
