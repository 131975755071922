import { Button, Dialog, Typography } from "@mui/material";
import { Delete } from "@mui/icons-material";
import clsx from "clsx";
import { useCallback, useState } from "react";
import { pluralizer } from "utils/transforms";

import "./DeleteProjectModal.scss";

interface Props {
  handleClose: () => void;
  onDelete: () => void;
  open: boolean;
  productCount: number;
  projectName: string;
  error?: boolean;
}

const DeleteProjectModal = ({
  projectName,
  handleClose,
  onDelete,
  open,
  productCount,
  error,
}: Props) => {
  const [waiting, setWaiting] = useState(false);
  const handleDelete = useCallback(async () => {
    setWaiting(true);
    await onDelete();
    setWaiting(false);
  }, [onDelete]);
  return (
    <Dialog open={open} className={clsx("delete-project-modal", { waiting })}>
      <h5>Are you sure you want to delete &ldquo;{projectName}&rdquo;?</h5>
      <p>
        The project contains {productCount === 0 ? "no" : productCount}{" "}
        {pluralizer("product", productCount)}.
      </p>
      <div className="actions">
        <Button
          disabled={waiting}
          disableElevation
          variant="outlined"
          color="secondary"
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          disabled={waiting}
          disableElevation
          variant="contained"
          color="primary"
          onClick={handleDelete}
          endIcon={<Delete />}
        >
          Yes, delete project
        </Button>
      </div>

      {error && (
        <Typography
          variant="body2"
          color="error"
          textAlign="center"
          role="alert"
        >
          Error deleting project. Check that you have permission to delete it.
        </Typography>
      )}
    </Dialog>
  );
};

export default DeleteProjectModal;
