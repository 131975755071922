import { useContext } from "react";
import { Alert, Snackbar } from "@mui/material";

import { SnackbarContext } from "context/SnackbarContext";

const CustomSnackbar = () => {
  const { visible, message, options, dismiss } = useContext(SnackbarContext);

  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      open={visible}
      onClose={dismiss}
      message={message}
      autoHideDuration={options.duration}
      transitionDuration={500}
    >
      <Alert onClose={dismiss} severity={options.severity} variant="standard">
        {message}
      </Alert>
    </Snackbar>
  );
};

export default CustomSnackbar;
