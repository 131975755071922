import { formatAreaUnit } from "utils/formatting";
import { numberFormat } from "../utils";
import { AreaUnit } from "gql/graphql";
import _ from "lodash";

export const GrossFloorAreaText = ({
  area,
  unit,
  label = true,
}: {
  area: number;
  unit: AreaUnit;
  label?: boolean;
}) => {
  const safeArea = _.isNaN(area) ? "--" : numberFormat(area);
  return (
    <>
      {label && "Gross Floor Area: "}
      <span>{safeArea}</span> <span>{formatAreaUnit(unit)}</span>
    </>
  );
};
