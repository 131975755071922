import { Button } from "@mui/material";
import { TANGIBLE_AWS_BUCKET_URL } from "../constants";
import "./CatchAllError.scss";

const CatchAllErrorFallback = ({ error, componentStack }: any) => {
  return (
    <div className="error-state" id="catch-all-error-fallback">
      <img
        src={`${TANGIBLE_AWS_BUCKET_URL}/empty-state.png`}
        alt="Empty state"
      />
      <h3>Hmm, something went wrong.</h3>
      <p>{error.toString()}</p>
      <p>{process.env.NODE_ENV === "development" && componentStack}</p>
      <h6>Let&apos;s take you back to safety</h6>

      <Button
        disableElevation
        variant="outlined"
        onClick={() => window.location.replace("/")}
      >
        Go to products catalog
        <i className="material-icons md-14">arrow_forward</i>
      </Button>
    </div>
  );
};

export default CatchAllErrorFallback;
