import { useState, useContext } from "react";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import TextField from "@mui/material/TextField";
import { TANGIBLE_AWS_BUCKET_URL } from "../../constants";
import { resetPassword } from "fetches/auth";
import { useNavigate, useParams } from "react-router-dom";
import { resetPasswordValidate } from "utils/validators";
import { SnackbarContext } from "context/SnackbarContext";
import "./ResetPassword.scss";
import { Box, Button, Typography } from "@mui/material";
import _ from "lodash";

export interface ResetPasswordForm {
  password1: string;
  password2: string;
}

const ResetPassword = () => {
  const { token } = useParams();
  const { flashMessage } = useContext(SnackbarContext);
  const [resetLoginErrors, setInvalidLogin] = useState<string[]>([]);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<ResetPasswordForm>({
    mode: "onChange",
    resolver: resetPasswordValidate,
  });
  const navigate = useNavigate();

  const onSubmit = async (data: ResetPasswordForm) => {
    const response = await resetPassword(
      data.password1,
      data.password2,
      token as string
    );

    if (response.ok) {
      navigate("/");
      flashMessage("Password was successfully reset.", { severity: "success" });
    } else {
      const json = await response.json();
      setInvalidLogin(json.errors || ["Could not reset password"]);
    }
  };

  return (
    <div id="reset-password-page">
      <header>
        <img
          src={`${TANGIBLE_AWS_BUCKET_URL}/tangible_red_logo_large.png`}
          alt="tangible logo"
        />
      </header>
      <form id="reset-password" onSubmit={handleSubmit(onSubmit)}>
        <h3>Set new password</h3>
        {resetLoginErrors.map((error) => (
          <small className="error-message">{error}</small>
        ))}
        <div style={{ width: 288, marginTop: 20 }}>
          <TextField
            fullWidth
            required
            label="Password"
            variant="outlined"
            type="password"
            {...register("password1")}
          />
          <ErrorMessage
            errors={errors}
            name="password1"
            message={errors.password1 as unknown as string}
            as={<small className="error-message" />}
          />
        </div>
        <div style={{ width: 288, marginTop: 20 }}>
          <TextField
            fullWidth
            required
            label="Re-type password"
            variant="outlined"
            type="password"
            {...register("password2")}
          />

          <ErrorMessage
            errors={errors}
            name="password2"
            message={errors.password2 as unknown as string}
            as={<small className="error-message" />}
          />
        </div>
        <Button
          type="submit"
          variant="contained"
          disableElevation
          disabled={!isValid}
        >
          Reset Password
        </Button>
        {!_.isEmpty(resetLoginErrors) ? (
          <Box mt={3}>
            <Button variant="text" component="a" href="/login">
              Request a new password token
            </Button>
          </Box>
        ) : null}
      </form>
      <br />
    </div>
  );
};

export default ResetPassword;
