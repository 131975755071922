import {
  Dispatch,
  SetStateAction,
  useCallback,
  ReactNode,
  SyntheticEvent,
} from "react";
import _ from "lodash";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";

import {
  AttributeTypeEnum,
  ExpiryEnum,
  PerformanceAttributeFilter,
  ProductFilterInput,
  ProductFilterValues,
  SourceEnum,
  SustainabilityAttributeFilter,
} from "gql/graphql";
import CollapsableFilterSubSection from "./CollapsableFiltersSubSection";
import "./CheckboxFilterCollection.scss";
import HideExpiredEPDsToggle from "./CustomHideExpiredEPDsToggle";
import YourUploadedEPDsCheckbox from "./CustomYourUploadedEPDsCheckbox";

const getCheckedValue = (
  attributes: any,
  attributeType: AttributeTypeEnum,
  checkboxValue: boolean
) => {
  if (attributeType === "ENUM") {
    return !_.isNil(
      _.find(attributes, {
        type: checkboxValue,
      })
    );
  } else if (attributeType === "BOOLEAN") {
    return _.get(_.find(attributes, { type: checkboxValue }), "boolValue");
  } else {
    console.warn(
      `Have not yet handled attributeType: ${attributeType} in checkboxes`
    );
  }
};

const CheckboxFilter = ({
  filter,
  filterCollectionAttributeType,
  filterStateKey,
  filtersState,
  setFiltersState,
}: {
  filter:
    | ProductFilterValues
    | PerformanceAttributeFilter
    | SustainabilityAttributeFilter;
  filterCollectionAttributeType: AttributeTypeEnum;
  filterStateKey:
    | "sustainabilityAttributes"
    | "performanceAttributes"
    | "certifiedWith";
  filtersState: ProductFilterInput;
  setFiltersState: Dispatch<SetStateAction<ProductFilterInput>>;
}) => {
  const checkboxValue =
    filterCollectionAttributeType === "ENUM"
      ? // @ts-ignore (we call it stringValue to enable type generation, but it's just called in the schema)
        filter?.asAttribute?.stringValue
      : // @ts-ignore
        filter.attributeType;

  const attributes = _.get(filtersState, filterStateKey);

  const checked = getCheckedValue(
    attributes,
    filterCollectionAttributeType,
    checkboxValue
  );

  const handleCheckboxChange = useCallback(
    (isChecked: boolean) => {
      // should uncheck
      if (isChecked) {
        const newFilterState = _.reject(attributes, { type: checkboxValue });

        // we need to completely remove the filter key from the state if it's empty
        // otherwise, query will return no results
        if (_.isEmpty(newFilterState)) {
          setFiltersState(_.omit(filtersState, filterStateKey));
        } else {
          setFiltersState({
            ...filtersState,
            [filterStateKey]: newFilterState,
          });
        }
      } else {
        const newFilterEntity =
          filterCollectionAttributeType === "ENUM"
            ? { type: checkboxValue }
            : { type: checkboxValue, boolValue: true };
        setFiltersState({
          ...filtersState,
          [filterStateKey]: [
            ...(filtersState[filterStateKey] || []),
            newFilterEntity,
          ],
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filtersState]
  );

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={Boolean(checked)}
          color="default"
          onChange={() => handleCheckboxChange(checked)}
        />
      }
      label={<Typography className="caption">{filter.displayName}</Typography>}
    />
  );
};

interface FilterCollectionProps {
  filterStateKey:
    | "sustainabilityAttributes"
    | "performanceAttributes"
    | "certifiedWith";
  filters: any;
  filtersState: ProductFilterInput;
  setFiltersState: Dispatch<SetStateAction<ProductFilterInput>>;
  collectionName?: string; // Won't render collapsible title if not included
}

const Container = ({
  children,
  collectionName,
}: {
  children: ReactNode;
  collectionName?: string;
}) =>
  collectionName ? (
    <CollapsableFilterSubSection
      collectionName={collectionName}
      children={children}
    />
  ) : (
    <>{children}</>
  );

const CheckboxFilterCollection = ({
  collectionName,
  filterStateKey,
  filters,
  filtersState,
  setFiltersState,
}: FilterCollectionProps) => {
  const handleExpiredToggleChange = useCallback(
    (checked: boolean) => {
      if (checked) {
        setFiltersState({
          ...filtersState,
          expiry: ExpiryEnum.Unexpired,
        });
      } else {
        setFiltersState(_.omit(filtersState, "expiry"));
      }
    },
    [filtersState, setFiltersState]
  );

  const handleYourUploadedEPDsCheckboxChange = useCallback(
    (checked: boolean) => {
      if (checked) {
        setFiltersState({
          ...filtersState,
          source: SourceEnum.User,
        });
      } else {
        setFiltersState(_.omit(filtersState, "source"));
      }
    },
    [filtersState, setFiltersState]
  );

  return (
    <Container collectionName={collectionName}>
      {collectionName === "EPD Type" && (
        <HideExpiredEPDsToggle
          toggledOn={filtersState.expiry === ExpiryEnum.Unexpired}
          handleToggleChange={handleExpiredToggleChange}
        />
      )}
      <FormControl>
        {_.map(_.orderBy(filters, "displayName"), (f) => (
          <CheckboxFilter
            key={f.displayName}
            filter={f}
            filterCollectionAttributeType={f.type}
            filterStateKey={filterStateKey} // "performanceAttributes e.g."
            filtersState={filtersState}
            setFiltersState={setFiltersState}
          />
        ))}
        {collectionName === "EPD Type" && (
          <YourUploadedEPDsCheckbox
            checked={_.get(filtersState, "source") === SourceEnum.User}
            handleCheckboxChange={handleYourUploadedEPDsCheckboxChange}
          />
        )}
      </FormControl>
    </Container>
  );
};

export default CheckboxFilterCollection;
