import React from "react";
import _ from "lodash";
import { formatAttribute } from "utils/transforms";
import { PerformanceAttributeTypeEnum } from "gql/graphql";

export type ModifiedPerformanceAttribute = {
  attributeType: PerformanceAttributeTypeEnum;
  __typename?: "BooleanPerformanceAttribute";
  booleanValue?: boolean;
  displayName?: string;
} & {
  attributeType: PerformanceAttributeTypeEnum;
  __typename?: "ScalarPerformanceAttribute";
  displayName?: string | null;
  scalarValue?: number;
  units?: string;
} & {
  attributeType: PerformanceAttributeTypeEnum;
  __typename?: "StringPerformanceAttribute";
  displayName?: string;
  stringValue?: string;
};

interface Props {
  performanceAttributes: ModifiedPerformanceAttribute[];
  material?: string;
}

/*
TODO: confirm the way r-value is showing up:   const unitDisplay = label === "r-value" ? "m2 K / W" : unit;
*/

const PerformanceAttributeDisplay = ({
  attributeType,
  displayName,
  stringValue,
  booleanValue,
  scalarValue,
  units,
}: ModifiedPerformanceAttribute) => {
  const valueDisplay = formatAttribute({
    attributeType,
    stringValue,
    booleanValue,
    scalarValue,
  });
  return (
    <p className="body-1">
      <span className="label">{displayName}:&nbsp;</span>
      <span>
        {valueDisplay} {units}
      </span>
    </p>
  );
};

const PerformanceAttributes = ({ material, performanceAttributes }: Props) => (
  <>
    <div className="performance-criteria section">
      <p className="subtitle-1">Performance Attributes</p>
      <div>
        {_.map(performanceAttributes, (pA) => (
          <PerformanceAttributeDisplay {...pA} key={pA.attributeType} />
        ))}
      </div>
    </div>
    <hr />
  </>
);

export default PerformanceAttributes;
