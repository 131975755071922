import { Certification, Document } from "gql/graphql";
import "./Document.scss";
import { useMemo } from "react";
import dayjs from "dayjs";
import clsx from "clsx";

import LocalizedFormat from "dayjs/plugin/localizedFormat";

dayjs.extend(LocalizedFormat);

const ProductDocumentUrl = ({ url, displayName }: Document & Certification) => {
  return (
    <a
      className="link"
      key={url}
      href={url as string}
      target="_blank"
      rel="noreferrer"
    >
      <span>{displayName}</span>
      <span className="material-icons menu md-18">launch</span>
    </a>
  );
};

const ProductDocumentDate = ({ expiresAt }: Document & Certification) => {
  const expirationDate = useMemo(() => dayjs(expiresAt), [expiresAt]);

  const isExpired = expirationDate.isBefore(new Date());

  const dateString = useMemo(
    () => expirationDate.format("ll"),
    [expirationDate]
  );

  return (
    <span className={clsx("expiration-date", { "is-expired": isExpired })}>
      Expire{isExpired ? "d" : "s"}:&nbsp;
      {dateString}
      {isExpired && (
        <span className="material-symbols-outlined md-18">pending_actions</span>
      )}
    </span>
  );
};

const ProductDocument = (document: Document & Certification) => {
  return (
    <div className="product-document">
      <ProductDocumentUrl {...document} />
      {document.expiresAt && <ProductDocumentDate {...document} />}
    </div>
  );
};

export default ProductDocument;
