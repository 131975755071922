import _ from "lodash";
import { FieldError, FieldErrors } from "react-hook-form";

export const standardErrorMessage = (
  errors: FieldErrors<any>,
  path: string
) => {
  const error = _.get(errors, path) as FieldError;
  if (error) {
    if (error.message) {
      return error.message;
    }

    switch (error.type) {
      case "min":
        return `Minimum value is ${(error.ref as HTMLInputElement).getAttribute(
          "min"
        )}.`;
      case "max":
        return `Maximum value is ${(error.ref as HTMLInputElement).getAttribute(
          "max"
        )}.`;
      case "maxLength":
        return `Maximum length (${(error.ref as HTMLInputElement).getAttribute(
          "maxLength"
        )} chars) exceeded.`;
      case "invalid":
        return `Invalid.`;
      default:
        return "* Required";
    }
  }
  return "";
};

export const ariaProps = (errors: FieldErrors<any>, path: string) => ({
  "aria-invalid": Boolean(_.get(errors, path)),
});
