import { FormControl, FormHelperText, Grid, InputLabel } from "@mui/material";
import { ProjectStage } from "gql/graphql";
import { useFormContext } from "react-hook-form";

import { HorizontalRadioGroup } from "pages/CreateProject/Components/Form/HorizontalRadioGroup";
import { MonthInput } from "pages/CreateProject/Components/Form/MonthInput";
import dayjs from "dayjs";

const ProjectStages = [
  ProjectStage.Concept,
  ProjectStage.Design,
  ProjectStage.Construction,
];

const Icons = {
  [ProjectStage.Concept]: "lightbulb",
  [ProjectStage.Design]: "design_services",
  [ProjectStage.Construction]: "construction",
};

const Captions = {
  [ProjectStage.Concept]:
    "Little to no information about specific products is known.",
  [ProjectStage.Design]:
    "Design is underway, and some or all materials have been selected.",
  [ProjectStage.Construction]:
    "The building is actively being built or has been built.",
};

export const Page1 = () => {
  const { getValues } = useFormContext();
  return (
    <>
      <section id="stage">
        <InputLabel htmlFor="stage-radio-group-id">
          What stage is the project in?
        </InputLabel>
        <FormHelperText>
          The largest reductions in emissions are made in the earliest stages of
          design.
        </FormHelperText>
        <FormControl fullWidth>
          <HorizontalRadioGroup
            id="stage-radio-group-id"
            name="stage"
            values={ProjectStages}
            rules={{ required: true }}
            captions={Captions}
            icons={Icons}
          />
        </FormControl>
      </section>

      <section id="dates">
        <Grid
          container
          flexDirection="row"
          gap={{ lg: 20, md: 8, sm: 4 }}
          pt={4}
        >
          <Grid item>
            <MonthInput
              name="startedAt"
              id="started-at"
              label="Start date"
              helperText="Year that concept and planning begins"
              controllerProps={{
                rules: {
                  required: "Start date is required",
                  validate: {
                    beforeCompletedAt: (value: dayjs.Dayjs) => {
                      const completedAt = getValues("completedAt");
                      if (!completedAt) return true;
                      return dayjs(value).isBefore(completedAt);
                    },
                  },
                },
              }}
            />
          </Grid>
          <Grid item>
            <MonthInput
              name="completedAt"
              id="completion-at"
              label="Completion date"
              helperText="Year of projected building occupancy"
              controllerProps={{
                rules: {
                  required: "Completion date is required",
                },
              }}
            />
          </Grid>
        </Grid>
      </section>
    </>
  );
};
