import { Fragment } from "react";
import _ from "lodash";

import { CarbonImpact, ComparedToBaseline, Maybe } from "gql/graphql";
import { carbonFootprintDisplay } from "utils/transforms";
import "./CarbonImpactSection.scss";
import { ProductCarbonRatioToBaselineChip as CarbonRatioToBaselineChip } from "components/CarbonChangeChip";
import { Link } from "react-router-dom";
import { ExternalLinks } from "../../constants";

const NoBaselineInfo = () => (
  <p className="missing-info">
    No industry average carbon information available
  </p>
);

const NoAbsoluteCarbonInfo = () => (
  <p className="missing-info">Carbon footprint unavailable</p>
);

const AbsoluteCarbonInfo = ({
  cradleToGateCarbon,
  declaredUnit,
}: {
  cradleToGateCarbon: Maybe<number> | undefined;
  declaredUnit: string;
}) => (
  <>
    <p className="body-1">
      <span className="label">Absolute Carbon:&nbsp;</span>
      <span>
        {carbonFootprintDisplay(cradleToGateCarbon as number, declaredUnit)}
      </span>
    </p>
    <p className="body-1">
      <span className="label max-width">Includes:&nbsp;</span> A1-A3 emissions
      only. A4-A5 are calculated separately, and travel distance is determined
      based on project location. Insulation products are calculated differently.
      &nbsp;
      <Link className="link" to={ExternalLinks.Methodology} target="_blank">
        Learn more
      </Link>
    </p>
  </>
);

interface SharedProps {
  declaredUnit: string;
  primaryCategoryName: string;
}

const CarbonInfo = ({
  carbonInfo,
  declaredUnit,
  primaryCategoryName,
}: SharedProps & { carbonInfo: Maybe<CarbonImpact> | undefined }) => {
  const cradleToGateCarbon = carbonInfo?.kgCo2e?.perUnit;
  const comparedToBaseline = carbonInfo?.comparedToBaseline;
  const carbonRatioToBaseline = comparedToBaseline?.ratioToBaseline;

  if (!_.isNumber(cradleToGateCarbon)) {
    return <NoAbsoluteCarbonInfo />;
  }
  return (
    <Fragment>
      {/*
        If there is baseline info available, we want the chip to appear above the Absolute Carbon.
        Otherwise, we want the message about no baseline info to appear after Absolute Carbon.
       */}
      <CarbonRatioToBaselineChip
        comparedToBaseline={comparedToBaseline as ComparedToBaseline}
        primaryCategoryName={primaryCategoryName}
      />
      <AbsoluteCarbonInfo
        cradleToGateCarbon={cradleToGateCarbon}
        declaredUnit={declaredUnit}
      />
      {_.isNil(carbonRatioToBaseline) && <NoBaselineInfo />}
    </Fragment>
  );
};

const CarbonImpactSection = ({
  carbonImpact,
  declaredUnit,
  primaryCategoryName,
}: SharedProps & { carbonImpact: CarbonImpact }) => {
  return (
    <Fragment>
      <div className="tangible-impact-section section">
        <p className="subtitle-1">Embodied Carbon</p>
        <div className="carbon-details">
          <CarbonInfo
            carbonInfo={carbonImpact}
            declaredUnit={declaredUnit}
            primaryCategoryName={primaryCategoryName}
          />
        </div>
      </div>
      <hr />
    </Fragment>
  );
};

export default CarbonImpactSection;
