import _ from "lodash";
import clsx from "clsx";
import { useContext, useMemo, useRef } from "react";
import { sum } from "d3-array";
import { scaleLinear } from "d3-scale";
import { select } from "d3-selection";

import { ContainerDimensions } from "types";
import { useContainerDimensions } from "utils/CustomHooks";
import "./SingleStackedBarChart.scss";
import WithLoadingState from "components/Reporting/WithLoadingState";
import { DataLoadingContext } from "context/DataLoadingContext";
import DiagonalHatchPattern from "components/Reporting/DiagonalHatchPattern";
import { KGCO2E } from "utils/formatting";
import { renderToString } from "react-dom/server";
import { setupTooltip, transformDataForStacking } from "utils/visualizations";

const GAP_BETWEEN_BARS = 2;

const PADDING = {
  top: 6,
  bottom: 8,
};
const VIZ_HEIGHT = 42 + PADDING.top + PADDING.bottom;
const BAR_HEIGHT = 32;
export interface Datum {
  hatched: boolean;
  key: string;
  value: number;
  label?: string;
  parenthetical?: string;
}

type Props = {
  data: Datum[];
  title: string;
  tooltipTitle: string;
  visualizationId: string;
  sortByKeyForLegend?: string;
  subtitle?: string;
};

export const SingleStackedBarChart = ({
  data,
  title,
  sortByKeyForLegend,
  subtitle,
  tooltipTitle,
  visualizationId,
}: Props) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const { dataLoading } = useContext(DataLoadingContext);

  // percentages only take into account positive values, but width of bars is based on absolute value
  const totalAbsValue = sum(data, (d) => Math.abs(d.value));

  const formattedData = useMemo(() => transformDataForStacking(data), [data]);

  const anyNegativeValues = useMemo(
    () => _.some(formattedData, (d) => d.value < 0),
    [formattedData]
  );

  useContainerDimensions({
    buildVisualization,
    buildVisualizationArgs: [
      formattedData,
      totalAbsValue,
      visualizationId,
      tooltipTitle,
    ],
    containerRef,
    updateOnResize: true,
  });

  // data itself should already by ordered by parent component
  // but sometimes we want to sort the legend differently
  // (e.g. in lifecycle stages, we want negative D stage to appear first in bar chart, but last in legend)
  const formattedAndResorted = useMemo(
    () =>
      sortByKeyForLegend
        ? _.orderBy(formattedData, sortByKeyForLegend)
        : formattedData,
    [formattedData, sortByKeyForLegend]
  );

  return (
    <div id={visualizationId} className="single-stacked-bar-chart">
      <div className="title">
        <p className="subtitle-1">{title}</p>
        {subtitle && <p>{subtitle}</p>}
      </div>
      <div ref={containerRef} role="figure">
        <WithLoadingState isLoading={dataLoading} height={92}>
          <>
            <svg>
              {_.map(
                data,
                (d) =>
                  d.hatched && (
                    <DiagonalHatchPattern key={d.key} id={d.key} size={6} />
                  )
              )}
              {anyNegativeValues && (
                <line className="zero" y1={10} y2={VIZ_HEIGHT - 10} />
              )}
              <g
                className="bars"
                transform={`translate(0, ${PADDING.top})`}
              ></g>
            </svg>
            <div
              className="tooltip default-border default-shadow"
              role="tooltip"
            />
            <div className="legend">
              {_.map(
                formattedAndResorted,
                ({ key, parenthetical, label, percent }) => {
                  return (
                    <div
                      key={key}
                      className="legend-item"
                      data-testid="legend-item"
                    >
                      <div className={clsx(["circle", key])}></div>
                      <span className="label body-1">
                        {label}
                        {parenthetical && <i> ({parenthetical})</i>}
                      </span>
                      <span className="percent">{Math.round(percent)}%</span>
                    </div>
                  );
                }
              )}
            </div>
          </>
        </WithLoadingState>
      </div>
    </div>
  );
};

export default SingleStackedBarChart;

interface FormattedDatum extends Datum {
  percent: number;
  trailingCumulative: number;
}

const buildVisualization = (
  { width: containerWidth, height: containerHeight }: ContainerDimensions,
  data: FormattedDatum[],
  cumulativeTotalKgCo2e: number,
  visualizationContainerId: string,
  tooltipTitle: string
) => {
  const totalOfNegativeValues = sum(data, (d) => {
    return d.value < 0 ? Math.abs(d.value) : 0;
  });

  const xScale = scaleLinear()
    .domain([0, cumulativeTotalKgCo2e])
    .range([0, containerWidth - (GAP_BETWEEN_BARS * _.size(data) - 1)]);

  const container = select(`#${visualizationContainerId}`);
  const svg = container
    .select("svg")
    .attr("width", "100%")
    .attr("height", VIZ_HEIGHT);

  const bars = svg
    .select(".bars")
    .selectAll("rect")
    .data(data)
    .join("rect")
    .attr("x", (d, i) => {
      // TODO: This will only support one negative stage
      return (
        xScale(d.trailingCumulative) + (i === 0 ? 0 : GAP_BETWEEN_BARS * i)
      );
    })
    .attr("width", (d) => xScale(Math.abs(d.value)))
    .attr("height", BAR_HEIGHT)
    .attr("class", (d) => clsx([d.key, { hatched: d.hatched }]))
    .attr("data-testid", "bar")
    .attr("fill", (d) => (d.hatched ? `url(#${d.key})` : "unset"));

  const tooltip = container.select(".tooltip");

  setupTooltip<FormattedDatum>(
    [bars],
    tooltip,
    (_d) => getTooltipContent(_d, tooltipTitle),
    {
      width: containerWidth,
      height: containerHeight,
    }
  );

  svg
    .select("line.zero")
    .attr("x1", xScale(totalOfNegativeValues) + GAP_BETWEEN_BARS / 2)
    .attr("x2", xScale(totalOfNegativeValues) + GAP_BETWEEN_BARS / 2)
    .attr("y1", 0);
};

const getTooltipContent = (d: Datum, title: string) =>
  `<span>${title}
    <strong>${_.capitalize(d.label)}</strong>
  </span>
  <span>Total carbon <strong>${renderToString(
    <KGCO2E bold kgCo2e={d.value} />
  )}</strong></span>`;
