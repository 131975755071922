import React, { PropsWithChildren, memo, useEffect } from "react";
import { UnauthorizedContext } from "./UnauthorizedContext";
import { ErrorResponse } from "@apollo/client/link/error";
import { useNavigate } from "react-router-dom";

type Props = {
  handleUnauthorized: (response: ErrorResponse) => void;
  logoutRoute: string;
};

export const UnauthorizedBoundary = memo(function UnauthorizedBoundary({
  children,
  logoutRoute,
  handleUnauthorized,
}: PropsWithChildren & Props) {
  const navigate = useNavigate();
  const [unauthorizedResponse, setUnauthorizedResponse] = React.useState<
    ErrorResponse | undefined
  >();

  useEffect(() => {
    if (unauthorizedResponse) {
      handleUnauthorized(unauthorizedResponse);
      navigate(logoutRoute);
      setUnauthorizedResponse(undefined);
    }
  }, [unauthorizedResponse, navigate, logoutRoute, handleUnauthorized]);

  return (
    <UnauthorizedContext.Provider
      value={{ unauthorizedResponse, setUnauthorizedResponse }}
    >
      {children}
    </UnauthorizedContext.Provider>
  );
});
