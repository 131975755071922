import { useNavigate } from "react-router-dom";

import {
  Address,
  ProjectDataFidelityEnum,
  Maybe,
  Area,
  Project,
} from "gql/graphql";
import "./ProjectsListRow.scss";
import WithLoadingState from "components/Reporting/WithLoadingState";
import { Button } from "@mui/material";
import { DataSourceText } from "components/DataSourceText";
import { DirectionalChange } from "pages/ProjectReport/Dashboards/Visualizations/DirectionalChange";
import _ from "lodash";
import { AreaText } from "components/AreaText";

const formatAddress = (address: Maybe<Address> | undefined) => {
  let formattedAddress = address?.city || address?.postalCode;

  if (address?.country) {
    return `${formattedAddress}, ${address.country}`;
  }
  return formattedAddress;
};

const ProjectListRow = ({
  dataLoading,
  project,
}: {
  dataLoading: boolean;
  project: Project;
}) => {
  const navigate = useNavigate();

  const {
    slug,
    name,
    dataFidelity,
    address,
    report,
    products,
    aboveGroundFloors,
  } = project || {};
  const carbonInfo = report?.carbon?.total;
  const perArea = report?.carbon?.total?.perArea?.kgCo2e;

  const empty = project === undefined;

  return (
    <WithLoadingState
      isLoading={dataLoading || empty}
      customLoadingElement={
        <tr className="loading">
          <td>
            <div className="loading-data" />
            <div className="loading-data" />
          </td>
          <td>
            <div className="loading-data" style={{ width: "100px" }} />
          </td>
          <td>
            <div className="loading-data" style={{ width: "50px" }} />
          </td>
          <td>
            <div className="loading-data" style={{ width: "100px" }} />
          </td>
          <td>
            <div className="loading-data" style={{ width: "100px" }} />
          </td>
        </tr>
      }
    >
      <tr key={slug} onClick={() => navigate(`/my-projects/${slug}/dashboard`)}>
        <td>
          <span className="bold body-2">{name} </span>

          <span className="address body-2">{formatAddress(address)}</span>
        </td>
        <td>{aboveGroundFloors && <AreaText area={aboveGroundFloors} />}</td>
        <td className="stat">
          {perArea ? Math.round(perArea) : <span>Project area N/A</span>}
        </td>
        <td>
          {dataFidelity && products && (
            <DataSourceText
              dataFidelity={dataFidelity}
              totalProductCount={products?.totalCount}
            />
          )}
        </td>
        <td>
          {dataFidelity === ProjectDataFidelityEnum.Estimated ? (
            <Button
              variant="text"
              className="add-products no-underline"
              onClick={(e) => {
                e.stopPropagation();
                navigate(`/products/all`);
              }}
            >
              Add Products
            </Button>
          ) : (
            _.isNumber(carbonInfo?.reduction?.relative) &&
            carbonInfo?.reduction?.relative !== 0 && (
              <>
                <DirectionalChange
                  change={carbonInfo?.reduction?.relative}
                  format="0%"
                />{" "}
                vs average
              </>
            )
          )}
        </td>
      </tr>
    </WithLoadingState>
  );
};

export default ProjectListRow;
