import _ from "lodash";

import {
  LifecycleStage,
  ProjectDataFidelityEnum,
  ReportTotalImpactByLifecycleStage,
} from "gql/graphql";
import SingleStackedBarChart from "./SingleStackedBarChart";
import "./CarbonByLifecycleStageStackedBarChart.scss";

type Props = {
  data: ReportTotalImpactByLifecycleStage[];
};

enum LifecycleStageHumanReadable {
  Manufacturing,
  Transportation,
  Construction,
  "Manufacturing, Transportation & Construction",
  "Transportation & Construction",
  "Maintenance & Use",
  "End of Life",
  Reuse,
  "Manufacturing, Transportation, Construction, Maintenance & Use, and End of Life",
  "Manufacturing, Transportation, Maintenance & Use, End of Life",
}

const lifecycleStageAsEnglish = (lifecycleStage: string) => {
  const normalizedLifecycleStage = _.toUpper(lifecycleStage);
  switch (true) {
    case normalizedLifecycleStage === LifecycleStage.A1A2A3:
      return LifecycleStageHumanReadable.Manufacturing;
    case normalizedLifecycleStage === LifecycleStage.A4A5:
      return LifecycleStageHumanReadable["Transportation & Construction"];
    case normalizedLifecycleStage === LifecycleStage.A4:
      return LifecycleStageHumanReadable.Transportation;
    case normalizedLifecycleStage === LifecycleStage.A5:
      return LifecycleStageHumanReadable.Construction;
    case normalizedLifecycleStage === LifecycleStage.A1A2A3A4A5:
      return LifecycleStageHumanReadable[
        "Manufacturing, Transportation & Construction"
      ];
    case _.startsWith(_.lowerCase(lifecycleStage), "b"):
      return LifecycleStageHumanReadable["Maintenance & Use"];
    case _.startsWith(_.lowerCase(lifecycleStage), "c"):
      return LifecycleStageHumanReadable["End of Life"];
    case normalizedLifecycleStage === LifecycleStage.D:
      return LifecycleStageHumanReadable.Reuse;
    case normalizedLifecycleStage ===
      LifecycleStage.A1A2A3A4A5B1B2B3B4B5C1C2C3C4:
      return LifecycleStageHumanReadable[
        "Manufacturing, Transportation, Construction, Maintenance & Use, and End of Life"
      ];
    case normalizedLifecycleStage ===
      LifecycleStage.A1A2A3A4A5B1B2B3B4B5B6B7C1C2C3C4:
      return LifecycleStageHumanReadable[
        "Manufacturing, Transportation, Construction, Maintenance & Use, and End of Life"
      ];
    case normalizedLifecycleStage === LifecycleStage.A1A2A3A4B1B2B3B4B5C1C2C3C4:
      return LifecycleStageHumanReadable[
        "Manufacturing, Transportation, Maintenance & Use, End of Life"
      ];
    default:
      return normalizedLifecycleStage;
  }
};

const parentheticalText = (lifecycleStage: string) => {
  if (lifecycleStage === LifecycleStage.A1A2A3A4A5B1B2B3B4B5C1C2C3C4) {
    return "A1-C4";
  }
  return lifecycleStage;
};

export const CarbonByLifecycleStageStackedBarChart = ({ data }: Props) => {
  const formattedData = _.chain(data)
    // Negative kgCo2e should always come before positive kgCo2e
    // After that criteria is met, we want to sort alphabetically by lifecycle stage
    .orderBy([(d) => (d.kgCo2e < 0 ? 0 : 1), "lifecycleStage"], ["asc", "asc"])
    .map((d) => ({
      key: _.toLower(d.lifecycleStage),
      label: _.upperFirst(
        LifecycleStageHumanReadable[
          lifecycleStageAsEnglish(d.lifecycleStage) as number
        ]
      ),
      value: d.kgCo2e,
      parenthetical: `${parentheticalText(d.lifecycleStage)}${
        d.dataFidelity === ProjectDataFidelityEnum.Estimated
          ? ", estimated"
          : ""
      }`,
      hatched: d.dataFidelity === ProjectDataFidelityEnum.Estimated,
    }))
    .value();

  return (
    <SingleStackedBarChart
      visualizationId="lifecycle-stages-stacked-bar-chart"
      data={formattedData}
      title="Lifecycle Stage"
      subtitle="For all building elements"
      sortByKeyForLegend="parenthetical"
      tooltipTitle="Lifecycle Stage"
    />
  );
};

export default CarbonByLifecycleStageStackedBarChart;
