import _ from "lodash";
import { Link } from "react-router-dom";
import { Button, Icon } from "@mui/material";

import PermissionRestricted from "components/PermissionRestricted";
import { UserPermissionsEnum } from "gql/graphql";
import SingleProjectPreview, {
  ProjectPreviewProps,
} from "./SingleProjectPreview";

import "./ProjectsGalleryView.scss";

interface Props {
  loading: boolean;
  projects: ProjectPreviewProps[];
  refetchProjects: () => void;
}

const ProjectsGalleryView = ({ projects, refetchProjects, loading }: Props) => {
  return (
    <div id="projects-gallery-view" className="grid">
      {loading
        ? _.times(9, (i) => {
            return (
              <div key={i} className="single-project-preview loading-data" />
            );
          })
        : _.map(projects, (p) => (
            <SingleProjectPreview
              {...p}
              key={p.slug}
              refetchProjects={refetchProjects}
            />
          ))}

      {!loading && (
        <PermissionRestricted to={UserPermissionsEnum.CreateProject}>
          <Link to="/my-projects/create">
            <Button
              variant="outlined"
              className="default-shadow create-new-project"
            >
              <Icon>add</Icon> Create new project
            </Button>
          </Link>
        </PermissionRestricted>
      )}
    </div>
  );
};

export default ProjectsGalleryView;
