import _ from "lodash";
import { Link } from "react-router-dom";

import { SingleProjectMenu } from "components/ProjectActions/SingleProjectMenu";
import ProjectMap from "components/ProjectMap";
import {
  Maybe,
  Project,
  ProjectDataFidelityEnum,
  PropertyUse,
} from "../../gql/graphql";
import "./SingleProjectPreview.scss";
import { DataSourceText } from "components/DataSourceText";
import { KGCO2E } from "utils/formatting";
import Chip from "@mui/material/Chip";
import { DirectionalChange } from "pages/ProjectReport/Dashboards/Visualizations/DirectionalChange";
import { AreaText } from "components/AreaText";

export type ProjectPreviewProps = Omit<Project, "products" | "area"> & {
  area: number;
  areaUnits: string;
  productCount: number;
  propertyUse: PropertyUse;
  refetchProjects: () => void;
};

const SingleProjectPreview = (props: ProjectPreviewProps) => {
  const {
    name,
    dataFidelity,
    productCount,
    propertyUse,
    aboveGroundFloors,
    slug,
    address,
    report,
    refetchProjects,
  } = props;

  return (
    <div className="single-project-preview default-shadow">
      <SingleProjectMenu
        {...{
          icon: "expand_more",
          project: { slug, name },
          refetchProjects,
          productCount,
        }}
      />
      <Link to={`/my-projects/${slug}`} key={slug}>
        <ProjectMap address={address} width={133} height={200} />
        <div className="info">
          <p className="body-4 name">{name}</p>
          <div>
            <span>
              {_.join(_.compact([address?.city, address?.countryCode]), ", ")}
            </span>
            <p>
              <span className="project-type">{_.capitalize(propertyUse)}</span>
              {" • "}
              <span>
                {aboveGroundFloors ? (
                  <AreaText area={aboveGroundFloors} />
                ) : null}
              </span>
            </p>
          </div>
          <span>
            <DataSourceText
              dataFidelity={dataFidelity}
              totalProductCount={productCount}
            />
            <RelativeCarbonReduction
              dataFidelity={dataFidelity}
              relativeReduction={report?.carbon?.total?.reduction?.relative}
            />
          </span>
          <Chip
            color="secondary"
            className="carbon-intensity"
            label={
              <KGCO2E
                bold
                kgCo2e={report?.carbon?.total?.perArea?.kgCo2e}
                unit={report?.carbon?.total?.perArea?.unit}
                separator="/"
              />
            }
          />
        </div>
      </Link>
    </div>
  );
};

export default SingleProjectPreview;

const RelativeCarbonReduction = ({
  dataFidelity,
  relativeReduction,
}: {
  dataFidelity: Maybe<ProjectDataFidelityEnum> | undefined;
  relativeReduction: Maybe<number> | undefined;
}) => {
  if (
    dataFidelity !== ProjectDataFidelityEnum.ProductBased ||
    !relativeReduction
  ) {
    return null;
  }
  return (
    <>
      {", "}
      <DirectionalChange change={relativeReduction} format="0%" /> vs average
    </>
  );
};
