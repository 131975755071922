import React, { ReactElement } from "react";
import "./FieldWithError.scss";
import clsx from "clsx";

interface Props {
  fieldElement: ReactElement;
  className?: string | clsx.ClassValue[];
  errorMessage?: string;
}

const FieldWithError = (props: Props) => {
  const { fieldElement, errorMessage, className } = props;
  return (
    <div className={clsx("field-with-error", className)}>
      {fieldElement}
      {errorMessage && <p className="error">{errorMessage}</p>}
    </div>
  );
};

export default FieldWithError;
