import {
  ApolloLink,
  NormalizedCacheObject,
  ServerError,
  createHttpLink,
} from "@apollo/client";
import { GraphQLErrors } from "@apollo/client/errors";
import { setContext } from "@apollo/client/link/context";
import { ErrorResponse, onError } from "@apollo/client/link/error";
import { getMainDefinition } from "@apollo/client/utilities";
import { CachePersistor } from "apollo3-cache-persist";
import { GraphQLError } from "graphql";
import _ from "lodash";

export const onUnauthorized = async (
  cachePersistor: CachePersistor<NormalizedCacheObject>
) => {
  await cachePersistor.purge();
  await cachePersistor.cache.cache.reset();
};

type ErrorLinkProps = {
  cachePersistor: CachePersistor<NormalizedCacheObject>;
  handleGraphQLErrors: (graphQLErrors: GraphQLErrors) => void;
  handleNetworkError: () => void;
  handleUnauthorized: (response: ErrorResponse) => void;
};

export const DISABLE_DEFAULT_GRAPHQL_ERROR_HANDLING =
  "DISABLE_DEFAULT_GRAPHQL_ERROR_HANDLING";
export const errorsMiddleware = ({
  cachePersistor,
  handleGraphQLErrors,
  handleNetworkError,
  handleUnauthorized,
}: ErrorLinkProps) =>
  onError((errorResponse: ErrorResponse) => {
    const { networkError, graphQLErrors } = errorResponse;

    if (networkError) {
      if ((networkError as ServerError)?.statusCode === 401) {
        if (process.env.NODE_ENV === "development") {
          console.log("[GraphQL error]: Network Error: 401 Unauthorized");
        }
        onUnauthorized(cachePersistor).then(() => {
          handleUnauthorized(errorResponse);
        });
      } else {
        handleNetworkError();
      }
    }
    if (graphQLErrors) {
      const operationContext = errorResponse.operation.getContext();
      if (
        _.isUndefined(operationContext[DISABLE_DEFAULT_GRAPHQL_ERROR_HANDLING])
      ) {
        handleGraphQLErrors(graphQLErrors);
      }

      graphQLErrors.forEach(({ message, locations, path }) =>
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
      );
    }
  });

export const authMiddleware = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  // const token = localStorage.getItem('token');
  const token = process.env.TEMP_GRAPHQL_AUTH_TOKEN;
  const csrfToken = localStorage.getItem("X-CSRF-Token");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
      "X-CSRF-Token": csrfToken ? csrfToken : "",
    },
  };
});

export const defaultHttpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
  credentials: "include",
});

/*
  This middleware brings mutation errors to top-level (which would then pass it to the errorsMiddleware)
  e.g. results.data.project.upsertProducts.errors --> results.errors
*/
export const extractNestedErrorsMiddleware = new ApolloLink(
  (operation, forward) => {
    // Returns the first operation definition found in this document.
    // In the future, we may need to modify to handle multiple mutations at once
    const mainDefinition = getMainDefinition(operation.query);

    return forward(operation).map((result) => {
      // Right now, we only have nested errors in the case of mutations
      if (
        mainDefinition.kind === "OperationDefinition" &&
        mainDefinition.operation === "mutation"
      ) {
        const errors = searchForErrors(result.data);
        if (_.size(errors) > 0) {
          return { ...result, errors };
        }
      }
      return result;
    });
  }
);

const searchForErrors = (
  data: Record<string, any> | undefined | null
): GraphQLErrors => {
  const errors: GraphQLError[] = [];

  _.forOwn(data as Record<string, any>, (value, key) => {
    if (key === "errors") {
      if (_.isArray(value)) {
        errors.push(...value);
      } else {
        console.warn("errors is not array", value);
      }
    } else if (_.isObject(value)) {
      const foundErrors = searchForErrors(value);
      errors.push(..._.map(foundErrors, (e) => new GraphQLError(e.message)));
    }
  });

  return errors;
};
