import _ from "lodash";
import {
  ChangeEventHandler,
  useEffect,
  useCallback,
  useState,
  useRef,
} from "react";
import {
  GridRenderCellParams,
  GridRenderEditCellParams,
  useGridApiContext,
} from "@mui/x-data-grid";
import { ProductQuantityConfig } from "../ProductTable";
import { Box } from "@mui/material";

interface ExtraProp {
  edit?: boolean;
}

export const QuantityCell = (
  props: GridRenderCellParams<ProductQuantityConfig, any> & ExtraProp
) => {
  const { id, value, field, row, edit } = props;

  const apiRef = useGridApiContext();
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    async (event) => {
      await apiRef.current.setEditCellValue({
        id,
        field,
        value: { ...value, quantity: +event.target.value },
      });
    },
    [apiRef, id, field, value]
  );

  useEffect(() => {
    if (edit) {
      inputRef.current?.focus();
    }
  }, [edit]);

  return (
    <div key={_.get(row, "slug")}>
      <input
        ref={inputRef}
        type="number"
        id="quantity"
        value={value?.quantity || ""}
        onChange={handleInputChange}
        style={{
          pointerEvents: edit ? "auto" : "none",
        }}
        disabled={!edit}
      />
      <label htmlFor="quantity">{value?.unit}</label>
    </div>
  );
};

const InsulationEditQuantityComponent = (props: GridRenderEditCellParams) => {
  const { row, id, value, field } = props;
  const [state, setState] = useState(value);
  const apiRef = useGridApiContext();

  useEffect(() => {
    const updateApi = async () => {
      await apiRef.current.setEditCellValue({
        id,
        field,
        value: state,
      });
    };
    updateApi();
  }, [state, apiRef, id, field]);

  return (
    <div key={row.slug}>
      <Box mb={1}>
        <input
          type="number"
          id="quantity"
          value={state.quantity || ""}
          onChange={(e) =>
            setState({ ...state, ...{ quantity: e.target.value } })
          }
          onBlur={(e) =>
            setState({ ...state, ...{ quantity: e.target.value } })
          }
        />
        <label htmlFor="quantity">{value.unit}</label>
      </Box>
      <div>
        <input
          type="number"
          id="rValue"
          value={state.rValue || ""}
          onChange={(e) =>
            setState({ ...state, ...{ rValue: e.target.value } })
          }
          onBlur={(e) => setState({ ...state, ...{ rValue: e.target.value } })}
        />
        <label htmlFor="rValue">R-Value</label>
      </div>
    </div>
  );
};

export const CustomEditQuantityComponent = (
  props: GridRenderEditCellParams
) => {
  const { row } = props;

  const insulationSpecialCase = _.includes(
    row?.primaryCategory?.additionalFields,
    "R_VALUE"
  );

  return insulationSpecialCase ? (
    <InsulationEditQuantityComponent {...props} />
  ) : (
    <QuantityCell {...props} edit={true} />
  );
};
