import { useApolloClient, useLazyQuery } from "@apollo/client";
import { Organization, UserWithPermissions } from "gql/graphql";
import { GET_CURRENT_USER } from "graphql/queries/users";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  matchRoutes,
  RouteObject,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { login, logout } from "fetches/auth";
import { LoggedOutRoutes } from "../constants";
import { SnackbarContext } from "./SnackbarContext";
import { UnauthorizedContext } from "./UnauthorizedContext";

export type ContextType = {
  currentOrganization?: Organization;
  currentUser?: UserWithPermissions;
};

export type Methods = {
  login: (email: string, password: string) => Promise<Response>;
  logout: () => Promise<boolean>;
};

const EMPTY_STATE = {
  currentUser: undefined,
  currentOrganization: undefined,
};

export const useUserAndOrganizationContext = (): [ContextType, Methods] => {
  const { unauthorizedResponse } = useContext(UnauthorizedContext);
  const { flashMessage } = useContext(SnackbarContext);

  const navigate = useNavigate();
  const location = useLocation();

  const client = useApolloClient();

  const clearState = useCallback(async () => {
    setState(EMPTY_STATE);
    // Clear current user from cache synchronously
    client.writeQuery({
      query: GET_CURRENT_USER,
      data: {
        currentUser: null,
        currentOrganization: null,
      },
    });
    // 'If you just want the store to be cleared and don’t want to refetch active queries, use client.clearStore()'
    await client.clearStore();
  }, [client]);

  useEffect(() => {
    if (unauthorizedResponse) {
      clearState();
    }
  }, [unauthorizedResponse, clearState]);

  // Get from cache so that we don't have to flash login screen
  const cachedResult = client.readQuery({
    query: GET_CURRENT_USER,
  });
  // Local
  const [state, setState] = useState<ContextType>(cachedResult || EMPTY_STATE);

  // Network
  const [getUser, { data }] = useLazyQuery<ContextType>(GET_CURRENT_USER, {
    fetchPolicy: "network-only", // Still fetch from network in case user is deleted, permissions invalid, etc.
  });

  useEffect(() => {
    const routes: string[] = Object.values(LoggedOutRoutes);
    const routeObjects: RouteObject[] = routes.map((route: string) => ({
      path: route,
    }));

    // Routes within the LoggedOutRoutes key shouldn't request the user on page load.
    // I tried to do this by seeing if the cookie existed, but it's an HttpOnly cookie.
    if (!matchRoutes(routeObjects, location)) {
      getUser();
    }
  }, [getUser, location]);

  useEffect(() => {
    if (!data?.currentUser || !data?.currentOrganization) return;
    setState(data);
  }, [data]);

  const methods: Methods = {
    login: async (
      email: string,
      password: string,
      initialRoute = "/"
    ): Promise<Response> => {
      const response = await login(email, password);
      if (!response.ok) {
        setState({});
      } else {
        localStorage.setItem(
          "X-CSRF-Token",
          response.headers.get("X-CSRF-Token") || ""
        );
        const { user } = await response.json();
        if (user) {
          await getUser();
          navigate(initialRoute);
        }
      }
      return response;
    },
    logout: async (): Promise<boolean> => {
      await clearState();
      const response = await logout();
      flashMessage("Logged out successfully", { severity: "info" });
      return response;
    },
  };

  return [state, methods];
};

export const UserAndOrganizationContext = createContext<[ContextType, Methods]>(
  [
    EMPTY_STATE,
    {
      login: async () => Promise.resolve({} as Response),
      logout: () => Promise.resolve(false),
    },
  ]
);

export default UserAndOrganizationContext;
