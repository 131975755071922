import { useContext, useMemo, useRef } from "react";
import _ from "lodash";
import dayjs from "dayjs";
import { max, min } from "d3-array";
import { axisBottom, axisLeft } from "d3-axis";
import { scaleLinear, scaleTime } from "d3-scale";
import { timeFormat } from "d3-time-format";
import { select } from "d3-selection";
import { renderToString } from "react-dom/server";

import { ContainerDimensions } from "types";
import { useContainerDimensions } from "utils/CustomHooks";
import { AreaUnit, Maybe, PortfolioProjectConnection } from "gql/graphql";
import { KGCO2E } from "utils/formatting";
import { formatYAxisTicks } from "pages/Portfolio/Visualizations/utils";
import WithLoadingState from "components/Reporting/WithLoadingState";
import { setupTooltip } from "utils/visualizations";

import { DataLoadingContext } from "context/DataLoadingContext";
import PortfolioFiltersContext from "context/PortfolioFiltersContext";
import VisualizationTooltip from "components/Reporting/VisualizationTooltip";
import "./EmbodiedCarbonIntensityDotPlot.scss";

const MARGINS = {
  top: 0,
  left: 44,
  bottom: 40,
  right: 10,
};

const buildVisualization = (
  { width: containerWidth, height: containerHeight }: ContainerDimensions,
  data: Array<{
    carbonIntensity: number | undefined;
    dataFidelity: string;
    date: Date;
    name: string | undefined;
  }>,
  areaUnit: AreaUnit
) => {
  const vizWidth = containerWidth - MARGINS.left - MARGINS.right;
  const vizHeight = containerHeight - MARGINS.top - MARGINS.bottom;

  const dataOrderedByDate = _.orderBy(data, (d) => d.date, "asc");

  const minDate = min(dataOrderedByDate, (d) => d.date || Date.now());
  const endDate = dayjs("12-31-2050", "MM-DD-YYYY").toDate();
  const xScale = scaleTime()
    .domain([minDate, endDate] as [Date, Date])
    .range([0, vizWidth]);

  const maxCarbon = max(dataOrderedByDate, (d) => d.carbonIntensity);

  const yDomain = [0, (maxCarbon || 0) * 1.1]; // 10% padding so that the dot isn't right at the top of the visualization
  const yScale = scaleLinear()
    .domain(yDomain as [number, number])
    .range([vizHeight, 0])
    .unknown(vizHeight);

  const container = select("#embodied-carbon-intensity-dot-plot");
  const svg = container
    .select("svg")
    .attr("width", "100%")
    .attr("height", containerHeight);

  svg
    .select(".y-axis")
    .call(
      axisLeft(yScale)
        .ticks(3)
        .tickFormat(formatYAxisTicks as any) as any
    )
    .call((selection) => {
      // customizing the y axis
      selection.selectAll(".domain").remove();
      selection.selectAll(".tick line").attr("x2", vizWidth); // making full grid lines
    });

  const tooltip = container.select(".tooltip");

  const dots = svg
    .select(".dots")
    .selectAll("circle")
    .data(_.filter(data, (d: any) => !!d.carbonIntensity))
    .join("circle")
    .attr("cx", (d) => xScale(dayjs(d.date)) || 0)
    .attr("cy", (d) => yScale(d.carbonIntensity as number) || 0)
    .attr("class", (d) => _.kebabCase(d.dataFidelity));

  setupTooltip([dots], tooltip, (_d) => getTooltipContent(_d, areaUnit), {
    height: containerHeight,
    width: containerWidth,
  });

  svg
    .select(".x-axis")
    .attr("transform", `translate(${MARGINS.left}, ${vizHeight})`)
    .call(
      axisBottom(xScale)
        .ticks(4)
        .tickFormat(timeFormat("%Y") as unknown as any) as any
    )
    .call((selection) => {
      // customize the x-axis
      selection.selectAll(".domain").remove();
      selection.selectAll(".tick line").remove();
    });

  // find oldest project with emissions
  const firstProjectWithEmissions = _.first(
    _.filter(
      dataOrderedByDate,
      (d) => d?.carbonIntensity && d?.carbonIntensity > 0
    )
  ) as { carbonIntensity: number; date: Date };

  svg
    .select("line.net-zero")
    .attr("x1", MARGINS.left + (xScale(firstProjectWithEmissions?.date) || 0))
    .attr("x2", MARGINS.left + vizWidth - 30) // TODO: not sure why endDate isn't working
    .attr("y1", yScale(firstProjectWithEmissions?.carbonIntensity || 0))
    .attr("y2", yScale(0));
};

interface Props {
  projectsData: PortfolioProjectConnection["nodes"];
}
const EmbodiedCarbonIntensityDotPlot = ({ projectsData }: Props) => {
  const { dataLoading } = useContext(DataLoadingContext);
  const { filters } = useContext(PortfolioFiltersContext);

  const containerRef = useRef<HTMLDivElement>(null);

  const formattedData = useMemo(
    () =>
      _.map(projectsData, (node) => ({
        carbonIntensity: node?.report?.carbon?.total?.perArea?.kgCo2e,
        dataFidelity: node?.dataFidelity,
        date: dayjs(node?.completedAt).toDate(),
        name: node?.name,
      })),
    [projectsData]
  );

  useContainerDimensions({
    buildVisualization,
    buildVisualizationArgs: [formattedData, filters.areaUnit],
    containerRef,
    updateOnResize: true,
  });

  return (
    <div
      id="embodied-carbon-intensity-dot-plot"
      className="visualization-block border"
    >
      <p className="subtitle-1">Embodied Carbon Intensity</p>
      <p>
        <KGCO2E unit={filters.areaUnit} />
      </p>
      <div
        role="figure"
        style={{ width: "100%", flex: 1, minHeight: 0 }}
        ref={containerRef}
      >
        <VisualizationTooltip />
        <WithLoadingState isLoading={dataLoading}>
          <svg width="100%">
            <g className="x-axis" />
            <g
              className="y-axis"
              transform={`translate(${MARGINS.left}, ${MARGINS.top})`}
            />

            <line
              className="net-zero"
              x2={(containerRef.current?.clientWidth || 0) - MARGINS.right}
            />
            <g
              className="dots"
              transform={`translate(${MARGINS.left}, ${MARGINS.top})`}
            />
          </svg>
        </WithLoadingState>
      </div>
      <div className="legend">
        <div className="net-zero">
          <hr />
          <span>Net Zero by 2050</span>
        </div>
        <div className="product-based-emissions">
          <div className="circle" />
          <span>Product-based data</span>
        </div>
        <div className="uploaded-data-emissions">
          <div className="circle" />
          <span>Uploaded report</span>
        </div>
        <div className="estimated-emissions">
          <div className="circle" />
          <span>Estimated data</span>
        </div>
      </div>
    </div>
  );
};

export default EmbodiedCarbonIntensityDotPlot;

const getTooltipContent = (
  d: {
    carbonIntensity: number | undefined;
    date: Date;
    name: string | undefined;
  },
  areaUnit: AreaUnit
) => `<span>
    <strong>${d.name}</strong>
  </span>

  <span>${renderToString(
    <KGCO2E bold kgCo2e={d?.carbonIntensity} unit={areaUnit} />
  )}</span>
`;
