import _ from "lodash";
import { useCallback, useState } from "react";

import MenuItem from "@mui/material/MenuItem";

import { Add, ExpandMore } from "@mui/icons-material";
import PermissionRestricted from "components/PermissionRestricted";
import { Link } from "react-router-dom";
import { UserPermissionsEnum } from "../../gql/graphql";
import { AddToProjectList } from "./AddToProject";
import "./ProductActions.scss";
import {
  Button,
  ButtonProps,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuList,
  Popover,
  TextField,
} from "@mui/material";

interface Props {
  productSlug: string;
}

interface ProductActionPopoverProp {
  buttonContent: React.ReactNode;
  renderList: ({
    closeMenu,
    searchTerm,
  }: {
    closeMenu: () => void;
    searchTerm?: string;
  }) => React.ReactNode;
  buttonProps?: ButtonProps;
}

export const ProductActionsPopover = ({
  buttonContent,
  renderList,
  buttonProps,
}: ProductActionPopoverProp) => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleDropdownClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const debouncedSetSearchTerm = useCallback(
    _.throttle((value) => setSearchTerm(value), 500),
    []
  );

  const button = (
    <Button
      variant="outlined"
      className={`expand-button`}
      aria-controls={open ? "product-actions-menu" : undefined}
      aria-haspopup="true"
      aria-expanded={open ? "true" : undefined}
      onClick={handleDropdownClick}
      data-testid="open-project-menu"
      {..._.pickBy(buttonProps)}
    >
      {buttonContent}
    </Button>
  );

  return (
    <div className="buttons-section">
      {button}
      {open && (
        <Popover
          className="product-actions-menu actions-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={closeMenu}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <TextField
            label="Search projects"
            onChange={(e) => debouncedSetSearchTerm(e.target.value)}
            className="project-search"
            size="small"
          />
          <MenuList>{renderList({ closeMenu, searchTerm })}</MenuList>
        </Popover>
      )}
    </div>
  );
};

const ProductActions = ({ productSlug }: Props) => {
  const renderList = ({
    closeMenu,
    searchTerm,
  }: {
    closeMenu: () => void;
    searchTerm?: string;
  }) => (
    <div>
      <AddToProjectList
        productSlug={productSlug}
        closeMenu={closeMenu}
        searchTerm={searchTerm}
      />
      <PermissionRestricted to={UserPermissionsEnum.CreateProject}>
        <>
          <Divider />
          <MenuItem>
            <Link
              target="_blank"
              to={`/my-projects/create?from=product-actions-menu&productSlug=${productSlug}`}
              id="add"
            >
              <ListItemIcon>
                <i className="material-icons md-18">add</i>
              </ListItemIcon>
              <ListItemText>Create New Project</ListItemText>
            </Link>
          </MenuItem>
        </>
      </PermissionRestricted>
    </div>
  );

  return (
    <ProductActionsPopover
      buttonContent={<i className="material-icons md-24">expand_more</i>}
      renderList={renderList}
      buttonProps={{
        className: "expand-button",
      }}
    />
  );
};

interface ProductDetailProp {
  productSlug: string;
}

export const ProductDetailActions = ({ productSlug }: ProductDetailProp) => {
  const renderList = ({
    closeMenu,
    searchTerm,
  }: {
    closeMenu: () => void;
    searchTerm?: string;
  }) => (
    <AddToProjectList
      productSlug={productSlug}
      closeMenu={closeMenu}
      searchTerm={searchTerm}
    />
  );

  return (
    <ProductActionsPopover
      buttonContent="Add to project"
      buttonProps={{
        className: "expand-button primary add-to-project",
        startIcon: <ExpandMore />,
        endIcon: <Add />,
      }}
      renderList={renderList}
    />
  );
};

export default ProductActions;
