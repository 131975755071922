import { Dispatch, SetStateAction } from "react";
import _ from "lodash";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { CATALOG_SORT_OPTIONS } from "../../constants";
import { SortCatalogBy } from "./types";

interface Props {
  setSortTerm: Dispatch<SetStateAction<SortCatalogBy>>;
  sortTerm: SortCatalogBy;
}

const SortDropdown = ({ sortTerm, setSortTerm }: Props) => {
  return (
    <FormControl size="small">
      <InputLabel id="sort-select-label">Sort by</InputLabel>
      <Select
        labelId="sort-select-label"
        onChange={(e) => {
          setSortTerm(e.target.value as SortCatalogBy);
        }}
        value={sortTerm}
      >
        {_.map(CATALOG_SORT_OPTIONS, ({ displayLabel }, key) => (
          <MenuItem value={key} color="secondary" key={displayLabel}>
            {displayLabel}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SortDropdown;
