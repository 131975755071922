import { Box } from "@mui/material";
import {
  FormEvent,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useRef,
} from "react";
import { UserInputFields } from "pages/Users/UserInputFields";
import { User } from "gql/graphql";
import { gql, useApolloClient, useMutation } from "@apollo/client";
import { UPDATE_USER } from "graphql/mutations/users";

import "./EditUserForm.scss";
import { SnackbarContext } from "context/SnackbarContext";

interface Props {
  afterSubmit: (event: FormEvent, formData: Partial<User>) => void;
  user: User;
}

export const EditUserForm = ({
  afterSubmit,
  user,
  children,
}: Props & PropsWithChildren) => {
  const firstFieldRef = useRef<HTMLInputElement>();
  useEffect(() => {
    if (firstFieldRef.current) {
      firstFieldRef.current?.focus();
      firstFieldRef.current.selectionStart = 0;
      firstFieldRef.current.selectionEnd = firstFieldRef.current.value.length;
    }
  }, [firstFieldRef]);

  // // Update
  const [updateUser] = useMutation(UPDATE_USER);
  const { flashMessage } = useContext(SnackbarContext);
  const client = useApolloClient();
  const handleSubmit = useCallback(
    async (event: FormEvent) => {
      event.preventDefault();
      const formData = new FormData(event.target as HTMLFormElement);
      const name = formData.get("name") as string;
      const changeset = { id: user.id, name };
      await updateUser({
        variables: {
          input: {
            user: changeset,
          },
        },
      });
      client.writeFragment({
        id: user.id,
        fragment: gql`
          fragment UserFields on User {
            name
          }
        `,
        data: {
          name,
        },
      });
      flashMessage("User updated successfully.", { severity: "success" });
      afterSubmit(event, { ...user, ...changeset });
    },
    [updateUser, user, client, flashMessage, afterSubmit]
  );

  return (
    <form name="edit-user-form" onSubmit={handleSubmit}>
      <Box className="input-fields">
        <UserInputFields firstFieldRef={firstFieldRef} user={user} />
      </Box>
      {children}
    </form>
  );
};
