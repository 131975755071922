import _ from "lodash";
import { Link, useNavigate } from "react-router-dom";
import { useContext, useMemo, useRef } from "react";
import clsx from "clsx";
import "d3-transition";

import { useContainerDimensions } from "utils/CustomHooks";
import {
  ChangePercentageChipConfig,
  getChangePercentageChipConfig,
} from "../StatBlockAlternate";
import genericProductImg from "imgs/generic_product_img.svg";
import { ProjectReportData } from "pages/ProjectReport/ProjectReport";
import WithLoadingState from "components/Reporting/WithLoadingState";
import VisualizationTooltip from "components/Reporting/VisualizationTooltip";

import "./CarbonImpactByProduct.scss";

import { DirectionalChange, CHANGE_THRESHOLD } from "./DirectionalChange";
import { buildVisualization, formatData } from "./CarbonImpactByProduct/utils";
import { Box, Button, Icon, Typography } from "@mui/material";
import UploadEPDForm from "pages/ProjectReport/UploadEPD/UploadEPDForm";
import { ModalContext } from "context/ModalContext";

interface Props {
  dataLoading: boolean;
  hasProducts: boolean;
  noProductsWithQuantities: boolean;
  data?: ProjectReportData;
}

const Title = ({
  projectReductionRelative,
  projectReductionAbsolute,
}: {
  projectReductionAbsolute: number;
  projectReductionRelative: ChangePercentageChipConfig;
}) =>
  projectReductionRelative.value < 0 ? (
    <>
      Your project shows{" "}
      <DirectionalChange includeWord change={projectReductionRelative.value} />{" "}
      in carbon impact vs industry average products.
    </>
  ) : (
    <>
      You achieved{" "}
      <DirectionalChange
        indefiniteArticle
        includeWord
        change={projectReductionRelative.value}
      />{" "}
      vs industry average products
      {/* with an additional 9% reduction achievable */}.
      {/* TODO: Understand how to add achievable */}
    </>
  );

export const VIZ_ID = "carbon-impact-by-product";
const CarbonChangeImpactByProduct = ({
  data,
  dataLoading,
  hasProducts,
}: Props) => {
  const formattedData = useMemo(() => formatData(data), [data]);

  const containerRef = useRef<HTMLDivElement>(null);

  const projectReductionRelative = getChangePercentageChipConfig(
    data?.carbon?.total?.reduction?.relative || 0
  );

  const projectReductionAbsolute =
    data?.carbon?.total?.reduction?.absolute || 0;

  useContainerDimensions({
    buildVisualization,
    buildVisualizationArgs: [formattedData, dataLoading],
    containerRef,
    updateOnResize: true,
  });

  const hasProductsWithBaselines = Boolean(_.size(formattedData));
  const reductionWord =
    projectReductionRelative.value <= 0 ? "increase" : "reduction";

  return (
    <div id={VIZ_ID} className="border visualization-block">
      <p className="subtitle-1">Impact by Product</p>
      <p className="truncate">
        Product contribution to carbon impact, A1-A3 emissions
      </p>
      <WithLoadingState isLoading={dataLoading} height={24} marginBottom={16}>
        <p className="body-1">
          {hasProductsWithBaselines ? (
            <Title
              projectReductionRelative={projectReductionRelative}
              projectReductionAbsolute={projectReductionAbsolute}
            />
          ) : (
            ""
          )}
        </p>
      </WithLoadingState>
      <div
        role="figure"
        className="container"
        style={{ width: "100%", flex: 1, minHeight: 0 }}
        ref={containerRef}
      >
        {!dataLoading && <VisualizationTooltip />}
        <WithLoadingState isLoading={dataLoading}>
          {hasProductsWithBaselines ? (
            <>
              <svg>
                <line className="no-change" />

                <g className="bars" />
                <g className="percentages" />
                <g className="y-axis" />
                <line className="divider" x1={0} />
                <g className="sum-total">
                  <text className="total">Total Product Impact</text>
                  <text
                    className={clsx("total-percentage", reductionWord, {
                      "no-change":
                        Math.abs(projectReductionRelative.value) <=
                        CHANGE_THRESHOLD,
                    })}
                    transform="translate(-8,0)"
                  >
                    <DirectionalChange
                      textOnly
                      showArrow={false}
                      change={projectReductionRelative.value}
                    />
                  </text>
                </g>
              </svg>
              <div className="legend">
                <div>
                  <div className="circle reduction" /> Reduction
                </div>
                <div>
                  <span className="circle increase" />
                  Increase
                </div>
                <div>
                  <span className="line baseline" />
                  Industry Average
                </div>
              </div>
            </>
          ) : (
            <InsufficientDataMessage hasProducts={hasProducts} />
          )}
        </WithLoadingState>
      </div>
    </div>
  );
};

export default CarbonChangeImpactByProduct;

const InsufficientDataMessage = ({ hasProducts }: { hasProducts: boolean }) => {
  const navigate = useNavigate();
  const { getModal, modals } = useContext(ModalContext);
  const epdModalContext = getModal(UploadEPDForm, modals);

  if (hasProducts) {
    return (
      <div className="empty-message">
        <p className="body-2">
          You&apos;ll need to <Link to="/products">add products</Link> that have
          industry averages to view this chart.
        </p>
        <p className="body-2">
          Make sure products you have added also have Quantities using the
          Products tab.
        </p>
      </div>
    );
  }
  return (
    <div className="empty-message no-products">
      <img src={genericProductImg} alt="generic product" />
      <Typography variant="body2">Add products to see their impact</Typography>
      <Box display="flex" flexDirection={"row"} columnGap={1}>
        <Button
          disableElevation
          variant="outlined"
          startIcon={<Icon>arrow_downward</Icon>}
          onClick={epdModalContext?.open}
        >
          Import
        </Button>
        <Button
          disableElevation
          variant="outlined"
          startIcon={<Icon>search</Icon>}
          onClick={() => navigate("/products")}
        >
          Search
        </Button>
      </Box>
    </div>
  );
};
