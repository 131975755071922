import { GuidedProjectOnboarding } from "./GuidedProjectOnboarding";
import "./CreateProject.scss";

const CreateProject = () => {
  return (
    <>
      <header>
        <div>
          <h5>Create a project</h5>
        </div>
      </header>
      <div id="create-project">
        <GuidedProjectOnboarding />
      </div>
    </>
  );
};

export default CreateProject;
