import {
  Fragment,
  useCallback,
  useMemo,
  useState,
  useContext,
  useEffect,
} from "react";
import _ from "lodash";
import { useQuery } from "@apollo/client";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

import { ModalContextProvider } from "context/ModalContext";
import {
  AreaUnit,
  AsyncStatusEnum,
  ReportCarbonPerArea,
  Maybe,
  ProjectProductSortColumn,
  RecommendationScenario,
  Sort,
} from "gql/graphql";
import { GET_PROJECT_RECOMMENDATIONS } from "graphql/queries/projects";
import { KGCO2E } from "utils/formatting";
import RecommendationScenarioPreviewModal from "./RecommendationScenarioPreviewModal";
import EmptyRecommendations from "./EmptyRecommendations";
import "./Recommendations.scss";
import { ScenarioRow } from "./ScenarioRow";
import { DataLoadingContext } from "context/DataLoadingContext";
import WithLoadingState from "components/Reporting/WithLoadingState";
import { useNavigate } from "react-router-dom";

interface Props {
  carbonPerArea: ReportCarbonPerArea;
  projectSlug: string;
  refetchQueries: () => void;
  poll?: boolean;
}

const POLL_INTERVAL = 3000;
const MAX_TRIES = 3;

const Recommendations = ({
  projectSlug,
  carbonPerArea,
  refetchQueries,
  poll = true,
}: Props) => {
  const navigate = useNavigate();
  const { dataLoading } = useContext(DataLoadingContext);
  const [
    recommendationPreviewModalOpen,
    setRecommendationScenarioPreviewModalOpen,
  ] = useState<boolean>(false);

  const openPreviewModal = useCallback(
    () => setRecommendationScenarioPreviewModalOpen(true),
    []
  );
  const closePreviewModal = useCallback(
    () => setRecommendationScenarioPreviewModalOpen(false),
    []
  );

  const [scenarioInPreview, setScenarioInPreview] = useState<{
    scenario: RecommendationScenario | null;
    title: string | null;
  }>({ scenario: null, title: null });

  const recommendationPreviewModal = useMemo(
    () => ({
      modal: RecommendationScenarioPreviewModal,
      isOpen: recommendationPreviewModalOpen,
      open: openPreviewModal,
      close: closePreviewModal,
    }),
    [recommendationPreviewModalOpen, openPreviewModal, closePreviewModal]
  );

  const { data, stopPolling, startPolling, refetch } = useQuery(
    GET_PROJECT_RECOMMENDATIONS,
    {
      variables: { slug: projectSlug },
    }
  );

  const asyncStatus = useMemo(
    () => _.get(data, "project.recommendations.asyncStatus.status"),
    [data]
  );

  useEffect(() => {
    if (!asyncStatus || !poll) return;

    let interval: NodeJS.Timeout;
    if (asyncStatus === AsyncStatusEnum.Completed) {
      stopPolling();
    } else {
      refetch();
      startPolling(POLL_INTERVAL);
      interval = setTimeout(() => {
        stopPolling();
      }, POLL_INTERVAL * MAX_TRIES);
    }

    return () => {
      if (interval) clearTimeout(interval);
    };
  }, [asyncStatus, stopPolling, startPolling, refetch, poll]);

  const previewScenario = useCallback(
    (scenario: RecommendationScenario, title: string) => {
      openPreviewModal();
      setScenarioInPreview({ scenario, title });
    },
    [openPreviewModal, setScenarioInPreview]
  );

  const handleAccepted = useCallback(async () => {
    if (!poll) return;
    await refetchQueries();
    navigate(`/my-projects/${projectSlug}/products`, {
      state: {
        sort: {
          by: ProjectProductSortColumn.CreatedAt,
          direction: Sort.Descending,
        },
      },
    });
  }, [refetchQueries, navigate, projectSlug, poll]);

  const recommendationEdges = data?.project?.recommendations?.edges;
  const recommendationScenarios = _.get(
    recommendationEdges,
    "0.node.scenarios.nodes",
    []
  );
  const recommendationReason = _.get(recommendationEdges, "0.reason.message");
  const recommendationsToShow = shouldShowRecommendations(
    recommendationReason,
    recommendationEdges,
    recommendationScenarios
  );

  return (
    <ModalContextProvider modals={[recommendationPreviewModal]}>
      <RecommendationScenarioPreviewModal
        open={recommendationPreviewModalOpen}
        closeModal={closePreviewModal}
        scenario={scenarioInPreview}
        fromIntensity={carbonPerArea?.kgCo2e}
        onAccepted={handleAccepted}
      />
      <Box id="recommendations" className="default-border">
        <WithLoadingState isLoading={dataLoading} height={300}>
          {recommendationsToShow ? (
            _.map(recommendationEdges, (edge) => (
              <Fragment key={edge.node.id}>
                <Typography variant="h6">{edge.node?.title}</Typography>
                <Table aria-label="simple table">
                  <TableHeader projectAreaUnit={carbonPerArea?.unit} />
                  <TableBody>
                    <ScenarioRow
                      current
                      scenarioId="current-design"
                      scenarioName="Current design"
                      carbonPerArea={carbonPerArea}
                    />
                    {_.map(edge.node?.scenarios?.nodes, (scenario, idx) => {
                      const { title, id, carbon } = scenario;
                      const name = `Scenario ${idx + 1}`;
                      const previewThisScenario = () =>
                        previewScenario(scenario, name);
                      return (
                        <ScenarioRow
                          key={id}
                          scenarioId={id}
                          scenarioName={name}
                          previewScenario={previewThisScenario}
                          description={title}
                          carbonPerArea={carbon?.perArea}
                          relativeCarbonReduction={carbon.reduction?.relative}
                        />
                      );
                    })}
                  </TableBody>
                </Table>
              </Fragment>
            ))
          ) : (
            <EmptyRecommendations
              asyncStatus={data?.project?.recommendations?.asyncStatus?.status}
              reason={recommendationReason}
            />
          )}
        </WithLoadingState>
      </Box>
    </ModalContextProvider>
  );
};
export default Recommendations;

const TableHeader = ({
  projectAreaUnit,
}: {
  projectAreaUnit: Maybe<AreaUnit> | undefined;
}) => (
  <TableHead>
    <TableRow>
      <TableCell>Recommended for you</TableCell>
      <TableCell>
        Project Intensity <br />
        (<KGCO2E unit={projectAreaUnit} />)
      </TableCell>
      <TableCell className="reduction">
        % Project
        <br /> Reduction
      </TableCell>
      <TableCell></TableCell>
    </TableRow>
  </TableHead>
);

function shouldShowRecommendations(reason: string, edges: any, scenarios: any) {
  if (/found some/.test(reason) && !scenarios.length) {
    return false;
  }
  return /lower-carbon concrete options/.test(reason) && _.size(edges) > 0;
}
