import { Maybe, ProductGeography } from "gql/graphql";
import _ from "lodash";

export const geographyNames = (geographies: Array<any>) => {
  const geographyNames = _.map(geographies, (geo) => geo.name);

  return _.isEmpty(geographyNames) ? ["World"] : geographyNames;
};

// format array of geographies as a string joined by ,
// add + X others for a long list
// we are using an approximate guess for the maxChars allowed to limit
// the list to a specific line length (it's not perfect)
// in a specific display context

const SEPARATOR = ", ";
export const getFormattedGeographies = (
  productGeographies: Maybe<ProductGeography[]>,
  maxChars: number
) => {
  const geoNames = geographyNames(productGeographies || []);

  let charCount = 0;
  let idx = 0;
  let includedGeos = [];
  while (
    charCount + _.size(geoNames[idx]) < maxChars &&
    idx < _.size(geoNames)
  ) {
    charCount += _.size(geoNames[idx]) + SEPARATOR.length;
    includedGeos.push(geoNames[idx]);
    idx++;
  }
  if (idx > 0 && idx < _.size(geoNames)) {
    return `${_.join(includedGeos, SEPARATOR)} + ${
      _.size(geoNames) - idx
    } more`;
  }
  return includedGeos.join(SEPARATOR);
};
