import { useMemo } from "react";

import { Project } from "gql/graphql";
import placeholderProjectMapImg from "imgs/placeholder_project_map.png";
import { GOOGLE_MAPS_API_KEY } from "../constants";

import "./ProjectMap.scss";

interface Props {
  address: Project["address"];
  height?: number;
  width?: number;
}

const GOOGLE_MAPS_MAP_ID = "a0f4c8ed7f7c4d1a"; // associates our custom styling with this map
const ZOOM_FOR_STREET_ADDRESS = "17";
const ZOOM_FOR_ZIP_CODE = "11";

const ProjectMap = ({ address, width = 350, height = 233 }: Props) => {
  const mapsParamsString = useMemo(() => {
    if (!address?.latitude || !address?.longitude) return null;
    const params = {
      center: `${address?.latitude},${address?.longitude}`,
      zoom: address.street1 ? ZOOM_FOR_STREET_ADDRESS : ZOOM_FOR_ZIP_CODE,
      size: `${width}x${height}`,
      key: GOOGLE_MAPS_API_KEY,
      map_id: GOOGLE_MAPS_MAP_ID,
      scale: "2", // high resolution
    };
    return new URLSearchParams(params).toString();
  }, [address, width, height]);

  const url = useMemo(() => {
    return mapsParamsString
      ? `https://maps.googleapis.com/maps/api/staticmap?${mapsParamsString}`
      : placeholderProjectMapImg;
  }, [mapsParamsString]);

  return (
    <div
      className="project-map"
      style={{ backgroundImage: `url(${url})`, width }}
    />
  );
};

export default ProjectMap;
