import _ from "lodash";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

import FieldWithError from "components/FieldWithError";
import { ProjectStructureTypeEnum, PropertyUse } from "gql/graphql";
import { standardErrorMessage } from "utils/forms";

export const PropertyDetailsFields = () => {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext();
  return (
    <>
      <FieldWithError
        className="property-details-field row structure-type"
        errorMessage={standardErrorMessage(errors, "structureType")}
        fieldElement={
          <Controller
            name="structureType"
            control={control}
            render={({ field }) => (
              <>
                <InputLabel
                  id="structure-type-select-label"
                  htmlFor="structure-type-select"
                  className="body-4"
                  aria-label="Primary Structural System"
                >
                  Primary Structural System
                </InputLabel>
                <FormControl fullWidth size="small">
                  <InputLabel htmlFor="structure-type-select">
                    Structural System
                  </InputLabel>
                  <Select
                    inputProps={{
                      id: "structure-type-select",
                    }}
                    labelId="structure-type-select-label"
                    size="small"
                    {...field}
                    {...register("structureType", { required: true })}
                  >
                    {_.map(ProjectStructureTypeEnum, (use, key) => (
                      <MenuItem key={use} value={use}>
                        {_.startCase(key)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </>
            )}
          />
        }
      />

      <FieldWithError
        className="property-details-field row2 property-use"
        errorMessage={standardErrorMessage(errors, "propertyUse")}
        fieldElement={
          <Controller
            name="propertyUse"
            control={control}
            render={({ field }) => (
              <>
                <InputLabel
                  htmlFor="property-use-select"
                  id="property-use-select-label"
                  className="body-4"
                >
                  Primary Building Use
                </InputLabel>
                <FormControl fullWidth size="small">
                  <InputLabel htmlFor="property-use-select">
                    Building Use
                  </InputLabel>
                  <Select
                    inputProps={{
                      id: "property-use-select",
                    }}
                    labelId="property-use-select-label"
                    size="small"
                    {...field}
                    {...register("propertyUse", { required: true })}
                  >
                    {_.map(PropertyUse, (use) => (
                      <MenuItem key={use} value={use}>
                        {_.capitalize(use)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </>
            )}
          />
        }
      />
    </>
  );
};

export default PropertyDetailsFields;
