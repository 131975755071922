import ArrowForward from "@mui/icons-material/ArrowForward";
import { TANGIBLE_AWS_BUCKET_URL } from "../constants";
import "./NotFound.scss";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";

interface Props {
  entity: "product" | "page" | "project";
}

const ProductsLink = ({ entity }: Props) => (
  <Link to="/products">
    <Button disableElevation variant="contained" color="primary">
      {entity === "page" ? "Go to products page" : "Explore Product Catalog"}
      <ArrowForward />
    </Button>
  </Link>
);

const ProjectsLink = () => (
  <Link to="/my-projects">
    <Button disableElevation variant="contained" color="primary">
      View all projects
      <ArrowForward />
    </Button>
  </Link>
);

const NotFound = ({ entity }: Props) => {
  return (
    <div className="error-state not-found">
      <img
        src={`${TANGIBLE_AWS_BUCKET_URL}/empty-state.png`}
        alt={`no ${entity} to show`}
      />
      <h3>Hmm, we can&apos;t seem to find that {entity}.</h3>
      <h6>
        {entity === "page"
          ? "Let's take you back where you need to go."
          : "Let us help you find something else."}{" "}
      </h6>
      {entity === "project" ? (
        <ProjectsLink />
      ) : (
        <ProductsLink entity={entity} />
      )}
    </div>
  );
};

export default NotFound;
