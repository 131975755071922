import { Dispatch, SetStateAction } from "react";
import _ from "lodash";
import Box from "@mui/material/Box";
import "./ProductCatalogFilters.scss";
import { AvailableFilters, ProductFilterInput } from "gql/graphql";
import PerformanceFiltersSection from "./Filters/PerformanceFiltersSection";
import ProductFiltersSection from "./Filters/ProductFiltersSection";
import SustainabilityFiltersSection from "./Filters/SustainabilityFiltersSection";

interface Props {
  availableFilters: AvailableFilters;
  clearAllFilters: () => void;
  filterCriteria: ProductFilterInput;
  onInteraction: () => void;
  setFilterCriteria: Dispatch<SetStateAction<any>>;
}

const ProductFilters = (props: Props) => {
  const {
    availableFilters,
    clearAllFilters,
    filterCriteria,
    onInteraction,
    setFilterCriteria,
  } = props;

  const {
    productFilters,
    performanceAttributeFilters,
    sustainabilityAttributeFilters,
  } = availableFilters;

  return (
    <Box
      className="catalog-filters"
      marginTop="40px"
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      onClick={onInteraction}
    >
      <p
        onClick={clearAllFilters}
        role="button"
        className="clear-filters-button"
      >
        Clear All Filters
      </p>

      {!_.isEmpty(productFilters) && (
        <ProductFiltersSection
          filtersAvailable={productFilters}
          sectionTitle="Product"
          filtersState={filterCriteria}
          setFiltersState={setFilterCriteria}
        />
      )}
      {!_.isEmpty(sustainabilityAttributeFilters) && (
        <SustainabilityFiltersSection
          filtersAvailable={sustainabilityAttributeFilters}
          sectionTitle="Sustainability"
          filtersState={filterCriteria}
          setFiltersState={setFilterCriteria}
        />
      )}
      {!_.isEmpty(performanceAttributeFilters) && (
        <PerformanceFiltersSection
          filtersAvailable={performanceAttributeFilters}
          sectionTitle="Performance"
          filtersState={filterCriteria}
          setFiltersState={setFilterCriteria}
        />
      )}
    </Box>
  );
};

export default ProductFilters;
