import { CheckCircle } from "@mui/icons-material";
import { TableRow, TableCell, Typography, Chip, Button } from "@mui/material";
import clsx from "clsx";
import { ReportCarbonChangeChip } from "components/CarbonChangeChip";
import { ReportCarbonPerArea } from "gql/graphql";
import { useMemo } from "react";
import { KGCO2E } from "utils/formatting";

import "./ScenarioRow.scss";

interface ScenarioRowProps {
  carbonPerArea: ReportCarbonPerArea;
  scenarioId: string;
  scenarioName: string;
  current?: boolean;
  description?: string;
  previewScenario?: () => void;
  relativeCarbonReduction?: number;
}

export const ScenarioRow = ({
  scenarioName,
  carbonPerArea,
  current,
  description: descriptionProp,
  previewScenario,
  relativeCarbonReduction,
}: ScenarioRowProps) => {
  const description = useMemo(() => {
    if (current) {
      return null;
    }
    return descriptionProp;
  }, [current, descriptionProp]);
  return (
    <TableRow
      className={clsx("scenario-row", { current })}
      onClick={previewScenario}
    >
      <TableCell>
        <Typography variant="subtitle1">{scenarioName}</Typography>
        <Typography variant="body2">{description}</Typography>
      </TableCell>
      <TableCell>
        {carbonPerArea && (
          <Chip
            className={clsx("carbon-intensity", { current })}
            color="secondary"
            label={
              <KGCO2E
                bold
                kgCo2e={carbonPerArea.kgCo2e}
                unit={carbonPerArea.unit}
                separator="/"
              />
            }
          />
        )}
      </TableCell>
      <TableCell className="reduction">
        {!current && (
          <ReportCarbonChangeChip
            carbonReductionRelative={relativeCarbonReduction}
            showTooltip={false}
            includeSuffix={false}
            percentageOnly
          />
        )}
      </TableCell>
      <TableCell>
        {current ? (
          <CheckCircle />
        ) : (
          <Button
            disableFocusRipple
            disableElevation
            onClick={previewScenario}
            variant="contained"
            color="secondary"
          >
            Preview
          </Button>
        )}
      </TableCell>
    </TableRow>
  );
};
