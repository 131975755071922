import { gql } from "@apollo/client";
import { ORGANIZATION_CONFIG_FRAGMENT } from "graphql/fragments";

export const GET_CURRENT_USER = gql`
  ${ORGANIZATION_CONFIG_FRAGMENT}

  query getCurrentUser {
    currentUser {
      id
      name
      email
      permissions
      # TODO: Add organization details when they are available on backend
    }
    currentOrganization {
      name
      slug
      config {
        ...OrganizationConfigFragment
      }
    }
  }
`;

export const GET_USERS = gql`
  query GetAllUsers($sort: UserSortInput, $after: String, $first: Int) {
    users(sort: $sort, after: $after, first: $first) {
      edges {
        node {
          id
          name
          email
        }
        cursor
      }
    }
  }
`;
