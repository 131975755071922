import _ from "lodash";
import { useCallback, useEffect, useState } from "react";
import { Button } from "@mui/material";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import numeral from "numeral";

import "./Pagination.scss";
import { PAGINATION_RESULT_COUNT } from "../constants";
import { pluralizer } from "utils/transforms";
import { PageInfo } from "gql/graphql";
import clsx from "clsx";

export type PaginationConfig = {
  after?: string | null;
  before?: string | null;
  first?: number | null;
  last?: number | null;
};

export const CustomPagination = ({
  entity,
  onPaginate,
  connection,
  loading = false,
  padForFilters = false,
}: {
  connection:
    | {
        edges?: any[];
        nodes?: any[];
        pageInfo?: PageInfo;
        totalCount?: number;
      }
    | undefined;
  entity: "product" | "project";
  onPaginate: (config: PaginationConfig) => void;
  loading?: boolean;
  padForFilters?: boolean;
}) => {
  const pageInfo = connection?.pageInfo as PageInfo;
  const hasNextPage = pageInfo?.hasNextPage;
  const hasPreviousPage = pageInfo?.hasPreviousPage;
  const visibleItemCount =
    connection?.edges?.length || connection?.nodes?.length;
  const totalCount = connection?.totalCount || 0;

  const [paginationConfig, setPaginationConfig] = useState<PaginationConfig>(
    {}
  );

  const getPreviousPage = useCallback(() => {
    setPaginationConfig({
      ...paginationConfig,
      last: PAGINATION_RESULT_COUNT,
      first: null,
      after: "",
      before: pageInfo?.startCursor,
    });
  }, [pageInfo?.startCursor, paginationConfig]);

  const getNextPage = useCallback(() => {
    setPaginationConfig({
      ...paginationConfig,
      after: pageInfo?.endCursor,
      before: "",
      first: PAGINATION_RESULT_COUNT,
      last: null,
    });
  }, [pageInfo?.endCursor, paginationConfig]);

  useEffect(() => {
    if (_.isEmpty(paginationConfig)) return;

    onPaginate(paginationConfig);
  }, [paginationConfig, onPaginate]);

  return (
    <nav
      className={clsx("custom-pagination", {
        "pad-for-filters": padForFilters,
      })}
    >
      {!loading && !totalCount && <span>No results.</span>}
      {totalCount > 0 && (
        <span>
          Showing {visibleItemCount || totalCount} out of{" "}
          {numeral(totalCount).format("0,0")} {pluralizer(entity, totalCount)}
        </span>
      )}
      <div className="pagination-buttons">
        {hasPreviousPage && (
          <Button
            variant="text"
            className="no-underline"
            onClick={getPreviousPage}
            startIcon={<ChevronLeft />}
          >
            Previous Page
          </Button>
        )}
        {hasNextPage && (
          <Button
            variant="text"
            className="no-underline"
            onClick={getNextPage}
            endIcon={<ChevronRight />}
          >
            Next Page
          </Button>
        )}
      </div>
    </nav>
  );
};
