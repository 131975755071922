import { useCallback, useContext } from "react";
import { useMutation } from "@apollo/client";
import { SnackbarContext } from "context/SnackbarContext";
import { Maybe } from "gql/graphql";
import { UPSERT_PRODUCT_TO_PROJECT } from "graphql/mutations/projects";
import { GET_REPORT_DETAILS } from "graphql/queries/reports";

type UseAddProductReturn = {
  addProduct: (productSlug: Maybe<string>) => Promise<void>;
  loading: boolean;
};

export const useAddProduct = ({
  projectSlug,
}: {
  projectSlug?: Maybe<string>;
}): UseAddProductReturn => {
  const { flashMessage } = useContext(SnackbarContext);
  const [saveProductToProject, { loading }] = useMutation(
    UPSERT_PRODUCT_TO_PROJECT,
    {
      refetchQueries: [
        { query: GET_REPORT_DETAILS, variables: { slug: projectSlug } },
      ],
      onCompleted: () => {
        flashMessage("Product has been added to your project", {
          severity: "success",
        });
      },
    }
  );

  const addProductToProject = useCallback(
    async (productSlug?: Maybe<string>) => {
      if (!projectSlug) return;

      await saveProductToProject({
        variables: {
          input: {
            slug: projectSlug,
            products: [
              {
                slug: productSlug,
              },
            ],
          },
        },
      });
    },
    [projectSlug, saveProductToProject]
  );

  const addProduct = useCallback(
    async (productSlug?: Maybe<string>) => {
      await addProductToProject(productSlug);
    },
    [addProductToProject]
  );

  return {
    addProduct,
    loading,
  };
};

export default useAddProduct;
