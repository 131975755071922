import { Fragment, ReactElement, useContext } from "react";
import _ from "lodash";
import numeral from "numeral";

import "./StatBlock.scss";
import { AreaUnit } from "gql/graphql";
import WithLoadingState from "components/Reporting/WithLoadingState";
import { Icon } from "@mui/material";
import { DataLoadingContext } from "context/DataLoadingContext";

export const getChangeChipConfig = (value: number, unit?: string) => {
  // Add decimals if the abs(value) is less than one
  let format;
  if (_.inRange(value, -1, 1)) {
    format = "0.0";
  } else {
    format = "0,0";
  }
  return { value, unit, format };
};

export type ChangePercentageChipConfig = {
  format: string;
  value: number;
  unit?: AreaUnit;
};

export const getChangePercentageChipConfig = (
  value: number,
  unit?: AreaUnit
): ChangePercentageChipConfig => {
  // Add decimals if the abs(value) is less than one PERCENT
  let format;
  if (_.inRange(value, -0.01, 0.01)) {
    format = "0.0%";
  } else {
    format = "0,0%";
  }
  return { value, unit, format };
};

interface StatBlockProps {
  statFormat: string;
  change?: {
    format: string;
    value: number;
    unit?: string;
  };
  id?: string;
  stat?: string | number;
  subtitle?: string | ReactElement;
  title?: string | ReactElement;
}

const StatBlock = ({
  id,
  stat,
  statFormat,
  title,
  change,
  subtitle,
}: StatBlockProps) => {
  const { dataLoading } = useContext(DataLoadingContext);

  return (
    <div id={id} className="stat-block border">
      <WithLoadingState isLoading={dataLoading}>
        <div className="subtitle">
          <p className="subtitle-1">{_.isBoolean(title) ? "" : title}</p>
          {change && (
            <span
              className={`chip ${
                change.value > 0 ? "below-baseline" : "above-baseline"
              }`}
              data-testid="carbon-change-chip"
            >
              <Icon className="material-icons md-14">{`arrow_${
                change.value > 0 ? "down" : "up"
              }ward`}</Icon>
              <span className="unit">
                {numeral(Math.abs(change.value)).format(change.format)}{" "}
                {change.unit}
              </span>
            </span>
          )}
        </div>
      </WithLoadingState>
      <WithLoadingState isLoading={dataLoading}>
        <Fragment>
          <h3>{stat ? numeral(stat).format(statFormat) : "--"}</h3>
        </Fragment>
      </WithLoadingState>
      {!_.isUndefined(subtitle) && (
        <WithLoadingState isLoading={dataLoading}>
          <p className="subtitle-2">{subtitle || ""}</p>
        </WithLoadingState>
      )}
    </div>
  );
};

export default StatBlock;
