import { gql } from "@apollo/client";

export const UPDATE_USER = gql`
  mutation update($input: UpdateUserInput!) {
    user {
      update(input: $input) {
        user {
          id
          name
          email
        }
      }
    }
  }
`;
export const DELETE_USER = gql`
  mutation delete($input: DeleteUserInput!) {
    user {
      delete(input: $input) {
        user {
          id
        }
        errors {
          message
        }
      }
    }
  }
`;

export const CREATE_USER = gql`
  mutation create($input: CreateUserInput!) {
    user {
      create(input: $input) {
        user {
          id
          name
          email
        }
        errors {
          message
        }
      }
    }
  }
`;
