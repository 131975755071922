import numeral from "numeral";
import { GridRenderCellParams } from "@mui/x-data-grid";

import "./CarbonChangeCell.scss";
import { roundToDigits } from "utils/formatting";
import clsx from "clsx";
import { Icon } from "@mui/material";
import _ from "lodash";

const CarbonChangeCell = (props: GridRenderCellParams<any, any>) => {
  const value = props.row.carbonChange;
  const roundedValue = _.isNull(value) ? null : roundToDigits(value, 2);
  const formattedValue = _.isNull(roundedValue)
    ? "N/A"
    : `${Math.floor(Math.abs(roundedValue * 100))}%`;

  return (
    <div
      className={clsx({
        increase: roundedValue && roundedValue < 0,
        decrease: roundedValue && roundedValue > 0,
      })}
    >
      {roundedValue && roundedValue !== 0 ? (
        <Icon className="material-icons md-14">
          {roundedValue < 0 && "arrow_upward"}
          {roundedValue > 0 && "arrow_downward"}
        </Icon>
      ) : null}
      {formattedValue}
    </div>
  );
};

export default CarbonChangeCell;
