import { ExpiryEnum } from "gql/graphql";
import { PAGINATION_RESULT_COUNT } from "../../constants";
import { SortCatalogBy } from "./types";

export const defaultPaginationParams = {
  first: PAGINATION_RESULT_COUNT,
};

export const defaultQueryParams = {
  ...defaultPaginationParams,
  filter: { expiry: ExpiryEnum.Unexpired } as { [key: string]: any },
  sortKey: SortCatalogBy.dateAdded,
};
