import { useApolloClient, useMutation } from "@apollo/client";
import { ApolloClientWithCachePersistor } from "ApolloClient/CustomApolloProvider";
import { SnackbarContext } from "context/SnackbarContext";
import { User, UserEdge } from "gql/graphql";
import { DELETE_USER } from "graphql/mutations/users";
import { GET_USERS } from "graphql/queries/users";
import _ from "lodash";
import { useCallback, useContext } from "react";

export const useDeleteUser = () => {
  const { flashMessage } = useContext(SnackbarContext);
  const [deleteUser] = useMutation(DELETE_USER, {
    refetchQueries: [{ query: GET_USERS }],
  });
  const client = useApolloClient();
  const perform = useCallback(
    async (userToDelete: Partial<User>) => {
      await deleteUser({
        variables: { input: { user: { id: userToDelete.id } } },
        update: (cache) => {
          // Remove the user from the cache by filtering out the userToDelete
          // https://www.apollographql.com/docs/react/caching/cache-interaction/#using-cachemodify
          cache.modify({
            fields: {
              users(existingRefs = [], { readField }) {
                const edges = _.get(existingRefs, "edges", []);
                return {
                  edges: edges.filter((edge: UserEdge) => {
                    return readField("id", edge.node!) !== userToDelete.id;
                  }),
                };
              },
            },
          });
        },
      });
      flashMessage(`User ${userToDelete.name} was deleted.`, {
        severity: "success",
      });
      // TODO: Figure out why apollo sometimes doesn't purge the user from the cache
      (client as ApolloClientWithCachePersistor).cachePersistor?.persist();
      return userToDelete;
    },
    [client, flashMessage, deleteUser]
  );
  return { perform };
};
