import { Box, Typography } from "@mui/material";

export const Footer = () => (
  <Box pt={4}>
    <Typography variant="body2">
      Need help?{" "}
      <a className="link" href="mailto:info@tangiblematerials.com">
        Contact Tangible Support
      </a>
    </Typography>
  </Box>
);
