import _ from "lodash";
import dayjs from "dayjs";
import {
  CarbonInput,
  CertificationInput,
  LciaMethodEnum,
  LifecycleStage,
  ProductCreateInput,
} from "gql/graphql";
import { DATE_STRING } from "utils/formatting";

export type UploadEpdInput = Omit<ProductCreateInput, "certifications"> & {
  carbon: {
    kgCo2e: number;
    lciaMethod: LciaMethodEnum;
  };
  certification: CertificationInput;
};

const transformCarbonField = (carbonAsInput: {
  kgCo2e: number;
  lciaMethod: "" | LciaMethodEnum;
}): CarbonInput => {
  const { lciaMethod, kgCo2e } = carbonAsInput;

  // lciaMethod is optional
  const formattedObj = lciaMethod === "" ? {} : { lciaMethod };
  return {
    ...formattedObj,
    totalImpactByStage: [
      {
        stage: LifecycleStage.A1A2A3,
        kgCo2e,
      },
    ],
  };
};

export const transformProductInputForMutation = (
  input: UploadEpdInput
): ProductCreateInput => {
  // lciaMethod is the only optional field
  const emptyValsRemoved = _.omitBy(
    input,
    (val, key) => key === "carbon" && !val
  );

  const { carbon, certification, ...rest } =
    emptyValsRemoved as unknown as UploadEpdInput;

  return {
    ...rest,
    certifications: [
      {
        ...certification,
        issuedAt: dayjs(certification.issuedAt).format(DATE_STRING),
        expiresAt: dayjs(certification.expiresAt).format(DATE_STRING),
      },
    ],
    carbon: transformCarbonField(carbon),
  };
};
