import { DocumentNode, useApolloClient, useLazyQuery } from "@apollo/client";
import ArrowRightAlt from "@mui/icons-material/ArrowRightAlt";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import clsx from "clsx";
import _ from "lodash";

import {
  Project,
  ProjectProductSortColumn,
  ProjectProductSortInput,
  Report,
  ReportUploadEdge,
  Sort,
  UserPermissionsEnum,
} from "gql/graphql";
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

import {
  GET_PROJECT_DETAILS,
  PROJECT_PRODUCTS_COUNT,
} from "graphql/queries/projects";
import { GET_REPORT_DETAILS } from "graphql/queries/reports";

import { EditProjectForm } from "components/CreateEditProjectForm";
import { PaginationConfig } from "components/Pagination";
import PermissionRestricted from "components/PermissionRestricted";
import WithLoadingState from "components/Reporting/WithLoadingState";
import TabPanel from "components/CustomTabPanel";
import { DataLoadingContext } from "context/DataLoadingContext";
import { ModalContextProvider } from "context/ModalContext";
import { useTitle } from "context/TitleContext";
import { useScrollTo, useScrollToTop } from "utils/CustomHooks";

import ProductTable from "./ProductTable/ProductTable";
import UploadedReports from "./UploadedReports";
import { PAGINATION_RESULT_COUNT } from "../../constants";
import ReportDashboard from "./Dashboards/ReportDashboard";
import ProjectOverview, {
  ProjectOverviewLoadingState,
} from "./ProjectOverview";

import ProjectDocuments from "./ProjectDocuments";
import Recommendations from "./Recommendations/Recommendations";
import ProjectReportTabs from "./Tabs";
import UploadBuildingFilesModal from "./UploadBuildingFilesModal";
import UploadEPDForm from "./UploadEPD/UploadEPDForm";
import "./ProjectReport.scss";
import { useRecommendationsTabStatus } from "./Recommendations/hooks";

const tabNames = [
  "dashboard",
  "products",
  "estimations",
  "recommendations",
  "details",
  "report",
  "uploads",
  "documents",
] as const;
export type TabNames = (typeof tabNames)[number];

const OLD_TAB_NAMES: { [key: string]: TabNames } = { report: "dashboard" };

export interface ProjectReportData extends Report {
  name: string;
}

const DEFAULT_TAB: TabNames = "dashboard";

const useError = (error: any) => {
  if (error?.graphQLErrors && error.message.match(/not found/i)) {
    throw new Error("Not Found");
  }
};

const DEFAULT_PRODUCT_SORT: ProjectProductSortInput = {
  by: ProjectProductSortColumn.CreatedAt,
  direction: Sort.Descending,
};

function getInitialSort(navigationState: any): ProjectProductSortInput {
  const sort = navigationState.state?.sort;
  if (!sort) return DEFAULT_PRODUCT_SORT;
  return {
    by: sort.by,
    direction: sort.direction,
  };
}

const ProjectReport = () => {
  useScrollToTop();
  const navigationState = useLocation();

  const params = useParams<{ projectSlug: string; tabName: TabNames }>();

  const [currentTab, setCurrentTab] = useState<TabNames>(
    params.tabName || DEFAULT_TAB
  );
  const [uploadEpdFormModalOpen, setUploadEpdFormModalOpen] = useState(false);
  const [uploadBuildingFilesModalOpen, setUploadBuildingFilesModalOpen] =
    useState(false);

  const currentPage = useRef(0);
  const tabsRefs = {
    details: useRef<HTMLDivElement | null>(null),
  };

  const scrollToErrors = useScrollTo(tabsRefs.details);

  const initialProductSort = useMemo(
    () => getInitialSort(navigationState),
    [navigationState]
  );
  const [productSort, setProductSort] =
    useState<ProjectProductSortInput>(initialProductSort);

  const [
    getReportDetails,
    { data, error, called, loading, refetch: refetchReportDetails },
  ] = useLazyQuery<any>(GET_REPORT_DETAILS, {
    variables: { slug: params.projectSlug },
  });

  // Guard
  useError(error);

  useTitle(data?.project?.name || "Project Dashboard");

  const client = useApolloClient();
  const cacheResult = client.readQuery({
    query: PROJECT_PRODUCTS_COUNT,
    variables: { slug: params.projectSlug },
  });
  const cachedHasProjects =
    _.get(cacheResult, "project.products.totalCount", 0) > 0;

  const [paginationConfig, setPaginationConfig] = useState<PaginationConfig>({
    first: PAGINATION_RESULT_COUNT,
  });

  const navigate = useNavigate();

  const changeTab = useCallback(
    (newTab: TabNames) => {
      navigate(`/my-projects/${params.projectSlug}/${newTab}`, {
        replace: true,
      });
    },
    [navigate, params.projectSlug]
  );

  useEffect(
    function setCurrentTabWhenParameterChanges() {
      if (params.tabName) {
        /* 
        We want to ensure that if people are on old urls (e.g. /report)
        they will land on /dashboard. This can likely be removed soon.
        */
        if (OLD_TAB_NAMES[params.tabName]) {
          changeTab(OLD_TAB_NAMES[params.tabName]);
          return;
        }

        if (!tabNames.includes(params.tabName)) {
          changeTab(DEFAULT_TAB);
          return;
        }

        setCurrentTab(params.tabName);
      }
    },
    [params.tabName, changeTab]
  );

  const report: ProjectReportData = {
    ...data?.project?.report,
    name: data?.project?.name || "",
    dataFidelity: data?.project?.report?.dataFidelity,
  };

  const totalProductCount = data?.project?.products?.totalCount || 0;

  const pending = !called || loading;
  const hasProducts = cachedHasProjects || (called && totalProductCount > 0);
  const hasUploadedReports = !_.isEmpty(report?.uploads?.edges);
  const { hasRecommendations, getRecommendationsTabStatus } =
    useRecommendationsTabStatus({ projectSlug: params.projectSlug! });

  const refetchAll = useCallback(() => {
    refetchReportDetails();
    getRecommendationsTabStatus();
  }, [refetchReportDetails, getRecommendationsTabStatus]);

  const uploadEpdModal = useMemo(
    () => ({
      modal: UploadEPDForm,
      isOpen: uploadEpdFormModalOpen,
      open: () => setUploadEpdFormModalOpen(true),
      close: () => setUploadEpdFormModalOpen(false),
    }),
    [uploadEpdFormModalOpen, setUploadEpdFormModalOpen]
  );

  const uploadBuildingFilesModal = useMemo(
    () => ({
      modal: UploadBuildingFilesModal,
      isOpen: uploadBuildingFilesModalOpen,
      open: () => setUploadBuildingFilesModalOpen(true),
      close: () => setUploadBuildingFilesModalOpen(false),
    }),
    [uploadBuildingFilesModalOpen, setUploadBuildingFilesModalOpen]
  );

  useEffect(() => {
    getReportDetails({
      variables: {
        ...paginationConfig,
        productSort: productSort,
      },
    });
  }, [paginationConfig, productSort, getReportDetails]);

  useEffect(() => {
    const asyncStatus = data?.project?.recommendations?.asyncStatus;
    if (!asyncStatus) return;
    const asyncJobStatus = asyncStatus.status;
    getRecommendationsTabStatus({ poll: asyncJobStatus === "PROCESSING" });
  }, [
    data?.project?.recommendations?.asyncStatus,
    getRecommendationsTabStatus,
  ]);

  return (
    <ModalContextProvider modals={[uploadEpdModal, uploadBuildingFilesModal]}>
      <header>
        <h5>
          <Link to={"/my-projects"} className="parent-header">
            Projects
          </Link>
        </h5>
      </header>
      <DataLoadingContext.Provider value={{ dataLoading: loading }}>
        <div className="project-report">
          <div className="overview">
            <WithLoadingState
              isLoading={!called && loading}
              customLoadingElement={<ProjectOverviewLoadingState />}
            >
              <ProjectOverview
                projectMetadata={data?.project}
                refetchReportDetails={refetchReportDetails}
                totalProductCount={totalProductCount}
                carbonPerArea={report?.carbon?.total?.perArea}
              />
            </WithLoadingState>
            <div className="tabs-header">
              <ProjectReportTabs
                currentTab={currentTab}
                changeTab={changeTab}
                hasUploadedReports={hasUploadedReports}
                hasRecommendations={hasRecommendations}
                totalProductCount={totalProductCount}
              />
            </div>
          </div>
          <div className="content">
            <TabPanel<TabNames>
              currentTab={currentTab}
              tabName="dashboard"
              parentId="project-report"
            >
              {/* TODO: rename (not ) */}
              <ReportDashboard
                hasProducts={hasProducts}
                projectProducts={data?.project?.products?.edges}
                lastUpdatedDate={data?.project?.updatedAt}
                data={report}
              />
            </TabPanel>
            <TabPanel<TabNames>
              currentTab={currentTab}
              tabName="products"
              className={clsx({ empty: !hasProducts })}
              parentId="project-report"
            >
              <ProductsTab
                showTable={hasProducts}
                loading={pending}
                table={
                  <ProductTable
                    productConnection={data?.project?.products}
                    projectSlug={params.projectSlug as string}
                    refetchReportDetails={refetchReportDetails}
                    onPaginate={setPaginationConfig}
                    onSort={setProductSort}
                    paginationConfig={paginationConfig}
                    currentPage={currentPage}
                    loading={loading}
                  />
                }
              />
            </TabPanel>
            <TabPanel
              parentId="recommendations"
              currentTab={currentTab}
              tabName="recommendations"
            >
              <Recommendations
                projectSlug={params.projectSlug as string}
                carbonPerArea={data?.project?.report?.carbon?.total?.perArea}
                refetchQueries={refetchAll}
              />
            </TabPanel>
            <TabPanel
              currentTab={currentTab}
              tabName="details"
              parentId="project-report"
            >
              {data?.project && (
                <DataLoader
                  query={GET_PROJECT_DETAILS}
                  active={currentTab === "details"}
                  variables={{ slug: data.project.slug }}
                >
                  {({ project }) => {
                    if (!project) return null;
                    return (
                      <EditProjectForm
                        project={project}
                        afterErrors={scrollToErrors.scrollToElement}
                        afterSave={refetchAll}
                      />
                    );
                  }}
                </DataLoader>
              )}
            </TabPanel>
            {hasUploadedReports && (
              <TabPanel<TabNames>
                currentTab={currentTab}
                tabName="uploads"
                parentId="project-report"
              >
                <UploadedReports
                  uploadedReports={report.uploads.edges as ReportUploadEdge[]}
                />
              </TabPanel>
            )}
            <TabPanel<TabNames>
              currentTab={currentTab}
              tabName="documents"
              parentId="project-report"
            >
              <ProjectDocuments projectSlug={params.projectSlug} />
            </TabPanel>
          </div>
        </div>
      </DataLoadingContext.Provider>
      <PermissionRestricted to={UserPermissionsEnum.CreateProduct}>
        <UploadEPDForm
          closeModal={useCallback(
            () => setUploadEpdFormModalOpen(false),
            [setUploadEpdFormModalOpen]
          )}
          open={uploadEpdFormModalOpen}
          addToProjectAfterUpload={{ projectSlug: data?.project?.slug }}
          onComplete={useCallback(() => changeTab("products"), [changeTab])}
        />
      </PermissionRestricted>
      <UploadBuildingFilesModal
        projectSlug={data?.project?.slug}
        open={uploadBuildingFilesModalOpen}
        closeModal={useCallback(
          () => setUploadBuildingFilesModalOpen(false),
          [setUploadBuildingFilesModalOpen]
        )}
      />
    </ModalContextProvider>
  );
};

export default ProjectReport;

const DataLoader: React.FC<{
  active: boolean;
  children: (data: { project?: Project }) => ReactElement | null;
  query: DocumentNode;
  variables: { slug: string };
}> = ({ query, children, active, variables }) => {
  const [fetch, { data: formData }] = useLazyQuery<{ project: Project }>(query);
  useEffect(() => {
    fetch({ variables });
  }, [active, variables, fetch]);

  if (!formData) return null;

  return children(formData);
};

const ProductsTab: React.FC<{
  loading: boolean;
  showTable: boolean;
  table: ReactElement<any, any>;
}> = ({ loading, showTable, table }) => {
  const navigate = useNavigate();

  if (showTable) {
    return table;
  }

  if (loading) {
    return <div className="loading-data"></div>;
  }

  return (
    <div className="project-report-no-products">
      <Typography variant="body1">
        No saved products yet. Explore Tangible’s product database to find
        sustainable products to add to your project.
      </Typography>
      <Button
        variant="outlined"
        onClick={() => navigate("/products")}
        endIcon={<ArrowRightAlt />}
      >
        Explore Products
      </Button>
    </div>
  );
};
