import { DeleteOutline } from "@mui/icons-material";
import {
  Dialog,
  DialogContent,
  Typography,
  DialogActions,
  Button,
} from "@mui/material";
import { Organization, User } from "gql/graphql";

export const DeleteUserDialog = ({
  handleDelete,
  onClose,
  open,
  organization,
  user,
}: {
  handleDelete: () => void;
  onClose: () => void;
  open: boolean;
  organization?: Partial<Organization>;
  user?: Partial<User>;
}) => (
  <Dialog open={open} onClose={onClose}>
    <DialogContent>
      <Typography variant="h6">Delete user</Typography>
      <Typography variant="body1">
        Deleting users cannot be undone. {user?.name || "This user"} will be
        removed
        {organization?.name
          ? ` from the ${organization.name} organization`
          : ""}
        .
      </Typography>
    </DialogContent>
    <DialogActions>
      <Button
        disableElevation
        variant="contained"
        color="secondary"
        onClick={onClose}
      >
        Cancel
      </Button>
      <Button
        disableElevation
        variant="contained"
        color="error"
        onClick={() => {
          handleDelete();
          onClose();
        }}
        startIcon={<DeleteOutline />}
      >
        Delete
      </Button>
    </DialogActions>
  </Dialog>
);
