import { BoxProps, Box, Typography, Button, Link } from "@mui/material";

import { UserIcon } from "icons/UserIcon";

import "./ProfileImage.scss";

interface ProfileImageProps extends BoxProps {
  email?: string;
  name?: string;
}

export const ProfileImage = (props: ProfileImageProps) => {
  return (
    <Box
      className="profile-image"
      display="flex"
      flexDirection="column"
      {...props}
    >
      <Typography variant="h6" className="subheading">
        Profile image
      </Typography>
      <Box display="flex" flexDirection="row" pt={1}>
        <UserIcon name={props.name} email={props.email} />
        <Box
          className="avatar-text"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          ml={2}
        >
          <Link href="https://www.gravatar.com" target="_blank">
            <Button disableElevation variant="contained" color="secondary">
              Change on Gravatar
            </Button>
          </Link>
          <Typography variant="body2" pt={1}>
            Use your work email to update your profile image on Gravatar.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
