import { useCallback, useEffect, useMemo, useState } from "react";
import _ from "lodash";
import numeral from "numeral";
import { useLocation } from "react-router-dom";

import "./ProductsCatalog.scss";
import ProductGrid from "components/ProductGrid";
import ProductFilters from "./ProductCatalogFilters";
import { pluralizer } from "utils/transforms";
import SortDropdown from "./SortDropdown";
import { CATALOG_SORT_OPTIONS, PAGINATION_RESULT_COUNT } from "../../constants";
import { CustomPagination, PaginationConfig } from "components/Pagination";
import ReachOutLink from "components/ReachOutLink";
import { GET_CATALOG_PRODUCTS } from "graphql/queries/products";
import { ApolloError, useLazyQuery } from "@apollo/client";
import { AvailableFilters, ProductFilterInput } from "gql/graphql";
import { deserializeQueryParams, toJSONPath, getDefinedParams } from "./utils";
import { InteractionComponent } from "types";
import { defaultPaginationParams, defaultQueryParams } from "./constants";
import { SortCatalogBy } from "./types";
import { useScrollToTop } from "utils/CustomHooks";
import SearchBar from "components/SearchBar";

const Filtered = ({ icon = "filter_alt" }: { icon?: string }) => (
  <div className="filtered">
    <i className="material-icons md-24">{icon}</i> <span>Results Filtered</span>
  </div>
);

const ProductsCatalog = () => {
  const { scrollToTop } = useScrollToTop();
  const location = useLocation();

  /*

  INITIAL FILTER, SORT, and PAGINATION FROM QUERYSTRING

  */

  const { filter: initialFilterCriteria, ...initialQueryParams } =
    useMemo(() => {
      if (location.search === "") return defaultQueryParams;
      return {
        ...defaultQueryParams,
        ...deserializeQueryParams(location.search),
      };
    }, [location.search]);

  /*

  STATE

  */
  const [activeInteractionComponent, setActiveInteractionComponent] =
    useState<InteractionComponent>(InteractionComponent.none);

  const [filterCriteria, setFilterCriteria] = useState<ProductFilterInput>(
    initialFilterCriteria
  );
  const hasCustomFilters = useMemo(
    () => !_.isEmpty(filterCriteria),
    [filterCriteria]
  );
  const setFilterCriteriaAndClearPagination = useCallback(
    (newFilterCriteria: ProductFilterInput) => {
      // This is a bit hacky, we don't want to set the state without when the country dropdown is cleared
      const updatedFilterCriteria = _.isEmpty(newFilterCriteria.country?.in)
        ? _.omit(newFilterCriteria, "state")
        : newFilterCriteria;
      setPaginationConfig(defaultPaginationParams);
      setFilterCriteria(updatedFilterCriteria);
    },
    []
  );

  const [sortTerm, setSortTerm] = useState<SortCatalogBy>(
    initialQueryParams.sortKey
  );
  const [paginationConfig, setPaginationConfig] = useState<PaginationConfig>(
    _.pick(initialQueryParams as Partial<PaginationConfig>, [
      "first",
      "last",
      "before",
      "after",
    ])
  );

  const [searchTerm, setSearchTerm] = useState<string>(
    initialFilterCriteria.q || ""
  );

  /*

  Click event callbacks

  */

  const clearAllFilters = useCallback(() => {
    setFilterCriteriaAndClearPagination(defaultQueryParams.filter);
    setSearchTerm(defaultQueryParams.filter.q || "");
    setSortTerm(defaultQueryParams.sortKey);
  }, [setFilterCriteriaAndClearPagination]);

  /*

  QUERYING

  */

  const handleQueryError = useCallback(
    (error: ApolloError) => {
      if (error.message.includes("Variable $filter")) {
        // Filters were invalid, clear them.
        // TODO: We should switch loading states to use `loading` prop,
        // and discuss user experience if a querystring is so invalid
        // that a product load fails.
        clearAllFilters();
      }
    },
    [clearAllFilters]
  );

  const [getCatalogProducts, { loading, data }] = useLazyQuery(
    GET_CATALOG_PRODUCTS,
    { onError: handleQueryError }
  );

  useEffect(() => {
    const fetchProducts = async () => {
      const variables = {
        ...paginationConfig,
        sort: CATALOG_SORT_OPTIONS[sortTerm].config,
        filter: filterCriteria,
      };

      await getCatalogProducts({
        variables,
      });

      scrollToTop();
    };

    fetchProducts();
  }, [
    filterCriteria,
    paginationConfig,
    sortTerm,
    getCatalogProducts,
    scrollToTop,
  ]);

  // These functions avoid rerender on location change
  const currentPath = useCallback(() => location.pathname, [location.pathname]);
  const currentSearch = useCallback(() => location.search, [location.search]);
  const setURLState = useCallback((pathWithSearch: string) => {
    window.history.replaceState({}, "", pathWithSearch);
  }, []);
  useEffect(
    function updateQueryParams() {
      // Start with initial URL
      const queryParams = new URLSearchParams();

      const filterWithoutQ = _.omit(filterCriteria, "q");

      const sort = _.findKey(SortCatalogBy, (v) => v === sortTerm);
      const params = {
        filter: filterWithoutQ,
        ...paginationConfig,
        sort: sort,
      };
      const definedParams = getDefinedParams(params);

      // Get JSON paths to append to query
      const queryJSONPaths = toJSONPath(definedParams);

      // Compile query params
      for (const [k, v] of Object.entries(queryJSONPaths)) {
        if (v === undefined) continue;
        queryParams.set(k, v);
      }

      if (filterCriteria.q) {
        queryParams.set("q", filterCriteria.q);
      }

      const string = queryParams.toString();
      if (!string.length) {
        setURLState(string);
      } else if (string !== currentSearch()) {
        // It doesn't really make sense for us to allow modifying query params on
        // a subtab, so we switch to `all`
        setURLState(`/products/all?${string}`);
      }
    },
    [
      filterCriteria,
      sortTerm,
      paginationConfig,
      currentPath,
      currentSearch,
      setURLState,
    ]
  );

  /*

  DATA TRANSFORMS

  */

  const availableFilters: AvailableFilters =
    data?.catalog?.products?.availableFilters;

  const catalogProducts =
    _.map(data?.catalog?.products?.edges, (edge) => edge.node) || [];

  const onPaginate = (paginationConfig: PaginationConfig) => {
    setPaginationConfig(paginationConfig);
  };

  return (
    <>
      <header>
        <h3>Product Database</h3>
        <div>
          {hasCustomFilters && !loading && <Filtered />}
          {!loading && data?.catalog?.products?.totalCount > 0 && (
            <p className="chip">
              {numeral(data?.catalog?.products?.totalCount).format("0,0")}{" "}
              {pluralizer("result", data?.catalog?.products?.totalCount)}
            </p>
          )}

          <SortDropdown sortTerm={sortTerm} setSortTerm={setSortTerm} />
          <div className="horizontal-divider" />
          <SearchBar
            onSearch={(searchTerm: string) => {
              // Clear pagination vars if set
              setActiveInteractionComponent(InteractionComponent.search);
              setFilterCriteriaAndClearPagination({
                ...filterCriteria,
                q: searchTerm,
              });
            }}
            placeholder="Search products"
            value={searchTerm}
            onChange={setSearchTerm}
          />
          <ReachOutLink />
        </div>
      </header>
      <div id="products-page">
        <div id="filters-and-catalog">
          {data?.catalog?.products?.availableFilters ? (
            <ProductFilters
              availableFilters={availableFilters}
              clearAllFilters={clearAllFilters}
              onInteraction={() =>
                setActiveInteractionComponent(InteractionComponent.filter)
              }
              filterCriteria={filterCriteria}
              setFilterCriteria={setFilterCriteriaAndClearPagination}
            />
          ) : (
            <div className="filters-loading">
              {_.map(_.range(0, 16), (num: number, idx: number) => (
                <div key={`${idx}_${num}`} />
              ))}
            </div>
          )}
          {data?.catalog?.products?.availableFilters ? (
            <ProductGrid
              activeInteractionComponent={activeInteractionComponent}
              productNodes={catalogProducts}
            />
          ) : (
            <div className="products-loading">
              {_.map(
                _.range(0, PAGINATION_RESULT_COUNT),
                (num: number, idx: number) => (
                  <div className="loading-block" key={`${idx}_${num}`}></div>
                )
              )}
            </div>
          )}
        </div>
      </div>
      <CustomPagination
        padForFilters
        entity="product"
        onPaginate={onPaginate}
        connection={data?.catalog?.products}
        loading={loading}
      />
    </>
  );
};

export default ProductsCatalog;
