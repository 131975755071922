import _ from "lodash";
import { Fragment, useContext, useEffect } from "react";
import { Paper, Box, Button, Typography } from "@mui/material";
import { Add as CreateIcon } from "@mui/icons-material";
import UsersTable from "./components/UsersTable";

import { useQuery } from "@apollo/client";
import { GET_USERS } from "graphql/queries/users";
import { useCallback, useMemo, useState } from "react";
import { User, UserConnection, UserPermissionsEnum } from "gql/graphql";
import { UserDrawer } from "./components/UserDrawer";
import UserAndOrganizationContext from "context/UserAndOrganizationContext";
import { useDeleteUser } from "./hooks/useDeleteUser";
import { DeleteUserDialog } from "./components/DeleteUserDialog";

import "./Users.scss";
import { useCreateUser } from "./hooks/useCreateUser";
import { CreateUserDialog } from "./components/CreateUserDialog";
import clsx from "clsx";
import { useUserPermissions } from "components/PermissionRestricted";

export enum UserManagementMode {
  Show = "show",
  Edit = "edit",
  Delete = "delete",
  Create = "create",
}

export const Users = ({ perPage = 25 }: { perPage?: number }) => {
  const [{ currentOrganization }] = useContext(UserAndOrganizationContext);
  const canCreateUsers = useUserPermissions(UserPermissionsEnum.CreateUsers);
  const [drawerState, setDrawerState] = useState<{
    mode: UserManagementMode;
    user?: User;
  }>();
  const [modalState, setModalState] = useState<{
    mode?: UserManagementMode;
    user?: Partial<User>;
  }>();

  const { data, fetchMore } = useQuery<{ users: UserConnection }>(GET_USERS, {
    variables: { first: perPage },
  });
  const users = useMemo(
    () => _.map(data?.users?.edges, "node"),
    [data?.users?.edges]
  );
  const cursor = useMemo(
    () => _.last(data?.users?.edges)?.cursor,
    [data?.users?.edges]
  );

  const [hasMoreToLoad, setHasMoreToLoad] = useState(false);

  useEffect(() => {
    if (_.size(data?.users?.edges) % perPage === 0) {
      setHasMoreToLoad(true);
    }
  }, [data?.users?.edges, perPage]);

  const loadMore = useCallback(async () => {
    if (cursor) {
      const result = await fetchMore({
        variables: { first: perPage, after: cursor },
      });
      if (_.isEmpty(_.get(result, "data.users.edges", []))) {
        setHasMoreToLoad(false);
      }
    }
  }, [cursor, fetchMore, perPage]);

  // Delete
  const { perform: performDelete } = useDeleteUser();
  const { perform: performCreate } = useCreateUser();

  const handleEvent = useCallback(
    (mode: UserManagementMode, user: Partial<User>) => {
      if (mode === UserManagementMode.Delete) {
        setDrawerState(undefined); // Close drawer during modal present
        setModalState({ user, mode });
        return;
      }
      setDrawerState({ ...drawerState, mode });
    },
    [drawerState]
  );
  const handleCreate = useCallback(() => {
    setModalState({ mode: UserManagementMode.Create });
  }, [setModalState]);

  return (
    <Fragment key="users">
      <Paper id="users" elevation={0}>
        <Box p={2}>
          <Box display="flex" justifyContent="space-between" mb={3}>
            <Typography variant="h6">Users</Typography>
            {canCreateUsers ? (
              <Button
                disableElevation
                variant="contained"
                color="primary"
                onClick={handleCreate}
              >
                <CreateIcon /> Invite new user
              </Button>
            ) : null}
          </Box>

          <UsersTable
            className={clsx({ "has-more-to-load": hasMoreToLoad })}
            users={users}
            onUserAction={useCallback(
              (user, mode) => {
                if (mode === UserManagementMode.Delete) {
                  setModalState({ user, mode });
                  return;
                }
                setDrawerState({ user, mode });
              },
              [setDrawerState, setModalState]
            )}
          />

          {cursor && hasMoreToLoad ? (
            <Box display="flex" justifyContent="center" mt={3}>
              <Button variant="outlined" color="primary" onClick={loadMore}>
                Load more
              </Button>
            </Box>
          ) : null}
        </Box>
      </Paper>
      <UserDrawer
        open={Boolean(drawerState)}
        mode={drawerState?.mode}
        onClose={useCallback(() => setDrawerState(undefined), [setDrawerState])}
        handleEvent={handleEvent}
        organization={currentOrganization}
        user={drawerState?.user}
      />
      <CreateUserDialog
        open={modalState?.mode === UserManagementMode.Create}
        handleCreate={useCallback(
          (newUser: Partial<User>) => {
            performCreate(newUser);
          },
          [performCreate]
        )}
        onClose={useCallback(() => {
          setModalState((state) => ({ ...state, mode: undefined }));
        }, [setModalState])}
      />
      <DeleteUserDialog
        open={modalState?.mode === UserManagementMode.Delete}
        handleDelete={useCallback(() => {
          performDelete(modalState?.user as User);
        }, [modalState, performDelete])}
        onClose={useCallback(() => {
          setModalState((state) => ({ ...state, mode: undefined }));
        }, [setModalState])}
        user={modalState?.user}
        organization={currentOrganization}
      />
    </Fragment>
  );
};
