import { PropsWithChildren, useState } from "react";
import "./CollapsableFiltersSection.scss";

interface Props {
  sectionTitle: string;
}

const CollapsableFiltersSection = ({
  sectionTitle,
  children,
}: PropsWithChildren<Props>) => {
  const [collapsed, setCollapsed] = useState<boolean>(false);

  return (
    <div className={`filters-section ${collapsed ? "collapsed" : ""}`}>
      <p
        className="subtitle-2 section-header"
        onClick={() => {
          setCollapsed(!collapsed);
        }}
      >
        {sectionTitle}{" "}
        <i className="material-icons md-18">{collapsed ? "add" : "remove"}</i>
      </p>
      {collapsed ? null : children}
    </div>
  );
};

export default CollapsableFiltersSection;
