import _ from "lodash";
import { MutationFunction } from "@apollo/client";
import { BlobUpload } from "@rails/activestorage/src/blob_upload";
import { FileChecksum } from "@rails/activestorage/src/file_checksum";

import { DirectUploadEnum, FileMetadataInput } from "gql/graphql";
import { promisify } from "utils/transforms";

const calculateChecksum = (file: File): Promise<string> =>
  promisify<string>(FileChecksum.create)(file);

interface UploadConfig {
  handleUploadProgress: (progress: number) => void;
  headers: Record<string, string>;
  url: string;
}

export const uploadFile = async (
  file: File,
  { url, headers, handleUploadProgress }: UploadConfig
) =>
  new Promise((resolve, reject) => {
    // TODO: Do we want to promisify?
    const upload = new BlobUpload({
      file,
      directUploadData: {
        headers,
        url,
      },
    });

    upload.xhr.upload.addEventListener(
      "progress",
      (event: ProgressEvent<XMLHttpRequestEventTarget>) => {
        const percent = (event.loaded / event.total) * 100;

        handleUploadProgress(percent);
      }
    );

    upload.create((error: Error, blob: Blob) => {
      if (error) {
        reject(error);
        return;
      } else {
        resolve(blob); // blob is an empty string
      }
    });
  });

const getFileInfo = async (file: File): Promise<FileMetadataInput> => ({
  filename: file.name,
  byteSize: file.size,
  checksum: await calculateChecksum(file),
  contentType: file.type,
});

export const getDirectUploadInfo = async ({
  mutation,
  files,
  uploadType,
}: {
  files: File[];
  mutation: MutationFunction;
  uploadType: DirectUploadEnum;
}) => {
  return await mutation({
    variables: {
      input: {
        files: await Promise.all(
          _.map(files, async (f) => {
            const fileInfo = await getFileInfo(f);
            return {
              ...fileInfo,
              type: uploadType,
            };
          })
        ),
      },
    },
  });
};
