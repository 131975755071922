import { gql } from "@apollo/client";

export const CREATE_DIRECT_UPLOAD = gql`
  mutation CreateDirectUpload($input: CreateDirectUploadInput!) {
    createDirectUpload(input: $input) {
      filesForUpload {
        id
        url
        headers
        filename
      }
    }
  }
`;
