// https://unused-css.com/blog/css-shake-animation/

import { Button, Grid, Typography } from "@mui/material";
import clsx from "clsx";
import React from "react";
import { useEffect, useRef } from "react";
import { InvalidContext } from "../Context/InvalidContext";
import _ from "lodash";
import { FieldErrors } from "react-hook-form";

import "./NavButtons.scss";

const ANIMATION_TIMEOUT_MS = 100;

// https://stackoverflow.com/a/64758985
const playAnimation = (el: HTMLElement | null) => {
  if (!window.KeyframeEffect) return;
  const effect = new KeyframeEffect(
    el, // Element to animate
    [
      // Keyframes
      { transform: "rotate(0deg)" },
      { transform: "rotate(5deg)" },
      { transform: "rotate(0deg)" },
      { transform: "rotate(-5deg)" },
      { transform: "rotate(0deg)" },
    ],
    { duration: ANIMATION_TIMEOUT_MS, direction: "alternate", easing: "linear" } // Keyframe settings
  );

  const animation = new Animation(effect, document.timeline);

  animation.play();
};

interface NavButtonsProps {
  tabsCount: number;
  value: number;
  afterAnimation?: () => void;
  disabled?: boolean;
  errors?: FieldErrors<any>;
  prev?: () => void;
  submitting?: boolean;
  submittingLabel?: string;
}

export const NavButtons = ({
  value,
  prev,
  tabsCount,
  disabled = false,
  submitting = false,
  submittingLabel = "Creating project...",
  afterAnimation = _.noop,
  errors = {},
}: NavButtonsProps) => {
  const nextRef = useRef<HTMLButtonElement | null>(null);
  const { date } = React.useContext(InvalidContext);
  useEffect(() => {
    let timeout: _.DebouncedFunc<() => void> | undefined;
    if (date) {
      playAnimation(nextRef.current);
      timeout = _.debounce(() => {
        afterAnimation();
      }, ANIMATION_TIMEOUT_MS);
      timeout();
    }
    return () => timeout?.cancel?.();
  }, [date, afterAnimation]);
  return (
    <Grid
      container
      id="nav-buttons"
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <Grid item className="left">
        {prev && (
          <Button
            disableElevation
            disabled={disabled}
            variant="text"
            className={clsx("back-button", { visible: value > 1 })}
            onClick={prev}
          >
            Back
          </Button>
        )}
      </Grid>
      <Grid item className="right">
        <Grid container flexDirection="row" alignItems="center">
          {errors?.root?.message && (
            <Grid item pr={2}>
              <Typography color="error" variant="body2">
                {errors.root.message}
              </Typography>
            </Grid>
          )}
          <Grid item>
            {value === tabsCount ? ( // Last page
              <Button
                disableElevation
                type="submit"
                disabled={disabled}
                ref={nextRef}
                variant="contained"
                className={clsx(["submit", "visible"])}
              >
                {submitting ? submittingLabel : "Finish and close"}
              </Button>
            ) : (
              <Button
                disableElevation
                type="submit"
                disabled={disabled}
                ref={nextRef}
                variant="contained"
                className={clsx("next", {
                  visible: value < tabsCount,
                })}
              >
                {submitting ? submittingLabel : "Next"}
              </Button>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default NavButtons;
