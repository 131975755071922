import React from "react";
import { SUPPORT_EMAIL_ADDRESS } from "../constants";
import "./ReachOutLink.scss";

const ReachOutLink = () => (
  <p className="reach-out">
    <span>Does something seem off?</span>
    <br />
    <a href={`mailto:${SUPPORT_EMAIL_ADDRESS}`}>Contact our team.</a>{" "}
    <i className="material-icons md-18">arrow_forward</i>
  </p>
);

export default ReachOutLink;
