import { Grid, Popper, PopperProps } from "@mui/material";
import { GridColumnHeaderTitle } from "@mui/x-data-grid";
import { ReactNode, useRef } from "react";
import { PopperComponentProps, useHeaderPopper } from "../Tooltips/hooks";

import "./HeaderTitleWithCaption.scss";

const CustomPopper = (props: PopperComponentProps & PopperProps) => (
  <Popper {...props} />
);

export const HeaderTitleWithCaption = ({
  label,
  caption,
  columnWidth,
  tooltipElementType = CustomPopper,
}: {
  caption: ReactNode;
  columnWidth: number;
  label: string;
  tooltipElementType?: React.ElementType<PopperComponentProps>;
}) => {
  const headerRef = useRef(null);
  const popper = useHeaderPopper(headerRef, tooltipElementType);
  return (
    <>
      <Grid
        ref={headerRef}
        className="column-header"
        container
        flexDirection="column"
      >
        <Grid key="label" item className="label">
          <GridColumnHeaderTitle label={label} columnWidth={columnWidth} />
        </Grid>
        <Grid key="caption" item className="caption">
          {caption}
        </Grid>
      </Grid>
      {popper}
    </>
  );
};
