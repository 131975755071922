import { gql } from "@apollo/client";
import {
  PAGINATION_FRAGMENT,
  PRODUCT_CARD_FRAGMENT,
  PROJECT_FILES_FRAGMENT,
  PROJECT_FRAGMENT,
} from "../fragments";

export const GET_PROJECT_DETAILS = gql`
  ${PRODUCT_CARD_FRAGMENT}
  ${PROJECT_FRAGMENT}
  ${PAGINATION_FRAGMENT}
  query GetProjectDetails(
    $slug: String!
    $first: Int
    $last: Int
    $after: String
    $before: String
  ) {
    project(slug: $slug) {
      ...ProjectFragment
      products(first: $first, last: $last, after: $after, before: $before) {
        totalCount
        edges {
          node {
            ...ProductCardFragment
          }
        }
        pageInfo {
          ...PaginationFragment
        }
      }
      address {
        fullAddress
        state
        postalCode
        street1
        countryCode
        stateCode
      }
    }
  }
`;

export const GET_PROJECTS = gql`
  ${PAGINATION_FRAGMENT}
  ${PROJECT_FRAGMENT}
  query GetProjects(
    $filter: ProjectFilterInput
    $first: Int
    $last: Int
    $after: String
    $before: String
    $areaUnit: AreaUnit
    $sort: ProjectSortInput
  ) {
    projects(
      filter: $filter
      first: $first
      last: $last
      after: $after
      before: $before
      areaUnit: $areaUnit
      sort: $sort
    ) {
      nodes {
        dataFidelity
        ...ProjectFragment

        products {
          totalCount
        }

        report {
          carbon {
            total {
              perArea {
                kgCo2e
                unit
              }
              reduction {
                relative
              }
            }
          }
        }
      }

      pageInfo {
        ...PaginationFragment
      }
      totalCount
    }
  }
`;

export const PROJECT_PRODUCTS_COUNT = gql`
  query ProjectProductsCount($slug: String!) {
    project(slug: $slug) {
      products {
        totalCount
      }
    }
  }
`;

const SCENARIO_SWAP_FRAGMENT = gql`
  fragment ScenarioSwapFragment on RecommendationScenarioSwap {
    node {
      ... on Product {
        id
        name
        slug
      }
    }
    environmentalImpact {
      carbon {
        comparedToBaseline {
          ratioToBaseline
        }
        declaredUnit
        kgCo2e {
          perUnit
        }
      }
    }
    geography {
      name
    }
  }
`;

export const GET_PROJECT_RECOMMENDATIONS = gql`
  query GetProjectRecommendations($slug: String!) {
    project(slug: $slug) {
      slug
      recommendations {
        asyncStatus {
          status
        }
        totalCount
        edges {
          reason {
            message
          }
          node {
            id
            title
            scenarios {
              totalCount
              nodes {
                id
                title
                carbon {
                  perArea {
                    unit
                    kgCo2e
                  }
                  reduction {
                    relative
                  }
                }
                swaps {
                  from {
                    ...ScenarioSwapFragment
                  }

                  to {
                    ...ScenarioSwapFragment
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${SCENARIO_SWAP_FRAGMENT}
`;

export const GET_PROJECT_DOCUMENTS = gql`
  query GetProjectFiles($slug: String!) {
    project(slug: $slug) {
      slug
      ...ProjectFilesFragment
    }
  }
  ${PROJECT_FILES_FRAGMENT}
`;
