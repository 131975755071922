import {
  ApolloClient,
  FetchResult,
  useMutation,
  useQuery,
} from "@apollo/client";
import {
  AreaUnit,
  CreateProjectPayload,
  InputMaybe,
  Project,
  ProjectCreationInput,
  ProjectStage,
  ProjectStructureTypeEnum,
  ProjectUpdateInput,
  PropertyUse,
  UserError,
} from "gql/graphql";
import _ from "lodash";
import mapValuesDeep, { TypedObject } from "map-values-deep";
import { useCallback, useMemo } from "react";
import { UseFormSetError } from "react-hook-form";
import { formToInput } from "./utils";
import { CREATE_PROJECT } from "graphql/mutations/projects";
import { GuidedProjectCache } from "./GuidedProjectCache";
import { DISABLE_DEFAULT_GRAPHQL_ERROR_HANDLING } from "ApolloClient/links";
import { GET_PROJECTS } from "graphql/queries/projects";

type FormMaybe<T> = InputMaybe<T> & "";

export const defaultFormValues = {
  // Page 1
  stage: "" as FormMaybe<ProjectStage>,
  startedAt: "",
  completedAt: "",
  // Page 2
  aboveGroundFloors: {
    areaPerFloor: {
      area: "" as FormMaybe<number>,
      units: AreaUnit.Ft2,
    },
    count: "" as FormMaybe<number>,
  },
  belowGroundFloors: {
    areaPerFloor: {
      area: "" as FormMaybe<number>,
      units: AreaUnit.Ft2,
    },
    count: "" as FormMaybe<number>,
  },
  parkingFloors: {
    areaPerFloor: {
      area: "" as FormMaybe<number>,
      units: AreaUnit.Ft2,
    },
    count: "" as FormMaybe<number>,
  },

  structureType: "" as FormMaybe<ProjectStructureTypeEnum>,
  propertyUse: "" as FormMaybe<PropertyUse>,
  // Page 3
  name: "",
  description: "",
  address: {
    postalCode: "",
    city: "",
    stateCode: "",
    countryCode: "",
    street1: "",
    street2: "",
    latitude: "",
    longitude: "",
  },
  // Other
  includeInPortfolio: true,
};

export function useDefaultValues(
  values: TypedObject<Project>,
  defaults = defaultFormValues
) {
  const toMerge = useMemo(
    () =>
      mapValuesDeep(values, (value: Object | string | null | number) => {
        return _.isNull(value) ? "" : value;
      }),
    [values]
  );
  return _.merge(_.cloneDeep(defaults), toMerge);
}

export function useSetServerErrors<T>(
  setError: UseFormSetError<Partial<T>>,
  key: "root" = "root",
  prefix = "Error"
) {
  return useCallback(
    (errors: Error[] | UserError[]) => {
      const message = _.get(errors, "[0].message", "Please try again later.");

      setError(key, {
        message: `${prefix}: ${message}`,
      });
    },
    [key, prefix, setError]
  );
}

export function useSubmit() {
  const [mutation] = useMutation(CREATE_PROJECT, {
    context: { [DISABLE_DEFAULT_GRAPHQL_ERROR_HANDLING]: true },
    refetchQueries: [GET_PROJECTS],
  });

  return useCallback(
    async (data: ProjectCreationInput) => {
      const result: FetchResult<{
        data: { project: ProjectCreationInput[] };
      }> = await mutation({
        variables: {
          input: {
            project: {
              ...formToInput(data),
            },
          },
        },
      });
      const mutationResponses: CreateProjectPayload[] = _.values(
        _.get(result, "data.project")
      );
      return _.find(
        mutationResponses,
        (project) => (project as CreateProjectPayload).errors
      ) as CreateProjectPayload;
    },
    [mutation]
  );
}

export function useCache<T>(client: ApolloClient<any>) {
  const { data: cachedData } = useQuery<{ project: Project }>(
    GuidedProjectCache.query,
    {
      variables: { slug: GuidedProjectCache.slug },
      fetchPolicy: "cache-only",
    }
  );
  const cache = useMemo(() => new GuidedProjectCache(client), [client]);
  const setCachedData = useCallback(
    (values: Partial<T>) => {
      cache.save(values);
    },
    [cache]
  );

  return { cache, cachedData, setCachedData };
}

export function determineHighestValidTab(values: ProjectUpdateInput) {
  const {
    stage,
    startedAt,
    completedAt,
    structureType,
    propertyUse,
    address,
    aboveGroundFloors,
    name,
  } = values;
  const pageValues = {
    1: [stage, startedAt, completedAt],
    2: [
      structureType,
      propertyUse,
      aboveGroundFloors?.count,
      aboveGroundFloors?.areaPerFloor?.area,
      aboveGroundFloors?.areaPerFloor?.units,
    ],
    3: [name, address?.postalCode],
  };
  if (_.some(pageValues[1], _.isEmpty)) return 1;
  if (_.some(pageValues[2], _.isEmpty)) return 2;
  if (_.some(pageValues[3], _.isEmpty)) return 3;
  return 1;
}

export const scrollToHiddenInvalidError = ({
  bottomOffset = 85,
  selector = "form .custom-error-messages .MuiTypography-root",
}: {
  bottomOffset?: number;
  selector?: string;
}) => {
  const errorElements = document.querySelectorAll(selector);
  const hiddenElements = _.compact(
    _.filter(errorElements, (element) => {
      // if the element is not visible, scroll to it
      if (!element.scrollIntoView) return;
      const rect = element.getBoundingClientRect();
      if (rect.top < 0 || rect.bottom + bottomOffset > window.innerHeight) {
        return element;
      }
    })
  );
  if (hiddenElements.length === errorElements.length) {
    return errorElements[0]?.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  }
};
